const state = {
    isMenuVisible : true,
    isToggleMenuVisible : true,
    isUserDropDownVisible : true,
    renderMenuNumber : 1,

    usuarioLogadoDados : {id : 0, profissionalTipoId : 0, nome : "", email : ""},
    usuarioLogadoAutenticacao : {id : null, accessToken : null, expires_in : 0},

    instanciaBancoDeDados : 0,

    menuOptions : [],
    unformatedMenuOptions : [],
    grauPermissaoMinimo : 900,

    assinanteSimpro : "N",
    assinanteBrasindice : "N",
}

const getters ={
    getMenu(state) {
        return state.isMenuVisible;
    },

    getToggleMenu(state) {
        return state.isToggleMenuVisible;
    },

    getUserDropDown(state) {
        return state.isUserDropDownVisible;
    },

    getUsuarioLogadoDados(state) {
        return state.usuarioLogadoDados;
    },

    getUsuarioLogadoAutenticacao(state) {
        return state.usuarioLogadoAutenticacao;
    },

    getMenuOptions(state) {
        return state.menuOptions;
    },

    getUnformatedMenuOptions(state) {
        return state.unformatedMenuOptions;
    },

    getAssinanteSimpro(state) {
        return state.assinanteSimpro
    },

    getAssinanteBrasindice(state) {
        return state.assinanteBrasindice
    },

    getRenderMenuNumber(state) {
        return state.renderMenuNumber;
        
    },

    getInstanciaBancoDeDados(state) {
        return state.instanciaBancoDeDados;
    }
}

const mutations = {
    toggleMenu(state, isVisible) {
        if (isVisible === undefined) {
            state.isToggleMenuVisible = !state.isToggleMenuVisible
        } else {
            state.isToggleMenuVisible = isVisible;

            if (!isVisible) {
                state.isMenuVisible = false;
            }
        }
    },

    menu(state, isVisible) {
        if (isVisible === undefined) {
            state.isMenuVisible = !state.isMenuVisible
        } else {
            state.isMenuVisible = isVisible;
        }
    },

    userDropDown(state, isVisible) {
        state.isUserDropDownVisible = isVisible;
    },

    //logIn(state, user) {
    //    state.userLogado = {...user}
    //},
//
    //logOff(state) {
    //    state.userLogado = {}
    //},

    setUsuarioLogadoDados(state, dados) {
        state.usuarioLogadoDados = {...dados}
    },

    setUsuarioLogadoAutenticacao(state, autenticacao) {
        state.usuarioLogadoAutenticacao = {...autenticacao}
    },

    setMenuOptions(state, menu) {
        state.menuOptions = [...menu];
    },
    
    setUnformatedMenuOptions(state, menu) {
        state.unformatedMenuOptions = [...menu];
    },

    setAssinanteSimpro(state, valor) {
        state.assinanteSimpro = valor;
    },

    setAssinanteBrasindice(state, valor) {
        state.assinanteBrasindice = valor;
    },

    setRenderMenuNumber(state, valor) {
        state.renderMenuNumber += valor;
    },

    setInstanciaBancoDeDados(state, valor) {
        state.instanciaBancoDeDados = valor;
    }
}

const actions = {
    toggleMenu({commit}, isVisible) {
        commit("toggleMenu", isVisible);
    },

    menu({commit}, isVisible) {
        commit("menu", isVisible);
    },

    userDropDown({commit}, isVisible) {
        commit("userDropDown", isVisible);
    },

    //logIn({commit}, user) {
    //    commit("logIn", user);
    //},
//
    //logOff({commit}) {
    //    commit("logOff");
    //},

    setUsuarioLogadoDados({commit}, dados) {
        commit("setUsuarioLogadoDados", dados);
    },

    setUsuarioLogadoAutenticacao({commit}, autenticacao) {
        commit("setUsuarioLogadoAutenticacao", autenticacao);
    },

    setMenuOptions({commit}, menu, unformated) {
        commit("setMenuOptions", menu, unformated)
    },

    setUnformatedMenuOptions({commit}, menu) {
        commit("setUnformatedMenuOptions", menu)
    },

    setAssinanteSimpro({commit}, valor) {
        commit("setAssinanteSimpro", valor)
    },

    setAssinanteBrasindice({commit}, valor) {
        commit("setAssinanteBrasindice", valor)
    },

    setRenderMenuNumber({commit}, valor) {
        commit("setRenderMenuNumber", valor);
    },

    setInstanciaBancoDeDados({commit}, valor) {
        commit("setInstanciaBancoDeDados", valor);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
