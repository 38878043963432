<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Guia de SADT - {{guiaSelecionada.paciente}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <!-- <div class="row justify-content-end"> -->
      <!-- </div> -->
      <div class="row">
        <div class="col-lg-1 flex-center">
            <label for="registroANS" class="control-label"><strong>1</strong>- Registro ANS</label><br>
            <span id="registroANS" class="control-label">{{guiaSelecionada.registroAns}}</span>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaPrestador" class="control-label"><strong>2</strong>- Nº Guia no Prestador</label>
            <input type="text" id="guiaPrestador" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaPrestador"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaPrincipal" class="control-label"><strong>3</strong>- Nº Guia Principal</label>
            <input type="text" id="guiaPrincipal" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaPrincipal"/>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="datAutorizacao" class="control-label"><strong>4</strong>- Data Autorização</label><br>
            <input type="date" id="datAutorizacao" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datAutorizacao"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="senhaAutorizacao" class="control-label"><strong>5</strong>- Senha Autorização</label><br>
            <input type="text" id="senhaAutorizacao" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.senhaAutorizacao"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="datValidadeSenha" class="control-label"><strong>6</strong>- Validade Senha</label><br>
            <input type="date" id="datValidadeSenha" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datValidadeSenha"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaOperadora" class="control-label"><strong>7</strong>- Nº Guia atribuído pela Operadora</label>
            <input type="text" id="guiaOperadora" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaOperadora"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="datAtendimento" class="control-label">Data do Atendimento</label>
            <input type="date" id="datAtendimento" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datAtendimento"/>
        </div>
        <div class="col-lg-2">
            <label for="profissionalExecutante" class="control-label">Profissional Executante</label>
            <select id="profissionalExecutante" class="form-control" 
            :disabled="!liberarCampos" v-model="guiaSelecionada.profissionalExecutanteId" selected @change="selecionouProfissionalExecutante()">
                <option v-for="profissional in profissionaisExecutantes" :key="profissional.id" :value="profissional.id">
                    {{profissional.nome}}
                </option>
            </select>
        </div>
      </div>

      <!---------------------------------------- Dados do Beneficiário  -------------------------------->
      <p class="msgGrupo">Dados do Beneficiário</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="carteirinha" class="control-label"><strong>8</strong>- Nº da Carteirinha</label>
            <input type="text" id="carteirinha" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.carteirinha"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="datValidadeCarteirinha" class="control-label"><strong>9</strong>- Validade da Carteirinha</label>
            <input type="date" id="datValidadeCarteirinha" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datValidadeCarteirinha"/>
        </div>
        <div class="col-lg-3 flex-center">
            <label for="nomePaciente" class="control-label"><strong>10</strong>- Nome</label><br>
            <span id="nomePaciente" class="control-label">{{guiaSelecionada.paciente}}</span>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="CNS" class="control-label"><strong>11</strong>- Cartão Nacional de Saúde</label><br>
            <span id="CNS" class="control-label">{{guiaSelecionada.cnsNumero}}</span>
        </div>
        <div class="col-lg-2">
            <label for="atendimentoRN" class="control-label"><strong>12</strong>- Atendimento RN</label>
            <select id="atendimentoRN" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.atendimentoRn" selected>
                <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                  {{resposta.valor}}
                </option>
            </select>
        </div>
      </div>
      <!---------------------------------------- Dados do Solicitante  -------------------------------->
      <p class="msgGrupo">Dados do Solicitante</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="COP" class="control-label"><strong>13</strong>- Código na Operadora</label><br>
            <span id="COP" class="control-label">{{contratadoOperadoraSolicitante.numero}}</span>
        </div>

        <div class="col-lg-3">
            <label for="contratadoSolicitante" class="control-label"><strong>14</strong>- Nome do Contratado</label>
            <select id="contratadoSolicitante" class="form-control" 
              :disabled="!liberarCampos" v-model="guiaSelecionada.contratadoSolicitanteId" selected @change="selecionouContratadoSolicitante()">
                <option v-for="contratado in contratados" :key="contratado.id" :value="contratado.id">
                    {{contratado.nome}}
                </option>
            </select>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2">
            <label for="profissionalSolicitante" class="control-label"><strong>15</strong>- Profissional Solicitante</label>
            <select id="profissionalSolicitante" class="form-control" :disabled="!liberarCampos" 
                v-model="guiaSelecionada.profissionalSolicitanteId" selected @click="selecionouProfissionalSolicitante()">
                <option v-for="profissional in profissionaisSolicitantes" :key="profissional.id" :value="profissional.id">
                    {{profissional.nome}}
                </option>
            </select>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="conselhoProfissional" class="control-label"><strong>16</strong>- Conselho Profissional</label><br>
            <span id="conselhoProfissional" class="control-label">{{conselho.codigo}}</span>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="numeroConselho" class="control-label"><strong>17</strong>- Número do Conselho</label><br>
            <span id="numeroConselho" class="control-label">{{profissionalSolicitante.conselhoNumero}}</span>
        </div>
        <div class="col-lg-1 flex-center">
            <label for="ufConselho" class="control-label"><strong>18</strong>- UF</label><br>
            <span id="ufConselho" class="control-label">{{profissionalSolicitante.conselhoUf}}</span>
        </div>
        <div class="col-lg-1 flex-center">
            <label for="cbos" class="control-label"><strong>19</strong>- CBOS</label><br>
            <span id="cbos" class="control-label">{{profissionalSolicitante.cbos}}</span>
        </div>
      </div>
      <!------------ Dados da Solicitação / Procedimentos ou Itens Assistenciais Solicitados  --------->
      <p class="msgGrupo">Dados da Solicitação / Procedimentos ou Itens Assistenciais Solicitados</p>

      <div class="row">
        <div class="col-lg-2">
            <label for="caraterAtendimento" class="control-label"><strong>21</strong>- Caráter do Atendimento</label>
            <select id="caraterAtendimento" class="form-control" 
              :disabled="!liberarCampos" v-model="guiaSelecionada.tissCaraterAtendimento" selected>
                <option v-for="resposta in caraterAtendimento" :key="resposta.opcao" :value="resposta.opcao">
                    {{resposta.opcao}}-{{resposta.valor}}
                </option>
            </select>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="datSolicitacao" class="control-label"><strong>22</strong>- Data Solicitação</label>
            <input type="date" id="datSolicitacao" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datSolicitacao"/>
        </div>
        <div class="col-lg-8 flex-center">
            <label for="indicacaoClinica" class="control-label"><strong>23</strong>- Indicação Clínica</label>
            <input type="text" id="indicacaoClinica" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.indicacaoClinica"/>
        </div>
      </div>
      <!---------------------------------------- Dados do Contratado Executante  -------------------------------->
      <!--
      <p class="msgGrupo">Dados do Contratado Executante</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="COP" class="control-label"><strong>29</strong>- Código na Operadora</label><br>
            <span id="COP" class="control-label">{{contratadoOperadoraExecutante.numero}}</span>
        </div>

        <div class="col-lg-3">
            <label for="contratadoExecutante" class="control-label"><strong>30</strong>- Nome do Contratado</label>
            <select id="contratadoExecutante" class="form-control" 
              :disabled="!liberarCampos" v-model="guiaSelecionada.contratadoExecutanteId" selected @change="selecionouContratadoExecutante()">
                <option v-for="contratado in contratados" :key="contratado.id" :value="contratado.id">
                    {{contratado.nome}}
                </option>
            </select>
        </div>

        <div class="col-lg-1 flex-center">
            <label for="CNES" class="control-label"><strong>31</strong>- Código CNES</label><br>
            <span id="CNES" class="control-label">{{contratadoExecutante.cnes}}</span>
        </div>
      </div>
      -->
      <!---------------------------------------- Dados do Atendimento / Procedimento Realizado  -------------------------------->
      <p class="msgGrupo">Dados do Atendimento / Procedimento Realizado</p>

      <div class="row">
        <div class="col-lg-2">
            <label for="codAtendimento" class="control-label"><strong>32</strong>- Tipo Atendimento</label>
            <select id="codAtendimento" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.tissCodAtendimento" selected>
                <option v-for="resposta in codAtendimento" :key="resposta.opcao" :value="resposta.opcao">
                 {{resposta.opcao}}-{{resposta.valor}}
                </option>
            </select>
        </div>
        <div class="col-lg-3">
            <label for="indicadorAcidente" class="control-label"><strong>33</strong>- Indicação Acidente</label>
            <select id="indicadorAcidente" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.tissCodIndicadorAcidente" selected>
                <option v-for="resposta in indicadorAcidente" :key="resposta.opcao" :value="resposta.opcao">
                  {{resposta.opcao}}-{{resposta.valor}}
                </option>
            </select>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
            <label for="observacao" class="control-label"><strong>58</strong>- Observação / Justificativa</label>
            <textarea id="observacao" class="form-control" rows="2" cols="180" :disabled="!liberarCampos" v-model="guiaSelecionada.tissObservacao"/>
        </div>
      </div>
      <!---------------------------------------- Botões Cancelar / Confirmar  -------------------------------->
      <br>
      <template v-if="liberarCampos">
          <div class="row justify-content-center">
            <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
              @click="cancelarOperacao()">Cancelar
            </button>
            <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
              @click="gravar()">Confirmar
            </button>
          </div>
      </template>
      <template v-else>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Fechar
          </button>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import profissionais from "@/mixins/profissionais"
import contratados from "@/mixins/contratados"
import contratadoOperadoras from "@/mixins/contratadoOperadoras"
import cboss from "@/mixins/cboss";
import conselhos from "@/mixins/conselhos"
import guias from "@/mixins/guias";

import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import tiss from "@/utils/tiss";

export default {
    mixins : [profissionais, contratados, contratadoOperadoras, cboss, conselhos, guias, global],
    computed: {
        ...mapGetters({
            guiaSelecionada : "guias/getGuiaSelecionada",
            loteSelecionado : "lotes/getLoteSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarCampos() {
            return this.loteSelecionado.datFechamento == null;
        }
    },
    data() {
        return {
            erro : "",
            sim_nao : constantes.sim_nao,
            indicadorAcidente : tiss.indicadorAcidente,
            caraterAtendimento : tiss.caraterAtendimento,
            codAtendimento : tiss.codAtendimento,
            contratadoOperadoraSolicitante : {},
            contratadoOperadoraExecutante : {},
            contratadoSolicitante : {},
            profissionalExecutante : {},
            profissionalSolicitante : {},
            cbos : {},
            conselho : {},
            profissionaisExecutantes : [],
            profissionaisSolicitantes : []
        }
    },
    methods: {
        ...mapActions({
            adicionarAlteracoes : "faturamentos/adicionarAlteracoes",
            setUltimaGuiaSalva : "guias/setUltimaGuiaSalva",
        }),
        retornar() {
            this.$router.go(-1);
        },
        cancelarOperacao() {
            this.retornar();
        },
        selecionouContratadoSolicitante() {
            this.contratadoSolicitante = this.contratados.filter(c => c.id == this.guiaSelecionada.contratadoSolicitanteId)[0];
            this.carregarContratadoOperadora(this.usuarioLogadoDados.tenant, 'S', this.contratadoSolicitante.id, this.guiaSelecionada.operadoraId);
        },
        //selecionouContratadoExecutante() {
        //    this.contratadoExecutante = this.contratados.filter(c => c.id == this.guiaSelecionada.contratadoExecutanteId)[0];
        //    this.carregarContratadoOperadora(this.usuarioLogadoDados.tenant, 'E',this.contratadoExecutante.id, this.guiaSelecionada.operadoraId);
        //},
        selecionouProfissionalExecutante() {
            this.profissionalExecutante = this.profissionaisExecutantes.filter(p => p.id == this.guiaSelecionada.profissionalExecutanteId)[0];
        },
        selecionouProfissionalSolicitante() {
            this.profissionalSolicitante = this.profissionaisSolicitantes.filter(p => p.id == this.guiaSelecionada.profissionalSolicitanteId)[0];
        },
        gravar() {
            this.gravarGuiaFaturamento(this.usuarioLogadoDados.tenant);
        },
        verificarCampos() {
            if (this.guiaSelecionada.guiaPrestador==null) {
                this.$toasted.show("Informe o Número da Guia no Prestador", {type : "info", icon: "times"});
                return false;
            }
            
            if (this.guiaSelecionada.guiaPrincipal==null) {
                this.$toasted.show("Informe o Número da Guia Principal", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.guiaOperadora==null) {
                this.$toasted.show("Informe o Número da Guia na Operadora", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.carteirinha==null) {
                this.$toasted.show("Informe o Número da Carteirinha", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.atendimentoRn==null) {
                this.$toasted.show("Informe Atendimento RN", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.contratadoSolicitanteId==null) {
                this.$toasted.show("Informe o Contratado Solicitante", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.profissionalSolicitanteId==null) {
                this.$toasted.show("Informe o Profissional Solicitante", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.tissCaraterAtendimento==null) {
                this.$toasted.show("Informe Carater do Atendimento", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.datSolicitacao==null) {
                this.$toasted.show("Informe data da Solicitação", {type : "info", icon: "times"});
                return false;
            }

            //if (this.guiaSelecionada.contratadoExecutanteId==null) {
            //    this.$toasted.show("Informe o Contratado Executante", {type : "info", icon: "times"});
            //    return false;
            //}

            if (this.guiaSelecionada.tissCodAtendimento==null) {
                this.$toasted.show("Informe Tipo Atendimento", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.tissCodIndicadorAcidente==null) {
                this.$toasted.show("Informe Indicação de Acidente", {type : "info", icon: "times"});
                return false;
            }

            return true;
        }
    },
    async created() {
        this.showMenu(false);
        await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisExecutantes = [...this.profissionais];
        this.profissionais = [];

        await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisSolicitantes = [...this.profissionais];
        this.profissionais = [];

        if (this.guiaSelecionada.profissionalSolicitanteId > 0) {
            this.selecionouProfissionalSolicitante();
        }

        await this.carregarContratados(this.usuarioLogadoDados.tenant);

        if (this.guiaSelecionada.contratadoSolicitanteId > 0) {
            this.selecionouContratadoSolicitante();
        }
    },
    beforeRouteLeave (to, from, next) {
        next(true);
    }
}
</script>

<style scoped>
    .msgGrupo {
      background-color:green;
      color: black;
      text-align: left;
      padding-left: 5px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>