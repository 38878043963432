<template>
  <div id="CadOperadoraFaturamento">
    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="TabelaMedicamentos" class="control-label">Tabela Medicamentos:</label>
          <select
            id="TabelaMedicamentos"
            class="form-control"
            :disabled="!liberarCampos"
            v-model="operadoraSelecionada.lanctoCodTabelaMedicamentos"
          >
            <option
              v-for="tabela in tabelasDespesas"
              :key="tabela.opcao"
              :value="tabela.opcao"
            >{{tabela.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-4">
          <label for="TabelaMateriais" class="control-label">Tabela Materiais:</label>
          <select
            id="TabelaMateriais"
            class="form-control"
            :disabled="!liberarCampos"
            v-model="operadoraSelecionada.lanctoCodTabelaMateriais"
          >
            <option
              v-for="tabela in tabelasDespesas"
              :key="tabela.opcao"
              :value="tabela.opcao"
            >{{tabela.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="PermiteRH" class="control-label">Permite RH:</label>
          <select
            id="PermiteRH"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.permiteRestritoHospitalar"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="CBHPMEdicao" class="control-label">CBHPM Edição/Porte:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CBHPMEdicao"
            name="CBHPMEdicao"
            v-model="operadoraSelecionada.cbhpmEdicao"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="CoefMaterial" class="control-label">% Coef.Material:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CoefMaterial"
            name="CoefMaterial"
            v-model="operadoraSelecionada.coefMaterial"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="CoefMedicamento" class="control-label">% Coef.Medicamento (PMC):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CoefMedicamento"
            name="CoefMedicamento"
            v-model="operadoraSelecionada.coefMedicamento"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="CoefMedicamentoRH" class="control-label">% Coef.Medicamento (RH):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CoefMedicamentoRH"
            name="CoefMedicamentoRH"
            v-model="operadoraSelecionada.coefMedicamentoRh"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="CoefMedicamentoPFB" class="control-label">% Coef.Medicamento (PFB):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CoefMedicamentoPFB"
            name="CoefMedicamentoPFB"
            v-model="operadoraSelecionada.coefMedicamentoPfb"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="UtilizaCodTuss" class="control-label">Utiliza Cod.TUSS:</label>
          <select
            id="UtilizaCodTuss"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.utilizaCodigoTuss"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="RetornoPorCBOS" class="control-label">Retorno por CBOS:</label>
          <select
            id="RetornoPorCBOS"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.retornoPorCbos"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="IncluirDespesasQtdeZero" class="control-label">Incluir Despesa Qtde Zero:</label>
          <select
            id="IncluirDespesasQtdeZero"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.incluirDespesaQtdeZero"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="TipoGuiaConsulta" class="control-label">Tipo Guia Consulta:</label>
          <select
            id="TipoGuiaConsulta"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.tipoGuiaAutoConsulta"
          >
            <option
              v-for="tipoLote in tiposLotes"
              :key="tipoLote.opcao"
              :value="tipoLote.opcao"
            >{{tipoLote.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="ConsultaPorPlanoPadrao" class="control-label">Buscar Consulta Plano Padrão:</label>
          <select
            id="ConsultaPorPlanoPadrao"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.consultaPorPlanoPadrao"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="ValorCobradoIncPortes" class="control-label">Valor Cobrado + Porte:</label>
          <select
            id="ValorCobradoIncPortes"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.valorCobradoIncPortes"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="ValorCobradoIncFilmes" class="control-label">Valor Cobrado + Filme:</label>
          <select
            id="ValorCobradoIncFilmes"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.valorCobradoIncFilmes"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="BuscarDespesasProprias" class="control-label">Busca Despesas Próprias:</label>
          <select
            id="BuscarDespesasProprias"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.buscarDespesasProprias"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="PrecosCongelados" class="control-label">Preços Congelados:</label>
          <select
            id="PrecosCongelados"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.precosCongelados"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import tabelaProcedimentos from "@/mixins/tabelaProcedimentos"

export default {
  mixins : [tabelaProcedimentos],
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      listaTabelas : "tabelaProcedimentos/getAllTabelas",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      tiposLotes: constantes.tiposLotes,
      erro : "",
      tabelasDespesas : constantes.tabelasDespesas,
    }
  },
  methods: {
    ...mapActions({
      setTabelas : "tabelaProcedimentos/setTabelas"
    }),
    //carregarTabelasProcedimentos() {
    //  this.$http
    //    .get("/tabelaprocedimentos")
    //    .then(resp => {
    //      this.setTabelas(resp.data);
    //      this.erro = "";
    //    })
    //    .catch(error => (this.erro = error))
    //    .finally(() => {
    //      this.loading = false;
    //    });
    //}
  },
  created() {
    this.carregarTabelasProcedimentos(0);
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>