const state = {
    origemRecebimento : {},
    recebimentoSelecionado : {}
}

const getters = {
    getOrigemRecebimento(state) {
        return state.origemRecebimento;
    },
    getRecebimentoSelecionado(state) {
        return state.recebimentoSelecionado;
    }
}

const mutations = {
    setOrigemRecebimento(state, origem) {
        state.origemRecebimento = {...origem}
    },
    setRecebimentoSelecionado(state, recebimento) {
        state.recebimentoSelecionado = {...recebimento};
    }
}

const actions = {
    setOrigemRecebimento({commit}, origem) {
        commit("setOrigemRecebimento", origem)
    },
    setRecebimentoSelecionado({commit}, recebimento) {
        commit("setRecebimentoSelecionado", recebimento);
    },
    formLeave({commit}) {
        commit("setOrigemRecebimento", null);
        commit("setRecebimentoSelecionado", null);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
