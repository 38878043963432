<template>
  <div id="CadOperadoraFinanceiro">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="GerarControleRecebimento" class="control-label">Gerar Controle Recebimento:</label>
          <select
            id="GerarControleRecebimento"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.gerarControleRecebimento"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="ImpSobreResidual" class="control-label">Imposto Sobre Residual:</label>
          <select
            id="ImpSobreResidual"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.impSobreResidual"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-3">
          <label for="ImpTotalMenosGlosa" class="control-label">Imposto Sobre Total Fatura - Glosa:</label>
          <select
            id="ImpTotalMenosGlosa"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.impTotalMenosGlosa"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao
    }
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>