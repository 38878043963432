<template>
  <div class="frmProfissionaisExecutantes">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Profissionais Executantes</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="profissionalSelecionado.id" />
          </div>

          <div class="form-group col-lg-4">
            <label for="Nome" class="control-label">Nome:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Nome"
              name="Nome"
              v-model="profissionalSelecionado.nome"
              aria-describedby="NomeHelp"
            />
            <small id="NomeHelp" class="form-text text-muted">Nome completo do Profissional.</small>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label for="cbos" class="control-label">CBOS:</label>
            <select
              id="cbos"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalSelecionado.cbosId"
            >
              <option v-for="cbos in cboss" :key="cbos.id" :value="cbos.id">{{cbos.codigo}} - {{cbos.descricao}}</option>
            </select>
          </div>

          <div class="form-group col-lg-4">
            <label for="conselho" class="control-label">Conselho:</label>
            <select
              id="conselho"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalSelecionado.conselhoId"
            >
              <option
                v-for="conselho in conselhos"
                :key="conselho.id"
                :value="conselho.id"
              >{{conselho.codigo}} - {{conselho.descricao}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="conselhoNumero" class="control-label">Número Conselho:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="conselhoNumero"
              name="conselhoNumero"
              v-model="profissionalSelecionado.conselhoNumero"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="conselhoUf" class="control-label">UF:</label>
            <select
              id="conselhoUf"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalSelecionado.conselhoUf"
            >
              <option v-for="uf in ufs" :key="uf.opcao" :value="uf.opcao">{{uf.valor}}</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger ml-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="modo='Alterar'"
            class="btn btn-sm btn-secondary ml-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Gravar</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:550px;overflow:auto">
        <b-spinner v-if="loadingProfissionais" label="carregando..."/>
        <b-table v-if="loadedProfissionais" hover striped small sort-icon-left :items="profissionais" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="info" size="sm" class='mr-2' @click="operadorasRelacionadas(row.item)">
              <i class="fa fa-hospital-o"/>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loadedProfissionais" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import profissionais from "@/mixins/profissionais";
import cboss from "@/mixins/cboss";
import conselhos from "@/mixins/conselhos";
import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  mixins: [profissionais, cboss, conselhos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      loadingProfissionais: false,
      loadedProfissionais: false,
      erro: "",
      mensagem : "",
      retornando: false,
      modo: "",
      profissionalSelecionado : {},
      camposTabela: [
        {key : "id", label : "Id"},
        {key : "nome", label : "Nome" , sortable: true},
        {key : "cbos", label : "CBOS"},
        {key : "conselho", label : "Conselho"},
        {key : "conselhoNumero", label : "Numero"},
        {key : "ativo", label : "Ativo"},
        {key : "externo", label : "Externo"},
        {key : "actions", label : "Ações"}
      ],
      ufs: constantes.uf,
    }
  },
  watch : {
    page() {
      this.carregarMedicos();
    }
  },
  methods: {
    ...mapActions({
      setProfissionalSelecionado : "profissionais/setProfissionalSelecionado"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }
      
      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/profissionais/${this.profissionalSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarMedicos();
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    limparCampos() {
      this.profissionalSelecionado = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    },
    cancelarOperacao() {
      this.profissionalSelecionado = {}
      this.modo = "";
      this.erro = "";
    },
    async carregarMedicos() {
      await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, this.page);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.profissionalSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
        this.$http
          .put(`/v1.0/profissionais/${this.profissionalSelecionado.id}`, this.profissionalSelecionado)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarMedicos();
            this.$toasted.global.defaultSuccess();
           })
           .catch(error => {
             this.erro = errors.tratarErros(error);
           })
           .finally(() => {});
      } else {
        this.profissionalSelecionado.profissionalTipoId = 1;
        this.profissionalSelecionado.ativo = "N";
        this.profissionalSelecionado.externo = "S";
        this.profissionalSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
  
        this.$http
          .post("/v1.0/profissionais", this.profissionalSelecionado)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarMedicos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalSelecionado.nome === undefined) {
        this.$toasted.show("Digite o Nome do Profissional", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.cbosId === undefined) {
        this.$toasted.show("Selecione o CBOS do Profissional", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.conselhoId === undefined) {
        this.$toasted.show("Selecione o Conselho do Profissional", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.conselhoNumero === undefined) {
        this.$toasted.show("Digite o Número do Conselho do Profissional", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.conselhoUf === undefined) {
        this.$toasted.show("Selecione o UF do Conselho do Profissional", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    operadorasRelacionadas(profissional) {
      this.setProfissionalSelecionado(profissional);
      this.$router.push('/frmProfissionalOperadoras');
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarMedicos();
  },
}
</script>

<style scoped>
</style>
