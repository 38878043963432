<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Portes</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="portes" class="control-label">Portes</label>
          <select
            class="form-control"
            id="portes"
            name="portes"
            selected
            v-model="porteEdicaoId"
            @change="buscarPortes()"
          >
            <option
              v-for="porte in portesEdicoes"
              :key="porte.id"
              :value="porte.id"
            >{{ porte.edicao }}</option>
          </select>
        </div>
        <template v-if="porteCBHPM != null">
          <div class="form-group col-lg-1">
            <label for="uco" class="control-label">UCO</label>
            <input id="uco" type="number" class="form-control" v-model='porteCBHPM.uco' disabled/>
          </div>
          <div class="form-group col-lg-1">
            <label for="urgencia" class="control-label">Urgência x</label>
            <input id="urgencia" type="number" class="form-control" v-model='porteCBHPM.urgencia' disabled/>
          </div>
        </template>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <!-- <div style="display:flex; flex-direction:row; justify-content:center; max-height:350;overflow:auto"> -->
    <div style="display:flex; flex-direction:row; justify-content:center;height:490px;overflow:auto">
      <b-spinner v-if="loading" label="carregando..."/>
      <b-table v-if="loaded" class="col-lg-2" hover striped small sort-icon-left :items="portes" :fields="camposTabela"/>
      <b-table v-if="loaded" class="col-lg-2" hover striped small sort-icon-left :items="portesAnestesicos" :fields="camposTabelaPortesAnestesicos"/>
      <b-table v-if="loaded" class="col-lg-2" hover striped small sort-icon-left :items="auxiliaresCirurgias" :fields="camposTabelaAuxiliaresCirurgicos"/>
      <b-table v-if="loaded" class="col-lg-2" hover striped small sort-icon-left :items="multiCirurgias" :fields="camposTabelaMultiCirurgias"/>
      <b-table v-if="loaded" class="col-lg-2" hover striped small sort-icon-left :items="salas" :fields="camposTabelaSalas"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import portesCBHPM from "@/mixins/portesCBHPM";
import global from "@/mixins/global";

export default {
  mixins: [portesCBHPM, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    //liberarCampos() {
    //  return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    //}
  },
  data() {
    return {
      camposTabela: [
        { key: 'porte', label: 'Porte'},
        { key: 'valor', label: 'Valor R$'},
      ],
      camposTabelaAuxiliaresCirurgicos: [
        { key: 'auxiliar', label: 'Auxiliar Nº'},
        { key: 'porcentagem', label: 'Porcentagem'},
      ],
      camposTabelaSalas: [
        { key: 'sala', label: 'Sala'},
        { key: 'multiplicarPor', label: 'Multiplicar Por'},
      ],
      camposTabelaPortesAnestesicos: [
        { key: 'porteAnestesico', label: 'Porte Anestésico'},
        { key: 'porte', label: 'Porte'},
      ],
      camposTabelaMultiCirurgias: [
        { key: 'via', label: 'Via'},
        { key: 'porcentagemPrincipal', label: '% Principal'},
        { key: 'porcentagemDemais', label: '% Demais'},
      ],
      erro: "",
      modo: "",
      mensagem : "",
      loading: false,
      loaded: false,
      porteCBHPM : {},
      porteEdicaoId : 0,
      portesEdicoes: [],

      portes : [],
      auxiliaresCirurgias : [],
      salas : [],
      portesAnestesicos : [],
      multiCirurgias : [],
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    async buscarPortes() {
      this.loading = true;
      this.loaded = false;
      await this.carregarPortesCBHPMPeloId(this.porteEdicaoId);
      this.loading = false;
      this.loaded = true;

      this.portes = [...this.porteCBHPM.portes];
      this.auxiliaresCirurgias = [...this.porteCBHPM.auxiliaresCirurgias];
      this.salas = [...this.porteCBHPM.salas];
      this.portesAnestesicos = [...this.porteCBHPM.portesAnestesicos];
      this.multiCirurgias = [...this.porteCBHPM.multiCirurgias];
    },
  },
  created() {
    this.showMenu(false);
    this.carregarPortesCBHPM('S');
  },
}
</script>

<style scoped>
</style>