<template>
  <div>
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
      <span>Controle de Atendimentos de {{pacienteSelecionado.nome}} - Prontuário: {{pacienteSelecionado.id}}</span>
      <hr />
    </div>

    <div class="container-fluid">
        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>

        <div class="row" v-if="modoPacienteOperadora=='Incluir' || modoPacienteOperadora==''">
          <div class="col-12">
            <button
              class="btn btn-sm btn-info"
              @click="incluirPacienteOperadora()"
            >{{modoPacienteOperadora=='' ? "Incluir Nova Operadora para o(a) Paciente" : "Fechar Inclusão"}}</button>
          </div>
        </div>
        <br />
        
        <!-- Edição / Inclusão PacienteOperadora - Início -->
        <div class="registro" v-if="modoPacienteOperadora!=''">
          <div class="row" v-if="modoPacienteOperadora=='Incluir'">
            <div class="form-group col-lg-2">
              <label for="OperadoraAgendamento" class="control-label">Operadora:</label>
              <select
                class="form-control"
                id="OperadoraAgendamento"
                name="Ativo"
                selected
                v-model="pacienteOperadoraSelecionada.operadoraId"
              >
                <option
                  v-for="operadora in operadoras"
                  :key="operadora.id"
                  :value="operadora.id"
                >{{ operadora.fantasia }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-3">
              <label for="titular" class="control-label">Titular</label>
              <input type="text" id="titular" v-model="pacienteOperadoraSelecionada.titular" class="form-control"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="carteirinha" class="control-label">Carteirinha</label>
              <input type="text" id="carteirinha" v-model="pacienteOperadoraSelecionada.carteirinha" class="form-control"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="datValidade" class="control-label">Validade</label>
              <input type="date" id="datValidade" v-model="pacienteOperadoraSelecionada.datValidade" class="form-control"/>
            </div>
            <div class="form-group col-lg-1">
              <label for="produto" class="control-label">Produto</label>
              <input type="text" id="produto" v-model="pacienteOperadoraSelecionada.produto" class="form-control"/>
            </div>
            <div class="form-group col-lg-1">
              <label for="tipo" class="control-label">Tipo</label>
              <input type="text" id="tipo" v-model="pacienteOperadoraSelecionada.tipo" class="form-control"/>
            </div>
          </div>
          <div class="row justify-content-center">
            <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
              @click="cancelarPacienteOperadora()">Cancelar
            </button>
            <button class="btn btn-sm btn-warning ml-1" style="margin-bottom:10px;"
              @click="gravarPacienteOperadora()">Gravar Alterações
            </button>
          </div>
        </div>
        <!-- Edição / Inclusão PacienteOperadora - Fim -->

        <div style="display:flex; flex-direction:row; justify-content:center">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="!loading" hover striped small sort-icon-left class="col-lg-9"
            :items="pacienteOperadoras" :fields="camposTabela"
            @row-clicked="selecionouPacienteOperadora">
            <template v-slot:cell(datValidade)="data">
                {{ data.value | formatDate}}
            </template>    
            <template v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" class="mr-1" @click="alterarPacienteOperadora(row.item)">
                <i class="fa fa-pencil" />
              </b-button>
              <b-button variant="danger" size="sm" class="mr-1" @click="excluirPacienteOperadora(row.item)">
                <i class="fa fa-minus" />
              </b-button>
            </template>
          </b-table>
        </div>  
        <hr/>

        <div class="row" v-if="modoPOProfissional=='Incluir' || modoPOProfissional==''">
          <div class="col-12">
            <button
              class="btn btn-sm btn-info"
              @click="incluirPOProfissional()"
            >{{modoPOProfissional=='' ? "Incluir Novo Profissional para o(a) Paciente" : "Fechar Inclusão"}}</button>
          </div>
        </div>
        <br />

        <!-- Edição / Inclusão POProfissionais - Início -->
        <div class="registro" v-if="modoPOProfissional!=''">
          <div class="row" v-if="modoPOProfissional=='Incluir'">
            <div class="form-group col-lg-4">
              <label for="profissional" class="control-label">Profissional:</label>
              <select
                class="form-control"
                id="profissional"
                name="profissional"
                selected
                v-model="pOProfissionalSelecionado.profissionalId"
              >
                <option
                  v-for="profissional in profissionais"
                  :key="profissional.id"
                  :value="profissional.id"
                >{{ profissional.nome }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-2">
              <label for="atendimento" class="control-label">Tipo:</label>
              <select
                class="form-control"
                id="atendimento"
                name="atendimento"
                selected
                v-model="pOProfissionalSelecionado.atendimento"
              >
                <option
                  v-for="tipo in tipoAtendimento"
                  :key="tipo.opcao"
                  :value="tipo.opcao"
                >{{ tipo.opcao }} - {{ tipo.valor }}</option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="datPrivisita" class="control-label">Primeira Visita</label>
              <input type="date" id="datPriVisita" v-model="pOProfissionalSelecionado.datPriVisita" class="form-control"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="datUltVisita" class="control-label">Última Visita</label>
              <input type="date" id="datUltVisita" v-model="pOProfissionalSelecionado.datUltVisita" class="form-control"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="datUltConsulta" class="control-label">Última Consulta</label>
              <input type="date" id="datUltConsulta" v-model="pOProfissionalSelecionado.datUltConsulta" class="form-control"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="datRetorno" class="control-label">Prazo Retorno</label>
              <input type="date" id="datRetorno" v-model="pOProfissionalSelecionado.datRetorno" class="form-control"/>
            </div>
            <div class="form-group col-lg-1">
              <label for="Ativo" class="control-label">Faltou:</label>
              <select
                class="form-control"
                id="Ativo"
                name="Ativo"
                selected
                v-model="pOProfissionalSelecionado.faltouUltimaVisita"
              >
                <option
                  v-for="falta in tiposFaltas"
                  :key="falta.opcao"
                  :value="falta.opcao"
                >{{ falta.valor }}</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-center">
            <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
              @click="cancelarPOProfissional()">Cancelar
            </button>
            <button class="btn btn-sm btn-warning ml-1" style="margin-bottom:10px;"
              @click="gravarPOProfissional()">Gravar Alterações
            </button>
          </div>
        </div>
        <!-- Edição / Inclusão POProfissionais - Fim -->

        <div v-if="pOProfissionais.length>0" style="display:flex; flex-direction:row; justify-content:center">
          <b-table v-if="!loading" hover striped small sort-icon-left class="col-lg-9"
            :items="pOProfissionais" :fields="camposTabelaPOProfissionais"
            @row-clicked="selecionouPOProfissional">
            >
            <template v-slot:cell(datPriVisita)="datPriVisita">
                {{ datPriVisita.value | formatDate}}
            </template>    
            <template v-slot:cell(datUltVisita)="datUltVisita">
                {{ datUltVisita.value | formatDate}}
            </template>    
            <template v-slot:cell(datUltConsulta)="datUltConsulta">
                {{ datUltConsulta.value | formatDate}}
            </template>    
            <template v-slot:cell(datRetorno)="datRetorno">
                {{ datRetorno.value | formatDate}}
            </template>    
            <template v-slot:cell(faltouUltimaVisita)="faltouUltimaVisita">
                {{faltouUltimaVisita.value = faltouUltimaVisita.value == 'S' ? "Sim" : ""}}
            </template>
            <template v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" class="mr-1" @click="alterarPOProfissional(row.item)">
                <i class="fa fa-pencil" />
              </b-button>
              <b-button variant="danger" size="sm" class="mr-1" @click="excluirPOProfissional(row.item)">
                <i class="fa fa-minus" />
              </b-button>
            </template>
          </b-table>
        </div >
        <div style="display:flex; flex-direction:row; justify-content:center">
          <b-table v-if="(!loading) && agendaAtendimentos.length>0" hover striped small sort-icon-left class="col-lg-9"
            :items="agendaAtendimentos" :fields="camposTabelaAgenda">
            <template v-slot:cell(destinado)="data">
                {{ data.value == "C" ? "Cons" : data.value == "S" ? "Sessão" : "Mensal"}}
            </template>      
            <template v-slot:cell(data)="dataAtendimento"> 
                {{ dataAtendimento.value | formatDate}} 
            </template>                     
          </b-table>
        </div>  
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import pacienteOperadoras from "@/mixins/pacienteOperadoras"
import pOProfissionais from "@/mixins/pOProfissionais"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import operadoras from "@/mixins/operadoras"
import profissionais from "@/mixins/profissionais"
import global from "@/mixins/global"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    mixins : [pacienteOperadoras, pOProfissionais, agendaAtendimentos, profissionais, operadoras, global],
    computed : {
      ...mapGetters({
        usuarioLogadoDados : "global/getUsuarioLogadoDados",
        pacienteSelecionado : "pacientes/getPacienteSelecionado",
        pacienteModulo : "pacientes/getModulo",
      }),
    },
    data() {
      return {
        erro : "",
        loading : true,
        modoPacienteOperadora : "",
        modoPOProfissional : "",
        pacienteId : 0,
        pacienteOperadoraSelecionada : null,
        pOProfissionalSelecionado : null,
        tiposFaltas : [{opcao : 'N', valor : "Não Faltou"}, {opcao : 'F', valor : "Faltou"}, {opcao : 'X', valor : "Faltou Avisando"}, {opcao : 'Ø', valor : "Falta não Cobrada"}],
        tipoAtendimento : constantes.tipoAtendimento,
        camposTabela : [
            {key : 'operadora', label : 'Operadora', sortable : true},
            {key : 'titular', label : 'Titular'},
            {key : 'carteirinha', label : 'Carteirinha'},
            {key : 'datValidade', label: 'Validade'},
            {key : 'produto', label: 'Produto'},
            {key : 'tipo', label : 'Tipo'},
            {key : 'actions', label : 'Ações'}
        ],
        camposTabelaPOProfissionais : [
            {key : 'profissional', label : 'Profissional', sortable : true},
            {key : 'cbos', label : 'CBOS', sortable : true},
            {key : 'atendimento', label : 'Tipo', sortable : true},
            {key : 'datPriVisita', label : 'Prim. Visita', sortable : true},
            {key : 'datUltVisita', label : 'Últ. Visita', sortable : true},
            {key : 'datUltConsulta', label : 'Últ. Consulta', sortable : true},
            {key : 'datRetorno', label : 'Retorno até', sortable : true},
            {key : 'faltouUltimaVisita', label : 'Faltou', sortable : true},
            {key : 'actions', label : 'Ações'}
        ],
        camposTabelaAgenda : [
            {key : 'data', label : 'Data', sortable : true},
            {key : 'horario', label : 'Horário', sortable : true},
            {key : 'destinado', label : 'Para', sortable : true},
            {key : 'preferencial', label : 'Pref', sortable : true},
            {key : 'preferencial2', label : 'Pref2', sortable : true},
            {key : 'preferencial3', label : 'Pref3', sortable : true},
            {key : 'atendimento', label : 'Tipo', sortable : true},
            {key : 'observacao', label: 'Observação', sortable : true},
            {key : 'confirmado', label: 'Conf', sortable : true},
            {key : 'status', label : 'Status'},
            {key : 'horaChegada', label : 'Chegada', sortable: true},
            {key : 'horaAtendimento', label : 'Atend'},
            {key : 'horaSaida', label : 'Saída'},
            {key : 'horaChegada', label : 'Chegada'},
            {key : 'operadora', label: 'Operadora', sortable : true},
            {key : 'planoSaude', label: 'Plano', sortable : true},
            {key : 'pendente', label : 'Pagar'},
            {key : 'recebido', label : 'Recebido'},
        ],
        pacienteOperadoras : []
      }
    },
    watch : {
      pacienteOperadoras() {
        if (this.pacienteOperadoras.length>0) {
          this.pacienteOperadoraSelecionada = {...this.pacienteOperadoras[0]}
          this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadoraSelecionada.id);
        } else {
          this.pacienteOperadoraSelecionada = null;
        }
      },
    },
    methods : {
      ...mapActions({
        formLeave : "pacientes/formLeave",
      }),
      retornar() {
        this.$router.go(-1);
      },
      selecionouPacienteOperadora(pacienteOperadora) {
        this.agendaAtendimentos = [];
        this.pacienteOperadoraSelecionada = {...pacienteOperadora}
        this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadoraSelecionada.id);
      },
      alterarPacienteOperadora(pacienteOperadora) {
        if (pacienteOperadora.datValidade!=null) {
          pacienteOperadora.datValidade = funcoes.mySqlToJSDateFormat(pacienteOperadora.datValidade);
        }
        
        this.modoPacienteOperadora = "Alterar";
        this.pacienteOperadoraSelecionada = {...pacienteOperadora}
      },
      incluirPacienteOperadora() {
        this.modoPacienteOperadora = this.modoPacienteOperadora == "Incluir" ? "" : "Incluir";
        this.pacienteOperadoraSelecionada = {}

        if (this.modoPacienteOperadora=="Incluir") {
          this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
        }
      },
      cancelarPacienteOperadora() {
        this.pacienteOperadoraSelecionada = null;
        this.modoPacienteOperadora = "";
      },
      excluirPacienteOperadora(pacienteOperadora) {
        this.$bvModal
          .msgBoxConfirm("Excluir Registro Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`/v1.0/pacienteoperadoras/${this.usuarioLogadoDados.tenant}/${pacienteOperadora.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";

                  this.carregarPacienteOperadoras(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id);
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      gravarPacienteOperadora() {
        if (this.modoPacienteOperadora=="Alterar") {
          this.pacienteOperadoraSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.$http.put(`/v1.0/pacienteoperadoras/${this.usuarioLogadoDados.tenant}/${this.pacienteOperadoraSelecionada.id}`, this.pacienteOperadoraSelecionada)
          .then(() => {
            this.modoPacienteOperadora = "";
            this.pacienteOperadoraSelecionada = null;
            this.carregarPacienteOperadoras(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        } else {
          if (this.pacienteOperadoras.filter(f => f.operadoraId == this.pacienteOperadoraSelecionada.operadoraId).length>0) {
            this.$toasted.show("Essa Operadora já consta na lista do(a) Paciente.", {type: "info" , icon : "times"});
            return;
          }

          this.pacienteOperadoraSelecionada.pacienteId = this.pacienteSelecionado.id;
          this.pacienteOperadoraSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

          this.$http.post(`/v1.0/pacienteoperadoras/${this.usuarioLogadoDados.tenant}`, this.pacienteOperadoraSelecionada)
          .then(() => {
            this.modoPacienteOperadora = "";
            this.pacienteOperadoraSelecionada = null;
            this.carregarPacienteOperadoras(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        }
      },
      selecionouPOProfissional(pOProfissional) {
        this.carregarAgendaPacienteProfissional(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, pOProfissional.profissionalId, this.pacienteOperadoraSelecionada.operadoraId);
      },
      alterarPOProfissional(pOProfissional) {
        this.modoPOProfissional = "Alterar";

        if (pOProfissional.datPriVisita!=null) {
          pOProfissional.datPriVisita = funcoes.mySqlToJSDateFormat(pOProfissional.datPriVisita);
        }
        if (pOProfissional.datUltVisita!=null) {
          pOProfissional.datUltVisita = funcoes.mySqlToJSDateFormat(pOProfissional.datUltVisita);
        }
        if (pOProfissional.datUltConsulta!=null) {
          pOProfissional.datUltConsulta = funcoes.mySqlToJSDateFormat(pOProfissional.datUltConsulta);
        }
        if (pOProfissional.datRetorno!=null) {
          pOProfissional.datRetorno = funcoes.mySqlToJSDateFormat(pOProfissional.datRetorno);
        }

        this.pOProfissionalSelecionado = {...pOProfissional}
      },
      incluirPOProfissional() {
        this.modoPOProfissional = this.modoPOProfissional == "Incluir" ? "" : "Incluir";
        this.pOProfissionalSelecionado = {}

        if (this.modoPOProfissional=="Incluir") {
          //this.carregarProfissionais(this.usuarioLogadoDados.tenant, 1, "S", 0);
          this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);
        }
      },
      cancelarPOProfissional() {
        this.pOProfissionalSelecionado = null;
        this.modoPOProfissional = "";
      },
      excluirPOProfissional(pOProfissional) {
        this.$bvModal
          .msgBoxConfirm("Excluir Registro Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/poprofissionais/${this.usuarioLogadoDados.tenant}/${pOProfissional.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";

                  this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadoraSelecionada.id);
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      gravarPOProfissional() {
        if (this.modoPOProfissional=="Alterar") {
          this.pOProfissionalSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.$http.put(`/v1.0/poprofissionais/${this.usuarioLogadoDados.tenant}/${this.pOProfissionalSelecionado.id}`, this.pOProfissionalSelecionado)
          .then(() => {
            this.modoPOProfissional = "";
            this.pOProfissionalSelecionado = null;
            this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadoraSelecionada.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        } else {
          if (this.pOProfissionais.filter(f => f.profissionalId == this.pOProfissionalSelecionado.profissionalId).length>0) {
            this.$toasted.show("Esse Profissional já consta na lista da Operadora do(a) Paciente.", {type: "info" , icon : "times"});
            return;
          }

          this.pOProfissionalSelecionado.pacienteOperadoraId = this.pacienteOperadoraSelecionada.id;
          this.pOProfissionalSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

          this.$http.post(`/v1.0/poprofissionais/${this.usuarioLogadoDados.tenant}`, this.pOProfissionalSelecionado)
          .then(() => {
            this.modoPOProfissional = "";
            this.pOProfissionalSelecionado = null;
            this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadoraSelecionada.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        }
      },
    },
    created() {
      this.showMenu(false);
      this.carregarPacienteOperadoras(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id);
    },
    beforeRouteLeave(to, from , next) {
      if (this.pacienteModulo=="Agenda") {
        this.formLeave();
      }

      next(true);
    }
}
</script>

<style scoped>

</style>
