import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrarProcedimento: "",
            servicoId: 0,
            filtrar: "",
            countProcedimento : 0,
            limitProcedimento : 0,
            procedimentos: [],
        }
    },
    methods: {
        async carregarProcedimentosPeloId(id) {
          await this.$http
            .get(`/v1.0/procedimentos/${id}`)
            .then(resp => {
              this.procedimento = resp.data;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async carregarProcedimentosPelaTabela(tabela, page) {
          await this.$http
            .get(`/v1.0/procedimentos/tabela/${tabela}/${page}`)
            .then(resp => {
              this.procedimentos = resp.data.registros;
              this.countProcedimento = resp.data.count;
              this.limitProcedimento = resp.data.limit;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async carregarProcedimentosPeloGrupo(tabela, grupo, page) {
          await this.$http
            .get(`/v1.0/procedimentos/${tabela}/grupo/${grupo}/${page}`)
            .then(resp => {
              this.procedimentos = resp.data.registros;
              this.countProcedimento = resp.data.count;
              this.limitProcedimento = resp.data.limit;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async carregarProcedimentosPelaDescricao(tabela, procedimento, page) {
          await this.$http
            .get(`/v1.0/procedimentos/${tabela}/procedimento/${procedimento}/${page}`)
            .then(resp => {
              this.procedimentos = resp.data.registros;
              this.countProcedimento = resp.data.count;
              this.limitProcedimento = resp.data.limit;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async carregarProcedimentosPeloCodigo(tabela, codigo, page) {
          await this.$http
            .get(`/v1.0/procedimentos/${tabela}/codigo/${codigo}/${page}`)
            .then(resp => {
              this.procedimentos = resp.data.registros;
              this.countProcedimento = resp.data.count;
              this.limitProcedimento = resp.data.limit;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async carregarProcedimentosFaixaCodigos(tabela, codInicial, codFinal, page) {
            await this.$http
              .get(`/v1.0/procedimentos/${tabela}/FaixaCodigo/${codInicial}/${codFinal}/${page}`)
              .then(resp => {
                this.procedimentos = resp.data.registros;
                this.countProcedimento = resp.data.count;
                this.limitProcedimento = resp.data.limit;
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loading = false;
              });
        }
    }
}
