import errors from "@/utils/errors"

export default {
    data() {
        return {
            planosSaude : [],
            planoSaude : {},
        }
    },
    methods : {
        async carregarPlanosSaude(tenant, operadoraId, ativoLocal) {
          this.loading = true;
          this.loaded = false;

          await this.$http
          .get(`/v1.0/planossaude/${tenant}/${operadoraId}/${ativoLocal}`)
          .then(resp => {
            this.planosSaude = resp.data;
            this.erro = "";
            this.loaded = true;
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
            this.loading = false;
          });
        },
        async carregarPlanoSaudePeloId(tenant, planoSaudeId) {
          this.loading = true;

          await this.$http.get(`/v1.0/planossaude/${tenant}/${planoSaudeId}`)
          .then(resp => {
            this.planoSaude = resp.data;
            this.erro = "";
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => this.loading = false)
        }
    }
}
