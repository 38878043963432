<template>
  <div id="CadDespesaEstoque">
    <br />
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="UnidadeContabil" class="control-label">Unidade Contábil (Compra):</label>
          <select
            id="UnidadeContabil"
            :disabled="!liberarCampos"
            class="form-control"
            selected
            v-model="despesaSelecionada.unidadeContabil"
          >
            <option
              v-for="unidademedida in unidadesmedidas"
              :key="unidademedida.id"
              :value="unidademedida.termo"
            >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
          </select>
        </div>

        <div class="form-group col-lg-3">
          <label for="UnidadeConsumo" class="control-label">Unidade Consumo (Estoque):</label>
          <select
            id="UnidadeConsumo"
            :disabled="!liberarCampos"
            class="form-control"
            selected
            v-model="despesaSelecionada.unidadeConsumo"
          >
            <option
              v-for="unidademedida in unidadesmedidas"
              :key="unidademedida.id"
              :value="unidademedida.termo"
            >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
          </select>
        </div>

        <div class="form-group col-lg-3">
          <label for="UnidadeTiss" class="control-label">Unidade Faturamento (TISS):</label>
          <select
            id="UnidadeTiss"
            :disabled="!liberarCampos"
            class="form-control"
            selected
            v-model="despesaSelecionada.unidadeTiss"
          >
            <option
              v-for="unidademedida in unidadesmedidas"
              :key="unidademedida.id"
              :value="unidademedida.termo"
            >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Estoque" class="control-label">Saldo:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Estoque"
            name="Estoque"
            v-model="despesaSelecionada.estoque"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Minimo" class="control-label">Mínimo:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Minimo"
            name="Minimo"
            v-model="despesaSelecionada.minimo"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Maximo" class="control-label">Máximo:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Maximo"
            name="Maximo"
            v-model="despesaSelecionada.maximo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import unidadesMedidas from "@/mixins/unidadesMedidas";

export default {
  mixins: [unidadesMedidas],
  computed: {
    ...mapState({
      modo: state => state.despesas.modo,
      despesaSelecionada: state => state.despesas.despesaSelecionada,
    }),
    ...mapGetters({
      usuarioLogadoDados: "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  },
  data() {
    return {
      erro: ""
    }
  },
  methods: {
  },
  created() {
    this.carregarUnidadesMedidas(this.usuarioLogadoDados.tenant);
  }
}
</script>

<style scoped>
</style>