const state = {
    ultimaBusca : {operadoraId : 0, faturaId : 0, loteId : 0},
    alteracoes : 0,
}

const getters = {
    getUltimaBusca(state) {
        return state.ultimaBusca;
    },
    getAlteracoes(state) {
        return state.alteracoes;
    }
}

const mutations = {
    setUltimaBusca(state, ultimaBusca) {
        state.ultimaBusca = {...ultimaBusca}
    },

    adicionarAlteracoes(state) {
        state.alteracoes++;
    },

    zerarAlteracoes(state) {
        state.alteracoes = 0;
    },

    limparRegistro(state) {
        state.ultimaBusca = {}
    }
}

const actions = {
    setUltimaBusca({commit}, ultimaBusca) {
        commit('setUltimaBusca', ultimaBusca);
    },

    adicionarAlteracoes({commit}) {
        commit('adicionarAlteracoes')
    },

    zerarAlteracoes({commit}) {
        commit('zerarAlteracoes');
    },

    limparRegistro({commit}) {
        commit('limparRegistro');
    },

    formLeave({commit}) {
        commit('limparRegistro');
        commit('zerarAlteracoes');
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}