import errors from "@/utils/errors";
//import { ExistingObjectReplication } from "@aws-sdk/client-s3";
import {saveAs} from 'file-saver';

export default {
    data() {
        return {
        }
    },
    methods: {
      carregarRelatorioProducaoPorGrupo(tenant, datInicial, datFinal, particular, exibirConveniosValores, siglaServicos) {
        if (datInicial == null || datFinal == null) {
          this.erro = "Informe a Data Inicial e a Data Final";
          return;
        }
        
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosfinanceiros/${tenant}/ProducaoPorGrupo/${datInicial}/${datFinal}/${particular}/${exibirConveniosValores}/${siglaServicos}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'ProducaoPorGrupo.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioProducaoIndividualPorGrupo(tenant, profissionalId, datInicial, datFinal, particular, exibirConveniosValores, siglaServicos) {
        if (profissionalId == 0) {
          this.erro = "Selecione o Profissional";
          return
        }
        
        if (datInicial == null || datFinal == null) {
          this.erro = "Informe a Data Inicial e a Data Final";
          return;
        }

        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosfinanceiros/${tenant}/ProducaoIndividualPorGrupo/${profissionalId}/${datInicial}/${datFinal}/${particular}/${exibirConveniosValores}/${siglaServicos}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'ProducaoIndividualPorGrupo.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioCaixaDiario01(tenant, datInicial, datFinal) {
        if (datInicial == null || datFinal == null) {
          this.erro = "Informe a Data Inicial e a Data Final";
          return;
        }

        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosfinanceiros/${tenant}/CaixaDiario01/${datInicial}/${datFinal}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'CaixaDiario01.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioRecebimentosPorProfissional(tenant, datInicial) {
        if (datInicial == null) {
          this.erro = "Informe a Data Inicial";
          return;
        }

        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosrecebimentos/${tenant}/porDataAtendimentoProfissional/${datInicial}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'RecebimentosPorProfissional.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioRecebimentosPorSiglaServico(tenant, datInicial) {
        if (datInicial == null) {
          this.erro = "Informe a Data Inicial";
          return;
        }

        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosrecebimentos/${tenant}/porDataAtendimentoSiglaServico/${datInicial}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'RecebimentosPorSiglaServico.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      }
    }
}
