import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrar: "",
            count : 0,
            limit : 0,
            empresas : [],
        }
    },
    methods : {
        carregarEmpresas(tenant, pagina) {
            if (this.filtrar == "") {
              this.$http
                .get(`/v1.0/empresas/${tenant}/pagina/${pagina}`)
                .then(resp => {
                  this.empresas = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;
                  this.erro = "";
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {
                  this.loading = false;
                  this.loaded = true;
                });
            } else {
              this.$http
                .get(`/v1.0/empresas/${tenant}/descricao/${this.filtrar}/${pagina}`)
                .then(resp => {
                  this.empresas = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;
                  this.erro = "";
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {
                  this.loading = false;
                  this.loaded = true;
                });
            }
          },
    }
}
