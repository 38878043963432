<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Lançamentos - {{fluxoSelecionado.descricao}} - {{contaSelecionada.descricao}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
        <div class="row">
            <div class="form-group col-lg-2">
                <label for="datInicial" class="control-label">Data Inicial</label>
                <input type="date" id="datInicial" class="form-control" v-model="datInicial">
            </div>
            <div class="form-group col-lg-2">
                <label for="datFinal" class="control-label">Data Final</label>
                <input type="date" id="datFinal" class="form-control" v-model="datFinal">
            </div>
            <div class="form-group col-lg-1">
              <button id="btnFiltrar" class="form-control" style="margin-top:24px" @click="iniciarCarregarLancamentos()">
                <i class="fa fa-search"/> 
              </button>
            </div>
            <div class="form-group col-lg-2">
                <label for="datNovoLancamento" class="control-label">Novo Lançamento</label>
                <input type="date" id="datNovoLancamento" class="form-control" v-model="datNovoLancamento">
            </div>
            <div class="form-group col-lg-1">
              <b-button id="btnFiltrar" variant="info" size="sm" style="margin-top:29px" 
                        v-b-tooltip.hover title="Adicionar nova Data" @click="incluirNovoLancamento()">
                <i class="fa fa-plus"/> 
              </b-button>
            </div>
        </div>
        <hr/>
        <!-- Tabela com os Lancamentos -------------------------------------------------------->
        <div style="display:flex; flex-direction:row; justify-content:center;height:470px;overflow:auto">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="loaded" class="col-lg-12" hover striped small sort-icon-left :items="lancamentos" :fields="camposTabela">
            <template v-slot:cell(actions)="row">
              <!-- <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)">
                <i class="fa fa-pencil"/> 
              </b-button>   -->
              <b-button variant="info" size="sm" @click="lancamentoItens(row.item)" class="m-1">
                Lançamentos 
              </b-button>  
              <b-button variant="danger" size="sm" @click="excluirLancamento(row.item)" class="m-1">
                <i class="fa fa-trash"/>
              </b-button>  
            </template>
            <template v-slot:cell(data)="data">
              {{data.value | formatDate}}
            </template>
            <template v-slot:cell(saldoInicial)="saldoInicial">
              {{saldoInicial.value.toFixed(2).replace(".",",")}}
            </template>
            <template v-slot:cell(creditos)="creditos">
              {{creditos.value.toFixed(2).replace(".",",")}}
            </template>
            <template v-slot:cell(debitos)="debitos">
              {{debitos.value.toFixed(2).replace(".",",")}}
            </template>
            <template v-slot:cell(saldoFinal)="saldoFinal">
              {{saldoFinal.value.toFixed(2).replace(".",",")}}
            </template>
          </b-table>
        </div>
        <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import lancamentos from "@/mixins/lancamentos"
import global from "@/mixins/global"
import errors from "@/utils/errors"

export default {
    mixins : [lancamentos, global],
    computed : {
        ...mapGetters({
            fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
            contaSelecionada : "contas/getContaSelecionada",
            ultimaBusca : "lancamentos/getUltimaBusca",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarCampos() {
            return this.modo == "Incluir" || this.modo == "Alterar"
        }
    },
    data() {
      return {
        page : 1,
        erro : "",
        mensagem : "",
        loading : false,
        loaded : false,
        modo : "",
        datInicial : "",
        datFinal : "",
        datNovoLancamento : "",
        camposTabela : [
            {key : "data", label : "Data"},
            {key : "saldoInicial", label : "Saldo Inicial R$"},
            {key : "debitos", label : "Débitos R$"},
            {key : "creditos", label : "Créditos R$"},
            {key : "saldoFinal", label : "Saldo Final R$"},
            {key : "saldoInicial", label : "Saldo Inicial R$"},
            {key : "actions", label : "Ações"},
        ]
      }
    },
    watch : {
        page() {
            
        }
    },
    methods : {
        ...mapActions({
          setLancamentoSelecionado : "lancamentos/setLancamentoSelecionado",
          setUltimaBusca : "lancamentos/setUltimaBusca"
        }),
        retornar() {
            this.$router.go(-1);
        },
        iniciarCarregarLancamentos() {
          this.loading = true;
          this.loaded = false;

          this.carregarLancamentosPelaContaNoPeriodo(this.usuarioLogadoDados.tenant, this.contaSelecionada.id, this.datInicial, this.datFinal, this.page);
        },
        incluirNovoLancamento() {
          if (!this.verificarPermissao('Incluir')) {
            return
          }

          if (this.datNovoLancamento == "") {
            this.$toasted.show("Informe a Data para Novo Lançamento", {type : "info", icon : "times"});
            return;
          }

          let novoLancamento = {contaId : this.contaSelecionada.id, data : this.datNovoLancamento, 
                                saldoInicial : 0, saldoFinal : 0,
                                datCadastro : new Date(), operadorCadastroId : this.usuarioLogadoDados.profissionalId}

          this.$http.post(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}`, novoLancamento)
          .then(resp => {
            this.erro = "";

            if (resp.data.id==undefined) {
              this.$toasted.show("Data já foi lançada anteriormente", {type: "info", icon : "times"});
            } else {
              this.$toasted.global.defaultSuccess();
              this.iniciarCarregarLancamentos();
            }
          })
          .catch(error => this.erro = errors.tratarErros(error));
        },
        lancamentoItens(lancamento) {
          this.setLancamentoSelecionado(lancamento);
          this.setUltimaBusca({datInicial : this.datInicial, datFinal : this.datFinal});
          this.$router.push("/frmLancamentoItens");
        },
        excluirLancamento(lancamento) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            this.$bvModal
              .msgBoxConfirm("Excluir o Lançamento selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                    this.$http.delete(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}/${lancamento.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                        this.erro = "";
                        this.iniciarCarregarLancamentos();
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error));
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
    
            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");
    
            return false;
          }
    
          return true;
        }
    },
    created() {
        this.showMenu(false);

        if (this.ultimaBusca != null) {
          this.datInicial = this.ultimaBusca.datInicial;
          this.datFinal = this.ultimaBusca.datFinal;

          this.iniciarCarregarLancamentos();
          this.setUltimaBusca(null);
        }
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
