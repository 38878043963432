<template>
  <div>
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
      <!-- <span v-if="agendaAtendimentoSelecionada.pacienteId==null">Edição Horário Livre</span>
      <span v-else>Edição Horário do(a) Paciente {{pacienteSelecionado.nome}} - Prontuário {{pacienteSelecionado.id}}</span> -->
      <span>{{mensagemCabecalho}}</span>
      <hr />
    </div>

    <div class="container-fluid">
      <!-- Horário Livre -->
      <template v-if="agendaAtendimentoSelecionada.pacienteId==undefined">
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="Horario" class="control-label">Horário:</label>
            <input
              type="time"
              class="form-control"
              id="Horario"
              name="Horario"
              v-model="agendaAtendimentoSelecionada.horario"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="destinado" class="control-label">Tipo Atendimento:</label>
            <select
              id="destinado"
              class="form-control"
              selected
              v-model="agendaAtendimentoSelecionada.destinado"
            >
              <option
                v-for="resposta in opcoesDestinados"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="Preferencial" class="control-label">1º Preferencial</label>
            <select
              class="form-control"
              id="Preferencial"
              name="Preferencial"
              selected
              v-model="agendaAtendimentoSelecionada.preferencial"
            >
              <option
                v-for="servico in servicos"
                :key="servico.siglaServico"
                :value="servico.siglaServico"
              >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="Preferencial2" class="control-label">2º Preferencial</label>
            <select
              class="form-control"
              id="Preferencial2"
              name="Preferencial2"
              selected
              v-model="agendaAtendimentoSelecionada.preferencial2"
            >
              <option
                v-for="servico in servicos"
                :key="servico.siglaServico"
                :value="servico.siglaServico"
              >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="Preferencial3" class="control-label">3º Preferencial</label>
            <select
              class="form-control"
              id="Preferencial3"
              name="Preferencial3"
              selected
              v-model="agendaAtendimentoSelecionada.preferencial3"
            >
              <option
                v-for="servico in servicos"
                :key="servico.siglaServico"
                :value="servico.siglaServico"
              >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
            </select>
          </div>
          <div class="form-group col-lg-3">
            <label for="ObservacaoHorarioLivre" class="control-label">Observação Horário:</label>
            <input
              type="text"
              class="form-control"
              id="ObservacaoHorarioLivre"
              name="ObservacaoHorarioLivre"
              v-model="agendaAtendimentoSelecionada.observacao"
            />
          </div>
        </div>
      </template>
      <!-- Horário Ocupado -->
      <template v-else>
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="OperadoraAgendamento" class="control-label">Operadora:</label>
            <select
              class="form-control"
              id="OperadoraAgendamento"
              name="Ativo"
              selected
              :disabled="!liberarCampos"
              v-model="operadoraId"
            >
              <option
                v-for="operadora in operadoras"
                :key="operadora.id"
                :value="operadora.id"
              >{{ operadora.fantasia }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="PlanoSaudeAgendamento" class="control-label">Plano:</label>
            <select
              class="form-control"
              id="PlanoSaudeAgendamento"
              name="Ativo"
              :disabled="operadoraId==0 || !liberarCampos"
              selected
              v-model="agendaAtendimentoSelecionada.planoSaudeId"
            >
              <option
                v-for="planosaude in planosSaude"
                :key="planosaude.id"
                :value="planosaude.id"
              >{{ planosaude.plano }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="Atendimento" class="control-label">Tipo Atendimento</label>
            <select
              class="form-control"
              id="Atendimento"
              name="Atendimento"
              :disabled="!liberarCampos"
              selected
              v-model="agendaAtendimentoSelecionada.atendimento"
            >
              <option
                v-for="tipoAtendimento in tiposAtendimento"
                :key="tipoAtendimento.opcao"
                :value="tipoAtendimento.opcao"
              >{{ tipoAtendimento.opcao }} - {{ tipoAtendimento.valor }}</option>
            </select>
          </div>
          <div class="form-group col-lg-1">
            <label for="Confirmado" class="control-label">Confirmado</label>
            <select
              class="form-control"
              id="Confirmado"
              name="Confirmado"
              :disabled="!liberarCampos"
              selected
              v-model="agendaAtendimentoSelecionada.confirmado"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{ resposta.valor }}</option>
            </select>
          </div>
          <div class="form-group col-lg-3">
            <label for="ObservacaoHorarioOcupado" class="control-label">Observação Horário:</label>
            <input
              type="text"
              class="form-control"
              id="ObservacaoHorarioOcupado"
              name="ObservacaoHorarioOcupado"
              v-model="agendaAtendimentoSelecionada.observacao"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Procedimento" class="control-label">Procedimento:</label>
            <input
              type="text"
              class="form-control"
              id="Procedimento"
              name="Procedimento"
              v-model="agendaAtendimentoSelecionada.procedimento"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Pendente" class="control-label">Pendente:</label>
            <input
              type="number"
              class="form-control"
              id="Pendente"
              name="Pendente"
              v-model.number="agendaAtendimentoSelecionada.pendente"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Recebido" class="control-label">Recebido:</label>
            <input
              type="number"
              class="form-control"
              id="Recebido"
              name="Recebido"
              v-model.number="agendaAtendimentoSelecionada.recebido"
            />
          </div>
        </div>
      </template>
      <!-- Botões de Cancelar / Confirmação -->
      <hr/>
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <div class="row justify-content-center">
        <button
          class="btn btn-sm btn-danger"
          style="margin-bottom:10px;"
          @click="cancelar()"
        >Cancelar</button>
        <button
          class="btn btn-sm btn-info ml-1"
          style="margin-bottom:10px;"
          @click="gravar()"
        >Gravar Alterações</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import servicos from "@/mixins/servicos";
import operadoras from "@/mixins/operadoras";
import planosSaude from "@/mixins/planosSaude";
import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors"

export default {
  mixins: [servicos, operadoras, planosSaude, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      pacienteSelecionado : "pacientes/getPacienteSelecionado",
      agendaAtendimentoSelecionada: "agendaAtendimentos/getAgenda",
    }),
    opcoesDestinados: function() {
      return constantes.destinados.filter(function(u) {
        return u.opcao != "T";
      });
    },
    liberarCampos() {
        return this.agendaAtendimentoSelecionada.status == null || this.agendaAtendimentoSelecionada.status == "" ? true : false;
    }
  },
  data() {
    return {
      erro : "",
      operadoraId: 0,
      mensagemCabecalho : "",
      tiposAtendimento: constantes.tipoAtendimento,
      sim_nao: constantes.sim_nao
    }
  },
  watch: {
    operadoraId() {
      this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.operadoraId, "S");
    }
  },
  methods: {
    ...mapActions({
      formLeave : "pacientes/formLeave",
    }),
    retornar() {
      this.$router.go(-1);
    },
    gravar() {
      if (this.agendaAtendimentoSelecionada.pacienteId == null) {
        this.gravarAgendaHorarioLivre();
      } else {
        this.gravarAgendaHorarioOcupado();
      }
    },
    gravarAgendaHorarioLivre() {
      var horarioLivre = {
        id: this.agendaAtendimentoSelecionada.id,
        horario: this.agendaAtendimentoSelecionada.horario,
        destinado: this.agendaAtendimentoSelecionada.destinado,
        preferencial: this.agendaAtendimentoSelecionada.preferencial,
        preferencial2: this.agendaAtendimentoSelecionada.preferencial2,
        preferencial3: this.agendaAtendimentoSelecionada.preferencial3,
        observacao: this.agendaAtendimentoSelecionada.observacao,
        operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
      }

      this.$http
        .put(
          `/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/AlterarHorarioLivre/${horarioLivre.id}`,
          horarioLivre
        )
        .then(() => {
          this.erro = "";
          this.$toasted.global.defaultSuccess();
          this.$router.go(-1);
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    gravarAgendaHorarioOcupado() {
      var horarioOcupado = {
        id: this.agendaAtendimentoSelecionada.id,
        atendimento: this.agendaAtendimentoSelecionada.atendimento,
        confirmado: this.agendaAtendimentoSelecionada.confirmado,
        observacao: this.agendaAtendimentoSelecionada.observacao,
        procedimento: this.agendaAtendimentoSelecionada.procedimento,
        pendente: this.agendaAtendimentoSelecionada.pendente,
        recebido: this.agendaAtendimentoSelecionada.recebido,
        planoSaudeId: this.agendaAtendimentoSelecionada.planoSaudeId,
        operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
      }

      this.$http
        .put(
          `/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/AlterarHorarioOcupado/${horarioOcupado.id}`,
          horarioOcupado
        )
        .then(() => {
          this.erro = "";
          this.$toasted.global.defaultSuccess();
          this.$router.go(-1);
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelar() {
      this.$router.go(-1);
    }
  },
  created() {
    this.carregarServicos(this.usuarioLogadoDados.tenant);
    if (this.agendaAtendimentoSelecionada.pacienteId != null) {
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);
      this.operadoraId = this.agendaAtendimentoSelecionada.operadoraId;

      this.mensagemCabecalho = `Edição Horário do(a) Paciente ${this.pacienteSelecionado.nome} - Prontuário ${this.pacienteSelecionado.id}`;
    } else {
      this.mensagemCabecalho = "Edição Horário Livre";
    }
  },
  beforeRouteLeave(to, from , next) {
    this.formLeave();
    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>