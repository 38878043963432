import errors from "@/utils/errors";

export default {
    data() {
        return {
            cIModeloItens : []
        }
    },
    methods : {
        carregarCIModeloItens(tenant, cIModeloId) {
            this.$http.get(`/v1.0/CIModeloItens/${tenant}/CIModelo/${cIModeloId}`)
            .then(resp => {
                this.cIModeloItens = resp.data;
                this.loading = false;
                this.erro= ""
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
