<template>
    <div>
      <div class="cabecalho">
        <div class="coluna1">
          <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
        </div>
        <div class="coluna2">
          <span>Opções de Relatórios de Faturamento</span>
        </div>
        <div class="coluna3"/>
      </div>  

      <div class="container">
        <div class= "row">
            <template v-if="grupoUsuarioLogado != 'Corpo Clínico'">
                <div class="col-lg-2">
                    <button class="btn btn-info btn-sm" id="RelatorioPorOperadora" @click="relatorioSelecionado=10">Por Operadora / Período</button>
                </div>
            </template>
            <div class="col-lg-2">
                <button class="btn btn-info btn-sm" id="RelatorioPorProfissional" @click="relatorioSelecionado=20">Por Profissional / Período</button>
            </div>
        </div>
        <hr>
        <br>

        <template v-if="relatorioSelecionado>0">
          <template v-if="grupoUsuarioLogado != 'Corpo Clínico' && relatorioSelecionado<=10">
            <div class="row">
              <div class="col-lg-2">
                <label for="selecionarOperadora" class="control-label" style="margin-bottom: 0px">Operadora</label>
                <select id="selecionarOperadora" class="form-control" v-model="operadoraId">
                    <option v-for="operadora in operadoras" :key="operadora.id" :value="operadora.id">
                        {{operadora.fantasia}}
                    </option>
                </select>
              </div>

              <div class="col-lg-3">
                <label for="selecionarModelo" class="control-label" style="margin-bottom: 0px">Agrupado</label>
                <select id="selecionarModelo" class="form-control" v-model="modeloPorOperadora">
                    <option v-for="modeloPorOperadora in modelosPorOperadora" :key="modeloPorOperadora.opcao" :value="modeloPorOperadora.opcao">
                        {{modeloPorOperadora.descricao}}
                    </option>
                </select>
              </div>

              <div class="col-lg-2">
                <label for="selecionarOrientacao" class="control-label" style="margin-bottom: 0px">Orientação</label>
                <select id="selecionarOrientacao" class="form-control" v-model="orientacao">
                    <option v-for="orientacao in orientacoes" :key="orientacao.opcao" :value="orientacao.opcao">
                        {{orientacao.descricao}}
                    </option>
                </select>
              </div>

              <div class="col-lg-2 flex-center">
                <label for="dataInicial" class="control-label" style="margin-bottom: 0px">Data Inicial</label>
                <input type="date" id="dataInicial" class="form-control" v-model="datInicial" @dblclick="dataAtual()"/>
              </div>
              <div class="col-lg-2 flex-center">
                <label for="dataFinal" class="control-label" style="margin-bottom: 0px">Data Final</label>
                <input type="date" id="dataFinal" class="form-control" v-model="datFinal" @dblclick="dataAtual()"/>
              </div>

              <div class="col-lg-1">
              <button id="botaoImprimir" class="btn btn-sm btn-info" style="margin-top:28px; width:100%" @click="chamarRelatorio()">Imprimir</button>
              </div>
            </div>
          </template>
        </template>

        <b-spinner v-if="loadingRelatorio" label="carregando..."/>
        <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global";
import operadoras from "@/mixins/operadoras";
import relatoriosFaturamentos from "@/mixins/relatoriosFaturamentos";
export default {
    mixins : [operadoras, relatoriosFaturamentos, global],
    data() {
      return {
        datInicial : null,
        datFinal : null,
        loadingRelatorio : false,
        erro : "",

        loading : false,
        grupoUsuarioLogado : "",
        profissionalId : 0,
        relatorioSelecionado : 0,

        modelosPorOperadora : [
          {opcao : 1, descricao : "Por Profissional / Serviço"},
          {opcao : 2, descricao : "Por Serviço / Profissional"},
          {opcao : 3, descricao : "Por Atendimento / Profissional"},
        ],

        orientacoes : [
          {opcao : "R", descricao : "Retrato"},
          {opcao : "P", descricao : "Paisagem"}
        ],

        modeloPorOperadora : 1,
        orientacao : 'R'
      }
    },
    computed : {
      ...mapGetters({
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
      })
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        chamarRelatorio() {
            if (this.relatorioSelecionado == 10) {
              this.carregarRelatorioPorOperadora(this.usuarioLogadoDados.tenant, this.operadoraId, this.datInicial, this.datFinal, this.modeloPorOperadora, this.orientacao);
            }
        }
    },
    created() {
       this.showMenu(false);
       this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);

        this.grupoUsuarioLogado = this.usuarioLogadoDados.perfilAcesso.perfil.grupo;

        if (this.grupoUsuarioLogado == 'Corpo Clínico') {
            this.profissionalId = this.usuarioLogadoDados.profissionalId;
        }
    }
}
</script>


<style scoped>

</style>