<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
        <button @click="home" class="btn btn-sm btn-primary ml-1"><i class="fa fa-home"/></button>
      </div>
      <div class="coluna2">
        <span>{{cIModeloSelecionado.memo}} - Itens</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Item ao Modelo</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-sm-2 col-md-4 col-lg-9">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="cIModeloItem.memo"
              aria-describedby="DescricaoHelp"
            />
            <small id="DescricaoHelp" class="form-text text-muted">Descrição Completa do Ítem do Modelo.</small>
          </div>
          <div class="form-group col-lg-2">
            <label for="QuebraLinha" class="control-label">Quebra Linha</label>
            <select
              id="QuebraLinha"
              class="form-control"
              :disabled="!liberarCampos"
              v-model="cIModeloItem.quebraLinha"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center; max-height:500px; overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="!loading"
          class="col-lg-10"
          hover
          striped
          small
          sort-icon-left
          :items="cIModeloItens"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>

          <template v-slot:cell(quebraLinha)="quebraLinha">
              {{quebraLinha.value = quebraLinha.value == 'S' ? "Sim" : "Não"}}
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import cIModeloItens from "@/mixins/cIModeloItens";
import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  mixins: [cIModeloItens, global],
  computed: {
    ...mapGetters({
      cIModeloSelecionado : "cIModelos/getCIModeloSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      cIModeloItem: {},
      retornando : false,
      loading: true,
      erro: "",
      modo : "",
      mensagem : "",
      camposTabela: [
        { key: "id", label: "Código"},
        { key: "memo", label: "Descrição", sortable: true },
        { key: "quebraLinha", label: "Quebra Linha"},
        { key: "actions", label: "Ações" }
      ],
      sim_nao : constantes.sim_nao
    }
  },
  methods: {
    home() {
      this.retornando = true;
      this.$router.push('/frmPrincipal');
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.cIModeloItem = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouRegistro(cIModeloItem) {
      this.cIModeloItem = { ...cIModeloItem }
      this.modo = "Registro";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.cIModeloItem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/CIModeloItens/${this.usuarioLogadoDados.tenant}/${this.cIModeloItem.id}`, this.cIModeloItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarCIModeloItens(this.usuarioLogadoDados.tenant, this.cIModeloSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.cIModeloItem.cIModeloId = this.cIModeloSelecionado.id;
        this.cIModeloItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/CIModeloItens/${this.usuarioLogadoDados.tenant}`, this.cIModeloItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarCIModeloItens(this.usuarioLogadoDados.tenant, this.cIModeloSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/CIModeloItens/${this.usuarioLogadoDados.tenant}/${this.cIModeloItem.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarCIModeloItens(this.usuarioLogadoDados.tenant, this.cIModeloSelecionado.id);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
                this.$$toasted.global.defaultError();
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.cIModeloItem = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.cIModeloItem = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.cIModeloItem.memo == "" || this.cIModeloItem.memo == undefined) {
        this.$toasted.show("Digite a Descrição para o Modelo", {type : "info", icon : "times"});
        return false;
      }

      if (this.cIModeloItem.quebraLinha == undefined) {
        this.$toasted.show("Selecione se o Modelo realiza uma Quebra de Linha", {type : "info", icon : "times"});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.carregarCIModeloItens(this.usuarioLogadoDados.tenant, this.cIModeloSelecionado.id);
    this.showMenu(false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.retornando && (this.modo=="Incluir" || this.modo=="Alterar")) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
