<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Tipos de Usuários</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Tipo de Profissional</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="profissionalTipo.id"
            />
          </div>

          <div class="form-group col-lg-3">
            <label for="Tipo" class="control-label">Tipo</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Tipo"
              name="Tipo"
              v-model="profissionalTipo.tipo"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="Grupo" class="control-label">Grupo</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Grupo"
              name="Grupo"
              v-model="profissionalTipo.grupo"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Funcao" class="control-label">Função</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Funcao"
              name="Funcao"
              v-model="profissionalTipo.funcao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="realizaAtendimento" class="control-label">Realiza Atendimento</label>
            <select
              id="realizaAtendimento"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalTipo.realizaAtendimento"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"/>

          <div class="form-group col-lg-1">
            <label for="grauPermissao" class="control-label">Camada</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="grauPermissao"
              name="grauPermissao"
              v-model.number="profissionalTipo.grauPermissao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="nivelPermissao" class="control-label">Nível Permissão na Camada</label>
            <select
              id="nivelPermissao"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalTipo.nivelPermissao"
            >
              <option
                v-for="resposta in nivelCRUD"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-3">
            <label for="nivelPermissaoViewInferiores" class="control-label">Nível Permissão View Camadas Inferiores</label>
            <select
              id="nivelPermissaoViewInferiores"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalTipo.nivelPermissaoViewInferiores"
            >
              <option
                v-for="resposta in nivelView"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-3">
            <label for="nivelPermissaoCRUDInferiores" class="control-label">Nível Permissão CRUD Camadas Inferiores</label>
            <select
              id="nivelPermissaoCRUDInferiores"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalTipo.nivelPermissaoCRUDInferiores"
            >
              <option
                v-for="resposta in nivelCRUD"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="MFA" class="control-label">Multi-Factor Authentication</label>
            <select
              id="MFA"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalTipo.mfa"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="modo='Alterar'"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center;max-height:550px;overflow:auto">
        <b-spinner v-if="loadingPerfis" label="carregando..."/>
        <b-table
          v-if="loadedPerfis"
          class="col-lg-8"
          hover
          striped
          small
          sort-icon-left
          :items="perfis"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button
              variant="warning"
              size="sm"
              @click="selecionouProfissionalTipo(row.item)"
              class="mr-2"
            >
              <i class="fa fa-pencil" />
            </b-button>
          </template>

          <template v-slot:cell(realizaAtendimento)="realizaAtendimento">
            {{realizaAtendimento.value = realizaAtendimento.value == "S" ? "Sim" : ""}}
          </template>
          <template v-slot:cell(mfa)="mfa">
            {{mfa.value = mfa.value == "S" ? "Sim" : ""}}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import perfis from "@/mixins/perfis";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";

export default {
  mixins: [perfis, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      modo: "",
      loadingPerfis: false,
      loadedPerfis: false,
      profissionalTipo: {},
      camposTabela: [
        { key: "id", label: "Código" },
        { key: "tipo", label: "Tipo", sortable: true },
        { key: "grupo", label: "Grupo", sortable: true },
        { key: "funcao", label: "Função", sortable: true },
        { key: "realizaAtendimento", label: "Atendimento", sortable: true },
        { key: "grauPermissao", label: "Camada" },
        { key: "nivelPermissao", label: "Nível Permissão" },
        { key: "mfa", label: "MFA" },
        { key: "actions", label: "Ações" }
      ],
      sim_nao : constantes.sim_nao,
      nivelCRUD : constantes.nivelCRUD,
      nivelView : constantes.nivelView
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouProfissionalTipo(profissionalTipo) {
      this.profissionalTipo = { ...profissionalTipo }
      this.modo = "Registro";
    },
    incluirRegistro() {
      this.profissionalTipo = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    cancelarOperacao() {
      this.profissionalTipo = {}
      this.modo = "";
      this.erro = "";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.profissionalTipo.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/perfis/${this.usuarioLogadoDados.tenant}/${this.profissionalTipo.id}`,
            this.profissionalTipo
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarPerfis(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.profissionalTipo.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/perfis/${this.usuarioLogadoDados.tenant}`, this.profissionalTipo)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.limparCampos();
            this.carregarPerfis(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?",{
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
      })
      .then(value => {
        if (value) {
          this.$http
            .delete(`v1.0/perfis/${this.usuarioLogadoDados.tenant}/${this.profissionalTipo.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
  
              this.limparCampos();
              this.carregarPerfis(this.usuarioLogadoDados.tenant);
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {});
        }
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
    },
    verificarCamposPreenchidos() {
      if (
        this.profissionalTipo.tipo === "" ||
        this.profissionalTipo.tipo === undefined
      ) {
        this.$toasted.show("Digite o Tipo", { type: "info", icon: "times" });

        return false;
      }

      if (
        this.profissionalTipo.grauPermissao === "" ||
        this.profissionalTipo.grauPermissao === undefined
      ) {
        this.$toasted.show("Digite o Nível de Permissão (1 à 5)", { type: "info", icon: "times" });

        return false;
      }

      return true;
    },
    limparCampos() {
      this.profissionalTipo = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    }
  },
  created() {
    this.showMenu(false);
    this.carregarPerfis(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo == "Incluir" || this.modo == "Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
