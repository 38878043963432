<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Operadoras</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <div class="form-group col-lg-4">
          <input type="radio" id="ativa" value="S" v-model="ativaLocal" />
          <label for="ativa" class="control-label ml-1 mr-4">Exibir Ativas</label>

          <input type="radio" id="inativa" value="N" v-model="ativaLocal" />
          <label for="inativa" class="control-label ml-1 mr-4">Exibir Inativas</label>

          <button @click="IncluirNovoRegistro()" class="btn btn-sm btn-info ml-2">Incluir Nova Operadora</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center; height:530px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loaded" class="col-lg-10" hover striped small sort-icon-left 
          :items="operadoras" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button class="mr-2" variant="warning" size="sm" @click="selecionouOperadora(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>

            <button
              class="btn btn-sm btn-info mr-1"
              @click.stop="selecionouPlanoSaude(row.item)"
            >Planos de Saúde</button>
          </template>
        </b-table>
        <!-- <table class="table table-sm table-hover col-lg-12" v-if="!loading"> -->
          <!-- <thead class="thead-light"> -->
            <!-- <th>Id</th> -->
            <!-- <th>Operadora</th> -->
            <!-- <th>Razão Social</th> -->
            <!-- <th>Início</th> -->
            <!-- <th></th> -->
          <!-- </thead> -->
          <!-- <tbody> -->
            <!-- <tr -->
              <!-- v-for="operadora in operadoras" -->
              <!-- :key="operadora.id" -->
              <!-- @click="selecionouOperadora(operadora)" -->
            <!-- > -->
              <!-- <td>{{operadora.id}}</td> -->
              <!-- <td>{{operadora.fantasia}}</td> -->
              <!-- <td>{{operadora.razaoSocial}}</td> -->
              <!-- <td>{{operadora.datInicioVigencia}}</td> -->
              <!-- <td> -->
                <!-- <button -->
                  <!-- class="btn btn-sm btn-info mr-2" -->
                  <!-- @click.stop="selecionouContato(operadora)" -->
                <!-- >Contatos</button> -->
                <!-- <button -->
                  <!-- class="btn btn-sm btn-info mr-2" -->
                  <!-- @click.stop="selecionouPlanoSaude(operadora)" -->
                <!-- >Planos de Saúde</button> -->
                <!-- <button -->
                  <!-- class="btn btn-sm btn-info mr" -->
                  <!-- @click.stop="selecionouDespesasCobertas(operadora)" -->
                <!-- >Taxas</button> -->
              <!-- </td> -->
            <!-- </tr> -->
          <!-- </tbody> -->
        <!-- </table> -->
      </div>
      <b-pagination v-if="!loading" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import operadoras from "@/mixins/operadoras";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [operadoras, global],
  computed: {
    ...mapGetters({
      ativa: "operadoras/getStatusAtiva",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      page : 1,
      ativaLocal: "S",
      loading: true,
      loaded: false,
      retornando: false,
      erro: "",
      mensagem : "",
      operadora: {},
      camposTabela : [
        {key : "id", label : "Id"},
        {key : "fantasia", label : "Operadora", sortable : true},
        {key : "razaoSocial", label : "Razão Social", sortable : true},
        {key : "actions", label : "Ações"},
      ],
    }
  },
  watch : {
    page() {
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, this.ativa, this.page);
    },
    ativaLocal() {
      this.selecionouOpcao(this.ativaLocal);
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, this.ativa, this.page);
    }
  },
  methods: {
    ...mapActions({
      setOperadoraAtiva: "operadoras/setStatusAtiva",
      setOperadoraSelecionada: "operadoras/setOperadoraSelecionada",
      limparRegistroSelecionado : "operadoras/limparRegistroSelecionado",
      formLeave: "operadoras/formLeave"
    }),
    IncluirNovoRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }

      this.limparRegistroSelecionado();
      this.$router.push('/cadOperadora');
    },
    async selecionouOperadora(operadora) {
      await this.carregarOperadora(this.usuarioLogadoDados.tenant, operadora.id);

      this.setOperadoraSelecionada(this.operadora);
      this.$router.push("/cadOperadora");
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    selecionouOpcao() {
      this.setOperadoraAtiva(this.ativaLocal);
    },
    selecionouContato(operadora) {
      this.setOperadoraSelecionada(operadora);
      this.$router.push("/frmOperadoraContatos");
    },
    selecionouPlanoSaude(operadora) {
      this.setOperadoraSelecionada(operadora);
      this.$router.push("/frmPlanosSaude");
    },
    selecionouDespesasCobertas(operadora) {
      this.setOperadoraSelecionada(operadora);
      this.$router.push("/frmDespesasCobertas");
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarOperadoras(this.usuarioLogadoDados.tenant, this.ativa, this.page);
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }
    next(true);
  }
}
</script>

<style scoped>
</style>
