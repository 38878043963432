<template>
  <div class="formulario">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Pacientes cadastrados</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <!-- <div class="corpo"> -->
      <div class="container-fluid">
        <div id="filtrarRegistros" class="row">
          <div class="form-group col-lg-3">
            <label for="campoBuscar" class="control-label">Selecione o Campo para Busca:</label>
            <br />
            <input type="radio" id="codigo" name="busca" value="Codigo" v-model="campoBusca" />
            <label for="codigo" class="control-label ml-1 mr-2">Código</label>
            <input type="radio" id="nome" name="busca" value="Nome" v-model="campoBusca" />
            <label for="nome" class="control-label ml-1 mr-2">Nome</label>
            <input type="radio" id="sobrenome" name="busca" value="SobreNome" v-model="campoBusca" />
            <label for="sobrenome" class="control-label ml-1 mr-2">Sobrenome</label>
            <input type="radio" id="cpf" name="busca" value="CpfNumero" v-model="campoBusca" />
            <label for="cpf" class="control-label ml-1">CPF</label>
          </div>

          <div class="form-group col-lg-3">
            <label for="Filter" class="control-label">Buscar:</label>
            <input
              type="text"
              class="form-control"
              id="filter"
              @input="filtrar=$event.target.value"
              placeholder="digite o que deseja localizar..."
            />
          </div>
          <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:25px">
            <button id="btnFiltrar" class="form-control" @click="iniciarCarregarPacientes()">
              <i class="fa fa-search"/>
            </button>
          </div>
          <div class="form-group col-sm-1 col-md-1 col-lg-2" style="padding-top:25px">
            <button
              id="Incluir"
              @click="incluirRegistro()"
              class="btn btn-md btn-info mr-2"
            >Novo Paciente</button>
          </div>
        </div>

        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>

        <div style="display:flex; flex-direction:row; justify-content:center;height:480px;overflow:auto">
          <!-- <b-table v-if="!loading" class="col-lg-12" hover striped small sort-icon-left :items="pacientes" :fields="camposTabela"> -->
            <!-- <template v-slot:cell(actions)="row"> -->
              <!-- <b-button variant="warning" size="sm" @click="selecionouPaciente(row.item)"> -->
                <!-- <i class="fa fa-pencil"/> -->
              <!-- </b-button> -->
            <!-- </template> -->
          <!-- </b-table> -->

          <b-spinner v-if="loading" label="carregando..."/>
          <table class="table table-sm table-hover col-lg-12" v-if="loaded">
            <thead class="thead-light">
              <th>Id</th>
              <th>Nome</th>
              <th>Data Nasc.</th>
              <th>CPF</th>
              <th>DDD</th>
              <th>Celular</th>
              <th>Ações</th>
            </thead>
            <tbody>
              <tr
                v-for="paciente in pacientes"
                :key="paciente.id"
              >
                <td>{{paciente.id}}</td>
                <td>{{paciente.nome}}</td>
                <td>{{paciente.datNascimento | formatDate}}</td>
                <td>{{paciente.cpfNumero}}</td>
                <td>{{paciente.DddCelular}}</td>
                <td>{{paciente.celular}}</td>
                <td>
                  <button class="btn btn-sm btn-warning" @click="selecionouPaciente(paciente)"><i class="fa fa-pencil"/></button>
                  <button class="btn btn-sm btn-info ml-1" v-if="pacienteModulo=='Agenda'" @click="agendarPaciente(paciente)"><i class="fa fa-calendar-o"/></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
      </div>
    <!-- </div> -->
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pacientes from "@/mixins/pacientes";
import global from "@/mixins/global";
import funcoes from "@/utils/funcoes";
import errors from "@/utils/errors";

export default {
  mixins: [pacientes, global],
  computed: {
    ...mapGetters({
      pacienteModulo : "pacientes/getModulo",
      ultimaBusca : "pacientes/getUltimaBusca",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    })
  },
  data() {
    return {
      page : 1,
      count : 0,
      limit : 0,
      campoBusca: "Nome",
      loading: false,
      loaded: false,
      erro: "",
      modo: "",
      mensagem : "",
      filtrar: "",
      pacientes: [],
      camposTabela: [
        {key : "id", label : "Pront"},
        {key : "nome", label : "Nome do Paciente", sortable : true},
        {key : "datNascimento", label : "Nascimento"},
        {key : "cpfNumero", label : "CPF"},
        {key : "DddCelular", label : "DDD"},
        {key : "celular", label : "Celular"},
        {key : "actions", label : "Ações"},
      ],
      retornando : false,
      agendandoPaciente : false
    }
  },
  watch: {
    page() {
      this.iniciarCarregarPacientes();
    }
  },
  methods: {
    ...mapActions({
      setPacienteSelecionado: "pacientes/setPacienteSelecionado",
      limparRegistroSelecionado: "pacientes/limparRegistroSelecionado",
      setUltimaBusca: "pacientes/setUltimaBusca",
      formLeave: "pacientes/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    iniciarCarregarPacientes() {
      if (this.campoBusca == "Codigo") {
        this.carregarPacientePeloId(this.usuarioLogadoDados.tenant, this.filtrar, true);
      } else if (this.campoBusca == "Nome") {
        this.carregarPacientesPeloNome(this.usuarioLogadoDados.tenant, this.filtrar, this.page);
      } else if (this.campoBusca == "SobreNome") {
        this.carregarPacientesPeloSobreNome(this.usuarioLogadoDados.tenant, this.filtrar, this.page);
      } else if (this.campoBusca == "CpfNumero") {
        this.carregarPacientesPeloCpf(this.usuarioLogadoDados.tenant, this.filtrar, this.page);
      }
    },
    async selecionouPaciente(pacienteSelecionado) {
      await this.carregarPacienteRegistrarAcesso(this.usuarioLogadoDados.tenant, pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);

      this.paciente.datNascimento = funcoes.mySqlToJSDateFormat(this.paciente.datNascimento);
      this.setPacienteSelecionado(this.paciente);
      this.$router.push("/cadPaciente");
    },
    agendarPaciente(paciente) {
      let pacienteAgendar = {id : paciente.id, paciente : paciente.nome, reagendarId : null};
      this.setPacienteSelecionado(pacienteAgendar);
      this.agendandoPaciente = true;
      this.retornar();
    },
    incluirRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }
      
      this.limparRegistroSelecionado();
      this.$router.push('/cadPaciente');
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);

    if (this.ultimaBusca.campoBusca != undefined) {
      this.campoBusca = this.ultimaBusca.campoBusca,
      this.filtrar = this.ultimaBusca.filtrar,
      this.page = this.ultimaBusca.page;
      this.count = this.ultimaBusca.count;
      this.limit = this.ultimaBusca.limit;

      this.iniciarCarregarPacientes();
    }
  },
  beforeRouteLeave(to, from , next) {
    if (this.retornando) {
      if (!this.agendandoPaciente) {
        this.formLeave();
      }
      this.setUltimaBusca(null);
    } else {
      let registro = {
        campoBusca : this.campoBusca,
        filtrar : this.filtrar,
        page : this.page,
        count : this.count,
        limit : this.limit,
      }
  
      this.setUltimaBusca(registro);
    }

    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
