<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{consultorioSelecionado.descricao}} - Itens</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Ítem</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-sm-1 col-md-1 col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="consultorioItem.id" />
          </div>

          <div class="form-group col-sm-2 col-md-4 col-lg-3">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="consultorioItem.descricao"
            />
          </div>
          <div class="form-group col-sm-2 col-md-4 col-lg-3">
            <label for="NomeCientifico" class="control-label">Nome Científico</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="NomeCientifico"
              name="NomeCientifico"
              v-model="consultorioItem.nomeCientifico"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-2 col-md-4 col-lg-2">
            <label for="Uso" class="control-label">Uso</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Uso"
              name="Uso"
              v-model="consultorioItem.uso"
            />
          </div>
          <div class="form-group col-sm-2 col-md-4 col-lg-2">
            <label for="Unidade" class="control-label">Unidade</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Unidade"
              name="Unidade"
              v-model="consultorioItem.unidade"
            />
          </div>
          <div class="form-group col-sm-2 col-md-4 col-lg-2">
            <label for="Inicial" class="control-label">Inicial</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Inicial"
              name="Inicial"
              v-model="consultorioItem.inicial"
            />
          </div>
        </div>

        <!---------------------------- Inserir Campos e outros ------------------------------------->
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="InserirCampo" class="control-label">Inserir Campo:</label>
            <select
              id="InserirCampo"
              class="form-control"
              :disabled="!liberarCampos"
              @change="inserirCampo()"
              v-model="campo"
            >
              <option
                v-for="resposta in campos"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="InserirVariavel" class="control-label">Inserir Variável:</label>
            <select
              id="InserirVariavel"
              class="form-control"
              :disabled="!liberarCampos"
              @change="inserirVariavel()"
              v-model="variavel"
            >
              <option
                v-for="resposta in variaveis"
                :key="resposta.id"
                :value="resposta.nome"
              >{{resposta.descricao}}</option>
            </select>
          </div>
        </div>

        <!---------------------------------------- Editor -------------------------------------------->
        <div class="row">
          <div class="form-group col-lg-12">
            <VueEditor v-model="consultorioItem.memo" :disabled="!liberarCampos" style="max-height:400px; overflow:auto"/>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center; max-height:500px; overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="!loading"
          class="col-lg-6"
          hover
          striped
          small
          sort-icon-left
          :items="consultorioItensParcial"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button variant="info" size="sm" @click="selecionouModelos(row.item)" class="mr-2">
              Modelos
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import consultorioItens from "@/mixins/consultorioItens";
import global from "@/mixins/global";
import variaveis from "@/mixins/variaveis";
import {VueEditor} from "vue2-editor"
import constantes from "@/utils/constantes"
import errors from "@/utils/errors";

export default {
  mixins: [consultorioItens, variaveis, global],
  components : {VueEditor},
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      consultorioSelecionado : "consultorios/getConsultorioSelecionado"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      consultorioItem: {},
      retornando : false,
      loading: true,
      erro: "",
      mensagem : "",
      modo : "",
      
      variavel : "",
      campo : "",

      campos : constantes.inserirCampo,

      camposTabela: [
        { key: "id", label: "Código"},
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "actions", label: "Ações" }
      ]
    }
  },
  methods: {
    ...mapActions({
      setConsultorioItemSelecionado : "consultorioItens/setConsultorioItemSelecionado"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.consultorioItem = {}

      if (this.variaveis.length==0) {
        this.carregarVariaveis(this.usuarioLogadoDados.tenant);
      }

      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },    
    selecionouRegistro(consultorioItem) {
      //this.consultorioItem = { ...consultorioItem }

      this.carregarConsultorioItem(this.usuarioLogadoDados.tenant, consultorioItem.id);
      if (this.variaveis.length==0) {
        this.carregarVariaveis(this.usuarioLogadoDados.tenant);
      }

      this.modo = "Registro";
    },
    selecionouModelos(consultorioItem) {
      this.setConsultorioItemSelecionado(consultorioItem);
      this.$router.push('./frmCIModelos')
    },
    inserirCampo() {
      this.$bvModal
        .msgBoxConfirm("Inserir campo " + this.campo + "?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            if (this.consultorioItem.memo == undefined)  {
              this.consultorioItem.memo = this.campo;
            } else {
              this.consultorioItem.memo += this.campo;
            }
            this.campo = "";
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });

    },
    inserirVariavel() {
      this.$bvModal
        .msgBoxConfirm("Inserir variável " + this.variavel + "?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            if (this.consultorioItem.memo == undefined) {
              this.consultorioItem.memo = "««" + this.variavel + "»»";
            } else {
              this.consultorioItem.memo += "««" + this.variavel + "»»";
            }
            this.variavel = "";
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });

    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.consultorioItem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/consultorioItens/${this.usuarioLogadoDados.tenant}/${this.consultorioItem.id}`, this.consultorioItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarConsultorioItensParcial(this.usuarioLogadoDados.tenant, this.consultorioSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.consultorioItem.consultorioId = this.consultorioSelecionado.id;
        this.consultorioItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/consultorioItens/${this.usuarioLogadoDados.tenant}`, this.consultorioItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarConsultorioItensParcial(this.usuarioLogadoDados.tenant, this.consultorioSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/consultorioItens/${this.usuarioLogadoDados.tenant}/${this.consultorioItem.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarConsultorioItensParcial(this.usuarioLogadoDados.tenant, this.consultorioSelecionado.id);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.consultorioItem = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.consultorioItem = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.consultorioItem.descricao == "" || this.consultorioItem.descricao == undefined) {
        this.$toasted.show("Digite a Descrição para o Item", {type : "info", icon : "times"});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.carregarConsultorioItensParcial(this.usuarioLogadoDados.tenant, this.consultorioSelecionado.id);
    this.showMenu(false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.retornando && (this.modo=="Incluir" || this.modo=="Alterar")) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
