import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrar: "",
            count : 0,
            limit : 0,
            cboss : []
        }
    },
    methods: {
        carregarCbos(filtrar, pagina) {
          this.loadingCBOS = true;
          this.loadedCBOS = false;

          if (filtrar == "") {
            this.$http
              .get(`/v1.0/cbos/pagina/${pagina}`)
              .then(resp => {
                this.cboss = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;
                this.erro = "";

                this.loadedCBOS = true;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
              .finally(() => {
                this.loadingCBOS = false;
              })
          } else {
            this.$http
            .get(`/v1.0/cbos/${filtrar}/${pagina}`)
            .then(resp => {
              this.cboss = resp.data.registros;
              this.count = resp.data.count;
              this.limit = resp.data.limit;
  
              this.erro = "";
              this.loadedCBOS = true;
            })
            .catch(error => (this.erro = errors.tratarErros(error)))
            .finally(() => {
              this.loadingCBOS = false;
            })
          }
        },
        carregarCbosPeloCodTermo(codTermo) {
          this.loadingCBOS = true;
          this.loadedCBOS = false;

          this.$http.get(`/v1.0/cbos/codtermo/${codTermo}`)
          .then(resp => {
            this.cbos = resp.data;
            this.erro = "";

            this.loadedCBOS = true;
          })
          .catch(error => (this.erro = errors.tratarErros(error)))
          .finally(() => {
            this.loadingCBOS = false;
          })
        },
        carregarCbosPeloId(cbosId) {
          this.loadingCBOS = true;
          this.loadedCBOS = false;

          this.$http.get(`/v1.0/cbos/${cbosId}`)
          .then(resp => {
            this.cbos = resp.data;
            this.erro = "";

            this.loadedCBOS = true;
          })
          .catch(error => (this.erro = errors.tratarErros(error)))
          .finally(() => {
            this.loadingCBOS = false;
          })
 }
    }
}
