import errors from "@/utils/errors";

export default {
    data() {
        return {
            pOProfissionais : []
        }
    },
    methods : {
        async carregarPOProfissionais(tenant, pacienteOperadoraId) {
            await this.$http.get(`/v1.0/poprofissionais/${tenant}/pacienteOperadora/${pacienteOperadoraId}`)
            .then(resp => {
                this.pOProfissionais = resp.data;
                this.loading = false;
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
                //this.$toasted.global.defaultError();
            })
        }
    }
}
