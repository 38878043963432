<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Gerar Agenda de Atendimentos</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="quadro">
        <div class="entradas">
          <div class="form-group col-lg-2">
            <label for="datInicial" class="control-label">Data Inicial:</label>
            <input type="date" class="form-control" id="datInicial" v-model="datInicial" />
          </div>
          <div class="form-group col-lg-2">
            <label for="datFinal" class="control-label">Data Final:</label>
            <input type="date" class="form-control" id="datFinal" v-model="datFinal" />
          </div>
          <div class="form-group col-lg-3">
            <label for="SelecionarProfissional" class="control-label">Profissional:</label>
            <select id="SelecionarProfissional" class="form-control" v-model="profissionalId">
              <option
                v-for="profissional in profissionais"
                :key="profissional.id"
                :value="profissional.id"
              >{{profissional.nome}}</option>
            </select>
          </div>
          <div class="form-group col-lg-3">
            <label for="SelecionarUnidade" class="control-label">Unidade:</label>
            <select id="SelecionarUnidade" class="form-control" v-model="unidadeId">
              <option
                v-for="unidade in unidades"
                :key="unidade.id"
                :value="unidade.id"
              >{{unidade.descricao}}</option>
            </select>
          </div>
        </div>

        <div class="opcoes">
          <button class="btn btn-info btn-small" @click="gerarAgenda()">Gerar Agenda</button>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-center">
      <b-spinner v-if="loading" label="carregando..." />
      <b-spinner v-if="saving" label="gravando dados..." />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import profissionais from "@/mixins/profissionais"
import profissionalDisponibilidades from "@/mixins/profissionalDisponibilidades";
import unidades from "@/mixins/unidades";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
    mixins: [profissionais, profissionalDisponibilidades, unidades, global],
    data() {
        return {
            datInicial : null,
            datFinal : null,
            profissionalId : 0,
            unidadeId : 0,
            loading : false,
            saving : false,
            erro : ""
        }
    },
    watch: {
        profissionalId() {
            if (this.datInicial != null && this.datFinal != null && this.unidadeId != 0) {
                this.carregarProfissionalDisponibilidades(this.usuarioLogadoDados.tenant, this.unidadeId, this.profissionalId, 'T', 99);
            }
        },
        unidadeId() {
            if (this.datInicial != null && this.datFinal != null && this.profissionalId != 0) {
                this.carregarProfissionalDisponibilidades(this.usuarioLogadoDados.tenant, this.unidadeId, this.profissionalId, 'T', 99);
            }
        }
    },
    computed : {
        ...mapGetters({
            usuarioLogadoDados : "global/getUsuarioLogadoDados"
        })
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        gerarAgenda() {
            if (this.datInicial == null || this.datFinal == null) {
                this.$toasted.show("Informe a Data Inicial e a Data Final.", {type : "info", icon : "time"});
                return;
            }

            this.profissionalId = this.profissionalId > 0 ? this.profissionalId : 99999;

            if (this.unidadeId==0) {
                this.$toasted.show("Selecione o Unidade.", {type : "info", icon : "time"});
                return;
            }

            this.saving = true;

            this.$http.post(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/geraragenda/${this.profissionalId}/${this.unidadeId}/${this.datInicial}/${this.datFinal}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.$toasted.global.defaultSuccess();
              this.erro = ""
            })
            .catch((error) => this.erro = errors.tratarErros(error))
            .finally(() => {
              this.saving = false;
            })
        }
    },
    created() {
        this.showMenu(false);
        this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);
        this.carregarUnidades(this.usuarioLogadoDados.tenant);
    }
}
</script>

<style scoped>
.quadro {
  margin-top: 40px;
}

.entradas {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.opcoes {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
