import errors from "@/utils/errors";

export default {
    data() {
        return {
            countOrigem : 0,
            limitOrigem : 0,
            origens : [],

            countCentroOrigem : 0,
            limitCentroOrigem : 0,
            centroOrigens : [],
        }
    },
    methods : {
        carregarOrigens(tenant, fluxoId, pagina, incluirEspeciais) {
            this.$http.get(`/v1.0/origens/${tenant}/Fluxo/${fluxoId}/${pagina}`)
            .then(resp => {
                this.origens = resp.data.registros;
                this.countOrigem = resp.data.count;
                this.limitOrigem = resp.data.limit;

                this.erro = "";
                this.loading = false;

                if (!incluirEspeciais) {
                    this.origens = this.origens.filter(origem => origem.tipoSistema == null)
                }
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        carregarCentroOrigens(tenant, centroId, pagina) {
            this.$http.get(`/v1.0/centroOrigens/${tenant}/Centro/${centroId}/${pagina}`)
            .then(resp => {
                this.centroOrigens = resp.data.registros;
                this.countCentroOrigem = resp.data.count;
                this.limitCentroOrigem = resp.data.limit;

                this.erro = "";
                this.loadingCentroOrigens = false;
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
    }
}
