<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}}- Operadoras</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info mr-1"
            @click="incluirRegistro()"
          >{{modo=='' ? "Adicionar Nova Operadora" : "Fechar Adicionar"}}</button>
          <hr/>
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="Operadoras" class="control-label">Operadoras</label>
            <select
              id="Operadoras"
              class="form-control"
              :disabled="!liberarCampos || modo!='Incluir'"
              selected
              v-model="profissionalOperadoraSelecionada.operadoraId"
            >
              <option v-for="operadora in operadoras" :key="operadora.id" :value="operadora.id">
                {{operadora.fantasia}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="Documentos" class="control-label">Documento</label>
            <select
              id="Documentos"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalOperadoraSelecionada.documento"
            >
              <option v-for="profissionalDocumento in profissionaisDocumentos" :key="profissionalDocumento.opcao" :value="profissionalDocumento.opcao">{{profissionalDocumento.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="Numero" class="control-label">Número</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Numero"
              name="Numero"
              v-model="profissionalOperadoraSelecionada.numero"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="DocumentoSADT" class="control-label">Documento SADT</label>
            <select
              id="DocumentoSADT"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="profissionalOperadoraSelecionada.documentoSADT"
            >
              <option v-for="profissionalDocumento in profissionaisDocumentos" :key="profissionalDocumento.opcao" :value="profissionalDocumento.opcao">{{profissionalDocumento.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="NumeroSADT" class="control-label">Número SADT</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="NumeroSADT"
              name="NumeroSADT"
              v-model="profissionalOperadoraSelecionada.numeroSADT"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="gravar()">Confirmar
          </button>
        </div>
      </div>

      <!-- Exibição de Erros -->
      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>
      <hr style="margin:0px"/>
      <!-- Tabela de contratadosOperadoras -->
      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loadingProfissionalOperadora" label="carregando..."/>
        <b-table v-if="loadedProfissionalOperadora" class="col-lg-8" hover striped small sort-icon-left :items="profissionalOperadoras" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="editarOperadora(row.item)" class="mr-2">
              <i class="fa fa-pencil"/> 
            </b-button>  
            <b-button variant="danger" size="sm" @click="removerOperadora(row.item)" class="mr-2">
              <i class="fa fa-minus"/> 
            </b-button>  
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global";
import profissionalOperadoras from "@/mixins/operadoraProfissionais";
import operadoras from "@/mixins/operadoras";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
    mixins : [profissionalOperadoras, operadoras, global],
    computed: {
      ...mapGetters({
        profissionalSelecionado : "profissionais/getProfissionalSelecionado",
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
      }),
      liberarCampos() {
        return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
      }
    },
    data() {
      return {
        erro : "",
        loadingProfissionalOperadora : false,
        loadedProfissionalOperadora : false,
        modo : "",
        mensagem : "",
        profissionalOperadoraSelecionada : 0,
        camposTabela : [
          {key : "operadora", label : "Operadora", sortable : true},
          {key : "documento", label : "Documento"},
          {key : "numero", label : "Número"},
          {key : "actions", label : "Ações"},
        ],
        profissionaisDocumentos : constantes.contratadosDocumentos,
      }
    },
    methods: {
      retornar() {
        this.$router.go(-1);
      },
      cancelarOperacao() {
        this.profissionalOperadoraSelecionada = {}
        this.modo = "";
        this.erro = "";
      },
      editarOperadora(profissionalOperadora) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.modo = "Alterar";
        this.profissionalOperadoraSelecionada = {...profissionalOperadora}

        if (this.operadoras.length==0) {
          this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);
        }
      },
      incluirRegistro() {
        if (this.modo == "" && !this.verificarPermissao('Incluir')) {
          return
        }

        this.profissionalOperadoraSelecionada = {}
        this.modo = this.modo == "" ? "Incluir" : "";

        if (this.operadoras.length==0) {
          this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);
        }
      },
      gravar() {
        if (!this.verificarCampos()) {
          return;
        }

        if (this.modo=="Alterar") {
          this.profissionalOperadoraSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.$http.put(`/v1.0/operadorasprofissionais/${this.usuarioLogadoDados.tenant}/${this.profissionalOperadoraSelecionada.id}`, this.profissionalOperadoraSelecionada)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.profissionalOperadoraSelecionada = {}
            this.carregarProfissionalOperadoras(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        } else {
          if (!this.verificarDuplicidade()) {
            return;
          }

          this.profissionalOperadoraSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
          this.profissionalOperadoraSelecionada.profissionalId = this.profissionalSelecionado.id;

          this.$http.post(`/v1.0/operadorasprofissionais/${this.usuarioLogadoDados.tenant}`, this.profissionalOperadoraSelecionada)
          .then(() => {
            this.erro = "";
            this.profissionalOperadoraSelecionada = {}
            this.carregarProfissionalOperadoras(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        }
      },
      removerOperadora(profissionalOperadora) {
        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Remover Registro Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/operadorasprofissionais/${this.usuarioLogadoDados.tenant}/${profissionalOperadora.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";
                  this.$toasted.global.defaultSuccess();
                  this.carregarProfissionalOperadoras(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      verificarCampos() {
        if (this.profissionalOperadoraSelecionada.operadoraId==undefined) {
          this.$toasted.show("Selecione uma Operadora", {type : "info", icon : "times"});
          return false;
        }

        if (this.profissionalOperadoraSelecionada.documento==undefined) {
          this.$toasted.show("Selecione um Documento", {type : "info", icon : "times"});
          return false;
        }

        if (this.profissionalOperadoraSelecionada.numero==undefined) {
          this.$toasted.show("Digíte o Número do Documento", {type : "info", icon : "times"});
          return false;
        }

        return true;
      },
      verificarDuplicidade() {
        let cadastradas = this.profissionalOperadoras.filter(co => co.operadoraId == this.profissionalOperadoraSelecionada.operadoraId);

        if (cadastradas.length>0) {
          this.$toasted.show("Operadora já foi Adicionada anteriormente.", {type : "info", icon : "times"});
          return false;
        }

        return true;
      },
      verificarPermissao(operacao) {
        if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
          let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
  
          this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
          this.$bvModal.show("modal-mensagem");
  
          return false;
        }
  
        return true;
      }
    },
    created() {
        this.showMenu(false);
        this.carregarProfissionalOperadoras(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
    }
}
</script>

<style scoped>

</style>
