import errors from "@/utils/errors";

export default {
    data() {
      return {
        count : 0,
        limit : 0,
        especialidades : []
      }
    },
    methods: {
        async carregarEspecialidades(tenant, pagina) {
          await this.$http
            .get(`/v1.0/especialidades/${tenant}/pagina/${pagina}`)
            .then(resp => {
              this.especialidades = resp.data.registros;
              this.count = resp.data.count;
              this.limit = resp.data.limit;

              this.erro = "";
              this.loading = false;
            })
            .catch(error => (this.erro = errors.tratarErros(error)))
        }
    }
}
