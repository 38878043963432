<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Lançamentos de {{parametrosLanctoGuia.paciente.nome}} - Prontuário: {{parametrosLanctoGuia.paciente.id}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo=='Incluir' || modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirNovaGuia()"
          >{{modo=='' ? "Incluir Nova Guia para o(a) Paciente" : "Fechar Inclusão"}}</button>
        </div>
        <br />
      </div>
      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="col-lg-2 flex-center">
              <label for="selecionarData" class="control-label">Data Atendimento</label>
              <input type="date" id="selecionarData" class="form-control" v-model="guiaSelecionada.datAtendimento"/>
          </div>
          <div class="col-lg-2">
              <label for="profissionalSolicitante" class="control-label">Profissional Solicitante</label>
              <select id="profissionalSolicitante" class="form-control" v-model="guiaSelecionada.profissionalSolicitanteId" selected>
                  <option v-for="profissional in profissionaisSolicitantes" :key="profissional.id" :value="profissional.id">
                      {{profissional.nome}}
                  </option>
              </select>
          </div>
          <div class="col-lg-2">
              <label for="profissionalExecutante" class="control-label">Profissional Executante</label>
              <select id="profissionalExecutante" class="form-control" v-model="guiaSelecionada.profissionalExecutanteId" selected>
                  <option v-for="profissional in profissionaisExecutantes" :key="profissional.id" :value="profissional.id">
                      {{profissional.nome}}
                  </option>
              </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="OperadorasConta" class="control-label">Operadora</label>
            <select
              class="form-control"
              id="OperadorasConta"
              name="OperadorasConta"
              selected
              v-model="guiaSelecionada.operadoraId"
              @change="selecionouOperadora()"
            >
              <option
                v-for="operadora in operadoras"
                :key="operadora.id"
                :value="operadora.id"
              >{{ operadora.fantasia }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="PlanoSaudeConta" class="control-label">Plano</label>
            <select
              class="form-control"
              :disabled="guiaSelecionada.operadoraId==0"
              id="PlanoSaudeConta"
              name="PlanoSaudeConta"
              selected
              v-model="guiaSelecionada.planoSaudeId"
            >
              <option
                v-for="planosaude in planosSaude"
                :key="planosaude.id"
                :value="planosaude.id"
              >{{ planosaude.plano }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="from-group col-lg-2">
            <label for="atendimentoSeriado" class="control-label">Atendimento Seriado</label>
            <select id="atendimentoSeriado" class="form-control" :disabled="guiaSelecionada.planoSaudeId==0" 
              v-model="guiaSelecionada.atendimentoSeriado" selected>
              <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                {{resposta.valor}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="TipoGuiaConsulta" class="control-label">Tipo Guia</label>
            <select
              id="TipoGuiaConsulta"
              class="form-control"
              :disabled="guiaSelecionada.planoSaudeId==0"
              selected
              v-model="guiaSelecionada.tipoLote"
            >
              <option
                v-for="tipoLote in tiposLotes"
                :key="tipoLote.opcao"
                :value="tipoLote.opcao"
              >{{tipoLote.valor}}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="guiaOperadora" class="control-label">Guia na Operadora</label>
            <input type="text" id="guiaOperadora" class="form-control" v-model="guiaSelecionada.guiaOperadora" 
            :disabled="guiaSelecionada.tipoLote==-1">
          </div>
          <div class="form-group col-lg-2">
            <label for="guiaPrestador" class="control-label">Guia no Prestador</label>
            <input type="text" id="guiaPrestador" class="form-control" v-model="guiaSelecionada.guiaPrestador" 
            :disabled="guiaSelecionada.tipoLote==-1">
          </div>
          <div class="form-group col-lg-2">
            <label for="tissCaraterAtendimento" class="control-label">Carater Atendimento</label>
            <select
              id="tissCaraterAtendimento"
              class="form-control"
              selected
              v-model="guiaSelecionada.tissCaraterAtendimento"
            >
              <option
                v-for="item in caraterAtendimento"
                :key="item.opcao"
                :value="item.opcao"
              >{{item.valor}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 flex-center">
              <label for="datAutorizacao" class="control-label">Data Autorização</label>
              <input type="date" id="datAutorizacao" class="form-control" v-model="guiaSelecionada.datAutorizacao"
              :disabled="guiaSelecionada.guiaOperadora==null"/>
          </div>
          <div class="form-group col-lg-2">
            <label for="senhaAutorizacao" class="control-label">Senha Autorização</label>
            <input type="text" id="senhaAutorizacao" class="form-control" v-model="guiaSelecionada.senhaAutorizacao"
            :disabled="guiaSelecionada.guiaOperadora==null">
          </div>
          <div class="col-lg-2 flex-center">
              <label for="datValidadeSenha" class="control-label">Validade</label>
              <input type="date" id="datValidadeSenha" class="form-control" v-model="guiaSelecionada.datValidadeSenha" 
              :disabled="guiaSelecionada.senhaAutorizacao==null"/>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-lg-1 flex-center">
              <label for="valTratamento" class="control-label">R$ Tratamentos</label>
              <input type="number" id="valTratamento" class="form-control" v-model="guiaSelecionada.valTratamentos"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valMateriais" class="control-label">R$ Materiais</label>
              <input type="number" id="valMateriais" class="form-control" v-model="guiaSelecionada.valMateriais"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valMedicamentos" class="control-label">R$ Medicam.</label>
              <input type="number" id="valMedicamentos" class="form-control" v-model="guiaSelecionada.valMedicamentos"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valTaxas" class="control-label">R$ Taxas</label>
              <input type="number" id="valTaxas" class="form-control" v-model="guiaSelecionada.valTaxas"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valPacotes" class="control-label">R$ Pacotes</label>
              <input type="number" id="valPacotes" class="form-control" v-model="guiaSelecionada.valPacotes"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valDescontosTratamentos" class="control-label">R$ Desc. Trat</label>
              <input type="number" id="valDescontosTratamentos" class="form-control" v-model="guiaSelecionada.valDescontosTratamentos"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valDescontosDespesas" class="control-label">R$ Desc. Desp</label>
              <input type="number" id="valDescontosDespesas" class="form-control" v-model="guiaSelecionada.valDescontosDespesas"
              disabled/>
          </div>
          <div class="col-lg-1 flex-center">
              <label for="valTotal" class="control-label">R$ Total</label>
              <input type="number" id="valTotal" class="form-control" v-model="guiaSelecionada.valTotal"
              disabled/>
          </div>
        </div>
        <hr/>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
            @click="guiaCancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="gravarGuia()">Confirmar
          </button>
        </div>
      </div>
      <hr style="margin:0px"/>
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <!-- Tabela de Guias do Paciente -->
      <div style="display:flex; flex-direction:row; justify-content:center;max-height:400px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" hover striped small sort-icon-left 
          :items="guias" :fields="camposTabela">
          <template v-slot:cell(datAtendimento)="datAtendimento">
              {{ datAtendimento.value | formatDate}}
          </template>    
          <template v-slot:cell(datAutorizacao)="datAutorizacao">
              {{ datAutorizacao.value | formatDate}}
          </template>    
          <template v-slot:cell(datValidadeSenha)="datValidadeSenha">
              {{ datValidadeSenha.value | formatDate}}
          </template>    
          <template v-slot:cell(datCadastro)="datCadastro">
              {{ datCadastro.value | formatDate}}
          </template>    
          <template v-slot:cell(tipoLote)="tipoLote">
              {{ tipoLote.value == 1 ? "Cons" : tipoLote.value == 2 ? "SADT" : "Hon."}}
          </template>    
          <template v-slot:cell(particular)="particular">
              {{ particular.value == "S" ? "Sim" : "Não"}}
          </template>    

          <template v-slot:cell(atendimentoSeriado)="atendimentoSeriado">
            {{atendimentoSeriado.value == "S" ? "Sim" : "Não"}}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button v-if="row.item.loteId==null || chamadoPor=='Faturamento'" variant="warning" size="sm" class="mr-1" @click="alterarGuiaSelecionada(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button v-if="row.item.loteId==null || chamadoPor=='Faturamento'" variant="danger" size="sm" class="mr-1" @click="excluirGuiaSelecionada(row.item)">
              <i class="fa fa-trash"/>
            </b-button>
            <b-button  variant="info" size="sm" @click="lancarItens(row.item)">
              <i class="fa fa-money"/>
            </b-button>
          </template>
        </b-table>
      </div>  
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import operadoras from "@/mixins/operadoras"
import planosSaude from "@/mixins/planosSaude"
import profissionais from "@/mixins/profissionais"
import guias from "@/mixins/guias"
import global from "@/mixins/global"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"
import tiss from "@/utils/tiss"
import errors from "@/utils/errors"

export default {
    mixins : [operadoras, planosSaude, profissionais, guias, global],
    computed: {
        ...mapGetters({
          chamadoPor : "guias/getChamadoPor",
          parametrosLanctoGuia : "guias/getParametrosLanctoGuia",
          usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
      return {
        erro : "",
        loading : true,
        modo : "",
        guiaSelecionada : {},
        retornando : false,
        mensagem : "",
        camposTabela : [
          {key : "id", label : "Id"},
          {key : "datAtendimento", label : "Atendimento", sortable : true},
          {key : "tipoLote", label : "Tipo", sortable : true},
          {key : "atendimentoSeriado", label : "Seriado", sortable : true},
          {key : "profissionalExecutante", label : "Profissional Executante", sortable : true},
          {key : "guiaOperadora", label : "Guia Operad"},
          {key : "guiaPrestador", label : "Guia Prest"},
          {key : "operadora", label : "Operadora", sortable : true},
          {key : "planoSaude", label : "Plano de Saúde", sortable : true},
          {key : "particular", label : "Particular"},
          {key : "loteId", label : "Lote"},
          {key : "reciboMedico", label : "Nº Rec Méd"},
          {key : "actions", label : "Ações"}
        ],

        sim_nao : constantes.sim_nao,
        caraterAtendimento : tiss.caraterAtendimento,
        tiposLotes : constantes.tiposLotes,
        profissionaisSolicitantes : [],
        profissionaisExecutantes : [],
      }
    },
    methods: {
      ...mapActions({
        setGuiaChamadoPor : "guias/setChamadoPor",
        setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
        setGuiaSelecionada : "guias/setGuiaSelecionada",
        setPlanoSaudeSelecionado: "planosSaude/setPlanoSaudeSelecionado",
      }),
      retornar() {
        this.retornando = true;
        this.$router.go(-1);
      },
      selecionouOperadora() {
        this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.guiaSelecionada.operadoraId, 'S');
      },
      alterarGuiaSelecionada(guia) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.modo = "Alterar";

        this.guiaSelecionada = {
          id : guia.id,
          datAtendimento : funcoes.mySqlToJSDateFormat(guia.datAtendimento), 
          pacienteId : guia.pacienteId,
          profissionalSolicitanteId : guia.profissionalSolicitanteId, 
          profissionalExecutanteId : guia.profissionalExecutanteId, 
          guiaOperadora : guia.guiaOperadora,
          guiaPrestador : guia.guiaPrestador,
          planoSaudeId : guia.planoSaudeId, 
          planoSaudeLanctoId : guia.planoSaudeLanctoId,
          tipoLote : guia.tipoLote, 
          senhaAutorizacao : guia.senhaAutorizacao, 
          atendimentoSeriado : guia.atendimentoSeriado,
          tissCaraterAtendimento : guia.tissCaraterAtendimento,
          valTratamentos : guia.valTratamentos,
          valMateriais : guia.valMateriais,
          valMedicamentos : guia.valMedicamentos,
          valTaxas : guia.valTaxas,
          valPacotes : guia.valPacotes,
          valTotal : guia.valTotal,
          valDescontosTratamentos : guia.valDescontosTratamentos,
          valDescontosDespesas : guia.valDescontosDespesas,

          //não possui registro no backend
          operadoraId : guia.operadoraId,
        }

        if (guia.datAutorizacao==null) {
          this.guiaSelecionada.datAutorizacao = null;
        } else {
          this.guiaSelecionada.datAutorizacao = funcoes.mySqlToJSDateFormat(guia.datAutorizacao); 
        }

        if (guia.datValidadeSenha==null) {
          this.guiaSelecionada.datValidadeSenha = null;
        } else {
          this.guiaSelecionada.datValidadeSenha = funcoes.mySqlToJSDateFormat(guia.datValidadeSenha);
        }

        this.selecionouOperadora();
      },
      incluirNovaGuia() {
        if (this.modo == "" && !this.verificarPermissao('Incluir')) {
          return
        }

        this.modo = this.modo == "" ? "Incluir" : "";

        this.guiaSelecionada.datAtendimento = this.parametrosLanctoGuia.datAtendimento; 
        this.guiaSelecionada.pacienteId  = this.parametrosLanctoGuia.paciente.id;
        this.guiaSelecionada.profissionalSolicitanteId = this.parametrosLanctoGuia.profissionalSolicitanteId; 
        this.guiaSelecionada.profissionalExecutanteId = this.parametrosLanctoGuia.profissionalExecutanteId; 
        this.guiaSelecionada.operadoraId = this.parametrosLanctoGuia.operadoraId;
        this.guiaSelecionada.planoSaudeId = this.parametrosLanctoGuia.planoSaudeId;
        this.guiaSelecionada.atendimentoSeriado = null;
        this.guiaSelecionada.agendaAtendimentoId = this.parametrosLanctoGuia.agendaAtendimentoId > 0 ? this.parametrosLanctoGuia.agendaAtendimentoId : null;
        this.guiaSelecionada.atendimentoSeriado = 'N';
        this.guiaSelecionada.tissCaraterAtendimento = 'E';
      },
      excluirGuiaSelecionada(guia) {
        this.modo = "";

        if (!this.verificarPermissao('Excluir')) {
          return
        }

        if (guia.loteId!=null) {
          this.$toasted.show("A Guia Selecionada já foi enviada para o Faturamento.", {type : "info", icon : "times"});
          return;
        }

        this.$bvModal
          .msgBoxConfirm("Excluir Guia Selecionada?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.excluirGuia(this.usuarioLogadoDados.tenant, guia, "T", this.usuarioLogadoDados.profissionalId, "LanctoGuia");
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      lancarItens(guia) {
        //guia.paciente.id = this.parametrosLanctoGuia.paciente.id;
        //guia.paciente.nome = this.parametrosLanctoGuia.paciente.nome;

        this.setGuiaSelecionada(guia);
        this.setPlanoSaudeSelecionado()
        this.$router.push('/frmLanctoGuiaItens');
      },
      guiaCancelarOperacao() {
        this.modo = "";
        this.erro = "";
      },
      gravarGuia() {
        if (!this.verificarCamposPreenchidos()) {
          return;
        }

        if (this.modo=="Alterar") {
          this.guiaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.alterarGuia(this.usuarioLogadoDados.tenant, this.guiaSelecionada, "T", "LanctoGuia");
        } else {
          let planoPadrao = this.planosSaude.filter(p => p.padrao == "S");

          if (planoPadrao!=null) {
            this.guiaSelecionada.planoSaudeLanctoId = planoPadrao[0].id;
          } else {
            this.guiaSelecionada.planoSaudeLanctoId = this.guiaSelecionada.planoSaudeId
          }

          this.guiaSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
          this.guiaSelecionada.liberadoFaturamento = "S";

          this.incluirGuia(this.usuarioLogadoDados.tenant, this.guiaSelecionada, "T", "LanctoGuia");
        }
      },
      verificarCamposPreenchidos() {
        if (this.guiaSelecionada.datAtendimento==null) {
          this.$toasted.show("Digite a Data do Atendimento.", {type : "info", icon : "times"});
          return false;
        }

        if (this.guiaSelecionada.profissionalSolicitanteId==0) {
          this.$toasted.show("Selecione o Profissional Solicitante.", {type : "info", icon : "times"});
          return false;
        }

        if (this.guiaSelecionada.profissionalExecutanteId==0) {
          this.$toasted.show("Selecione o Profissional Solicitante.", {type : "info", icon : "times"});
          return false;
        }

        if (this.guiaSelecionada.planoSaudeId==0) {
          this.$toasted.show("Selecione o Plano de Saúde.", {type : "info", icon : "times"});
          return false;
        }

        if (this.guiaSelecionada.tipoLote==-1) {
          this.$toasted.show("Selecione o Tipo da Guia.", {type : "info", icon : "times"});
          return false;
        }

        return true;
      },
      verificarPermissao(operacao) {
        if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
          let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
  
          this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
          this.$bvModal.show("modal-mensagem");
  
          return false;
        }
  
        return true;
      }      
    },
    async created() {
      this.showMenu(false);
      
      this.loading = true;
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);

      if (this.parametrosLanctoGuia.operadoraId > 0) {
        this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.parametrosLanctoGuia.operadoraId, 'S');
      }
      this.carregarGuiasDoPaciente(this.usuarioLogadoDados.tenant, this.parametrosLanctoGuia.paciente.id, "T", "LanctoGuia");

      if (this.profissionaisSolicitantes.length==0) {
        await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisSolicitantes = [...this.profissionais];
        this.profissionais = [];
      }
      if (this.profissionaisExecutantes.length==0) {
        await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisExecutantes = [...this.profissionais];
        this.profissionais = [];
      }
      this.loading = false;

      this.guiaSelecionada = {
        datAtendimento : this.parametrosLanctoGuia.datAtendimento, 
        pacienteId : this.parametrosLanctoGuia.paciente.id,
        profissionalSolicitanteId : this.parametrosLanctoGuia.profissionalSolicitanteId, 
        profissionalExecutanteId : this.parametrosLanctoGuia.profissionalExecutanteId, 
        guiaOperadora : null,
        guiaPrestador : null,
        planoSaudeId : this.parametrosLanctoGuia.planoSaudeId, 
        tipoLote : -1, 
        datAutorizacao : null, 
        senhaAutorizacao : null, 
        datValidadeSenha : null,
        atendimentoSeriado : null,

        //não possui registro no backend
        operadoraId : this.parametrosLanctoGuia.operadoraId,
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.retornando) {
        this.setParametrosLanctoGuia(null);
        this.setGuiaChamadoPor("");
      }

      next(true);
    },
    //beforeDestroy() {
    //    this.setParametrosLanctoGuia(null);
    //    this.setGuiaChamadoPor("");
    //}
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
