const estruturaTabela = {
    id : 0, 
    codAnt : 0,  nome : "", nomeCertidao : "", sobreNome : "", codAlfa : "",
    ativo : "S", bloqueado : "N", datCadastro : null, datAlteracao : null, 
    datNascimento : null, naturalidade : "", sexo : "", cor : "", estadoCivil : "",
    logradouro : "", bairro : "", cidade : "", cep : "", numero : 0, complemento : "",  uf : "", 
    rgNumero : "",  rgEmissao : null, rgOrgao : "", cpfNumero : "", certidaoNascimento : "", 
    certidaoNascimentoEmissao : null, cnsNumero : "", 
    foneDDD : "", fone : "", celularDDD : "", celular : "", email : "",
    paiNome : "", paiProfissao : "", paiCpf : "", paiEmail : "", paiDdddCelular : "", paiCelular : "",
    maeNome : "", maeProfissao : "", maeCpf : "", maeEmail : "", maeDdddCelular : "", maeCelular : "",
    responsavelNome : "", responsavelRgNumero : "", responsavelRgOrgao : "", responsavelProfissao : "",
    responsavelCpf : "", responsavelEmail : "", responsavelDddCelular : "", responsavelCelular : "",
    indicacao : "", resultadoExame : "", observacao : "", observacaoMedico : "",
    datPrivisita : null, datUltVisita : null, datUltFalta : null,
    valMensalidade : 0, diaVencto : 0, datMatricula : null, datCancelamento : null, motivoCancelamento : "",
    observacaoNotaFiscal : "",
    alergia : "", vacinaReacao : "", vacinaObservacao : "", gestante : "N", medicacaoDiaria : "", pastaExame : "",
    login : "", senha : "", datPrimeiroAcesso : null, datUltimoAcesso : null, 
    profissaoId : null, empresaId : null, modeloId : null, operadorCadastroId : null, OperadorAlteracaoId : null
}

const state = {
    all : [],
    pacienteSelecionado : null,
    modo : "",
    modulo: "",
    ultimaBusca : {}
}

const getters = {
    getAllPacientes(state) {
        return state.all
    },

    getPacienteSelecionado(state) {
        return state.pacienteSelecionado
    },

    getModo(state) {
        return state.modo;
    },

    getModulo(state) {
        return state.modulo;
    },

    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setPacientes(state, listaPacientes) {
        state.all = listaPacientes
    },

    setPacienteSelecionado(state, paciente) {
        state.pacienteSelecionado = paciente
    },

    setModo(state, modo) {
        state.modo = modo;
    },

    setModulo(state, modulo) {
        state.modulo = modulo;
    },

    setUltimaBusca(state, ultimaBusca) {
        state.ultimaBusca = {...ultimaBusca};
    },

    limparRegistroSelecionado(state) {
        state.pacienteSelecionado = {...estruturaTabela}
    },
    
    limparLista(state) {
        state.all = [];
    },
    
    nullRegistroSelecionado(state) {
        state.pacienteSelecionado = null;
    }
}

const actions = {
    setPacientes({commit}, listaPacientes) {
        commit("setPacientes", listaPacientes)
    },

    setPacienteSelecionado({commit}, paciente) {
        commit("setPacienteSelecionado", paciente)
    },

    setModo({commit}, modo) {
        commit("setModo", modo);
    },

    setModulo({commit}, modulo) {
        commit("setModulo", modulo);
    },

    setUltimaBusca({commit}, ultimaBusca) {
        commit("setUltimaBusca", ultimaBusca)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado");
    },
    
    limparLista({commit}) {
        commit("limparLista")
    },
    
    nullRegistroSelecionado({commit}) {
        commit("nullRegistroSelecionado");
    },

    formLeave({commit}) {
        commit("nullRegistroSelecionado");
        commit("limparLista");
        commit("setModo", "");
        commit("setModulo", "");
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
