const state = {
    guiaTratamentoSelecionado : {}
}

const getters = {
    getGuiaTratamentoSelecionado(state) {
        return state.guiaTratamentoSelecionado;
    }
}

const mutations = {
    setGuiaTratamentoSelecionado(state, tratamento) {
        state.guiaTratamentoSelecionado = {...tratamento}
    }
}

const actions = {
    setGuiaTratamentoSelecionado({commit}, tratamento) {
        commit("setGuiaTratamentoSelecionado", tratamento)
    },

    limparRegistro({commit}) {
        commit("setGuiaTratamentoSelecionado", null)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}