import errors from "@/utils/errors";

export default {
    data() {
      return {
        perfis : []
      }
    },
    methods: {
        async carregarPerfis(tenant) {
            this.loadingPerfis = true;
            this.loadedPerfis = false;

            await this.$http
              .get(`/v1.0/Perfis/${tenant}`)
              .then(resp => {
                this.perfis = resp.data;
                this.erro = "";

                this.loadedPerfis = true;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
              .finally(() => {
                this.loadingPerfis = false;
              });
        }
    }
}
