const state = {
    lancamentoSelecionado : {},
    ultimaBusca : null,
}

const getters = {
    getLancamentoSelecionado(state) {
        return state.lancamentoSelecionado;
    },
    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setLancamentoSelecionado(state, lancamento) {
        state.lancamentoSelecionado = {...lancamento}
    },
    setUltimaBusca(state, busca) {
        state.ultimaBusca = busca == null ? null : {...busca}
    }
}

const actions = {
    setLancamentoSelecionado({commit}, lancamento) {
        commit("setLancamentoSelecionado", lancamento);
    },
    setUltimaBusca({commit}, busca) {
        commit("setUltimaBusca", busca)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}