<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Opções de Relatórios de Atendimento</span>
          </div>
          <div class="coluna3"/>
        </div>  
    </div>
</template>

<script>
import global from "@/mixins/global";
export default {
    mixins : [global],
    methods : {
        retornar() {
            this.$router.go(-1);
        }
    },
    created() {
       this.showMenu(true);
    }
}
</script>


<style scoped>

</style>