import errors from "@/utils/errors";

export default {
    data() {
        return {
            limit : 0,
            count : 0,
            recebimentosDoPaciente : [],
            recebimentosDoDia : []
        }
    },
    methods : {
        async carregarRecebimentosDoPaciente(tenant, pacienteId, pagina) {
            this.loadingRecebimentosDoPaciente = true;
            this.loadedRecebimentosDoPaciente = false;

            await this.$http.get(`/v1.0/recebimentos/${tenant}/Paciente/${pacienteId}/${pagina}`)
            .then(resp => {
                this.limit = resp.data.limit;
                this.count = resp.data.count;
                this.recebimentosDoPaciente = resp.data.registros;
                this.erro = "";

                this.recebimentosDoPaciente.forEach(element => {
                    element.valDescontos = element.valDescontoTratamentos + element.valDescontoDespesas;
                });

                this.loadedRecebimentosDoPaciente = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loadingRecebimentosDoPaciente = false;
            })
        }
    }
}
