const state = {
    loteSelecionado : {}
}

const getters = {
    getLoteSelecionado(state) {
        return state.loteSelecionado;
    }
}

const mutations = {
    setLoteSelecionado(state, lote) {
        state.loteSelecionado = {...lote}
    }
}

const actions = {
    setLoteSelecionado({commit}, lote) {
        commit("setLoteSelecionado", lote);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}