<template>
  <div id="CadDespesa">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
      <span v-if="despesaSelecionada.id>0">{{despesaSelecionada.descricao}}</span>
      <span v-else>Cadastro de Nova Despesa</span>
      <hr />
    </div>

    <div class="container-fluid">
      <div class="corpo">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Dados" :active="componentAtivo=='cadDespesaDados'">
                <cadDespesaDados/>
            </b-tab>
            <b-tab title="Estoque" :active="componentAtivo=='cadDespesaEstoque'">
                <cadDespesaEstoque/>
            </b-tab>
            <b-tab title="Faturamento" :active="componentAtivo=='cadDespesaFaturamento'">
                <cadDespesaFaturamento/>
            </b-tab>
          </b-tabs>
        </b-card>

        <hr />
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-5"
          >Gravar</button>
          <button
            id="Apresentações"
            :disabled="liberarCampos"
            @click="apresentacoes()"
            class="btn btn-sm btn-info"
          >Apresentações</button>
        </div>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import cadDespesaDados from "./cadDespesaDados";
import cadDespesaEstoque from "./cadDespesaEstoque";
import cadDespesaFaturamento from "./cadDespesaFaturamento";

import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [global],
  computed: {
    ...mapGetters({
      despesaSelecionada: "despesas/getDespesaSelecionada",
      statusSelecionouAlterar: "despesas/getStatusSelecionouAlterar",
      registroBrasindiceSelecionado : "tabelaBrasindice/getRegistroSelecionado",
      registroSimproSelecionado : "tabelaSimpro/getRegistroSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    despesa() {
      return this.despesaSelecionada;
    }
  },
  components: {
    cadDespesaDados,
    cadDespesaEstoque,
    cadDespesaFaturamento
  },
  data() {
    return {
      componentAtivo : "cadDespesaDados",
      erro: "",
      modo: "",
      mensagem : "",
      retornando : false,
    }
  },
  methods: {
    ...mapActions({
      limparRegistroSelecionado: "despesas/limparRegistroSelecionado",
      limparLista: "despesas/limparLista",
      setDespesaSelecionada : "despesas/setDespesaSelecionada",
      formLeave : "despesas/formLeave",
      setModo: "despesas/setModo"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.despesaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/despesas/${this.usuarioLogadoDados.tenant}/${this.despesaSelecionada.id}`,
            this.despesaSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "Registro";
            this.setModo(this.modo);

            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.despesaSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/despesas/${this.usuarioLogadoDados.tenant}`, this.despesaSelecionada)
          .then(() => {
            this.erro = "";
            this.modo = "Incluir";
            this.setModo(this.modo);

            this.limparCampos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
      this.setModo(this.modo);
    },
    cancelarOperacao() {
      this.modo = "";
      this.setModo(this.modo);
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    apresentacoes() {
      this.$router.push('/frmDespesaApresentacoes');
    },
    verificarCamposPreenchidos() {
      if (this.despesaSelecionada.descricao == "" || this.despesaSelecionada.descricao == undefined) {
        this.$toasted.show("Digite a Descrição", {type : 'info', icon : 'times'});
        return false;
      }
      
      if (this.despesaSelecionada.codMatTiss == 0 || this.despesaSelecionada.codMatTiss == undefined) {
        this.$toasted.show("Selecione o Tipo", {type : 'info', icon : 'times'});
        return false;
      }
      
      if (this.despesaSelecionada.unidadeContabil == "" || this.despesaSelecionada.unidadeContabil == undefined) {
        this.$toasted.show("Selecione a Unidade Contábil", {type : 'info', icon : 'times'});
        return false;
      }
      
      if (this.despesaSelecionada.unidadeConsumo == "" || this.despesaSelecionada.unidadeConsumo == undefined) {
        this.$toasted.show("Selecione a Unidade de Consumo", {type : 'info', icon : 'times'});
        return false;
      }
      
      if (this.despesaSelecionada.unidadeTiss == "" || this.despesaSelecionada.unidadeTiss == undefined) {
        this.$toasted.show("Selecione a Unidade de Faturamento", {type : 'info', icon : 'times'});
        return false;
      }
      
      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
  },
  mounted() {
    if (this.registroBrasindiceSelecionado.id != undefined) {
       this.modo = "Alterar";

       this.setRegistroBrasindiceSelecionado({});
       this.componentAtivo = "cadDespesaBrasindice";
    } else if (this.registroSimproSelecionado.id != undefined) {
       this.modo = "Alterar";

       this.setRegistroSimproSelecionado({});
       this.componentAtivo = "cadDespesaSimpro";
    } else {
      if (this.despesaSelecionada.id==0) {
        this.modo = "Incluir"
      } else {
        this.modo = "Registro"
      }
    }
    this.setModo(this.modo);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.setModo("");
            this.formLeave();
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      if (this.retornando) {
        this.formLeave();
      }
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
