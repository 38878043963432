<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{fluxoSelecionado.descricao}} - Centros</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
        <!-- Inclusão de Novos Ítems ---------------------------------------------------->
        <template v-if="modo==''">
        <div class="row">
            <div class="form-group col-lg-12">
              <label for="Movimento" class="control-label">Tipo Lançamento:</label>
              <select id="Movimento" class="form-control" v-model="movimento" selected>
                  <option value="D">Despesas</option>
                  <option value="R">Receitas</option>
              </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
                <label for="centros" class="control-label">Centros</label>
                <select id="centros" class="form-control" v-model="centro" selected>
                    <option v-for="centro in centros" :key="centro.id" :value="centro">
                        {{centro.estrutura}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
                <label for="novoItem" class="control-label">Novo Ítem</label>
                <input type="text" id="novoItem" class="form-control" v-model="descricaoNovoItem" placeholder="Digite a descrição para o Novo Ítem..."/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-12">
                <button class="form-control btn btn-sm btn-info" @click="adicionarItem()">Adicionar</button>
            </div>
        </div>
        </template>
        <!-- Alteração / Exclusão de Ítems ------------------------------------------------>
        <template v-else>
            <div class="registro">
              <div class="form-group col-lg-12">
                  <label for="Estrutura" class="control-label">Estrutura</label>
                  <input type="text" id="Estrutura" class="form-control" disabled v-model="centro.estrutura"/>
              </div>
              <div class="form-group col-lg-12">
                  <label for="Descricao" class="control-label">Centro Selecionado</label>
                  <input type="text" id="Descricao" class="form-control" :disabled="!liberarCampos" v-model="centro.descricao"/>
              </div>

              <hr />
              <div class="barraopcoescadastro">
                <button
                  id="Excluir"
                  :disabled="liberarCampos"
                  @click="excluirRegistro()"
                  class="btn btn-sm btn-danger ml-2"
                >Excluir</button>
                <button
                  id="Alterar"
                  :disabled="liberarCampos"
                  @click="alterarRegistro()"
                  class="btn btn-sm btn-secondary ml-2"
                >Alterar</button>
                <button
                  id="Cancelar"
                  @click="cancelarOperacao()"
                  class="btn btn-sm btn-secondary ml-2"
                >Cancelar</button>
                <button
                  id="Gravar"
                  :disabled="!liberarCampos"
                  @click="gravarRegistro()"
                  class="btn btn-sm btn-secondary ml-2"
                >Gravar</button>
                <button
                  id="Origem"
                  :disabled="liberarCampos"
                  @click="origensRelacionadas()"
                  class="btn btn-sm btn-info ml-5"
                >Origens Relacionadas</button>
              </div>

            </div>
        </template>
        <hr>

        <!-- Tabela com os Centros -------------------------------------------------------->
        <div style="display:flex; flex-direction:row; justify-content:center;height:315px;overflow:auto">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="!loading" class="col-lg-12" hover striped small sort-icon-left :items="centros" :fields="camposTabela">
            <template v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
                <i class="fa fa-pencil"/> 
              </b-button>  
            </template>
          </b-table>
        </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import centros from "@/mixins/centros"
import global from "@/mixins/global"
import errors from "@/utils/errors";

export default {
    mixins : [centros, global],
    computed : {
      ...mapGetters({
        fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
      }),
      liberarCampos() {
        return this.modo == "Alterar"
      }
    },
    data() {
      return {
          error : "",
          loading : true,
          centro : {},
          descricaoNovoItem : "",
          modo : "",
          mensagem : "",
          movimento : "D",
          camposTabela: [
              { key: 'id', label: 'Id'},
              { key: 'descricao', label: 'Centro' },
              { key: 'estrutura', label: 'Estrutura', sortable : true },
              { key: 'actions', label: 'Ações' }
          ],
      }
    },
    watch : {
      movimento() {
        this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, true, this.movimento)
      }
    },
    methods: {
        ...mapActions({
          setCentroSelecionado : "centros/setCentroSelecionado",
        }),
        retornar() {
            this.$router.go(-1);
        },
        adicionarItem() {
            if (!this.verificarPermissao('Incluir')) {
              return
            }

            if (this.centro.tipoSistema != null) {
              this.$toasted.show("Esse é um Centro Especial e não permite Novo Ítem relacionado", {type:"info", icon:"times"});
              return
            }

            if (this.centro.id == undefined) {
              this.$toasted.show("Selecione o Centro Pai para o Novo Ítem.", {type : "info", icon : "times"});
              return
            }

            if (this.descricaoNovoItem == "") {
              this.$toasted.show("Digite a descrição para o Novo ìtem.", {type: "info", icon: "times"})
              return;
            }

            let novoItem = {fluxoId : this.fluxoSelecionado.id, descricao : this.descricaoNovoItem, raiz : "N", tipo : this.centro.tipo, parentId : this.centro.id}

            novoItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.$http.post(`/v1.0/centros/${this.usuarioLogadoDados.tenant}`, novoItem)
            .then(() => {
                this.descricaoNovoItem = "";
                this.$toasted.global.defaultSuccess();
                this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, true, this.movimento);
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error));
        },
        selecionouRegistro(centro) {
          if (centro.tipoSistema != null) {
            this.$toasted.show("Esse é um Centro Especial e não permite Alteração", {type:"info", icon:"times"});
            return
          }

          this.centro = {...centro}
          this.modo = "Registro";
        },
        alterarRegistro() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.modo = 'Alterar';
        },        
        cancelarOperacao() {
          this.centro = {},
          this.modo = "";
          this.erro = "";
        },
        gravarRegistro() {
          this.centro.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.$http.put(`/v1.0/centros/${this.usuarioLogadoDados.tenant}/${this.centro.id}`, this.centro)
          .then(() => {
            this.erro = "";
            this.centro = {}
            this.modo = "";
            this.$toasted.global.defaultSuccess();
            this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, true, this.movimento);
          })
          .catch(error => this.erro = errors.tratarErros(error))
        },
        excluirRegistro() {
          if (!this.verificarPermissao('Excluir')) {
            return
          }

          if (this.centro.tipoSistema != null) {
            this.$toasted.show("Esse é um Centro Especial e não pode ser Excluído", {type:"info", icon:"times"});
            return
          }

          this.$http.get(`/v1.0/centros/${this.usuarioLogadoDados.tenant}/Parent/${this.centro.id}`)
          .then(resp => {
            this.erro = "";

            if (resp.data.length > 0) {
              this.$toasted.show("Ítem não pode ser Excluído pois possui Filhos na Estrutura", {type: "danger", icon: "times"})
              return
            }

            this.$bvModal
              .msgBoxConfirm("Excluir Registro Selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                  this.$http
                    .delete(`/v1.0/centros/${this.usuarioLogadoDados.tenant}/${this.centro.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                      this.erro = "";
                      this.modo = "";

                      this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, true, this.movimento);
                      this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
          })
          .catch(error => {
            this.erro = "Não foi possível verificar se Ítem possui Filhos: " + error;
            return
          })
        },
        origensRelacionadas() {
          this.$http.get(`/v1.0/centros/${this.usuarioLogadoDados.tenant}/Parent/${this.centro.id}`)
          .then(resp => {
            this.erro = "";

            if (resp.data.length > 0) {
              this.$toasted.show("Ítem possui Filhos na Estrutura e por isso não pode possuir Origens", {type: "danger", icon: "times"})
              return
            }

            this.setCentroSelecionado(this.centro);
            this.$router.push("/frmCentroOrigens");
          })
          .catch(error => {
            this.erro = "Não foi possível verificar se Ítem possui Filhos: " + error;
            return
          })
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
    
            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");
    
            return false;
          }
    
          return true;
        }        
    },
    created() {
        this.showMenu(false);
        this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, true, this.movimento);
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
