<template>
  <div id="frmAcompanhamentosPaciente">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Acompanhamento do(a) Paciente {{pacienteSelecionado.nome}} - {{pacienteSelecionado.id}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:370px">
        <b-spinner v-if="loadingAcompahamentos" label="carregando..."/>
        <b-table v-if="loadedAcompahamentos" class="col-lg-12" hover striped small sort-icon-left :items="acompanhamentos" :fields="camposTabela">
          <template v-slot:cell(datPrevista)="datPrevista">
            {{ datPrevista.value | formatDate }}
          </template>
          <template v-slot:cell(datContato)="datContato">
            {{ datContato.value | formatDate }}
          </template>
          <template v-slot:cell(datVisualizacao)="datVisualizacao">
            {{ datVisualizacao.value | formatDate }}
          </template>
        </b-table>
      </div>

      <b-pagination v-if="loadedAcompanhamentos" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import acompanhamentos from "@/mixins/acompanhamentos";
import global from "@/mixins/global";

export default {
    mixins : [acompanhamentos, global],
    computed : {
        ...mapGetters({
          pacienteSelecionado : "pacientes/getPacienteSelecionado",
          usuarioLogadoDados: "global/getUsuarioLogadoDados",
        })
    },
    watch : {
        page() {
            this.carregar();
        }
    },
    data() {
        return {
          loadedAcompanhamentos : false,
          loadingAcompahamentos : false,
          page : 1,
          count : 0,
          limit : 0,
          erro : "",
          camposTabela : [
            {key : "profissional", label : "Profissional", sortable : true},
            {key : "datPrevista", label : "Prevista", sortable : true},
            {key : "pergunta", label : "Pergunta"},
            {key : "datContato", label : "Contato", sortable : true},
            {key : "resposta", label : "Resposta"},
            {key : "datVisualizacao", label : "Visualizada", sortable : true},
          ]
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        carregar() {
            this.carregarPeloPaciente(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.page);
        }
    },
    created() {
        this.showMenu(false);
        this.carregar();
    }
}
</script>

<style scoped>

</style>
