<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Brasindice - {{registroSelecionado.medicamento}}</span>
          </div>
          <div class="coluna3"/>
        </div>  

        <div class="container-fluid">
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="codigoTiss" class="control-label">Cód Tiss</label>
                    <input type="text"  id="codigoTiss" class="form-control" readonly :value="registroSelecionado.codigoTiss"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codigoTuss" class="control-label">Cód Tuss</label>
                    <input type="text"  id="codigoTuss" class="form-control" readonly :value="registroSelecionado.codigoTuss"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codigoMedicamento" class="control-label">Cód Med</label>
                    <input type="text"  id="codigoMedicamento" class="form-control" readonly :value="registroSelecionado.codigoMedicamento"/>
                </div>
                <div class="form-group col-lg-3">
                    <label for="medicamento" class="control-label">Medicamento</label>
                    <input type="text"  id="medicamento" class="form-control" readonly :value="registroSelecionado.medicamento"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="RestritoHospitalar" class="control-label">RH</label>
                    <select id="RestritoHospitalar" class="form-control" disabled v-model="registroSelecionado.restritoHospitalar" selected>
                        <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                            {{resposta.valor}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-2">
                    <label for="EAN13" class="control-label">EAN13</label>
                    <input type="text"  id="EAN13" class="form-control" readonly :value="registroSelecionado.eaN13"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="codigoApresentacao" class="control-label">Cód Apresentação</label>
                    <input type="text"  id="codigoApresentacao" class="form-control" readonly :value="registroSelecionado.codigoApresentacao"/>
                </div>
                <div class="form-group col-lg-3">
                    <label for="apresentacao" class="control-label">Apresentação</label>
                    <input type="text"  id="apresentacao" class="form-control" readonly :value="registroSelecionado.apresentacao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codLaboratorio" class="control-label">Cód Lab.</label>
                    <input type="text"  id="codLaboratorio" class="form-control" readonly :value="registroSelecionado.codigoLaboratorio"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="laboratorio" class="control-label">Laboratório</label>
                    <input type="text"  id="laboratorio" class="form-control" readonly :value="registroSelecionado.laboratorio"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="IPI" class="control-label">IPI</label>
                    <input type="text"  id="IPI" class="form-control" readonly :value="registroSelecionado.ipi"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="pisCofins" class="control-label">Piscofins</label>
                    <select id="pisCofins" class="form-control" disabled v-model="registroSelecionado.pisCofins" selected>
                        <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                            {{resposta.valor}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="tipoPreco" class="control-label">Tipo Preço</label>
                    <input type="text"  id="tipoPreco" class="form-control" readonly :value="registroSelecionado.tipoPreco"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="qtde" class="control-label">Qtde</label>
                    <input type="text"  id="qtde" class="form-control" readonly :value="registroSelecionado.qtde"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="precoMedicamento" class="control-label">Preço Medicamento</label>
                    <input type="text"  id="precoMedicamento" class="form-control" readonly :value="registroSelecionado.precoMedicamento"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="precoUnitario" class="control-label">Preço Unitário</label>
                    <input type="text"  id="precoUnitario" class="form-control" readonly :value="registroSelecionado.precoUnitario"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="precoMedicamentoFabrica" class="control-label">Preço Medicamento Fábrica</label>
                    <input type="text"  id="precoMedicamentoFabrica" class="form-control" readonly :value="registroSelecionado.precoMedicamentoFabrica"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="precoUnitarioFabrica" class="control-label">Preço Unit Fábrica</label>
                    <input type="text"  id="precoUnitarioFabrica" class="form-control" readonly :value="registroSelecionado.precoUnitarioFabrica"/>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="edicao" class="control-label">Edição</label>
                    <input type="text"  id="edicao" class="form-control" readonly :value="registroSelecionado.edicao"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datCadastro" class="control-label">Cadastro</label>
                    <input type="text"  id="datCadastro" class="form-control" readonly :value="registroSelecionado.datCadastro"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datAlteracao" class="control-label">Atualização</label>
                    <input type="text"  id="datAlteracao" class="form-control" readonly :value="registroSelecionado.datAlteracao"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datExclusao" class="control-label">Exclusão</label>
                    <input type="text"  id="datExclusao" class="form-control" readonly :value="registroSelecionado.datExclusao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codigoTxt" class="control-label">Cód Txt</label>
                    <input type="text"  id="codigoTxt" class="form-control" readonly :value="registroSelecionado.codigoTXT"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="numeroLinha" class="control-label">Nº Linha</label>
                    <input type="text"  id="numeroLinha" class="form-control" readonly :value="registroSelecionado.numeroLinha"/>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"
import global from "@/mixins/global"

export default {
    mixins : [global],
    computed : {
        ...mapGetters({
            registroSelecionado : "tabelaBrasindice/getRegistroSelecionado"
        })
    },
    data() {
        return {
            sim_nao : constantes.sim_nao
        }
    },
    methods : {
        ...mapActions({
            setRegistroSelecionado : "tabelaBrasindice/setRegistroSelecionado"
        }),
        retornar() {
            this.setRegistroSelecionado({});
            this.$router.go(-1);
        }
    },
    created() {
        this.showMenu(false);

        this.registroSelecionado.datExclusao = funcoes.mySqlToJSDateFormat(this.registroSelecionado.datExclusao, "pt-br");
        this.registroSelecionado.datCadastro = funcoes.mySqlToJSDateFormat(this.registroSelecionado.datCadastro, "pt-br");
        this.registroSelecionado.datAlteracao = funcoes.mySqlToJSDateFormat(this.registroSelecionado.datAlteracao, "pt-br");
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>