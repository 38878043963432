<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{ operadoraSelecionada.fantasia }}  - Planos {{ ativoLocal == "S" ? "Ativos" : "Inativos"}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Plano</button>
          <hr/>
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col ml-2">
            <h4 style="text-align:left">Incluir Novo Plano</h4>
            <hr/>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1 ml-2">
            <label for="Id" class="control-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="planoSaudeSelecionado.id"
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="Plano" class="control-label">Plano</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Plano"
              name="Plano"
              v-model="planoSaudeSelecionado.plano"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="DatInicioVigencia" class="control-label">Data Início Vigência</label>
            <input
              type="date"
              class="form-control"
              :disabled="!liberarCampos"
              id="DatInicioVigencia"
              name="DatInicioVigencia"
              v-model="planoSaudeSelecionado.datInicioVigencia"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="DatFinalVigencia" class="control-label">Data Final Vigência</label>
            <input
              type="date"
              :disabled="!liberarCampos"
              class="form-control"
              id="DatFinalVigencia"
              name="DatFinalVigencia"
              v-model="planoSaudeSelecionado.datFinalVigencia"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Padrao" class="control-label">Padrão</label>
            <select
              id="Padrao"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="planoSaudeSelecionado.padrao"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="UtilizarPadrao" class="control-label">Util. Padrão</label>
            <select
              id="UtilizarPadrao"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="planoSaudeSelecionado.utilizarPadrao"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1 ml-2"></div>

          <div class="form-group col-lg-1">
            <label for="CodConsulta" class="control-label">Consulta</label>
            <input
              type="text"
              :disabled="!liberarCampos"
              class="form-control"
              id="CodConsulta"
              name="CodConsulta"
              v-model="planoSaudeSelecionado.codConsulta"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="PrazoRetorno" class="control-label">Retorno</label>
            <input
              type="number"
              :disabled="!liberarCampos"
              class="form-control"
              id="PrazoRetorno"
              name="PrazoRetorno"
              v-model.number="planoSaudeSelecionado.prazoRetorno"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="TamMinCarteirinha" class="control-label">Tam. Min. Carteirinha</label>
            <input
              type="number"
              :disabled="!liberarCampos"
              class="form-control"
              id="TamMinCarteirinha"
              name="TamMinCarteirinha"
              v-model.number="planoSaudeSelecionado.tamMinCarteirinha"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="TamMaxCarteirinha" class="control-label">Tam. Máx. Carteirinha</label>
            <input
              type="number"
              :disabled="!liberarCampos"
              class="form-control"
              id="TamMaxCarteirinha"
              name="TamMaxCarteirinha"
              v-model.number="planoSaudeSelecionado.tamMaxCarteirinha"
            />
          </div>

        </div>

        <div class="row">
          <div class="form-group col-lg-1 ml-2"></div>
          
          <div class="form-group col-lg-1">
            <label for="ValorFilme" class="control-label">Filme</label>
            <input
              type="text"
              :disabled="!liberarCampos"
              class="form-control"
              id="ValorFilme"
              name="ValorFilme"
              v-model.number="planoSaudeSelecionado.valorFilme"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="CoefPorteCbhpm" class="control-label">Coef.Porte CBHPM</label>
            <input
              type="text"
              :disabled="!liberarCampos"
              class="form-control"
              id="CoefPorteCbhpm"
              name="CoefPorteCbhpm"
              v-model.number="planoSaudeSelecionado.coefPorteCbhpm"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="CoefUCOCbhpm" class="control-label">Coef.UCO CBHPM</label>
            <input
              type="text"
              :disabled="!liberarCampos"
              class="form-control"
              id="CoefUCOCbhpm"
              name="CoefUCOCbhpm"
              v-model.number="planoSaudeSelecionado.coefUCOCbhpm"
            />
          </div>
        </div>

        <hr/>
        <div class="container-fluid">
          <div class="barraopcoescadastro">
            <button
              id="Alterar"
              :disabled="modo=='Incluir' || modo=='Alterar'"
              @click="alterarRegistro()"
              class="btn btn-sm btn-secondary mr-2"
            >Alterar</button>
            <button
              id="Cancelar"
              @click="cancelarOperacao()"
              class="btn btn-sm btn-secondary mr-2"
            >Cancelar</button>
            <button
              id="Gravar"
              :disabled="!liberarCampos"
              @click="gravarRegistro()"
              class="btn btn-sm btn-secondary mr-2"
            >Gravar</button>
          </div>
        </div>
      </div>

      <div id="filtrarRegistros" class="row">
        <div class="form-group ml-4 col-lg-4">
          <input type="radio" id="ativo" value="S" v-model="ativoLocal" />
          <label for="ativo" class="control-label ml-1 mr-2">Exibir Ativos</label>

          <input type="radio" id="inativo" value="N" v-model="ativoLocal" />
          <label for="inativo" class="control-label ml-1 mr-2">Exibir Inativos</label>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loaded" class="col-lg-9" hover striped small sort-icon-left :items="planosSaude" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" class="mr-2" @click="selecionouPlanoSaude(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <button
              class="btn btn-sm btn-info mr-2"
              @click="selecionouKits(row.item)"
            >Kits</button>
            <button
              class="btn btn-sm btn-info"
              @click="selecionouProcedimentosCobertos(row.item)"
            >Proc. Cobertos</button>
          </template>
          <template v-slot:cell(padrao)="padrao">
            {{padrao.value = padrao.value == "S" ? "Sim" : ""}}
          </template>
          <template v-slot:cell(utilizarPadrao)="utilizarPadrao">
            {{utilizarPadrao.value = utilizarPadrao.value == "S" ? "Sim" : "Não"}}
          </template>
        </b-table>

        <!-- <table class="table table-sm table-hover col-lg-12" v-if="!loading"> -->
          <!-- <thead class="thead-light"> -->
            <!-- <th>Id</th> -->
            <!-- <th>Plano</th> -->
            <!-- <th>Padrão</th> -->
            <!-- <th>Utiliz.Padrão</th> -->
            <!-- <th>Retorno</th> -->
            <!-- <th></th> -->
          <!-- </thead> -->
          <!-- <tbody> -->
            <!-- <tr -->
              <!-- v-for="planoSaude in listaPlanosSaude" -->
              <!-- :key="planoSaude.id" -->
            <!-- > -->
              <!-- <td>{{planoSaude.id}}</td> -->
              <!-- <td>{{planoSaude.plano}}</td> -->
              <!-- <td>{{planoSaude.padrao}}</td> -->
              <!-- <td>{{planoSaude.utilizarPadrao}}</td> -->
              <!-- <td>{{planoSaude.prazoRetorno}}</td> -->
              <!-- <td> -->
                <!-- <b-button variant="warning" size="sm" class="mr-2" @click="selecionouPlanoSaude(planoSaude)"> -->
                  <!-- <i class="fa fa-pencil"/> -->
                <!-- </b-button> -->
                <!-- <button -->
                  <!-- class="btn btn-sm btn-info mr-2" -->
                  <!-- @click="selecionouKits(planoSaude)" -->
                <!-- >Kits</button> -->
                <!-- <button -->
                  <!-- class="btn btn-sm btn-info" -->
                  <!-- @click="selecionouProcedimentosCobertos(planoSaude)" -->
                <!-- >Proc. Cobertos</button> -->
              <!-- </td> -->
            <!-- </tr> -->
          <!-- </tbody> -->
        <!-- </table> -->
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import constantes from "@/utils/constantes";
import { mapGetters, mapActions } from "vuex";
import planosSaude from "@/mixins/planosSaude";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";

export default {
  mixins : [planosSaude, global],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  watch: {
    ativoLocal() {
      this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id, this.ativoLocal);
    },
  },
  data() {
    return {
      ativoLocal: "S",
      loading: true,
      loaded: false,
      erro: "",
      mensagem : "",
      retornando: false,
      exibirAdicaoPlano: false,
      sim_nao: constantes.sim_nao,
      modo: "",
      planoSaudeSelecionado : {},
      camposTabela : [
        {key : "id", label : "Id"},
        {key : "plano", label : "Plano", sortable : true},
        {key : "padrao", label : "Padrão", sortable : true},
        {key : "utilizarPadrao", label : "Utilizar Padrão"},
        {key : "prazoRetorno", label : "Prazo Retorno"},
        {key : "codConsulta", label : "Cód. Consulta"},
        {key : "actions", label : "Ações"},
      ],
    }
  },
  methods: {
    ...mapActions({
      setPlanoSaudeSelecionado: "planosSaude/setPlanoSaudeSelecionado",
      formLeave: "planosSaude/formLeave"
    }),
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.planoSaudeSelecionado = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouPlanoSaude(planoSaude) {
      this.planoSaudeSelecionado = planoSaude;
      this.planoSaudeSelecionado.datInicioVigencia = funcoes.mySqlToJSDateFormat(this.planoSaudeSelecionado.datInicioVigencia);
      this.planoSaudeSelecionado.datFinalVigencia = funcoes.mySqlToJSDateFormat(this.planoSaudeSelecionado.datFinalVigencia);
      this.modo = "Registro";
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    selecionouKits(planoSaude) {
      this.setPlanoSaudeSelecionado(planoSaude);
      this.$router.push("/frmKits");
    },
    selecionouProcedimentosCobertos(planoSaude) {
      this.setPlanoSaudeSelecionado(planoSaude);
      this.$router.push("/frmProcedimentosCobertos");
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.planoSaudeSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/planossaude/${this.usuarioLogadoDados.tenant}/${this.planoSaudeSelecionado.id}`,
             this.planoSaudeSelecionado
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.modo="";
            this.$toasted.global.defaultSuccess();
            this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id, this.ativoLocal);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error)
          })
          .finally(() => {});

        } else {
          this.planoSaudeSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
          this.planoSaudeSelecionado.operadoraId = this.operadoraSelecionada.id;

          this.$http
            .post(`/v1.0/planossaude/${this.usuarioLogadoDados.tenant}`, this.planoSaudeSelecionado)
            .then(() => {
              this.erro = "";
              this.modo="Incluir";
              this.limparCampos();

              this.$toasted.global.defaultSuccess();
              this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id, this.ativoLocal);
            })
            .catch(error => {
              this.erro = errors.tratarErros(error)
            })
            .finally(() => {});
      }
    },
    cancelarOperacao() {
      this.planoSaudeSelecionado = {}
      this.modo = "";
      this.erro = "";
    },
    verificarCamposPreenchidos() {
      if (this.planoSaudeSelecionado.plano=="" || this.planoSaudeSelecionado.plano==undefined) {
        this.$toasted.show("Digite o Plano", {type: "info", icon: "times"});
        return false;
      }

      if (this.planoSaudeSelecionado.padrao=="" || this.planoSaudeSelecionado.padrao==undefined) {
        this.$toasted.show("Selecione se o Plano é Padrão", {type: "info", icon: "times"});
        return false;
      }

      if (this.planoSaudeSelecionado.utilizarPadrao=="" || this.planoSaudeSelecionado.utilizarPadrao==undefined) {
        this.$toasted.show("Selecione se o Plano utiliza o Plano Padrão", {type: "info", icon: "times"});
        return false;
      }

      if (this.planoSaudeSelecionado.codConsulta=="" || this.planoSaudeSelecionado.codConsulta==undefined) {
        this.$toasted.show("Digite o Código utilizado para Consulta", {type: "info", icon: "times"});
        return false;
      }

      if (this.planoSaudeSelecionado.prazoRetorno==0 || this.planoSaudeSelecionado.prazoRetorno== undefined) {
        this.$toasted.show("Digite o Prazo de Retorno", {type: "info", icon: "times"});
        return false;
      }

      return true;
    },
    limparCampos() {
      this.planoSaudeSelecionado = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id, this.ativoLocal);
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }
    next(true);
  }
}
</script>

<style scoped>
  label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
  }
</style>
