const estruturaTabela = {
    id : 0, codAnt : 0, descricao : "", codPacote : "", valPacote : 0, codigo : "", planoSaudeId : null
}

const state = {
    kitSelecionado : Object,
}

const getters = {
    getKitSelecionado(state) {
        return state.kitSelecionado
    },
}

const mutations = {
    setKitSelecionado(state, kit) {
        state.kitSelecionado = kit;
    },
    limparRegistroSelecionado(state) {
        state.kitSelecionado = {...estruturaTabela}
    },
}

const actions = {
    setKitSelecionado({commit}, kit) {
        commit("setKitSelecionado", kit);
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}