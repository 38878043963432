const state = {
    ultimaBusca : {}
}

const getters = {
    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setUltimaBusca(state, registro) {
        state.ultimaBusca = {...registro}
    }
}

const actions = {
    setUltimaBusca({commit}, registro) {
        commit("setUltimaBusca", registro)
    },
    formLeave({commit}) {
        commit("setUltimaBusca", {})
    }
}

export default {
    namespaced : true,
    state, 
    getters,
    mutations,
    actions
}