function gerarRelatorioPDF(parametros) {
    //const fs = require('fs');
    const path = require('path');

    const htmlPdf = require('html-pdf');
    const ejs = require('ejs');

    //const fs = require('fs'); //__non_webpack_require__


    //const express = require('express');
    //const app = express();

    const padraoFormat = "A4"
    const padraoOrientation = "landscape"
    const padraoMargenSuperior = 2
    const padraoMargenInferior = 2
    const padraoMargenEsquerda = 5
    const padraoMargenDireita = 5
    const padraoCabecalhoAltura = 20
    const padraoRodapeAltura = 20

    let format = parametros.configuracao.format ? parametros.configuracao.format : padraoFormat;
    let orientation = parametros.configuracao.orientation ? parametros.configuracao.orientation : padraoOrientation;
    let margenSuperior = parametros.configuracao.margenSuperior ? parametros.configuracao.margenSuperior : padraoMargenSuperior;
    let margenInferior = parametros.configuracao.margemInferior ? parametros.configuracao.margemInferior : padraoMargenInferior;
    let margenEsquerda = parametros.configuracao.margemEsquerda ? parametros.configuracao.margemEsquerda : padraoMargenEsquerda;
    let margenDireita = parametros.configuracao.margemDireita ? parametros.configuracao.margemDireita : padraoMargenDireita;
    let cabecalhoAltura = parametros.configuracao.cabecalhoAltura ? parametros.configuracao.cabecalhoAltura : padraoCabecalhoAltura;
    let rodapeAltura = parametros.configuracao.rodapeAltura ? parametros.configuracao.rodapeAltura : padraoRodapeAltura;

    let lista1 = null
    let lista2 = null
    let lista3 = null

    if (parametros.lista1) {lista1 = parametros.lista1}
    if (parametros.lista2) {lista2 = parametros.lista2}
    if (parametros.lista3) {lista3 = parametros.lista3}

    //parametros.titulo = "Gerando PDF"
    //parametros.clinica = 'Clínica Note - Sorocaba'
    //parametros.clinicaId = 'R9';
    //parametros.profissionalId = 19;
    //
    //parametros.relatorioId = "Rel-10-1"
    //parametros.relatorioNome = "relatorio"

    let grupo = parametros.identificacao.relatorioId.substring(0,3);
    let relId = parametros.identificacao.relatorioId.substring(3,20).trim();
    let pasta = '';
    let headerDetalhe = '';
    
    if (grupo=='Fat') {
        pasta = 'faturamento';

        if (relId == '0001') {
            headerDetalhe = `
                <h1>Detalhe Guia ${parametros.detalheHeader.guiaNaOperadora} do(a) Paciente ${parametros.detalheHeader.pacienteNome} - ${parametros.detalheHeader.pacienteId}</h1>
            `
        }
    } else if (grupo == 'Fin') {
        pasta = 'financeiro'
    } else if (grupo == 'Flx') {
        pasta = 'fluxoCaixa'
    } else if (grupo == 'Cad') {
        pasta = 'cadastro'
    } else if (grupo == 'Agd') {
        pasta = 'agenda'
    } else {
        return `Grupo não definido para Relatório ${grupo}`;
    }
    //------------------------------------------ Nome pdf gerado
    const data = new Date();
    //const pdfNome = data.getFullYear().toString();
    const pdfNome = `rel${parametros.identificacao.relatorioId}`+'_'+parametros.identificacao.clinicaId+'_'+parametros.identificacao.profissionalId.toString()+'_'+
                    data.getFullYear().toString()+data.getMonth().toString()+data.getDate().toString()+'_'+
                    data.getHours().toString()+data.getMinutes().toString()+data.getSeconds().toString();
    //------------------------------------------                

    const pastaRaiz = path.dirname(require.main.filename);
    
    //const existe = fs.existsSync('../viewengines/relatorios/faturamento/relFat0001.ejs');
//
    //if (!existe) {
    //    return '-- nao existe';
    //}

    let fileToRender = `${pastaRaiz}/viewengines/relatorios/${pasta}/rel${parametros.identificacao.relatorioId}.ejs`;

    try {
        ejs.renderFile(fileToRender, {lista1 : lista1, lista2 : lista2, lista3 : lista3}, (err, html) => {
            if (err) {
                return `erro render: ${err}`;
            } else {
                const options = {
                    format : `${format}`, 
                    orientation : `${orientation}`, 
                    border : {top : `${margenSuperior}mm`, bottom : `${margenInferior}mm`, left : `${margenEsquerda}mm`, right : `${margenDireita}mm`},
                    paginationOffset: 1,
                    header: {
                        'height': `${cabecalhoAltura}mm`,
                        'contents': `<div style="text-align: center;">
                                        ${parametros.identificacao.clinica}
                                    </div>
                                    <div style="text-align: center;">
                                        ${parametros.identificacao.titulo} - ${relId}
                                    </div>
                                    <div style="text-align: center;">
                                        ${headerDetalhe}}
                                    </div>
                                        `
                      },
                      footer: {
                        'height': `${rodapeAltura}mm`,
                        'contents': {
                          //first: 'Cover page',
                          //2: 'Second page', // Any page number is working. 1-based index
                          //default: '<span style="color: #444;">Página : {{page}}</span>/<span>{{pages}}</span>', // fallback value
                          default: '<hr><p style="color: #444; text-align:right">Página : {{page}}/{{pages}}</p>', 
                          //last: 'Last Page'
                        }
                      },            
                };
            
                htmlPdf.create(html, options).toFile(`${pastaRaiz}/uploads/${pdfNome}.pdf`, (err, res) => {
                    if (err) {
                        return `HtmlPdf error: ${err}`;
                    } else {
                        return res
                    }
                });
            }
        })
    } catch(e) {
        return 'Erro em Try/Catch'
    }
}

export default {
    gerarRelatorioPDF
}


//https://www.youtube.com/watch?v=SaHmXe6tFwc

//app.use(express.json);
//
//app.get(`/download`, (req, res) => {
//    res.type('pdf');
//    res.download(`./uploads/${pdfNome}.pdf`);
//})
//
//app.listen(3333);




