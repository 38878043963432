import errors from "@/utils/errors";

export default {
  data() {
    return {
      count: 0,
      limit: 0,
      profissionais: [],
    }
  },
  methods: {
    async carregarRealizaAtendimento(tenant, realizaAtendimento, ativo, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      await this.$http.get(`/v1.0/profissionais/${tenant}/RealizaAtendimento/${realizaAtendimento}/${ativo}/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.erro = "";

          this.loadedProfissionais = true;
        })
        .catch(error => this.erro = errors.tratarErros(error))
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisPeloPerfil(tenant, perfilId, campoFiltro, filtro, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      await this.$http
        .get(`/v1.0/profissionais/${tenant}/Perfil/${perfilId}/${campoFiltro}/${filtro}/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;

          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisPelaFuncao(tenant, funcao, apenasAtivos, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      await this.$http
        .get(`/v1.0/profissionais/${tenant}/Funcao/${funcao}/${apenasAtivos}/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisSolicitantes(tenant, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      await this.$http
        .get(`/v1.0/profissionais/${tenant}/solicitantes/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisExecutantes(tenant, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;
      
      await this.$http
        .get(`/v1.0/profissionais/${tenant}/executantes/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisFisioterapeutas(tenant, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;
      
      await this.$http
        .get(`/v1.0/profissionais/${tenant}/fisioterapeutas/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    carregarProfissionais(tenant, profissionalTipoId, apenasAtivos, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      this.$http
        .get(`/v1.0/profissionais/${tenant}/perfil/${profissionalTipoId}/${apenasAtivos}/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarProfissionaisAtivos(tenant, ativo) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      await this.$http
        .get(`/v1.0/profissionais/${tenant}/ativos/${ativo}`)
        .then(resp => {
          this.profissionais = resp.data;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    carregarProfissionaisExternos(tenant, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      this.$http
        .get(`/v1.0/profissionais/${tenant}/externos/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    carregarProfissionaisComAgendaPropria(tenant, pagina) {
      this.loadingProfissionais = true;
      this.loadedProfissionais = false;

      this.$http.get(`/v1.0/profissionais/${tenant}/PossuiAgendaPropria/${pagina}`)
        .then(resp => {
          this.profissionais = resp.data.registros;
          this.count = resp.data.count;
          this.limit = resp.data.limit;

          this.erro = "";
          this.loadedProfissionais = true;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProfissionais = false;
        });
    },
    async carregarAgendaPermitidos(tenant) {
      this.loadingAgendaPermitidos = true;

      await this.$http
        .get(`/v1.0/agendapermitidos/${tenant}/profissionalproprietario/${this.profissionalSelecionado.id}`)
        .then(resp => {
          this.permitidos = [...resp.data];
          this.erro = "";
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingAgendaPermitidos = false;
        });
    },
    async carregarProntuariosPermitidos(tenant) {
      this.loadingProntuariosPermitidos = true;

      await this.$http
        .get(`/v1.0/prontuariopermitidos/${tenant}/profissionalproprietario/${this.profissionalSelecionado.id}`)
        .then(resp => {
          this.permitidos = resp.data;
          this.erro = "";
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loadingProntuariosPermitidos = false;
        });
    },
  }
}
