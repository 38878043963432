<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Lançamentos - {{fluxoSelecionado.descricao}} - {{contaSelecionada.descricao}} - {{lancamentoSelecionado.data | formatDate}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
        <!-- Saldos do Lançamento Selecionado -------------------------------------------------------->
        <div class="row">
            <div class="form-group col-lg-1">
                <label for="saldoInicial" class="control-label">Saldo Inicial</label>
                <input type="text" id="saldoInicial" class="form-control" disabled :value="lancamentoSelecionado.saldoInicial.toFixed(2).replace('.',',')"/>
            </div>
            <div class="form-group col-lg-1">
                <label for="creditos" class="control-label">Créditos</label>
                <input type="text" id="creditos" class="form-control" disabled :value="lancamentoSelecionado.creditos.toFixed(2).replace('.',',')"/>
            </div>
            <div class="form-group col-lg-1">
                <label for="debitos" class="control-label">Débitos</label>
                <input type="text" id="debitos" class="form-control" disabled :value="lancamentoSelecionado.debitos.toFixed(2).replace('.',',')"/>
            </div>
            <div class="form-group col-lg-1">
                <label for="saldoFinal" class="control-label">Saldo Final</label>
                <input type="text" id="saldoFinal" class="form-control" disabled :value="lancamentoSelecionado.saldoFinal.toFixed(2).replace('.',',')"/>
            </div>
            <template v-if="modo==''">
                <div class="form-group col-lg-2" style="margin-top:25px">
                    <button class="btn btn-sm btn-info form-control" @click="incluirLancamento()">Novo Lançamento</button>
                </div>
                <div class="form-group col-lg-1" style="margin-top:25px">
                    <button class="btn btn-sm btn-info form-control" @click="recalcularLancamentos()">Recalcular</button>
                </div>
            </template>
        </div>
        <hr/>
        <!-- Campos para Incluir/Alterar um Item no Lancamento --------------------------------------------->
        <template v-if="modo!=''">
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="Movimento" class="control-label">Tipo Lançamento</label>
                    <select id="Movimento" class="form-control" v-model="movimento" selected>
                        <option value="D">Despesas</option>
                        <option value="R">Receitas</option>
                    </select>
                </div>
                <div class="form-group col-lg-10">
                    <label for="centrosCredito" class="control-label">Centros</label>
                    <select id="centrosCredito" class="form-control" v-model="lancamentoItem.centroId" selected @change="selecionouCentro()">
                        <option v-for="centro in centros" :key="centro.id" :value="centro.id">
                            {{centro.estrutura}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-3">
                    <label for="origens" class="control-label">Origens</label>
                    <select id="origens" class="form-control" v-model="lancamentoItem.origemId" selected>
                        <option v-for="centroOrigem in centroOrigens" :key="centroOrigem.origemId" :value="centroOrigem.origemId">
                            {{centroOrigem.origem}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valor" class="control-label">Valor R$</label>
                    <input type="number" id="valor" class="form-control" v-model.number="lancamentoItem.valor"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="tipoLancamentos" class="control-label">Em</label>
                    <select id="tipoLancamentos" class="form-control" v-model="lancamentoItem.tipoLancamentoId" @change="selecionouTipoLancamento()" selected>
                        <option v-for="tipoLancamento in tipoLancamentos" :key="tipoLancamento.id" :value="tipoLancamento.id">
                            {{tipoLancamento.descricao}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3">
                    <label for="descricao" class="control-label">Descrição</label>
                    <input type="text" id="descricao" class="form-control" v-model="lancamentoItem.descricao"/>
                </div>

                <!-- Utilizado apenas quando se tratar de uma transferência entre contas -->
                <template v-if="centro.tipoSistema=='TF' || modo=='Alterar'">
                    <div class="form-group col-lg-3">
                        <label for="contas" class="control-label">Conta Destino</label>
                        <select id="contas" class="form-control" v-model="lancamentoItem.contaTransferenciaId" selected>
                            <option v-for="conta in contas" :key="conta.id" :value="conta.id">
                                {{conta.descricao}}
                            </option>
                        </select>
                    </div>
                </template>
            </div>
            <template v-if="tipoLancamento=='CHEQUE'">
                <div class="row">
                    <div class="form-group col-lg-1">
                        <label for="chequeNumero" class="control-label">Cheque Número</label>
                        <input type="text" id="chequeNumero" class="form-control" v-model="lancamentoItem.chequeNumero"/>
                    </div>
                    <div class="form-group col-lg-1">
                        <label for="chequeAgencia" class="control-label">Cheque Agência</label>
                        <input type="text" id="chequeAgencia" class="form-control" v-model="lancamentoItem.chequeAgencia"/>
                    </div>
                    <div class="form-group col-lg-1">
                        <label for="chequeConta" class="control-label">Cheque Conta</label>
                        <input type="text" id="chequeConta" class="form-control" v-model="lancamentoItem.chequeConta"/>
                    </div>
                    <div class="form-group col-lg-1">
                        <label for="chequeSituacaox" class="control-label">Cheque Situação</label>
                        <select id="chequeSituacao" class="form-control" v-model="lancamentoItem.chequeSituacao" selected>
                            <option value="Lancado">Lançado</option>
                            <option value="Depositado">Depositado</option>
                            <option value="Compensado">Compensado</option>
                            <option value="Devolvido">Devolvido</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-2">
                        <label for="bancos" class="control-label">Banco</label>
                        <select id="bancos" class="form-control" v-model="lancamentoItem.bancoId" selected>
                            <option v-for="banco in bancos" :key="banco.id" :value="banco.id">
                                {{banco.codBanco}} - {{banco.nome}}
                            </option>
                        </select>
                    </div>
                </div>
            </template>
            <div class="row">
                <div class="form-group col-lg-6">
                    <button class="form-control btn btn-sm btn-secondary" @click="cancelarOperacao()">Cancelar Operação</button>
                </div>
                <div class="form-group col-lg-6">
                    <button class="form-control btn btn-sm btn-info" @click="gravarRegistro()">Gravar Lançamento</button>
                </div>
            </div>
        </template>
        <!-- Tabela com os Itens do Lancamento -------------------------------------------------------->
        <div :class="tableClass" style="display:flex; flex-direction:row; justify-content:center;">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="loaded" class="col-lg-12" hover striped small sort-icon-left :items="lancamentoItens" :fields="camposTabela">
            <template v-slot:cell(actions)="row">
              <b-button v-if="row.item.contaTransferenciaId==null" variant="warning" size="sm" @click="selecionouRegistro(row.item)">
                <i class="fa fa-pencil"/> 
              </b-button>  
              <b-button v-if="!(row.item.contaTransferenciaId!=null && row.item.movimento=='C')" variant="danger" size="sm" class="ml-1" @click="excluirRegistro(row.item)">
                <i class="fa fa-trash"/> 
              </b-button>  
            </template>
            <template v-slot:cell(datCadastro)="datCadastro">
              {{datCadastro.value | formatDate}}
            </template>
            <template v-slot:cell(valor)="valor">
              {{valor.value.toFixed(2).replace(".",",")}}
            </template>
          </b-table>
        </div>
        <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import lancamentoItens from "@/mixins/lancamentoItens"
import lancamentos from "@/mixins/lancamentos"
import centros from "@/mixins/centros"
import origens from "@/mixins/origens"
import bancos from "@/mixins/bancos"
import contas from "@/mixins/contas"
import global from "@/mixins/global"
import tipoLancamentos from "@/mixins/tipoLancamentos"
import errors from "@/utils/errors"

export default {
    mixins : [lancamentoItens, lancamentos, centros, origens, tipoLancamentos, bancos, contas, global],
    computed : {
        ...mapGetters({
            fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
            contaSelecionada : "contas/getContaSelecionada",
            lancamentoSelecionado : "lancamentos/getLancamentoSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarCampos() {
            return this.modo == "Incluir" || this.modo == "Alterar"
        },
        tableClass() {
          return {inEdit : this.modo != "", inEditCheque : this.modo != "" && this.tipoLancamento == "CHEQUE", noEdit : this.modo == ""}
        },
    },
    data() {
        return {
            page : 1,
            erro : "",
            loading : false,
            loaded : false,
            modo : "",
            movimento : "D",
            mensagem : "",
            tipoLancamento : "",
            lancamento : {},
            lancamentoItem : {},
            centro : {tipoSistema : ""},
            camposTabela : [
                {key : "datCadastro", label : "Lançado Em"},
                {key : "centro", label : "Centro"},
                {key : "origem", label : "Origem"},
                {key : "descricao", label : "Descrição"},
                {key : "tipoLancamento", label : "Forma"},
                {key : "movimento", label : "Movimento"},
                {key : "valor", label : "Valor R$"},
                {key : "actions", label : "Ações"},
            ],
            bkCreditos : 0,
            bkDebitos : 0,
            bkSaldoFinal : 0,
            bkValor : 0,
        }
    },
    watch : {
        page() {
            this.iniciarCarregarLancamentoItens();
        },
        movimento() {
            this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
        },
    },
    methods : {
        retornar() {
            this.finalizarLancamentos();
        },
        iniciarCarregarLancamentoItens() {
            this.loading = true;
            this.loaded = false;

            this.carregarLancamentoItens(this.usuarioLogadoDados.tenant, this.lancamentoSelecionado.id, this.page);
        },
        selecionouTipoLancamento() {
            this.tipoLancamento = this.tipoLancamentos.find(tipo => tipo.id == this.lancamentoItem.tipoLancamentoId).descricao.toUpperCase();
        },
        async selecionouRegistro(lancamentoItem) {
            this.modo = "Alterar";
            if (this.centros.length == 0) {
                await this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
            }

            if (this.tipoLancamentos.length == 0) {
                this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            }

            if (this.bancos.length == 0) {
                this.carregarBancos(this.usuarioLogadoDados.tenant, 0);
            }

            if (this.contas.length == 0) {
                this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.lancamentoSelecionado.contaId);
            }

            this.lancamentoItem = {...lancamentoItem}
            this.selecionouCentro();

            this.bkValor = this.lancamentoItem.valor;
            this.tipoLancamento = "";
        },
        incluirLancamento() {
            if (!this.verificarPermissao('Incluir')) {
              return
            }

            this.modo="Incluir";
            if (this.centros.length == 0) {
                this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
            }

            if (this.tipoLancamentos.length == 0) {
                this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            }

            if (this.bancos.length == 0) {
                this.carregarBancos(this.usuarioLogadoDados.tenant, 0);
            }

            if (this.contas.length == 0) {
                this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.lancamentoSelecionado.contaId);
            }

            this.bkValor = 0;
            this.tipoLancamento = "";
        },
        finalizarLancamentos() {
            //----------------------------------------------------------------- verificar se não houveram mudanças desde a entrada
            if (this.lancamentoSelecionado.creditos == this.bkCreditos &&
                this.lancamentoSelecionado.debitos == this.bkDebitos &&
                this.lancamentoSelecionado.saldoFinal == this.bkSaldoFinal) {
                this.$router.go(-1);

                return;
            }
            //----------------------------------------------------------------------------------------------------------------

            this.lancamentoSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}/${this.lancamentoSelecionado.id}`, this.lancamentoSelecionado)
            .then(() => {
                this.$toasted.global.defaultSuccess();
                this.$router.go(-1);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
                this.$toasted.global.defaultError();
            });
        },
        recalcularLancamentos() {
            let debitos = 0;
            let creditos = 0;

            this.lancamentoItens.forEach(lancamentoItem => {
                if (lancamentoItem.movimento=="D") {
                    debitos += lancamentoItem.valor;
                } else {
                    creditos += lancamentoItem.valor;
                }
            });

            this.lancamentoSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.lancamentoSelecionado.debitos = debitos;
            this.lancamentoSelecionado.creditos = creditos;
            this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial + creditos - debitos;

            this.$http.put(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}/${this.lancamentoSelecionado.id}`, this.lancamentoSelecionado)
            .then(() => {
                this.$toasted.global.defaultSuccess();
                this.$router.go(-1);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
                this.$toasted.global.defaultError();
            });

        },
        selecionouCentro() {
            this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.lancamentoItem.centroId, 0);

            //------- Utilizado apenas para guardar o Centro Selecionado e verificar se é um Centro de Especial do Sistema
            this.centro = this.centros.find(centro => centro.id == this.lancamentoItem.centroId);
        },
        gravarRegistro() {
            if (!this.verificarCamposPreenchidos()) {
                return;
            }

            if (this.centro.tipoSistema == "TF") {
                this.transferenciaEntreContas();
                return;
            }

            if (this.modo=="Alterar") {
                if (this.lancamentoItem.contaTransferenciaId > 0) {
                    this.transferirParaOutraConta();
                    return;
                }

                this.lancamentoItem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

                this.$http.put(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}/${this.lancamentoItem.id}`, this.lancamentoItem)
                .then(() => {
                    this.erro = "";
                    this.modo = "";

                    //---------------------------------------------------------------------------- Atualizando Saldos Localmente
                    if (this.bkValor != this.lancamentoItem.valor) {
                        if (this.lancamentoItem.movimento == "D") {
                            this.lancamentoSelecionado.debitos -= this.bkValor;
                            this.lancamentoSelecionado.debitos += this.lancamentoItem.valor;
                        } else {
                            this.lancamentoSelecionado.creditos -= this.bkValor;
                            this.lancamentoSelecionado.creditos += this.lancamentoItem.valor;
                        }
                        this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                                this.lancamentoSelecionado.creditos -
                                                                this.lancamentoSelecionado.debitos;
                    }
                    //----------------------------------------------------------------------------------------------------------
                    
                    this.bkValor = 0;
                    this.lancamentoItem = {}

                    this.iniciarCarregarLancamentoItens();
                    this.$toasted.global.defaultSuccess();
                })
                .catch(error => this.erro = errors.tratarErros(error));
            } else {
                this.lancamentoItem.lancamentoId = this.lancamentoSelecionado.id;
                this.lancamentoItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

                let centro = this.centros.find(c => c.id == this.lancamentoItem.centroId);
                this.lancamentoItem.movimento = centro.tipo == "D" ? "D" : "C";

                this.$http.post(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}`, this.lancamentoItem)
                .then(() => {
                    this.erro = "";
                    //this.modo = "";

                    //---------------------------------------------------------------------------- Atualizando Saldos Localmente
                    if (this.lancamentoItem.movimento == "D") {
                        this.lancamentoSelecionado.debitos += this.lancamentoItem.valor;
                    } else {
                        this.lancamentoSelecionado.creditos += this.lancamentoItem.valor;
                    }
                    this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                            this.lancamentoSelecionado.creditos -
                                                            this.lancamentoSelecionado.debitos;
                    //----------------------------------------------------------------------------------------------------------

                    this.lancamentoItem = {}

                    this.iniciarCarregarLancamentoItens();
                    this.$toasted.global.defaultSuccess();
                })
                .catch(error => this.erro = errors.tratarErros(error));
            }
        },
        verificarCamposPreenchidos() {
            if (this.lancamentoItem.centroId == undefined) {
                this.$toasted.show("Selecione o Centro do Lançamento.", {type:"info", icon:"times"});
                return false;
            }

            if (this.lancamentoItem.origemId == undefined) {
                this.$toasted.show("Selecione a Origem do Lançamento.", {type:"info", icon:"times"});
                return false;
            }

            if (this.valor == 0) {
                this.$toasted.show("Digite o Valor do Lançamento.", {type:"info", icon:"times"});
                return false;
            }

            if (this.lancamentoItem.tipoLancamentoId == 0) {
                this.$toasted.show("Digite a Forma do Lançamento.", {type:"info", icon:"times"});
                return false;
            }

            if (this.centro.tipoSistema == "TF" && this.lancamentoItem.contaTransferenciaId == undefined) {
                this.$toasted.show("Selecione a Conta Destino para a Transferência.", {type:"info", icon:"times"});
                return false;
            }

            return true;
        },
        async excluirRegistro(lancamentoItem) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            this.$bvModal
              .msgBoxConfirm("Excluir o Lançamento selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                    if (lancamentoItem.centro.tipoSistema == null) {
                        let bkValor = lancamentoItem.valor;
                        let bkMovimento = lancamentoItem.movimento;

                        //------------------------------------------- Lançamento não é originado de Transferência
                        this.$http.delete(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}/${lancamentoItem.id}/${this.usuarioLogadoDados.profissionalId}`)
                        .then(() => {
                            this.erro = "";
    
                            //------------------------------------------------------------------------- Atualizando Saldos Localmente
                            if (bkMovimento == "D") {
                                this.lancamentoSelecionado.debitos -= bkValor;
                            } else {
                                this.lancamentoSelecionado.creditos -= bkValor;
                            }
                            this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                                    this.lancamentoSelecionado.creditos -
                                                                    this.lancamentoSelecionado.debitos;
                            //--------------------------------------------------------------------------------------------------------
    
                            this.iniciarCarregarLancamentoItens();
                            this.$toasted.global.defaultSuccess();
                        })
                        .catch(error => this.erro = errors.tratarErros(error));
                    } else {
                        this.excluirTransferencia(lancamentoItem)
                    }
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        async transferenciaEntreContas() {
            if (!this.verificarPermissao('Alterar')) {
              return
            }

            //let bkLancamentoItem = {...this.lancamentoItem}

            //----------------------------------------------------- Lançar Saída na Conta Origem
            this.lancamentoItem.lancamentoId = this.lancamentoSelecionado.id;
            this.lancamentoItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.lancamentoItem.movimento = "D";

            await this.$http.post(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}/transferenciaEntreContas`, this.lancamentoItem)
                    .then(() => {
                        this.erro = "";
                        //---------------------------------------------------------------------------- Atualizando Saldos Localmente
                        this.lancamentoSelecionado.debitos += this.lancamentoItem.valor;
                        this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                                this.lancamentoSelecionado.creditos -
                                                                this.lancamentoSelecionado.debitos;
                        //----------------------------------------------------------------------------------------------------------
                        this.lancamentoItem = {}

                        this.modo = "";
                        this.iniciarCarregarLancamentoItens();
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    });
        },
        async transferirParaOutraConta() {
            if (!this.verificarPermissao('Alterar')) {
              return
            }

            let bkValor = this.lancamentoItem.valor;
            let bkMovimento = this.lancamentoItem.movimento;

            this.lancamentoItem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            await this.$http.put(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}/transferenciaParaOutraConta/${this.lancamentoItem.id}`, this.lancamentoItem)
                    .then(() => {
                        this.erro = "";
                        //---------------------------------------------------------------------------- Atualizando Saldos Localmente
                        if (bkMovimento=="D") {
                            this.lancamentoSelecionado.debitos -= bkValor;
                        } else {
                            this.lancamentoSelecionado.creditos -= bkValor;
                        }
                        this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                                this.lancamentoSelecionado.creditos -
                                                                this.lancamentoSelecionado.debitos;
                        //----------------------------------------------------------------------------------------------------------
                        this.lancamentoItem = {}

                        this.modo = "";
                        this.iniciarCarregarLancamentoItens();
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    });
        },
        async excluirTransferencia(lancamentoItem) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            let bkValor = lancamentoItem.valor;
            await this.$http.delete(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}/transferenciaEntreContas/${lancamentoItem.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                        this.erro = "";

                        this.lancamentoSelecionado.debitos -= bkValor;
                        this.lancamentoSelecionado.saldoFinal = this.lancamentoSelecionado.saldoInicial +
                                                                this.lancamentoSelecionado.creditos -
                                                                this.lancamentoSelecionado.debitos;

                        this.iniciarCarregarLancamentoItens();
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        cancelarOperacao() {
            this.modo = "";
            this.erro = "";
            this.lancamentoItem = {}
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");

            return false;
          }

          return true;
        }
    },
    created() {
        this.showMenu(false);
        this.iniciarCarregarLancamentoItens();

        this.bkCreditos = this.lancamentoSelecionado.creditos;
        this.bkDebitos = this.lancamentoSelecionado.debitos;
        this.bkSaldoFinal = this.lancamentoSelecionado.saldoFinal;
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }

    .inEdit {
      height: 278px;
      overflow: auto;
    }

    .inEditCheque {
      height: 200px;
      overflow: auto;
    }

    .noEdit {
      height: 485px;
      overflow: auto;
    }
</style>
