import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            operadoras : []
        }
    },
    methods : {
            async carregarOperadora(tenant, operadoraId) {
              this.loading = true;
              await this.$http
                    .get(`/v1.0/operadoras/${tenant}/${operadoraId}`)
                    .then(resp => {
                      this.operadora = resp.data;
                      this.error = "";
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {
                      this.loading = false;
                    })
          },
          async carregarOperadoras(tenant, ativa, pagina) {
            this.loading = true;
            this.loaded = false;

            await this.$http
                  .get(`/v1.0/operadoras/${tenant}/ativas/${ativa}/${pagina}`)
                  .then(resp => {
                    this.operadoras = resp.data.registros;
                    this.count = resp.data.count;
                    this.limit = resp.data.limit;
                  
                    this.error = "";
                    this.loaded = true;
                  })
                  .catch(error => {
                    this.erro = errors.tratarErros(error);
                  })
                  .finally(() => {
                    this.loading = false;
                  })
        },
        async carregarConsultasDiferenciadas(tenant, operadoraId) {
          this.loading = true;
          this.loaded = false;

          await this.$http
            .get(`/v1.0/profissionalConsultasDiferenciadas/${tenant}/operadora/${operadoraId}`)
            .then(resp => {
              this.profissionaisConsultasDiferenciadas = resp.data;
            
              this.error = "";
              this.loaded = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loading = false;
            })
        }
    }
}
