<template>
  <div id="CadOperadoraDados">
    <!-- <div class="container-fluid"> -->
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="paiNome" class="control-label" style="margin: 0px">Pai</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="paiNome"
            name="paiNome"
            v-model="pacienteSelecionado.paiNome"
          />
        </div>

        <div class="form-group col-lg-4">
          <label for="maeNome" class="control-label" style="margin: 0px">Mãe</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="maeNome"
            name="maeNome"
            v-model="pacienteSelecionado.maeNome"
          />
        </div>
      </div>

      <hr style="margin:0px"/>

      <div class="row">
        <div class="form-group col-lg-4">
          <label for="responsavelNome" class="control-label" style="margin: 0px">Responsável</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelNome"
            name="responsavelNome"
            v-model="pacienteSelecionado.responsavelNome"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="responsavelRgNumero" class="control-label" style="margin: 0px">R.G.</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelRgNumero"
            name="responsavelRgNumero"
            v-model="pacienteSelecionado.responsavelRgNumero"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="responsavelRgOrgao" class="control-label" style="margin: 0px">Orgão</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelRgOrgao"
            name="responsavelRgOrgao"
            v-model="pacienteSelecionado.responsavelRgOrgao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="responsavelCpf" class="control-label" style="margin: 0px">C.P.F.</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelCpf"
            name="responsavelCpf"
            v-model="pacienteSelecionado.responsavelCpf"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="responsavelProfissao" class="control-label" style="margin: 0px">Profissão</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelProfissao"
            name="responsavelProfissao"
            v-model="pacienteSelecionado.responsavelProfissao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="responsavelDddCelular" class="control-label" style="margin: 0px">DDD</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelDddCelular"
            name="responsavelDddCelular"
            v-model="pacienteSelecionado.responsavelDddCelular"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="responsavelCelular" class="control-label" style="margin: 0px">Celular</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelCelular"
            name="responsavelCelular"
            v-model.number="pacienteSelecionado.responsavelCelular"
          />
        </div>

        <div class="form-group col-lg-3">
          <label for="responsavelEmail" class="control-label" style="margin: 0px">Email</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="responsavelEmail"
            name="responsavelEmail"
            v-model="pacienteSelecionado.responsavelEmail"
          />
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      modo: "pacientes/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
