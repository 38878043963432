const state = {
    despesaCobertaSelecionada : Object,
    modo : ""
}

const getters = {
    getModo(state) {
        return state.modo;
    },

    getDespesaCobertaSelecionada(state) {
        return state.despesaCobertaSelecionada;
    }
}

const mutations = {
    setDespesaCobertaSelecionada(state, despesaCoberta) {
        state.despesaCobertaSelecionada = {...despesaCoberta}
    },

    setModo(state, modo) {
        state.modo = modo;
    },

    limparRegistroSelecionado(state) {
        state.despesaCobertaSelecionada = {}
    },
}

const actions = {
    setDespesaCobertaSelecionada({commit}, despesaCoberta) {
        commit("setDespesaCobertaSelecionada", despesaCoberta);
    },

    setModo({commit}, modo) {
        commit("setModo", modo)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado");
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('setModo', "");
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}