<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Unidades</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
        <button
          id="Incluir"
          @click="incluirRegistro()"
          class="btn btn-sm btn-info mr-2"
        >Incluir Nova Unidade</button>
        <hr/>
    </div>

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div class="container-fluid">
      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <table class="table table-sm table-hover col-lg-7" v-if="!loading">
          <thead class="thead-light">
            <th>Id</th>
            <th>Unidade</th>
            <th>Ativa</th>
            <th>DDD</th>
            <th>Fone</th>
            <th>WhatsApp</th>
            <th>Ações</th>
          </thead>
          <tbody>
            <tr
              v-for="unidade in unidades"
              :key="unidade.id"
            >
              <td>{{unidade.id}}</td>
              <td>{{unidade.descricao}}</td>
              <td>{{unidade.ativa}}</td>
              <td>{{unidade.foneDdd}}</td>
              <td>{{unidade.fone}}</td>
              <td>{{unidade.whatsapp}}</td>
              <td>
                <b-button variant="warning" size="sm" @click="selecionouUnidade(unidade)">
                  <i class="fa fa-pencil"/>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mxunidades from "@/mixins/unidades";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [mxunidades, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      ativaLocal: "S",
      loading: true,
      erro: "",
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      setUnidadeselecionada: "unidades/setUnidadeSelecionada",
      limparRegistroSelecionado : "unidades/limparRegistroSelecionado",
      setModo : "unidades/setModo",
      formLeave: "unidades/formLeave"
    }),
    incluirRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }
      
      this.limparRegistroSelecionado();
      this.setModo("Incluir");
      this.$router.push('/cadUnidade');
    },
    selecionouUnidade(unidade) {
      this.setUnidadeselecionada(unidade);
      this.setModo("Registro");
      this.$router.push("/cadUnidade");
    },
    retornar() {
      this.$router.go(-1);
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarUnidades(this.usuarioLogadoDados.tenant);
  },
}
</script>

<style scoped>
</style>