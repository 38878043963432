const state = {
    fluxoSelecionado : {}
}

const getters = {
    getFluxoSelecionado(state) {
        return state.fluxoSelecionado;
    },
}

const mutations = {
    setFluxoSelecionado(state, fluxo) {
        state.fluxoSelecionado = {...fluxo}
    }
}

const actions = {
    setFluxoSelecionado({commit}, fluxo) {
        commit("setFluxoSelecionado", fluxo);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}