import errors from "@/utils/errors";

export default {
    data() {
        return {
            operadoraProfissionais : [],
            profissionalOperadoras : [],
        }
    },
    methods: {
        carregarOperadoraProfissionais(tenant, operadoraId) {
            this.loadingProfissionalOperadora = true;
            this.loadedProfissionalOperadora = false;

            this.$http.get(`/v1.0/operadorasprofissionais/${tenant}/Operadora/${operadoraId}`)
            .then(resp => {
                this.operadoraProfissionais = resp.data;
                this.erro = "";

                this.loadedProfissionalOperadora = true;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error)
            })
            .finally(() => this.loadingProfissionalOperadora = false);
        },
        carregarProfissionalOperadoras(tenant, profissionalId) {
            this.loadingProfissionalOperadora = true;
            this.loadedProfissionalOperadora = false;

            this.$http.get(`/v1.0/operadorasprofissionais/${tenant}/Profissional/${profissionalId}`)
            .then(resp => {
                this.profissionalOperadoras = resp.data;
                this.erro = "";

                this.loadedProfissionalOperadora = true;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => this.loadingProfissionalOperadora = false);
        }
    }
}
