import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    data() {
        return {
            prontuarios : [],
            possuiProntuariosComProfissional : "N"
        }
    },
    methods : {
        carregarProntuarios(tenant, pacienteId, profissionalId) {
            this.$http.get(`/v1.0/prontuarios/${tenant}/paciente/${pacienteId}/${profissionalId}`)
            .then(resp => {
                this.prontuarios = resp.data;
                this.erro = "";

                this.previousContent = "";

                let Hoje = funcoes.mySqlToJSDateFormat(new Date(), "pt-BR");

                for(var indice=0; indice<this.prontuarios.length; indice++) {
                    let Data = funcoes.mySqlToJSDateFormat(this.prontuarios[indice].datCadastro, "pt-BR");
                    let dataHora = new Date(this.prontuarios[indice].datCadastro);

                    if (this.prontuarios[indice].profissionalId == profissionalId && Data == Hoje && this.prontuarios[indice].readOnly!="S") {
                        this.currentContent = {...this.prontuarios[indice]}
                    } else {
                        this.previousContent += "<h4 style='color:blue;'><Strong>" + Data + " às " + 
                                                dataHora.getHours()+":"+dataHora.getMinutes() + " - " +
                                                this.prontuarios[indice].profissional+"</Strong></h4><br>";
    
                        this.previousContent += this.prontuarios[indice].ficha+"<br>";
                    }
                }
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        carregarProntuariosComProfissional(tenant, pacienteId, profissionalId, profissionalConsultaId) {
            this.$http.get(`/v1.0/prontuarios/${tenant}/profissional/${profissionalId}/paciente/${pacienteId}/${profissionalConsultaId}/N`)
            .then(resp => {
                this.prontuarios = resp.data;
                this.erro = "";

                this.previousContent = "";

                let Hoje = funcoes.mySqlToJSDateFormat(new Date(), "pt-BR");

                for(var indice=0; indice<this.prontuarios.length; indice++) {
                    let Data = funcoes.mySqlToJSDateFormat(this.prontuarios[indice].datCadastro, "pt-BR");
                    let dataHora = new Date(this.prontuarios[indice].datCadastro);

                    if (this.prontuarios[indice].profissionalId == profissionalId && Data == Hoje && this.prontuarios[indice].readOnly!="S") {
                        this.currentContent = {...this.prontuarios[indice]}
                    } else {
                        this.previousContent += "<h4 style='color:blue;'><Strong>" + Data + " às " + 
                                                dataHora.getHours()+":"+dataHora.getMinutes() + " - " +
                                                this.prontuarios[indice].profissional+"</Strong></h4><br>";
    
                        this.previousContent += this.prontuarios[indice].ficha+"<br>";
                    }
                }
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        async verificarExistenciaProntuariosComProfissional(tenant, profissionalId, pacienteId, profissionalConsultaId) {
            await this.$http.get(`/v1.0/prontuarios/${tenant}/profissional/${profissionalId}/paciente/${pacienteId}/${profissionalConsultaId}/S`)
                .then(resp => {
                    this.possuiProntuariosComProfissional = resp.data;
                })
                .catch(error => this.erro = errors.tratarErros(error))
        },
    }
}
