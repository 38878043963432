<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Transferir - Sessões {{guiaOrigem.siglaServico}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="row justify-content-center">
                <div class="col-lg-2">
                    <label for="datInicial" class="control-label">Data Inicial</label>
                    <input type="date" id="datInicial" class="form-control" v-model="datInicial">
                </div>
                <div class="col-lg-2">
                    <label for="datFinal" class="control-label">Data Final</label>
                    <input type="date" id="datFinal" class="form-control" v-model="datFinal">
                </div>
                <div class="col-lg-4">
                  <label for="Guias" class="control-label">Para a Guia</label>
                  <select id="Guias" class="form-control" v-model="guiaDestinoId" @change="selecionouGuiaDestino()">
                    <option v-for="guiaDoPaciente in guiasDoPaciente" :key="guiaDoPaciente.id" :value="guiaDoPaciente.id">
                      Data: {{guiaDoPaciente.datAtendimento | formatDate}} - Guia Prestador: {{guiaDoPaciente.guiaPrestador}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2">
                    <button id="Selecionar" class="btn btn-small btn-info form-control mt-4" @click="selecionar()">Selecionar</button>
                </div>
                <div class="col-lg-2">
                    <button id="RemoverSelecao" class="btn btn-small btn-info form-control mt-4" @click="removerSelecao()">Remover Seleção</button>
                </div>
            </div>

            <div id="erro">
              <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
            </div>

            <div class="row justify-content-center mt-5">
              <div class="col-lg-8">
                <div style="height:450px;overflow:auto">
                  <b-table class="mt-2 mb-1" hover striped small sort-icon-left :items="guiasSelecionadas" :fields="camposTabela">
                      <template v-slot:cell(data)="data">
                          {{ data.value | formatDate}}
                      </template>    
                      <template v-slot:cell(datGuia)="datGuia">
                          {{ datGuia.value | formatDate}}
                      </template>    
                      <template v-slot:cell(selecionado)="selecionado">
                          {{selecionado.value = selecionado.value == "S" ? "Sim": ""}}
                      </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <button id="TransferirSessoes" class="btn btn-small btn-danger form-control" @click="confirmarExecutarTransferir()">Transferir Sessões Selecionadas</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import global from "@/mixins/global"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    mixins : [agendaAtendimentos, global],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            guiaOrigem : "guias/getGuiaSelecionada",
            guiasDoPaciente : "guias/getGuiasDoPaciente",
            pacienteDatasAgendadas : "agendaAtendimentos/getDatasSelecionadas",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
        return {
            erro : "",
            datInicial : null,
            datFinal : null,
            guiaDestinoId : 0,
            guiaSelecionada : null,
            guiasSelecionadas : [],
            camposTabela : [
              {key : "data", label : "Atend.", sortable : true},
              {key : "diaSemanaAbrev", label : "Dia", sortable : true},
              {key : "horario", label : "Horário", sortable : true},
              {key : "preferencial", label : "Pref"},
              {key : "preferencial2", label : "Pref 2"},
              {key : "preferencial3", label : "Pref 3"},
              {key : "status", label : "Status"},
              {key : "selecionado", label : "Selecionado"}
            ],
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        selecionouGuiaDestino() {
          this.guiaSelecionada = this.guiasDoPaciente.find(guia => guia.id == this.guiaDestinoId);
        },
        selecionar() {
            let datInicial = new Date(this.datInicial+"T00:00:00");
            let datFinal = new Date(this.datFinal+"T00:00:00");

            this.guiasSelecionadas.forEach(sessao => {
                let datSessao = new Date(sessao.data);

                if (datSessao >= datInicial && datSessao <= datFinal) {
                    sessao.selecionado = "S";
                }
            });
        },
        removerSelecao() {
            let datInicial = new Date(this.datInicial+"T00:00:00");
            let datFinal = new Date(this.datFinal+"T00:00:00");

            this.guiasSelecionadas.forEach(sessao => {
                let datSessao = new Date(sessao.data)

                if (datSessao >= datInicial && datSessao <= datFinal) {
                    sessao.selecionado = "N";
                }
            });
        },
        confirmarExecutarTransferir() {
          if (!this.verificarCamposPreenchidos()) {
            return;
          }

          this.$bvModal
            .msgBoxConfirm("Transferir Sessões Selecionadas?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.executarTransferir();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        verificarCamposPreenchidos() {
          if (this.guiaDestinoId==0) {
            this.$toasted.show("Selecione a Guia para onde deseja Transferir as Sessões Selecionadas.", {type:"info", icon:"times"});
            return false;
          }

          return true;
        },
        async executarTransferir() {
          let selecionados = this.guiasSelecionadas.filter(sessao => sessao.selecionado == "S");

          if (selecionados.length == 0) {
              this.$toasted.show("Não foram selecionadas Sessões para realizar a operação.", {type : "info", icon : "times"});
              return;
          }

          await this.transferirSessoesDaGuia(this.usuarioLogadoDados.tenant, selecionados);
          this.retornar();
        },
    },
    created() {
      this.showMenu(false);

      this.datInicial = funcoes.mySqlToJSDateFormat(new Date());
      this.datFinal = funcoes.mySqlToJSDateFormat(this.guiaOrigem.datValidadeSenha);

      this.guiasSelecionadas = this.pacienteDatasAgendadas.map(sessao => {
          let selecionado = "N";
          return {...sessao, selecionado}
      });
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>