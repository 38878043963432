const state = {
    guiaDespesaSelecionada : {}
}

const getters = {
    getGuiaDespesaSelecionada(state) {
        return state.guiaDespesaSelecionada;
    }
}

const mutations = {
    setGuiaDespesaSelecionada(state, guiaDespesa) {
        state.guiaDespesaSelecionada = {...guiaDespesa};
    }
}

const actions = {
    setGuiaDespesaSelecionada({commit}, guiaDespesa) {
        commit("setGuiaDespesaSelecionada", guiaDespesa);
    },

    limparRegistro({commit}) {
        commit("setGuiaDespesaSelecionada", null);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}