<template>
    <div>
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <div class="row">
        <div class="col-lg-5" style="height:500px;max-height:510px;overflow:auto">
          <VueEditor id="previousEditor" v-model="previousContent" :editor-toolbar="previousEditorToolBar" disabled/>
        </div>
        <div class="col-lg-7">
          <VueEditor id="currentEditor" ref="currentEditor" :disabled="!liberarCampos" v-model="currentContent.ficha" :editor-toolbar="currentEditorToolBar" style="height:500px;max-height:510px;overflow:auto"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
        </div>

        <!-- <button id="incluirAnamnese" :disabled="liberarCampos || currentContent.id != undefined" class="btn btn-sm btn-info" @click="incluirAnamnese()">Incluir</button> -->

        <div class="col-lg-7 mt-1">
          <button id="imprimirAnamnese" :disabled="!medicoDoPaciente" class="btn btn-sm btn-info" @click="imprimirAnamnese()">Imprimir</button>
          <button id="incluirAnamnese" :disabled="true || liberarCampos || !medicoDoPaciente" class="btn btn-sm btn-info ml-1" @click="incluirAnamnese()">Incluir</button>
          <button id="alterarAnamnese" :disabled="true || liberarCampos || currentContent.ficha=='' || !medicoDoPaciente" class="btn btn-sm btn-info ml-1" @click="alterarAnamnese()">Alterar</button>
          <button id="cancelarAnamnese" :disabled="!liberarCampos" class="btn btn-sm btn-secondary ml-1" @click="cancelarAnamnese()">Cancelar</button>
          <button id="gravarAnamnese" :disabled="!liberarCampos || !medicoDoPaciente" class="btn btn-sm btn-primary ml-1" @click="gravarAnamnese('N')">Gravar</button>
          <button id="gravarFecharAnamnese" :disabled="!liberarCampos || !medicoDoPaciente" class="btn btn-sm btn-primary ml-1" @click="fechar()">Gravar e Fechar</button>
          <button id="excluirAnamnese" :disabled="true || liberarCampos || currentContent.ficha=='' || currentContent.readOnly=='S' || !medicoDoPaciente" class="btn btn-sm btn-danger ml-5" @click="excluirAnamnese()">Excluir</button>
        </div>
      </div>
      <div class="Mensagem">
        <b-modal id="modal-mensagem" centered title="DoctorOn">
          <p class="my-4">{{ mensagem }}</p>
        </b-modal>
      </div>
      <b-spinner v-if="loadingRelatorio" label="carregando..."/>
      <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>    
</template>

<script>
import {VueEditor} from "vue2-editor"
import {mapGetters} from "vuex"
import prontuarios from "@/mixins/prontuarios"
import relatoriosAtendimentos from "@/mixins/relatoriosAtendimentos"
import errors from "@/utils/errors"

export default {
  mixins : [prontuarios, relatoriosAtendimentos],
  components : {VueEditor},
  computed : {
    ...mapGetters({
      pacienteSelecionado : "pacientes/getPacienteSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    medicoDoPaciente() {
      return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
    }
  },
  data() {
    return {
      modo : "",
      erro : "",
      previousContent : "",
      currentContent : {},
      backupContent : "",
      previousEditorToolBar : [ [] ],
      currentEditorToolBar : [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
      ],
      [{ color: [] }, { background: [] }],
      ],

      /* -------------------------------------------------------------------------- Modelo VueEditor
            const defaultToolbar = [
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              ["bold", "italic", "underline", "strike"], // toggled buttons
              [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
              ],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              ["link", "image", "video"],
              ["clean"] // remove formatting button
            ];
      */

      mensagem : "",
      loadingRelatorio : false
    }
  },
  methods: {
    imprimirAnamnese() {
      this.carregarRelatorioProntuario(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);
    },
    incluirAnamnese() {
      this.modo = "Incluir";
      this.currentContent = {}
    },
    alterarAnamnese() {
      this.modo = "Alterar";
      this.backupContent = this.currentContent.ficha;
    },
    excluirAnamnese() {
        this.$bvModal
        .msgBoxConfirm("Excluir Anamnese?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            if (this.currentContent.id == undefined) {
              this.currentContent = {}
            } else {
              this.$http.delete(`prontuarios/${this.usuarioLogadoDados.tenant}/${this.currentContent.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.currentContent = {}
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => this.erro = errors.tratarErros(error))
            }
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarAnamnese() {
      if (this.modo=='Incluir') {
        this.currentContent = {}
      } else {
        this.currentContent.ficha = this.backupContent;
      }

      this.modo = "";
    },
    fechar() {
      this.$bvModal
      .msgBoxConfirm("Após fechada a Anamnese não poderá mais ser Alterada, Continua?", {
        title: "Atenção",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Sim",
        cancelTitle: "Não",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      })
      .then(value => {
        if (value) {
          this.gravarAnamnese('S');
        }
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
        return
      });
    },
    gravarAnamnese(fechar) {
      let DataAtual = new Date();

      if (this.modo=="Alterar") {
        this.currentContent.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.currentContent.readOnly = fechar;

        this.$http.put(`/v1.0/prontuarios/${this.usuarioLogadoDados.tenant}/${this.currentContent.id}`, this.currentContent)
        .then(() => {
          this.erro = "";
          this.modo = "";
          this.$toasted.global.defaultSuccess();
          if (fechar=='S') {
            this.currentContent = {}
            this.chamarCarregarProntuarios();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
      } else {
        this.currentContent.pacienteId = this.pacienteSelecionado.id;
        this.currentContent.datCadastro = DataAtual;
        this.currentContent.horaCadastro = DataAtual.getHours()+":"+DataAtual.getMinutes();
        this.currentContent.profissionalId = this.usuarioLogadoDados.profissionalId;
        this.currentContent.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.currentContent.docHash = "";
        this.currentContent.assinaturaDigital = "";
        this.currentContent.inconsistencia = "N";
        this.currentContent.readOnly = fechar;

        this.$http.post(`/v1.0/prontuarios/${this.usuarioLogadoDados.tenant}`, this.currentContent)
        .then(resp => {
          this.erro = "";
          this.modo = "";
          this.$toasted.global.defaultSuccess();

          this.currentContent.id = resp.data.id;

          if (fechar=='S') {
            this.currentContent = {}
            this.chamarCarregarProntuarios();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
      }
    },
    chamarCarregarProntuarios() {
      this.carregarProntuarios(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);  
    }
  },
  created() {
    this.currentContent.ficha = "";
    this.chamarCarregarProntuarios();
  }
}
</script>

<style scoped>
</style>
