import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            lancamentosFixos : [],            
        }
    },
    methods : {
        carregarLancamentosFixos(tenant, fluxoId, movimento, pagina) {
            this.$http.get(`/v1.0/lancamentoFixos/${tenant}/Fluxo/${fluxoId}/${movimento}/${pagina}`)
            .then(resp => {
                this.erro = "",
                this.loading = false,

                this.lancamentosFixos = resp.data.registros;

                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.valorTotal = 0;
                this.lancamentosFixos.forEach(lancamento => this.valorTotal += lancamento.valor);
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
