const state = {
    opcoesGeraisRelatorio : {
        relatorioId : '',
        solicitarPeriodo : 'S', solicitarOperadora : 'S', solicitarFatura : 'S', solicitarLote : 'S', solicitarGuia : 'S',
        solicitarProfissionalExecutante : 'S', solicitarPaciente : 'S'
    }
}

const getters = {
    getOpcoesGeraisRelatorio(state) {
        return state.opcoesGeraisRelatorio;
    }
}

const mutations = {
    setOpcoesGeraisRelatorio(state, opcoes) {
        state.opcoesGeraisRelatorio = {...opcoes}
    }
}

const actions = {
    setOpcoesGeraisRelatorio({commit}, opcoes) {
        commit('setOpcoesGeraisRelatorio', opcoes);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}