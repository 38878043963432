<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Formas de Lançamentos Cadastradas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Nova Forma de Lançamento</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-sm-2 col-md-2 col-lg-2">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              v-model="tipoLancamento.descricao"
              aria-describedby="bancoHelp"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Dias" class="control-label">Dias Recbto</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Dias"
              v-model.number="tipoLancamento.diasRecebimento"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Taxa" class="control-label">Taxa</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Taxa"
              v-model.number="tipoLancamento.taxaCartao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="UtilizadoPara" class="control-label">Taxa Paga por</label>
            <select id="UtilizadoPara" class="form-control" v-model="tipoLancamento.taxaPagaPor" :disabled="!liberarCampos">
              <option value=1>Profissional</option>
              <option value=2>Clínica</option>
              <option value=3>Ambos</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="UtilizadoPara" class="control-label">Para</label>
            <select id="UtilizadoPara" class="form-control" v-model="tipoLancamento.utilizadoPara" :disabled="!liberarCampos">
              <option value="T">Todos</option>
              <option value="P">Apenas Pagamento</option>
              <option value="R">Apenas Recebimento</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center; height:530px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="!loading"
          class="col-lg-6"
          hover
          striped
          small
          sort-icon-left
          :items="tipoLancamentos"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>

          <template v-slot:cell(utilizadoPara)="utilizadoPara">
              {{utilizadoPara.value = utilizadoPara.value == "R" ? "Recebto" : utilizadoPara.value == "P" ? "Pagto" : "Todos"}}
          </template>

          <template v-slot:cell(taxaPagaPor)="taxaPagaPor">
              {{taxaPagaPor.value = taxaPagaPor.value == 1 ? "Profissional" : taxaPagaPor.value == "2" ? "Clínica" : taxaPagaPor.value == "3" ? "Ambos" : ""}}
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import tipoLancamentos from "@/mixins/tipoLancamentos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [tipoLancamentos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      tipoLancamento: {},
      loading: true,
      erro: "",
      modo : "",
      mensagem : "",
      camposTabela: [
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "diasRecebimento", label: "Dias", sortable: true },
        { key: "taxaCartao", label: "Taxa" },
        { key: "taxaPagaPor", label: "Taxa Paga Por" },
        { key: "utilizadoPara", label: "Para" },
        { key: "actions", label: "Ações" }
      ]
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.tipoLancamento = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouRegistro(modelo) {
      this.tipoLancamento = { ...modelo }
      this.modo = "Registro";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.tipoLancamento.taxaPagaPor = parseInt(this.tipoLancamento.taxaPagaPor);

      if (this.modo=="Alterar") {
        this.tipoLancamento.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/tipoLancamentos/${this.usuarioLogadoDados.tenant}/${this.tipoLancamento.id}`, this.tipoLancamento)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.tipoLancamento.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/tipoLancamentos/${this.usuarioLogadoDados.tenant}`, this.tipoLancamento)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`/v1.0/tipoLancamentos/${this.usuarioLogadoDados.tenant}/${this.tipoLancamento.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.tipoLancamento = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.tipoLancamento = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.tipoLancamento.descricao == "" || this.tipoLancamento.descricao == undefined) {
        this.$toasted.show("Digite a Descrição da Forma de Lançamento", {type:"info", icon:"times"});
        return false;
      }
      
      if (this.tipoLancamento.utilizadoPara == "" || this.tipoLancamento.utilizadoPara == undefined) {
        this.$toasted.show("Selecione para o que essa Forma de Lançamento será utilizada", {type:"info", icon:"times"});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
