const state = {
    contratadoSelecionado : {},
}

const getters = {
    getContratadoSelecionado(state) {
        return state.contratadoSelecionado;
    }
}

const mutations = {
    setContratadoSelecionado(state, contratado) {
        state.contratadoSelecionado = {...contratado}
    }
}

const actions = {
    setContratadoSelecionado({commit}, contratado) {
        commit("setContratadoSelecionado", contratado);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}