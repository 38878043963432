<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Lançamentos Fixos - {{fluxoSelecionado.descricao}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row">
          <div class="form-group col-lg-12">
            <label for="Movimento" class="control-label">Tipo Lançamento:</label>
            <select id="Movimento" class="form-control" v-model="movimento" selected>
                <option value="D">Despesas</option>
                <option value="R">Receitas</option>
            </select>
          </div>
      </div>

      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Lançamento Fixo</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
            <div class="form-group col-lg-11">
                <label for="centrosCredito" class="control-label">Centros</label>
                <select id="centrosCredito" class="form-control" v-model="lancamentoFixo.centroId" @change="selecionouCentro()" selected>
                    <option v-for="centro in centros" :key="centro.id" :value="centro.id">
                        {{centro.estrutura}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-1">
                <label for="dia" class="control-label">Dia</label>
                <input type="number" id="dia" class="form-control" v-model.number="lancamentoFixo.dia"/>
            </div>
            <div class="form-group col-lg-1">
                <label for="tipoDia" class="control-label">Tipo Dia:</label>
                <select id="tipoDia" class="form-control" v-model="lancamentoFixo.tipoDia" selected>
                    <option value="F">Dia Fixo</option>
                    <option value="U">Dia Útil</option>
                </select>
            </div>
            <div class="form-group col-lg-2">
                <label for="origens" class="control-label">Origem</label>
                <select id="origens" class="form-control" v-model="lancamentoFixo.origemId" selected>
                    <option v-for="centroOrigem in centroOrigens" :key="centroOrigem.origemId" :value="centroOrigem.origemId">
                        {{centroOrigem.origem}}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-2">
                <label for="conta" class="control-label">Conta</label>
                <select id="conta" class="form-control" v-model="lancamentoFixo.contaId" selected>
                    <option v-for="conta in contas" :key="conta.id" :value="conta.id">
                        {{conta.descricao}}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-1">
                <label for="valor" class="control-label">Valor R$</label>
                <input type="number" id="valor" class="form-control" v-model.number="lancamentoFixo.valor"/>
            </div>
            <div class="form-group col-lg-2">
                <label for="tipoLancamentos" class="control-label">Em</label>
                <select id="tipoLancamentos" class="form-control" v-model="lancamentoFixo.tipoLancamentoId" selected>
                    <option v-for="tipoLancamento in tipoLancamentos" :key="tipoLancamento.id" :value="tipoLancamento.id">
                        {{tipoLancamento.descricao}}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-2">
                <label for="descricao" class="control-label">Descrição</label>
                <input type="text" id="descricao" class="form-control" v-model="lancamentoFixo.descricao"/>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="form-group col-lg-5">
                <button class="form-control btn btn-sm btn-secondary" @click="cancelarOperacao()">Cancelar Operação</button>
            </div>
            <div class="form-group col-lg-6">
                <button class="form-control btn btn-sm btn-info" @click="gravarRegistro()">Gravar Lançamento</button>
            </div>
        </div>
      </div>
      <!-- Tabela com Lancamentos Fixos -------------------------------------------------------->
      <div :class="tableClass" style="display:flex; flex-direction:row; justify-content:center;">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" class="col-lg-12" hover striped small sort-icon-left :items="lancamentosFixos" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)">
              <i class="fa fa-pencil"/> 
            </b-button>  
            <b-button variant="danger" size="sm" class="ml-1" @click="excluirRegistro(row.item)">
              <i class="fa fa-trash"/> 
            </b-button>  
          </template>
          <template v-slot:cell(datCadastro)="datCadastro">
            {{datCadastro.value | formatDate}}
          </template>
          <template v-slot:cell(tipoDia)="tipoDia">
            {{tipoDia.value == "F" ? "Dia Fixo" : "Dia Útil"}}
          </template>
          <template v-slot:cell(valor)="valor">
            {{valor.value.toFixed(2).replace('.',',')}}
          </template>
        </b-table>
      </div>
      <!-- Barra de Opções -->
      <hr/>
      <div class="row justify-content-end">
        <label for="Total" class="control-label col-lg-1 mt-2">Total Fixos R$ ---></label>
        <input type="number" id="Total" class="form-control col-lg-1" :value="valorTotal.toFixed(2)" readonly/>

        <label for="Mes" class="control-label col-lg-1 mt-2">Gerar p/ Mês --></label>
        <select id="Mes" class="form-control col-lg-1" v-model="mes" selected>
          <option value=1>01</option>
          <option value=2>02</option>
          <option value=3>03</option>
          <option value=4>04</option>
          <option value=5>05</option>
          <option value=6>06</option>
          <option value=7>07</option>
          <option value=8>08</option>
          <option value=9>09</option>
          <option value=10>10</option>
          <option value=11>11</option>
          <option value=12>12</option>
        </select>

        <label for="Ano" class="control-label col-lg-1 mt-2">Gerar p/ Ano --></label>
        <select id="Ano" class="form-control col-lg-1" v-model="ano" selected>
          <option v-for="anoGerar in anos" :key="anoGerar" :value="anoGerar">
            {{anoGerar}}
          </option>
        </select>

        <button class="btn btn-sm btn-info ml-2 mr-4 col-lg-1" @click="prepararGerarLancamentos()">Gerar</button>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import lancamentosFixos from "@/mixins/lancamentosFixos"
import lancamentos from "@/mixins/lancamentos"
import centros from "@/mixins/centros"
import contas from "@/mixins/contas"
import origens from "@/mixins/origens"
import bancos from "@/mixins/bancos"
import global from "@/mixins/global"
import tipoLancamentos from "@/mixins/tipoLancamentos"
import feriados from "@/mixins/feriados"
import funcoes from "@/utils/funcoes.js"
import errors from "@/utils/errors";

export default {
    mixins : [lancamentosFixos, lancamentos, centros, contas, origens, tipoLancamentos, bancos, feriados, global],
    computed : {
        ...mapGetters({
            fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
            contaSelecionada : "contas/getContaSelecionada",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        tableClass() {
          return {inEdit : this.modo != "", noEdit : this.modo == ""}
        },
    },
    data() {
      return {
        erro : "",
        loading : true,
        modo : "",
        mensagem : "",
        movimento : "D",
        lancamento : {},
        lancamentoFixo : {},
        valorTotal : 0,
        camposTabela : [
            {key : "datCadastro", label : "Cadastro"},
            {key : "dia", label : "Dia"},
            {key : "tipoDia", label : "Tipo"},
            {key : "centro", label : "Centro"},
            {key : "origem", label : "Origem"},
            {key : "conta", label : "Conta Destino"},
            {key : "descricao", label : "Descrição"},
            {key : "valor", label : "Valor R$"},
            {key : "actions", label : "Ações"},
        ],
        mes : 0,
        ano : 0,
        anos: [],
      }
    },
    watch : {
        movimento() {
            this.dadosLancamentosFixos();
            this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        incluirRegistro() {
            if (!this.verificarPermissao('Incluir')) {
              return
            }

            this.modo = "Incluir";
            this.lancamentoFixo = {}

            if (this.centros.length == 0) {
                this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
            }

            if (this.contas.length == 0) {
                this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);
            }

            if (this.tipoLancamentos.length == 0) {
                this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            }
        },
        selecionouRegistro(lancamentoFixo) {
            this.modo = "Alterar";
            if (this.centros.length == 0) {
                this.carregarCentros(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, false, this.movimento);
            }

            if (this.contas.length == 0) {
                this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);
            }

            if (this.tipoLancamentos.length == 0) {
                this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
            }

            this.lancamentoFixo = {...lancamentoFixo}
            this.selecionouCentro();
        },
        excluirRegistro(lancamentoFixo) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            this.$bvModal
              .msgBoxConfirm("Excluir o Lançamento Fixo selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                    this.$http.delete(`/v1.0/lancamentoFixos/${this.usuarioLogadoDados.tenant}/${lancamentoFixo.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                        this.erro = "";

                        this.dadosLancamentosFixos();
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = error);
                }
              })
              .catch(error => {
                this.erro = error;
              });
        },
        selecionouCentro() {
            this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.lancamentoFixo.centroId, 0);
        },
        cancelarOperacao() {
          this.modo = "";
          this.erro = "";
          this.lancamentoFixo = {}
        },
        gravarRegistro() {
          if (!this.verificarCamposPreenchidos()) {
            return;
          }

          if (this.modo=="Alterar") {
            this.lancamentoFixo.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/lancamentoFixos/${this.usuarioLogadoDados.tenant}/${this.lancamentoFixo.id}`, this.lancamentoFixo)
            .then(() => {
              this.erro = "";
              this.modo = "";
              this.lancamentoFixo = {}

              this.$toasted.global.defaultSuccess();
              this.dadosLancamentosFixos();
            })
            .catch(error => this.erro = error)
          } else {
            this.lancamentoFixo.fluxoId = this.fluxoSelecionado.id;
            this.lancamentoFixo.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
            this.lancamentoFixo.movimento = this.movimento;

            this.$http.post(`/v1.0/lancamentoFixos/${this.usuarioLogadoDados.tenant}`, this.lancamentoFixo)
            .then(() => {
              this.erro = "";
              this.lancamentoFixo = {}

              this.$toasted.global.defaultSuccess();
              this.dadosLancamentosFixos();
            })
            .catch(error => this.erro = error)
          }
        },
        verificarCamposPreenchidos() {
          if (this.lancamentoFixo.centroId == undefined) {
            this.$toasted.show("Selecione o Centro para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.dia == undefined || this.lancamentoFixo.dia == 0) {
            this.$toasted.show("Informe o Dia para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.tipoDia == undefined) {
            this.$toasted.show("Informe o Tipo do Dia para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.centroId == undefined) {
            this.$toasted.show("Selecione o Centro para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.origemId == undefined) {
            this.$toasted.show("Selecione a Origem para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.valor == undefined || this.lancamentoFixo.valor == 0) {
            this.$toasted.show("Preencha o Valor R$ do Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.lancamentoFixo.tipoLancamentoId == undefined) {
            this.$toasted.show("Selecione a Forma para o Lançamento.", {type : "info", icon : "times"});
            return false;
          }

          return true;
        },
        async prepararGerarLancamentos() {
          this.lancamentosFixos.forEach(lancamentoFixo => {
            if (lancamentoFixo.tipoDia=="U") {
              lancamentoFixo.diaCalculado = funcoes.calcularDiaUtil(lancamentoFixo.dia, this.mes, this.ano, this.feriados);
            } else {
              lancamentoFixo.diaCalculado = lancamentoFixo.dia;
            }
          })
          
          //------------------------------------------------------- Utilizei um While porque o Foreach não aceitava o await
          let indice = 0;
          while (this.lancamentosFixos[indice]) {
            let lancamentoFixo = this.lancamentosFixos[indice];
            await this.gerarLancamentos(lancamentoFixo);

            indice++
          }

          this.$toasted.show("Operação Finalizada", {type: "success", icon: "check"});
          //-------------------------------------------------------
        },
        async gerarLancamentos(lancamentoFixo) {
            this.lancamento = {}
            let data = new Date(this.ano, this.mes-1, lancamentoFixo.diaCalculado);
            data = funcoes.mySqlToJSDateFormat(data);

            await this.carregarLancamentosPelaContaNaData(this.usuarioLogadoDados.tenant, lancamentoFixo.contaId, data);

            if (this.lancamento.id == undefined) {
              this.lancamento.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

              this.lancamento.fluxoId = lancamentoFixo.fluxoId;
              this.lancamento.contaId = lancamentoFixo.contaId;
              this.lancamento.data = data;

              await this.$http.post(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}`, this.lancamento)
                    .then(resp => {
                      this.lancamento = resp.data;
                      this.erro = "";
                    })
                    .catch(error => this.erro = error);
            }
            let lancamentoItem = {}
            lancamentoItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
            lancamentoItem.lancamentoId = this.lancamento.id;

            lancamentoItem.centroId = lancamentoFixo.centroId;
            lancamentoItem.origemId = lancamentoFixo.origemId;
            lancamentoItem.tipoLancamentoId = lancamentoFixo.tipoLancamentoId;

            lancamentoItem.descricao = lancamentoFixo.descricao;
            lancamentoItem.movimento = lancamentoFixo.movimento == "D" ? "D" : "C";
            lancamentoItem.valor = lancamentoFixo.valor;

            await this.$http.post(`/v1.0/lancamentoItens/${this.usuarioLogadoDados.tenant}`, lancamentoItem)
                  .then(() => {
                    this.erro = "";
                  })
                  .catch(error => this.erro = error)
            
            if (lancamentoItem.movimento == "D") {
                this.lancamento.debitos += lancamentoItem.valor;
            } else {
                this.lancamento.creditos += lancamentoItem.valor;
            }
            this.lancamento.saldoFinal = this.lancamento.saldoInicial +
                                         this.lancamento.creditos -
                                         this.lancamento.debitos;

            this.lancamento.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            await this.$http.put(`/v1.0/lancamentos/${this.usuarioLogadoDados.tenant}/${this.lancamento.id}`, this.lancamento)
                  .then(()  => {
                    this.erro = "";
                  })
                  .catch(error => this.erro = error)
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
    
            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");
    
            return false;
          }
    
          return true;
        },
        dadosLancamentosFixos() {
          this.carregarLancamentosFixos(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.movimento, 0);
        }        
    },
    created() {
        this.showMenu(false);
        this.dadosLancamentosFixos();
        this.carregarFeriados(this.usuarioLogadoDados.tenant, 0);

        let hoje = new Date();

        this.mes = hoje.getMonth()+1;
        this.ano = hoje.getFullYear();

        for(let i=0; i<10; i++) {
          this.anos.push((this.ano-1)+i);
        }
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }

    .inEdit {
      height: 291px;
      overflow: auto;
    }

    .noEdit {
      height: 460px;
      overflow: auto;
    }
</style>
