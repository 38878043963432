<template>
  <div id="CadPacienteDados">
    <!-- <div class="container-fluid"> -->
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Id" class="control-label">Id</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Id"
            name="Id"
            :value="pacienteSelecionado.id"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="CodAlfa" class="control-label">CodAlfa</label>
          <input
            type="text"
            class="form-control"
            disabled
            CodAlfa="CodAlfa"
            name="CodAlfa"
            :value="pacienteSelecionado.codAlfa"
          />
        </div>

        <div class="form-group col-lg-4">
          <label for="Nome" class="control-label">Nome na Certidão</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Nome"
            name="Nome"
            v-model="pacienteSelecionado.nome"
          />
        </div>

        <div class="form-group col-lg-4">
          <label
            for="NomeSocial"
            class="control-label"
          >Nome Social (Apenas qdo houver necessidade)</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="NomeSocial"
            name="NomeSocial"
            v-model="pacienteSelecionado.nomeSocial"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Ativo" class="control-label">Ativo</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Ativo"
            name="Ativo"
            selected
            v-model="pacienteSelecionado.ativo"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="Bloqueado" class="control-label">Bloqueado</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Bloqueado"
            name="Bloqueado"
            selected
            v-model="pacienteSelecionado.bloqueado"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="datNascimento" class="control-label">Data Nascimento</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="datNascimento"
            name="datNascimento"
            v-model="pacienteSelecionado.datNascimento"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Sexo" class="control-label">Sexo</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Sexo"
            name="Sexo"
            selected
            v-model="pacienteSelecionado.sexo"
          >
            <option
              v-for="confirma in sexo"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="EstadoCivil" class="control-label">Estado Civil</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="EstadoCivil"
            name="EstadoCivil"
            selected
            v-model="pacienteSelecionado.estadoCivil"
          >
            <option
              v-for="confirma in estadoCivil"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label
            for="Naturalidade"
            class="control-label"
          >Naturalidade</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Naturalidade"
            name="Naturalidade"
            v-model="pacienteSelecionado.naturalidade"
          />
        </div>
      </div>
      <hr style="margin:0px">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Cep" class="control-label">Cep</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cep"
            name="Cep"
            v-model="pacienteSelecionado.cep"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Logradouro" class="control-label">Logradouro</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Logradouro"
            name="Logradouro"
            v-model="pacienteSelecionado.logradouro"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="Numero" class="control-label">Número</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Numero"
            name="Numero"
            v-model.number="pacienteSelecionado.numero"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="Complemento" class="control-label">Complemento</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Complemento"
            name="Complemento"
            v-model="pacienteSelecionado.complemento"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Bairro" class="control-label">Bairro</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Bairro"
            name="Bairro"
            v-model.number="pacienteSelecionado.bairro"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Cidade" class="control-label">Cidade</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cidade"
            name="Cidade"
            v-model="pacienteSelecionado.cidade"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="UF" class="control-label">UF</label>
          <select
            id="UF"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="pacienteSelecionado.uf"
          >
            <option v-for="uf in ufs" :key="uf.valor" :value="uf.valor">{{uf.valor}}</option>
          </select>
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="FoneDDD" class="control-label">DDD</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="FoneDDD"
            name="FoneDDD"
            v-model="pacienteSelecionado.foneDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Fone" class="control-label">Fone</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fone"
            name="Fone"
            v-model="pacienteSelecionado.fone"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="celularDDD" class="control-label">DDD</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celularDDD"
            name="celularDDD"
            v-model="pacienteSelecionado.celularDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="celular" class="control-label">Celular</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celular"
            name="celular"
            v-model="pacienteSelecionado.celular"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="Email" class="control-label">E-mail</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Email"
            name="Email"
            v-model="pacienteSelecionado.email"
          />
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";
import operadoras from "@/mixins/operadoras";
import planosSaude from "@/mixins/planosSaude";

export default {
  mixins : [operadoras, planosSaude],
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      modo: "pacientes/getModo",
      pacienteModulo : "pacientes/getModulo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      sexo: constantes.sexo,
      estadoCivil: constantes.estadoCivil,
      ufs: constantes.uf,
    }
  },
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
