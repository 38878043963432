<template>
  <div id="CadDespesaDados">
    <br />
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Id" class="control-label">Id:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Id"
            name="Id"
            :value="despesaSelecionada.id"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="Descricao" class="control-label">Descrição:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Descricao"
            name="Descricao"
            v-model="despesaSelecionada.descricao"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="descricaoGenerico" class="control-label">Descrição Genérica ou Alternativa:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="descricaoGenerico"
            name="descricaoGenerico"
            v-model="despesaSelecionada.descricaoGenerico"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="restritoHospitalar" class="control-label">Restrito Hospitalar:</label>
          <select id="restritoHospitalar" class="form-control" :disabled="!liberarCampos" v-model="despesaSelecionada.restritoHospitalar" selected>
            <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
              {{resposta.valor}}
            </option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="registroAnvisa" class="control-label">Registro Anvisa:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="registroAnvisa"
            name="registroAnvisa"
            v-model="despesaSelecionada.registroAnvisa"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="DatInatividade" class="control-label">Data Inatividade:</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="DatInatividade"
            name="DatInatividade"
            v-model="despesaSelecionada.datInatividade"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="Tipo" class="control-label">Tipo:</label>
          <select
            class="form-control"
            id="DespesaTipo"
            :disabled="!liberarCampos"
            selected
            v-model="despesaSelecionada.despesaTipoId"
          >
            <option
              v-for="despesaTipo in despesaTipos"
              :key="despesaTipo.id"
              :value="despesaTipo.id"
            >{{despesaTipo.descricao}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="TISS" class="control-label">TISS:</label>
          <select
            class="form-control"
            id="TISS"
            :disabled="!liberarCampos"
            selected
            v-model="despesaSelecionada.codMatTiss"
          >
            <option
              v-for="tissCodigoDespesa in tissCodigosDespesas"
              :key="tissCodigoDespesa.opcao"
              :value="tissCodigoDespesa.opcao"
            >{{tissCodigoDespesa.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="TISS" class="control-label">Buscas Gerais:</label>
          <select
            class="form-control"
            id="TISS"
            :disabled="!liberarCampos"
            selected
            v-model="despesaSelecionada.exibirEmBuscasGerais"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-5">
          <label for="Referencia" class="control-label">Referência:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Referencia"
            name="Referencia"
            v-model="despesaSelecionada.referencia"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="Laboratorio" class="control-label">Laboratório:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Laboratorio"
            name="Laboratorio"
            v-model="despesaSelecionada.laboratorio"
          />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="form-group col-lg-5">
          <label for="fabricante" class="control-label">Fabricante:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="fabricante"
            name="fabricante"
            v-model="despesaSelecionada.fabricante"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="codRefFabricante" class="control-label">Cód. Ref. Fabricante:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="codRefFabricante"
            name="codRefFabricante"
            v-model="despesaSelecionada.codRefFabricante"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="autorizacaoFornecedor" class="control-label">Nº Autoriz. Func. Fornecedor:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="autorizacaoFornecedor"
            name="autorizacaoFornecedor"
            v-model="despesaSelecionada.autorizacaoFornecedor"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="codTuss" class="control-label">TUSS (Despesa sem Tabela):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="codTuss"
            name="codTuss"
            v-model="despesaSelecionada.codTuss"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import constantes from "@/utils/constantes";
import despesaTipos from "@/mixins/despesaTipos"

export default {
  mixins : [despesaTipos],
  computed: {
    ...mapState({
      modo: state => state.despesas.modo,
      despesaSelecionada: state => state.despesas.despesaSelecionada,
    }),
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  },
  methods: {},
  data() {
    return {
      sim_nao : constantes.sim_nao,
      tissCodigosDespesas: constantes.tissCodigosDespesas,
      loadingDespesaTipos : false,
      loadedDespesaTipos : false,
    }
  },
  created() {
    this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, 0);
  }
}
</script>

<style scoped>
</style>
