<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Variáveis para Ítens de Consultório</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Nova Variável</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="variavel.id" />
          </div>

          <div class="form-group col-lg-2">
            <label for="Nome" class="control-label">Variável</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Nome"
              name="Nome"
              v-model="variavel.nome"
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="variavel.descricao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="Obrigatorio" class="control-label">Obrigatório</label>
            <select
              id="Obrigatorio"
              class="form-control"
              :disabled="!liberarCampos"
              v-model="variavel.obrigatorio"
              selected
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger ml-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Gravar</button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <div style="display:flex; flex-direction:row; justify-content:center">
      <b-spinner v-if="loading" label="carregando..."/>
      <b-table v-if="!loading" class="col-lg-6" hover striped small sort-icon-left :items="variaveis" :fields="camposTabela">
        <template v-slot:cell(actions)="row">
          <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
            <i class="fa fa-pencil"/> 
          </b-button>  
        </template>

        <template v-slot:cell(obrigatorio)="obrigatorio">
          {{obrigatorio.value = obrigatorio.value == "S" ? "Sim" : "Não"}}
        </template>
      </b-table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import variaveis from "@/mixins/variaveis";
import constantes from "@/utils/constantes";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [variaveis, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    }
  },
  data() {
    return {
      variavel: {},
      camposTabela: [
        { key: 'nome', label: 'Variável', sortable: true },
        { key: 'descricao', label: 'Descrição', sortable: true },
        { key: 'obrigatorio', label: 'Obrigatório', sortable: true },
        { key: 'actions', label: 'Ações' }
      ],
      erro: "",
      modo: "",
      mensagem : "",
      loading: true,
      sim_nao : constantes.sim_nao
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.variavel = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouRegistro(variavel) {
      this.variavel = { ...variavel }
      this.modo = 'Registro';
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.variavel.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/variaveis/${this.usuarioLogadoDados.tenant}/${this.variavel.id}`, this.variavel)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarVariaveis(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
        })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.variavel.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/variaveis/${this.usuarioLogadoDados.tenant}`, this.variavel)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarVariaveis(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/variaveis/${this.usuarioLogadoDados.tenant}/${this.variavel.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarVariaveis(this.usuarioLogadoDados.tenant);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.variavel = {}
      this.modo = "";
      this.erro = "";
    },
    verificarCamposPreenchidos() {
      if (this.variavel.nome === "" || this.variavel.nome === undefined) {
        this.$toasted.show("Digite o Nome da Variável", {type : 'info', icon : 'times'});
        return false;
      }
       
      if (this.variavel.descricao === "" || this.variavel.descricao === undefined) {
        this.$toasted.show("Digite a Descrição da Variável", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    limparCampos() {
      this.variavel = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    }
  },
  created() {
    this.showMenu(false);
    this.carregarVariaveis(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
