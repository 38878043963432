import errors from "@/utils/errors";

export default {
    data() {
        return {
            tipoLancamentos : []
        }
    },
    methods : {
        carregarTipoLancamentos(tenant) {
            this.$http.get(`/v1.0/tipoLancamentos/${tenant}`)
            .then(resp => {
                this.tipoLancamentos = resp.data;
                
                this.erro = "";
                this.loading = false;
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
