<template>
  <div id="CadOperadoraDados">
    <!-- <div class="container-fluid"> -->
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="alergia" class="control-label">Alergias</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="alergia"
            name="alergia"
            v-model="pacienteSelecionado.alergia"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="gestante" class="control-label">Gestante</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="gestante"
            name="gestante"
            selected
            v-model="pacienteSelecionado.gestante"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="vacinaReacao" class="control-label">Vacina Reação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="vacinaReacao"
            name="vacinaReacao"
            v-model="pacienteSelecionado.vacinaReacao"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="vacinaObservacao" class="control-label">Vacina Observações</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="vacinaObservacao"
            name="vacinaObservacao"
            v-model="pacienteSelecionado.vacinaObservacao"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-12">
          <label for="medicacaoDiaria" class="control-label">Medicação Diária</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="medicacaoDiaria"
            name="medicacaoDiaria"
            v-model="pacienteSelecionado.medicacaoDiaria"
          />
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-12">
          <label for="pastaExame" class="control-label">Pasta de Exames no Servidor</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="pastaExame"
            name="pastaExame"
            v-model="pacienteSelecionado.pastaExame"
          />
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      modo: "pacientes/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
    }
  },
  methods: {}
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
