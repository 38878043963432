<template>
  <div id="CadUnidade">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.preNome}} {{profissionalSelecionado.nome}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="email" class="control-label">E-mail Login</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="email"
            name="email"
            v-model="usuarioSelecionado.email"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="datCadastro" class="control-label">Cadastro</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datCadastro"
            name="datCadastro"
            v-model="usuarioSelecionado.datCadastro"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datAlteracao" class="control-label">Última Alteração</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datAlteracao"
            name="datAlteracao"
            v-model="usuarioSelecionado.datAlteracao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="lastLoginTry" class="control-label">Última Tentativa Login</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="lastLoginTry"
            name="lastLoginTry"
            v-model="usuarioSelecionado.lastLoginTry"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="failedLoginsAttempts" class="control-label">Falhas de Login</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="failedLoginsAttempts"
            name="failedLoginsAttempts"
            v-model.number="usuarioSelecionado.failedLoginsAttempts"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datBloqueio" class="control-label">Data Bloqueio</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datBloqueio"
            name="datBloqueio"
            v-model="usuarioSelecionado.datBloqueio"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datUltTrocaSenha" class="control-label">Última Troca Senha</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datUltTrocaSenha"
            name="datUltTrocaSenha"
            v-model="usuarioSelecionado.datUltTrocaSenha"
          />
        </div>
      </div>

        <hr />

        <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>

        <div class="barraopcoescadastro">
          <button class="btn btn-sm btn-info mr-2" @click="usuarioPerfis()">Perfis de Acesso ao Sistema</button>
          <button class="btn btn-sm btn-info mr-2" @click="gerenciarSenha()">Senha de Acesso</button>
        </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global"
import errors from "@/utils/errors"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"

export default {
  mixins : [global],
  computed: {
    ...mapGetters({
      usuarioSelecionado : "usuarios/getUsuarioSelecionado",
      profissionalSelecionado : "profissionais/getProfissionalSelecionado",
      modo : "usuarios/getModo",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
  },
  data() {
    return {
      erro: "",
      retornando : false,
      usuario : {},
      mensagem : "",
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {
    ...mapActions({
      setModo : "usuarios/setModo",
      formLeave : "usuarios/formLeave",
      setDefinirSenha : "usuarios/setDefinirSenha"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.usuarioSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

      this.$http
        .put(
          `/v1.0/usuarios/${this.usuarioLogadoDados.tenant}/${this.usuarioSelecionado.id}`,
          this.usuarioSelecionado
        )
        .then(() => {
          this.erro = "";
          this.setModo("Registro");
          this.$toasted.global.defaultSuccess();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    cancelarOperacao() {
      this.$router.go(-1);
    },
    usuarioPerfis() {
      this.$router.push('/frmUsuarioPerfis');
    },
    gerenciarSenha() {
      let definirSenha = {
          profissionalId : this.profissionalSelecionado.id,
          usuarioId : this.usuarioSelecionado.id,
          nome : this.profissionalSelecionado.nome,
          email : this.usuarioSelecionado.email,
          perfilId : this.profissionalSelecionado.perfilRaizId,
          identity : null,
          chamadoPor : "cadUsuario",
          modo : "Alterar"
      }

      this.setDefinirSenha(definirSenha);
      this.$router.push('/frmDefinirSenha');
    },
    verificarCamposPreenchidos() {
      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);

    this.usuarioSelecionado.datCadastro = funcoes.mySqlToJSDateFormat(this.usuarioSelecionado.datCadastro);
    this.usuarioSelecionado.datAlteracao = funcoes.mySqlToJSDateFormat(this.usuarioSelecionado.datAlteracao);
    this.usuarioSelecionado.datBloqueio = funcoes.mySqlToJSDateFormat(this.usuarioSelecionado.datBloqueio);
    this.usuarioSelecionado.lastLoginTry = funcoes.mySqlToJSDateFormat(this.usuarioSelecionado.lastLoginTry);
    this.usuarioSelecionado.datUltTrocaSenha = funcoes.mySqlToJSDateFormat(this.usuarioSelecionado.datUltTrocaSenha);
  },
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
