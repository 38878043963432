<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Controle de Faturas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row">
          <div class="form-group col-lg-2" style="margin-left:0px">
              <label for="Operadoras" class="control-label">Operadoras</label>
              <select v-model="operadoraId" class="form-control" selected>
                  <option v-for="operadora in operadoras" :key="operadora.id" :value="operadora.id">
                      {{operadora.fantasia}}
                  </option>
              </select>
          </div>
      </div>
      <hr/>
      <div class="row" v-if="operadoraId>0 && modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirNovaFatura()"
          >Incluir Nova Fatura</button>
        </div>
      </div>
      <!-- Cadastro de Fatura -->
      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-2">
              <label for="datCadastro" class="control-label">Abertura</label>
              <input type="date" id="datCadastro" class="form-control" disabled v-model="faturaSelecionada.datCadastro">
          </div>
          <div class="form-group col-lg-2">
              <label for="datFechamento" class="control-label">Fechamento</label>
              <input type="date" id="datFechamento" class="form-control" disabled v-model="faturaSelecionada.datFechamento">
          </div>
          <div class="form-group col-lg-2">
              <label for="datEnvio" class="control-label">Envio para Operadora</label>
              <input type="date" id="datEnvio" class="form-control" disabled v-model="faturaSelecionada.datEnvio">
          </div>
          <div class="form-group col-lg-2">
              <label for="datVencto" class="control-label">Vencimento</label>
              <input type="date" id="datVencto" class="form-control" v-model="faturaSelecionada.datVencto">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-2">
              <label for="datInicial" class="control-label">Lançamentos Inicial</label>
              <input type="date" id="datInicial" class="form-control" v-model="faturaSelecionada.datInicial">
          </div>
          <div class="form-group col-lg-2">
              <label for="datFinal" class="control-label">Lançamentos Final</label>
              <input type="date" id="datFinal" class="form-control" v-model="faturaSelecionada.datFinal">
          </div>
          <div class="form-group col-lg-2">
              <label for="descricao" class="control-label">Descrição</label>
              <input type="text" id="descricao" class="form-control" v-model="faturaSelecionada.descricao">
          </div>
          <div class="form-group col-lg-1">
              <label for="referente" class="control-label">Refere</label>
              <input type="text" id="referente" class="form-control" v-model="faturaSelecionada.referente">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4">
              <label for="observacao" class="control-label">Observação</label>
              <input type="text" id="observacao" class="form-control" v-model="faturaSelecionada.observacao">
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="gravar()">Confirmar
          </button>
        </div>
      </div>
      <hr/>
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <!-- Tabela de Faturas -->
      <b-spinner v-if="loading" label="carregando..."/>
      <b-table v-if="!loading" striped hover small sort-icon-left :items="faturas" :fields="camposTabela">
          <template v-slot:cell(datInicial)="datInicial">
              {{datInicial.value | formatDate}}
          </template>

          <template v-slot:cell(datFinal)="datFinal">
              {{datFinal.value | formatDate}}
          </template>

          <template v-slot:cell(datFechamento)="datFechamento">
              {{datFechamento.value | formatDate}}
          </template>

          <template v-slot:cell(datEnvio)="datEnvio">
              {{datEnvio.value | formatDate}}
          </template>

          <template v-slot:cell(datVencto)="datVencto">
              {{datVencto.value | formatDate}}
          </template>

          <template v-slot:cell(datGerouCr)="datGerouCr">
              {{datGerouCr.value | formatDate}}
          </template>

          <template v-slot:cell(actions)="row">
              <b-button class="mr-1" variant="warning" size="sm" @click="alterarFaturaSelecionada(row.item)">
                <i class="fa fa-pencil"/>
              </b-button>

              <b-button class="mr-1" variant="danger" size="sm" @click="excluirFaturaSelecionada(row.item)">
                <i class="fa fa-trash"/>
              </b-button>

              <b-button class="mr-1" variant="info" size="sm" @click="selecionouLotes(row.item)">
                Lotes
              </b-button>
          </template>
      </b-table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import faturas from "@/mixins/faturas";
import operadoras from "@/mixins/operadoras";
import global from "@/mixins/global";
import funcoes from "@/utils/funcoes";
import errors from "@/utils/errors";

export default {
  mixins: [faturas, operadoras, global],
  data() {
    return {
      modo: "",
      erro: "",
      mensagem : "",
      loading : true,
      operadoraId : 0,
      faturaSelecionada : {},
      camposTabela : [
        {key : "id", label : "Fatura"},
        {key : "datInicial", label : "Início", sortable: true},
        {key : "datFinal", label : "Término", sortable: true},
        {key : "descricao", label : "Descrição"},
        {key : "referente", label : "Refere", sortable: true},
        {key : "datFechamento", label : "Fechada"},
        {key : "datEnvio", label : "Enviada", sortable: true},
        {key : "datVencto", label : "Vencto", sortable: true},
        {key : "datGerouCr", label : "Financeiro", sortable: true},
        {key : "totalFatura", label : "Total R$"},
        {key : "observacao", label : "Observação"},
        {key : "nFNumero", label : "Nº Nota Fiscal", sortable: true},
        {key : "actions", label : "Ações"}
      ],
    }
  },
  computed : {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    })
  },
  watch : {
      operadoraId() {
        this.carregarFaturasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
      }
  },
  methods: {
    ...mapActions({
        setFaturaSelecionada : "faturas/setFaturaSelecionada"
    }),
    retornar() {
      this.$router.go(-1);
    },
    cancelarOperacao() {
      this.faturaSelecionada = {}
      this.modo = "";
      this.erro = "";
    },
    incluirNovaFatura() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.modo = this.modo == "" ? "Incluir" : "";
      this.faturaSelecionada = {}
      if (this.modo=="Incluir") {
          this.faturaSelecionada.datCadastro = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datCadastro);
      }
    },
    alterarFaturaSelecionada(fatura) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
      this.faturaSelecionada = {...fatura}

      if (this.faturaSelecionada.datCadastro!=null) {
          this.faturaSelecionada.datCadastro = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datCadastro);
      }
      if (this.faturaSelecionada.datVencto!=null) {
          this.faturaSelecionada.datVencto = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datVencto);
      }
      if (this.faturaSelecionada.datInicial!=null) {
          this.faturaSelecionada.datInicial = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datInicial);
      }
      if (this.faturaSelecionada.datFinal!=null) {
         this.faturaSelecionada.datFinal   = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datFinal);
      }
      if (this.faturaSelecionada.datCadastro!=null) {
         this.faturaSelecionada.datCadastro = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datCadastro);
      }
      if (this.faturaSelecionada.datFechamento!=null) {
         this.faturaSelecionada.datFechamento = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datFechamento);
      }
      if (this.faturaSelecionada.datEnvio!=null) {
         this.faturaSelecionada.datEnvio = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datEnvio);
      }
      if (this.faturaSelecionada.datGerouCr!=null) {
         this.faturaSelecionada.datGerouCr = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datGerouCr);
      }
    },
    gravar() {
        if (this.modo=="Alterar") {
            this.faturaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/faturas/${this.usuarioLogadoDados.tenant}/${this.faturaSelecionada.id}`, this.faturaSelecionada)
            .then(() => {
                this.erro = "";
                this.carregarFaturasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
                this.faturaSelecionada = {}
                this.modo = "";
                this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            }) 
        } else {
            this.faturaSelecionada.operadoraId = this.operadoraId;
            this.faturaSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.$http.post(`/v1.0/faturas/${this.usuarioLogadoDados.tenant}`, this.faturaSelecionada)
            .then(() => {
                this.erro = "";
                this.carregarFaturasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
                this.faturaSelecionada = {}
                this.modo = "";
                this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            }) 
        }
    },
    excluirFaturaSelecionada(fatura) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal.msgBoxConfirm("Excluir Fatura Selecionada?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/faturas/${this.usuarioLogadoDados.tenant}/${fatura.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                  this.erro = "";
                  this.carregarFaturasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    selecionouLotes(fatura) {
        this.setFaturaSelecionada(fatura);
        this.$router.push("/frmLotes");
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);
  }
}
</script>

<style scoped>
    hr {
        margin-top:5px;
        margin-bottom:5px;
    }

    label {
        margin-bottom:0px;
    }
</style>
