<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Tabela Brasindice</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <div class="form-group col-lg-3">
            <input type="radio" id="porDescricao" v-model="campoBusca" value="Medicamento">
            <label for="porDescricao" class="control-label ml-1 mr-2">Descrição</label>
            <input type="radio" id="porLaboratorio" v-model="campoBusca" value="Laboratorio">
            <label for="porLaboratorio" class="control-label ml-1 mr-2">Laboratório</label>
            <input type="radio" id="porCodTiss" v-model="campoBusca" value="CodigoTiss">
            <label for="porCodTiss" class="control-label ml-1">Código Tiss</label>
        </div>

        <div class="form-group col-lg-3">
            <input type="radio" id="exibirAtivos" v-model="ativo" value="S">
            <label for="exibirAtivos" class="control-label ml-1 mr-2">Apenas Ativos</label>

            <input type="radio" id="exibirInativos" v-model="ativo" value="N">
            <label for="exibirInativos" class="control-label ml-1 mr-2">Apenas Inativos</label>
        </div>

        <div class="form-group col-sm-2 col-md-3 col-lg-4">
          <input
            type="text"
            class="form-control"
            id="campoFiltro"
            v-model="filtrar"
            placeholder="digite o que deseja localizar..."
          />
        </div>

        <div class="form-group col-sm-1 col-md-1 col-lg-1" >
          <button id="btnFiltrar" class="form-control" @click="buscar()">
            <i class="fa fa-search"/> 
          </button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>
  
      <hr />
      
      <div style="display:flex; flex-direction:row; justify-content:center;height:512px">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loaded" class="col-lg-12" hover striped small sort-icon-left :items="tabelaBrasindices" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button v-if="modulo=='Despesas'" variant="info" size="sm" class="ml-1" @click="alterarApresentacao(row.item)">
              Vincular
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import tabelaBrasindice from "@/mixins/tabelaBrasindice";
import global from "@/mixins/global";

export default {
  mixins: [tabelaBrasindice, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      ultimaBusca : "tabelaBrasindice/getUltimaBusca",
      modulo : "tabelaBrasindice/getModulo"
    }),
    liberarCampos() {
      return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    }
  },
  data() {  
    return {
      page : 1,
      campoBusca: "Medicamento",
      ativo: "S",
      filtrar: "",
      camposTabela: [
        { key: 'codigoTiss', label: 'Cód.Tiss', sortable: true },
        { key: 'laboratorio', label: 'Laboratório', sortable: true },
        { key: 'medicamento', label: 'Descrição', sortable: true },
        { key: 'apresentacao', label: 'Apresentação', sortable: true },
        { key: 'codigoApresentacao', label: 'Código', sortable: true },
        { key: 'tipoPreco', label: 'Tipo Preço', sortable: true },
        { key: 'precoMedicamento', label: 'Preço PMC', sortable: false },
        { key: 'precoUnitario', label: 'Preço PMC Unit', sortable: false },
        { key: 'precoMedicamentoFabrica', label: 'Preço PFB', sortable: false },
        { key: 'precoUnitarioFabrica', label: 'Preço PFB Unit', sortable: false },
        { key: 'restritoHospitalar', label: 'RH', sortable: true },
        { key: 'actions', label:'Ações'}
      ],
      erro: "",
      modo: "",
      loading: false,
      loaded : false,
    }
  },
  watch : {
    page() {
      this.iniciarCarregarTabelaBrasindice();
    }
  },
  methods: {
    ...mapActions({
      setRegistroSelecionado : "tabelaBrasindice/setRegistroSelecionado",
      setUltimaBusca : "tabelaBrasindice/setUltimaBusca"
    }),
    retornar() {
      this.setRegistroSelecionado(null);
      this.$router.go(-1);
    },
    iniciarCarregarTabelaBrasindice() {
      this.loading = true;
      this.loaded = false;

      this.filtrar = this.filtrar.toUpperCase();

      if (this.campoBusca == 'Medicamento') {
        this.carregarBrasindicePeloMedicamento(this.filtrar, this.ativo, this.page);
      } else if (this.campoBusca == 'Laboratorio') {
        this.carregarBrasindicePeloLaboratorio( this.filtrar, this.ativo, this.page);
      } else {
        this.carregarListaBrasindicePeloCodigoTiss(this.filtrar, this.ativo, this.page);
      }
    },
    buscar() {
        if (this.filtrar.length < 3) {
            this.$toasted.show("Informe o que você deseja buscar. Digite pelo menos 3 caracteres.", {type : "info", icon : "times"});
            return;
        }

        this.iniciarCarregarTabelaBrasindice();        
    },
    selecionouRegistro(registro) {
      this.setRegistroSelecionado(registro);

      let ultimaBusca = {campoBusca : this.campoBusca, ativo : this.ativo, filtrar : this.filtrar, page : this.page}

      this.setUltimaBusca(ultimaBusca);
      this.$router.push('/conTabelaBrasindice');
    },
    alterarApresentacao(registro) {
      this.setRegistroSelecionado(registro);
      this.$router.go(-1);
    }
  },
  created() {
    this.showMenu(false);
    
    if (this.ultimaBusca!=null) {
      this.campoBusca = this.ultimaBusca.campoBusca;
      this.ativo = this.ultimaBusca.ativo;
      this.filtrar = this.ultimaBusca.filtrar;
      this.page = this.ultimaBusca.page;

      if (this.page != this.ultimaBusca.page) {
        this.page = this.ultimaBusca.page
      } else {
        this.iniciarCarregarTabelaBrasindice();
      }

      this.setUltimaBusca(null);
    }
  },
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
  }
</style>