import {mapActions} from "vuex"
import errors from "@/utils/errors";

export default {
    data() {
        return {
            guias : [],
            guiaTratamentos : [],
            guiaDespesas : []
        }
    },
    methods : {
        ...mapActions({
            zerarAlteracoes : "faturamentos/zerarAlteracoes"
        }),
        async carregarGuiasDoPaciente(tenant, pacienteId, tipo, chamadoPor) {
            await this.$http.get(`/v1.0/guias/${tenant}/Paciente/${pacienteId}/${tipo}`)
                    .then(resp => {
                        this.guias = resp.data;

                        this.erro = "";
                        this.loading = false;

                        if (chamadoPor=="PacienteSessoes") {
                            this.destacarLinhasGuiasSessoes()
                        } else if (chamadoPor=="Faturamento") {
                            this.destacarLinhasGuiasLote();
                        } else if (chamadoPor=="LanctoGuia") {
                            this.destacarLinhasGuias();
                        }
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
        },
        async carregarGuiasDoLote(tenant, loteId, tipo) {
            await this.$http.get(`/v1.0/guias/${tenant}/Lote/${loteId}/${tipo}`)
                    .then(resp => {
                        this.guias = resp.data;
                        this.erro = "";
                        this.loading = false;
                        this.zerarAlteracoes();
                        this.destacarLinhasGuiasLote();
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
        },
        async carregarGuiasRelacionadasAoRecebimento(tenant, recebimentoId) {
            this.loadingGuiasRelacionadas = true;
            this.loadedGuiasRelacionadas = false;

            await this.$http.get(`/v1.0/guias/${tenant}/Recebimento/${recebimentoId}`)
                    .then(resp => {
                        this.guias = resp.data;
                        this.erro = "";
                        this.loadedGuiasRelacionadas = true;
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => this.loadingGuiasRelacionadas = false)
        },
        async carregarGuiasParticularesPendentes(tenant, pacienteId) {
            this.loadingGuiasPedendentes = true;
            this.loadedGuiasPedendentes = false;

            await this.$http.get(`/v1.0/guias/${tenant}/Pendentes/${pacienteId}`)
                    .then(resp => {
                        this.guias = resp.data;
                        this.erro = "";
                        this.loadedGuiasPedendentes = true;
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => this.loadingGuiasPedendentes = false)
        },
        async carregarGuiasParticularesNaoRecebidas(tenant, pacienteId) {
            this.loadingGuiasNaoRecebidas = true;
            this.loadedGuiasNaoRecebidas = false;

            await this.$http.get(`/v1.0/guias/${tenant}/NaoRecebidas/${pacienteId}`)
                    .then(resp => {
                        this.guias = resp.data;
                        this.erro = "";
                        this.loadedGuiasNaoRecebidas = true;
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => this.loadingGuiasNaoRecebidas = false)
        },
        async carregarGuiasParticularesNaoRecebidasNoPeriodo(tenant, datInicial, datFinal) {
            this.loadingGuiasNaoRecebidas = true;
            this.loadedGuiasNaoRecebidas = false;

            await this.$http.get(`/v1.0/guias/${tenant}/NaoRecebidasNoPeriodo/${datInicial}/${datFinal}`)
                    .then(resp => {
                        this.guias = resp.data;
                        this.erro = "";
                        this.loadedGuiasNaoRecebidas = true;
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => this.loadingGuiasNaoRecebidas = false)
        },
        incluirGuia(tenant, guia, tipo, chamadoPor) {
            this.$http.post(`/v1.0/guias/${tenant}`, guia)
            .then(() => {
                this.modo="";
                this.guiaSelecionada = {tipoLote : -1}
                this.$toasted.global.defaultSuccess();
                this.carregarGuiasDoPaciente(tenant, guia.pacienteId, tipo, chamadoPor);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        alterarGuia(tenant, guia, tipo, chamadoPor) {
            this.$http.put(`/v1.0/guias/${tenant}/${guia.id}`, guia)
            .then(() => {
                this.modo="";
                this.guiaSelecionada = {tipoLote : -1}
                this.$toasted.global.defaultSuccess();
                this.carregarGuiasDoPaciente(tenant, guia.pacienteId, tipo, chamadoPor);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        alterarStatusGuia(tenant, id, status, operadorAlteracaoId) {
            // [{"op" : "replace", "path" : "/Status", "value" : "S"}] - patch no BackEnd no lugar do Put

            let patchJSON = [{op : "replace", path : "/Status", value : status},
                             {op : "replace", path : "/OperadorAlteracaoId", value : operadorAlteracaoId}] ;


            this.$http.patch(`/v1.0/guias/${tenant}/alterarStatus/${id}`, patchJSON)
            .then(() => {
                this.adicionarAlteracoes();

                this.guias[this.guias.findIndex(i => i.id==id)].status = status;
                this.destacarLinhasGuiasLote();

                this.$toasted.global.defaultSuccess();
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        async liberadoFaturamento(tenant, id, liberadoFaturamento, operadorAlteracaoId, chamadoPor) {
            // [{"op" : "replace", "path" : "/LiberadoFaturamento", "value" : "S"}] - patch no BackEnd no lugar do Put

            let patchJSON = [{op : "replace", path : "/LiberadoFaturamento", value : liberadoFaturamento},
                             {op : "replace", path : "/OperadorAlteracaoId", value : operadorAlteracaoId}] ;

            await this.$http.patch(`/v1.0/guias/${tenant}/liberadoFaturamento/${id}`, patchJSON)
                    .then(() => {
                        this.adicionarAlteracoes();
                        
                        this.guias.find(i => i.id==id).liberadoFaturamento = liberadoFaturamento;
                        this.guiaSelecionada.liberadoFaturamento = liberadoFaturamento;

                        if (chamadoPor=="PacienteSessoes") {
                            this.destacarLinhasGuiasSessoes()
                        } else if (chamadoPor=="Faturamento") {
                            this.destacarLinhasGuiasLote();
                        } else if (chamadoPor=="LanctoGuia") {
                            this.destacarLinhasGuias();
                        }
                    
                        this.$toasted.global.defaultSuccess();
                        this.erro = "";
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
        },
        excluirGuia(tenant, guia, tipo, profissionalId, chamadoPor) {
            this.$http.delete(`v1.0/guias/${tenant}/${guia.id}/${profissionalId}`)
            .then(() => {
                this.$toasted.global.defaultSuccess();
                this.carregarGuiasDoPaciente(tenant, guia.pacienteId, tipo, chamadoPor);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        gravarGuiaFaturamento(tenant) {
            if (!this.verificarCampos()) {
                return;
            }

            this.guiaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            let statusAnterior = this.guiaSelecionada.status;
            let datAtendimento  = new Date(this.guiaSelecionada.datAtendimento + " 00:00:00");
            
            this.guiaSelecionada.datAtendimento  = datAtendimento;
            
            if (this.guiaSelecionada.datSolicitacao != null) {
                let datSolicitacao  = new Date(this.guiaSelecionada.datSolicitacao + " 00:00:00");
                this.guiaSelecionada.datSolicitacao  = datSolicitacao;
            }
            
            if (this.guiaSelecionada.datAutorizacao != null) {
                let datAutorizacao  = new Date(this.guiaSelecionada.datAutorizacao + " 00:00:00");
                this.guiaSelecionada.datAutorizacao  = datAutorizacao;
            }
            
            if (this.guiaSelecionada.datValidadeSenha != null) {
                let datValidadeSenha= new Date(this.guiaSelecionada.datValidadeSenha + " 00:00:00");
                this.guiaSelecionada.datValidadeSenha= datValidadeSenha;
            }

            this.guiaSelecionada.status = "V";

            this.$http.put(`/v1.0/guias/${tenant}/${this.guiaSelecionada.id}`, this.guiaSelecionada)
            .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.adicionarAlteracoes();
                this.setUltimaGuiaSalva(this.guiaSelecionada);
                
                this.retornar();
            })
            .catch(error => {
                this.guiaSelecionada.status = statusAnterior;
                this.erro = errors.tratarErros(error);
            })
        },

        destacarLinhasGuiasSessoes() {
            for (let i=0; i<this.guias.length; i++) {
                if (this.guias[i].liberadoFaturamento=="N") {
                  this.guias[i]._rowVariant = 'danger';
                } else {
                  this.guias[i]._rowVariant = 'success';
                }
            }
        },

        destacarLinhasGuias() {
            for (let i=0; i<this.guias.length; i++) {
                if (this.guias[i].loteId===null) {
                  this.guias[i]._rowVariant = 'danger';
                } else {
                  this.guias[i]._rowVariant = 'success';
                }
            }
        },

        destacarLinhasGuiasLote() {
            for (let i=0; i<this.guias.length; i++) {
                if (this.guias[i].status!="V") {
                  this.guias[i]._rowVariant = 'warning';
                } else {
                  this.guias[i]._rowVariant = 'success';
                }
            }
        },

        async carregarTratamentos(tenant, guiaId) {
            await this.$http.get(`/v1.0/guiaTratamentos/${tenant}/Guia/${guiaId}`)
                  .then(resp => {
                      this.guiaTratamentos = resp.data;
                      //this.erro = "";
                  })
                  .catch(error => this.erro = errors.tratarErros(error))
        },

        async carregarDespesas(tenant, guiaId) {
            await this.$http.get(`/v1.0/guiaDespesas/${tenant}/Guia/${guiaId}`)
                  .then(resp => {
                      this.guiaDespesas = resp.data;
                      //this.erro = "";
                  })
                  .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
