import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrar: "",
            profissoes : [],
            count : 0,
            limit : 0,
        }
    },
    methods : {
        carregarProfissoes(tenant, pagina) {
            if (this.filtrar == "") {
              this.$http
                .get(`/v1.0/profissoes/${tenant}/pagina/${pagina}`)
                .then(resp => {
                  this.profissoes = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;
                  this.erro = "";
                })
                .catch(error => (this.erro = errors.tratarErros(error)))
                .finally(() => {
                  this.loading = false;
                  this.loaded = true;
                });
            } else {
              this.$http
                .get(`/v1.0/profissoes/${tenant}/Descricao/${this.filtrar}/${pagina}`)
                .then(resp => {
                  this.profissoes = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;
                  this.erro = "";
                })
                .catch(error => (this.erro = errors.tratarErros(error)))
                .finally(() => {
                  this.loading = false;
                  this.loaded = true;
                });
            }
        }
    }
}
