<template>
  <div>
    <template v-if="modo==''">  
        <div class="row">
          <div class="form-group col-lg-12" style="margin-top:28px">
            <div class="row">
               <button :disabled="!medicoDoPaciente" id="incluirSolicitacao" class="btn btn-sm btn-info ml-5" @click="incluirSolicitacao()">Incluir Nova Solicitação</button>
               <button :disabled="!medicoDoPaciente || solicitacoesInternas.length == 0" id="imprimirSolicitacao" class="btn btn-sm btn-info ml-2" @click="imprimirSolicitacao()">Imprimir</button>
            </div>
          </div>
        </div>
    </template>

    <template v-if="modo!=''">
        <div class="container-fluid">
          <div class="row">
            <div class="form-group col-lg-4">
              <label for="procedimento" class="control-label">Procedimento</label>
              <input
                type="text"
                class="form-control"
                id="procedimento"
                name="procedimento"
                v-model="solicitacaoInternaSelecionada.procedimento"
              />
            </div>

            <div class="form-group col-lg-1">
              <label for="quantidade" class="control-label">Qtde</label>
              <input
                type="number"
                class="form-control"
                id="quantidade"
                name="quantidade"
                v-model.number="solicitacaoInternaSelecionada.quantidade"
              />
            </div>

            <div class="form-group col-lg-2">
              <label for="Sintomas" class="control-label">Sinais/Sintomas</label>
              <input
                type="text"
                class="form-control"
                id="Sintomas"
                name="Sintomas"
                v-model="solicitacaoInternaSelecionada.sinais_Sintomas"
              />
            </div>

            <div class="form-group col-lg-1">
              <label for="cid" class="control-label">CID</label>
              <input
                type="text"
                class="form-control"
                id="cid"
                name="cid"
                v-model="solicitacaoInternaSelecionada.cid"
              />
            </div>

            <div class="form-group col-lg-2">
                <button class="btn btn-sm btn-danger" style="margin-top:36px" @click="cancelar()">Cancelar</button>
                <button class="btn btn-sm btn-info ml-2" style="margin-top:36px" @click="gravar()">Gravar</button>
            </div>

          </div>
        </div>
    </template>

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div style="display:flex; flex-direction:row; justify-content:center; max-height:500px; overflow:auto">
      <b-spinner v-if="loadingSolicitacoesInternas" label="carregando..."/>
      <b-table
        v-if="loadedSolicitacoesInternas"
        class="col-lg-10"
        hover
        striped
        small
        sort-icon-left
        :items="solicitacoesInternas"
        :fields="camposTabela"
      >
        <template v-slot:cell(actions)="row">
          <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" :disabled="!medicoDoPaciente" class="mr-2">
            <i class="fa fa-pencil"/>
          </b-button>
          <b-button variant="danger" size="sm" @click="excluirRegistro(row.item)"  :disabled="!medicoDoPaciente" class="mr-2">
            <i class="fa fa-trash"/>
          </b-button>
        </template>

        <template v-slot:cell(datCadastro)="datCadastro">
            {{datCadastro.value | formatDate}}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import solicitacoesInternas from "@/mixins/solicitacoesInternas";
import relatoriosAtendimentos from "@/mixins/relatoriosAtendimentos";
import errors from "@/utils/errors.js"

export default {
    mixins : [solicitacoesInternas, relatoriosAtendimentos],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados"
        }),
        liberarCampos() {
          return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
        },
        medicoDoPaciente() {
          return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
        }
    },
    data() {
        return {
            erro : "",
            modo : "",
            loadingSolicitacoesInternas : false,
            loadedSolicitacoesInternas : false,
            solicitacaoInternaSelecionada : {},
            camposTabela : [
                {key : "datCadastro", label : "Data", sortable : true},
                {key : "procedimento", label : "Procedimento"},
                {key : "quantidade", label : "Qtde"},
                {key : "sinais_Sintomas", label : "Sintomas"},
                {key : "cid", label : "CID"},
                {key: "actions", label: "Ações" }
            ]
        }
    },
    methods : {
        incluirSolicitacao() {
            this.modo = "Incluir";
            this.limparSolicitacaoInternaSelecionada();
        },
        imprimirSolicitacao() {
          this.carregarRelatorioSolicitacaoInterna(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId, this.solicitacoesInternas[0].datSolicitacao);
        },
        selecionouRegistro(registro) {
            this.modo = "Alterar"
            this.solicitacaoInternaSelecionada = {
                id : registro.id, pacienteId : registro.pacienteId, profissionalId : registro.profissionalId, 
                procedimento : registro.procedimento,  quantidade : registro.quantidade, sinais_Sintomas : registro.sinais_Sintomas, cid : registro.cid
            }
        },
        limparSolicitacaoInternaSelecionada() {
            this.solicitacaoInternaSelecionada = {
                id : 0, procedimento : "", quantidade : 0, sinais_sintomas : "", cid : ""
            }
        },
        cancelar() {
            this.modo = "";
            this.erro = "";
            this.limparSolicitacaoInternaSelecionada();
        },
        gravar() {
            if (!this.verificarCamposPreenchidos()) {
                return;
            }

            if (this.modo=="Alterar") {
                this.solicitacaoInternaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

                this.$http.put(`/v1.0/solicitacoesinternas/${this.usuarioLogadoDados.tenant}/${this.solicitacaoInternaSelecionada.id}`, this.solicitacaoInternaSelecionada)
                .then(() => {
                    this.modo = "";
                    this.erro = "";
                    this.limparSolicitacaoInternaSelecionada();
                    this.$toasted.global.defaultSuccess();
                    this.carregarSolicitacoesInternas(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);
                })
                .catch(error => this.erro = errors.tratarErros(error))
            } else {
                this.solicitacaoInternaSelecionada.pacienteId = this.pacienteSelecionado.id;
                this.solicitacaoInternaSelecionada.profissionalId = this.usuarioLogadoDados.profissionalId;
                this.solicitacaoInternaSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
                this.solicitacaoInternaSelecionada.datSolicitacao = new Date().toISOString().split("T")[0];

                this.$http.post(`/v1.0/solicitacoesinternas/${this.usuarioLogadoDados.tenant}`, this.solicitacaoInternaSelecionada)
                .then(() => {
                    this.modo = "";
                    this.erro = "";
                    this.limparSolicitacaoInternaSelecionada();
                    this.$toasted.global.defaultSuccess();
                    this.carregarSolicitacoesInternas(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);
                })
                .catch(error => this.erro = errors.tratarErros(error))
            }
        },
        excluirRegistro(registro) {
            this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.$http.delete(`/v1.0/solicitacoesinternas/${this.usuarioLogadoDados.tenant}/${registro.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess();
                    this.carregarSolicitacoesInternas(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);
                    this.erro = "";
                })
                .catch(error => this.erro = errors.tratarErros(error))
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        verificarCamposPreenchidos() {
            if (this.solicitacaoInternaSelecionada.procedimento == null || this.solicitacaoInternaSelecionada.procedimento == "") {
                this.$toasted.show("Favor informar o Procedimento.", {type : "warning", icon : "warning"});
                return false;
            }

            if (this.solicitacaoInternaSelecionada.quantidade <= 0) {
                this.$toasted.show("Quantidade Inválida.", {type : "warning", icon : "warning"});
                return false;
            }

            return true;
        }
    },
    created() {
      this.carregarSolicitacoesInternas(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId, this.pacienteSelecionado.datAtenda);
    }
}
</script>

<style scoped>

</style>
