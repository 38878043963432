import errors from "@/utils/errors";

export default {
    data() {
        return {
            contratados : [],
        }
    },
    methods : {
        async carregarContratados(tenant) {
          await this.$http
            .get(`/v1.0/contratados/${tenant}`)
            .then(resp => {
              this.contratados = resp.data;
              this.erro = "";
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loading = false;
            });
          },
    }
}
