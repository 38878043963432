<template>
  <div id="cadMensagem">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Enviar Mensagem</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
        <template v-if="responder.recado==undefined">
          <b-spinner v-if="loadingProfissionais" label="carregando..."/>
          <div class="row" >
              <div class="form-group col-lg-6" style="max-height:235px;overflow:auto">
                  <label for="tabelaProfissionais" class="control-label">Profissionais</label>
                  <b-table id="tabelaProfissionais" v-if="loadedProfissionais" class="col-lg-12" hover striped small sort-icon-left :items="profissionais" :fields="camposTabelaProfissionais">
                    <template v-slot:cell(actions)="row">
                        <b-button 
                          variant="info"
                          size="sm"
                          @click="adicionar(row.item)"
                          class="mr-2"
                        >
                          <i class="fa fa-plus" />
                        </b-button>
                    </template>
                    <template v-slot:cell(data)="data">
                      {{ data.value | formatDate }}
                    </template>
                  </b-table>
              </div>

              <div class="form-group col-lg-6" style="max-height:235px;overflow:auto">
                  <label for="tabelaEnviarPara" class="control-label">Enviar Para</label>
                  <b-table id="tabelaEnviarPara" v-if="loadedProfissionais" class="col-lg-12" hover striped small sort-icon-left :items="enviarPara" :fields="camposTabelaEnviarPara">
                    <template v-slot:cell(actions)="row">
                        <b-button 
                          variant="danger"
                          size="sm"
                          @click="remover(row.item)"
                          class="mr-2"
                        >
                          <i class="fa fa-minus" />
                        </b-button>
                    </template>
                    <template v-slot:cell(data)="data">
                      {{ data.value | formatDate }}
                    </template>
                  </b-table>
              </div>
          </div>
        </template>
        <hr>
        <template v-if="loadedProfissionais">
          <div class="row">
            <div class="col-lg-12"> 
                <VueEditor id="currentEditor" ref="currentEditor" v-model="recado"  
                style="justify-content:center;height:300px;overflow:auto"/> 
            </div> 
          </div>
        </template>

        <div class="row">
            <div class="form-group col-lg-12">
                <button class="btn btn-sm btn-warning" @click="retornar()">Cancelar</button>
                <button class="btn btn-sm btn-info ml-2" @click="enviarMensagem()">Enviar Mensagem</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {VueEditor} from "vue2-editor";
import global from "@/mixins/global";
import profissionais from "@/mixins/profissionais";
import errors from "@/utils/errors";

export default {
    mixins : [profissionais, global],
    components : {VueEditor},
    computed : {
        ...mapGetters({
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
            responder : "mensagens/getRecado",
        }),
    },
    data() {
        return {
            erro : "",
            recado : "",
            loadingProfissionais : false,
            loadedProfissionais : false,
            currentEditorToolBar : [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["image", "code-block"]
            ],
            enviarPara : [],
            camposTabelaProfissionais : [
                {key : "nome", label : "Nome"},
                {key : "actions", label : "Ações"},
            ],
            camposTabelaEnviarPara : [
                {key : "nome", label : "Nome"},
                {key : "actions", label : "Ações"},
            ]
        }
    },
    methods : {
        ...mapActions({
            formLeave : "mensagens/formLeave"
        }),
        retornar() {
            this.$router.go(-1);
        },
        adicionar(profissionalSelecionado) {
            this.enviarPara.push(profissionalSelecionado);
            
            let indice = this.profissionais.findIndex(p => p.id == profissionalSelecionado.id);
            this.profissionais.splice(indice, 1);
        },
        remover(profissionalSelecionado) {
            this.profissionais.push(profissionalSelecionado);

            let indice = this.enviarPara.findIndex(p => p.id == profissionalSelecionado.id);
            this.enviarPara.splice(indice, 1);
        },
        enviarMensagem() {
            if (this.enviarPara.length==0 && this.responder.recado==undefined) {
                this.$toasted.show("Selecione para quem deseja enviar a mensagem", {type: "info", icon: "time"});
                return;
            }

            let enviarMensagemPara = [];

            if (this.responder.recado==undefined) {
                this.enviarPara.forEach(profissional => {
                    let mensagem = {};
    
                    mensagem.profissionalOrigemId = this.usuarioLogadoDados.profissionalId;
                    mensagem.profissionalDestinoId = profissional.id;
                    mensagem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
                    mensagem.data = new Date();
                    mensagem.nivel = "D";
                    mensagem.recado = this.recado;
    
                    enviarMensagemPara.push(mensagem);
                });
            } else {
                    let mensagem = {};
    
                    mensagem.profissionalOrigemId = this.usuarioLogadoDados.profissionalId;
                    mensagem.profissionalDestinoId = this.responder.profissionalDestinoId;
                    mensagem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
                    mensagem.data = new Date();
                    mensagem.nivel = "D";
                    mensagem.recado = this.recado;
    
                    enviarMensagemPara.push(mensagem);
            }

            this.$http.post(`/v1.0/mensagens/${this.usuarioLogadoDados.tenant}`, enviarMensagemPara)
            .then(() => {
              this.erro = "";
              this.$toasted.global.defaultSuccess();
              this.retornar();
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
        }
    },
    created() {
        this.showMenu(false);

        if (this.responder.recado != undefined) {
            this.recado = this.responder.recado;
            this.recado += "<hr/>";
            this.recado += "<br><h3 style='color:navy'>Resposta:</h3><br><br>";

            this.loadingProfissionais = false;
            this.loadedProfissionais = true;
        } else {
            this.carregarProfissionaisAtivos(this.usuarioLogadoDados.tenant, "S");
        }
    },
    beforeDestroy() {
        this.formLeave();
    }    
};
</script>

<style scoped>
</style>
