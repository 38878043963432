<template>
  <div id="frmMensagens">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Minhas Mensagens</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
        <div id="filtrarRegistros" class="row">
          <div class="form-group ml-4 col-lg-4">
            <input type="radio" id="recebidas" value="D" v-model="nivel" />
            <label for="recebidas" class="control-label ml-1 mr-2">Recebidas</label>

            <input type="radio" id="enviadas" value="O" v-model="nivel" />
            <label for="enviadas" class="control-label ml-1 mr-2">Enviadas</label>
          </div>
          <b-pagination v-if="loaded" class="col-lg-3 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>

          <div class="form-group ml-3 col-lg-3">
            <button class="btn btn-sm btn-info" @click="enviarMensagem()">Nova Mensagem</button>
            <button v-if="mensagemSelecionada.nivel=='D'" class="btn btn-sm btn-info ml-2" @click="responderMensagem()">Responder Mensagem</button>
          </div>
        </div>

        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>

        <div style="display:flex; flex-direction:row; justify-content:center;max-height:235px;overflow:auto">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="loaded" class="col-lg-8" hover striped small sort-icon-left :items="mensagens" :fields="camposTabela" @row-clicked="selecionouRegistro">
            <template v-slot:cell(actions)="row">
                <b-button 
                  variant="danger"
                  size="sm"
                  @click="excluirRegistro(row.item)"
                  class="mr-2"
                >
                  <i class="fa fa-trash" />
                </b-button>
                <b-button v-if="row.item.nivel=='D' && row.item.lida!='S'"
                  variant="info"
                  size="sm"
                  @click="marcarComoLida(row.item)"
                  class="mr-2"
                >
                  <i class="fa fa-eye" />
                </b-button>
            </template>
            <template v-slot:cell(data)="data">
              {{ data.value | formatDate }}
            </template>
          </b-table>
        </div>
        <hr>
        <template v-if="loaded">
          <div class="row">
            <div class="col-lg-12"> 
              <VueEditor id="previousEditor" v-model="mensagemSelecionada.recado" :editor-toolbar="previousEditorToolBar" disabled
              style="justify-content:center;height:300px;overflow:auto"/>
            </div>

              <!-- <div class="col-lg-12"> -->
                <!-- <VueEditor id="currentEditor" ref="currentEditor" v-model="mensagemSelecionada.recado"  -->
                <!-- style="justify-content:center;height:300px;overflow:auto"/> -->
              <!-- </div> -->
          </div>
        </template>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {VueEditor} from "vue2-editor"
import mensagens from "@/mixins/mensagens";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
    mixins : [mensagens, global],
    components : {VueEditor},
    computed : {
        ...mapGetters({
            usuarioLogadoDados : "global/getUsuarioLogadoDados"
        })
    },
    watch : {
        page() {
            this.carregar();
        },
        nivel() {
            this.carregar();
            this.mensagemSelecionada.nivel="";
        }
    },
    data() {
        return {
            loading : true,
            loaded : false,
            limit : 0,
            count : 0,
            page  : 1,
            nivel : "D",
            erro : "",
            modo : "",
            mensagem : "",
            previousEditorToolBar : [ [] ],
            currentEditorToolBar : [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["image", "code-block"]
            ],
            camposTabela : [
                {key : "data", label : "Data"},
                {key : "profissional", label : "De/Para"},
                {key : "actions", label : "Ações"}
            ],
            mensagemSelecionada : {recado : null}
        }
    },
    methods : {
        ...mapActions({
          setRecado : "mensagens/setRecado"
        }),
        retornar() {
          this.$router.go(-1);
        },
        carregar() {
          this.carregarMensagens(this.usuarioLogadoDados.tenant, this.usuarioLogadoDados.profissionalId, this.nivel, this.page);
        },
        selecionouRegistro(mensagemSelecionada) {
          this.mensagemSelecionada = {...mensagemSelecionada};
        },
        enviarMensagem() {
          this.$router.push("/cadMensagem");
        },
        responderMensagem() {
          let responder = {profissionalDestinoId : this.mensagemSelecionada.profissionalOrigemId, recado : this.mensagemSelecionada.recado};

          this.setRecado(responder);
          this.$router.push("/cadMensagem");
        },
        excluirRegistro(mensagemSelecionada) {
          if (!this.verificarPermissao('Excluir')) {
            return
          }

          this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
            })
            .then(value => {
              if (value) {
               this.$http
                 .delete(`v1.0/mensagens/${this.usuarioLogadoDados.tenant}/${mensagemSelecionada.id}/${this.usuarioLogadoDados.profissionalId}`)
                 .then(() => {
                   this.erro = "";
                   this.$toasted.global.defaultSuccess();
                   this.carregar();
                 })
                 .catch(error => {
                   this.erro = errors.tratarErros(error);
                 })
                 .finally(() => {});
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        marcarComoLida(mensagemSelecionada) {
          let mensagem = {id : mensagemSelecionada.id, operadorId : this.usuarioLogadoDados.profissionalId};

          this.$http.put(`/v1.0/mensagens/${this.usuarioLogadoDados.tenant}/marcarComoLida/${mensagem.id}`, mensagem)
          .then(() => {
            this.erro = "";
            this.$toasted.global.defaultSuccess();
            mensagemSelecionada.lida = 'S';
          })
          .catch(error => {this.erro = errors.tratarErros(error)})
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");

            return false;
          }

          return true;
        }    
    },
    created() {
        this.showMenu(false);
        this.carregar();
    }
}
</script>

<style scoped>

</style>
