<template>
  <div class="frmPrincipal">
    <div class="corpo">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <img alt="DoctorOn logo" src="../assets/doctorOn.png">
        </div>
        <div class="col-lg-4"></div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import global from '@/mixins/global'

export default {
  mixins : [global],
  data() {
    return {
      erro: ""
    }
  },
  created() {
    this.showMenu(true);
    this.showToggleMenu(true);
    this.showUserDropDown(true);
  },
}
</script>

<style scoped>
  .corpo {
      background-color: #efefef;
  }
</style>