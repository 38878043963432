<template>
    <footer class="footer">
        <span>Versão: <Strong> {{globalVersaoSistema}} </Strong> | Copyright <Strong> {{globalEmpresaDesenvolvedora}} </Strong></span>        
    </footer>
</template>

<script>
export default {
    name : 'Footer'
}
</script>

<style scoped>
    .footer {
        grid-area: footer;
        background-color: #DDD;
        color: #333;

        display : flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
    }
</style>