<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Bancos Cadastrados</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Banco</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Codigo" class="control-label">Código:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Codigo"
              v-model="banco.codBanco"
            />
          </div>

          <div class="form-group col-sm-2 col-md-3 col-lg-3">
            <label for="Nome" class="control-label">Banco:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Nome"
              v-model="banco.nome"
              aria-describedby="bancoHelp"
            />
            <small id="bancoHelp" class="form-text text-muted">Nome Completo do Banco.</small>
          </div>

          <div class="form-group col-lg-1">
            <label for="Digito" class="control-label">Dígito:</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Digito"
              v-model.number="banco.digito"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center; height:520px">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="!loading"
          class="col-lg-6"
          hover
          striped
          small
          sort-icon-left
          :items="bancos"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="!loading" class="justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import bancos from "@/mixins/bancos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [bancos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      banco: {},
      loading: true,
      erro: "",
      modo : "",
      mensagem : "",
      camposTabela: [
        { key: "codBanco", label: "Código", sortable: true },
        { key: "nome", label: "Banco", sortable: true },
        { key: "digito", label: "Dígito" },
        { key: "actions", label: "Ações" }
      ]
    }
  },
  watch : {
    page() {
        this.carregarBancos(this.usuarioLogadoDados.tenant, this.page)
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.banco = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouRegistro(modelo) {
      this.banco = { ...modelo }
      this.modo = "Registro";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.banco.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/bancos/${this.usuarioLogadoDados.tenant}/${this.banco.id}`, this.banco)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarBancos(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.banco.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/bancos/${this.usuarioLogadoDados.tenant}`, this.banco)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarBancos(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`/v1.0/bancos/${this.usuarioLogadoDados.tenant}/${this.banco.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarBancos(this.usuarioLogadoDados.tenant, this.page);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.banco = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.banco = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.banco.descricao == "" && this.banco.descricao == undefined) {
        return false;
      } else {
        return true;
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarBancos(this.usuarioLogadoDados.tenant, this.page);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
