<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Opções de Relatórios Financeiros</span>
          </div>
          <div class="coluna3"/>
        </div>  

        <div class="container">
            <div class= "row">
                <template v-if="grupoUsuarioLogado != 'Corpo Clínico'">
                    <div class="col-lg-2">
                        <button class="btn btn-info btn-sm" id="CaixaDiario01" @click="relatorioSelecionado=30">Caixa Diário - Particulares</button>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-info btn-sm" id="RecebimentoPorProfissional" @click="relatorioSelecionado=35">Recebimento diário por Profissional</button>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-info btn-sm" id="RecebimentoPorSiglaServico" @click="relatorioSelecionado=36">Recebimento diário por Grupo</button>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-info btn-sm" id="ProducaoPorGrupo" @click="relatorioSelecionado=20">Relatório de Produção Por Grupo</button>
                    </div>
                </template>
                <div class="col-lg-2">
                    <button class="btn btn-info btn-sm" id="ProducaoIndividualPorGrupo" @click="relatorioSelecionado=10">Relatório de Produção Individual Por Grupo</button>
                </div>
            </div>
            <hr>
            <br>
            <template v-if="relatorioSelecionado>0">
                <template v-if="relatorioSelecionado<30">
                    <div class="row">
                        <div class="col-lg-2">
                            <label for="servicos1" class="control-label" style="margin-bottom: 0px">Grupo</label>
                            <select id="servicos1" class="form-control" v-model="servico1" selected>
                                <option v-for="servico in servicos1" :key="servico.siglaServico" :value="servico.siglaServico">
                                    {{servico.descricao}}
                                </option>
                            </select>
                        </div>

                        <template v-if="servico1!='Todos'">
                            <div class="col-lg-2">
                                <label for="servicos2" class="control-label" style="margin-bottom: 0px">Grupo</label>
                                <select id="servicos2" class="form-control" v-model="servico2" selected>
                                    <option v-for="servico in servicos2" :key="servico.siglaServico" :value="servico.siglaServico">
                                        {{servico.descricao}}
                                    </option>
                                </select>
                            </div>
                        </template>

                        <template v-if="servico2!=''">
                            <div class="col-lg-2">
                                <label for="servicos3" class="control-label" style="margin-bottom: 0px">Grupo</label>
                                <select id="servicos3" class="form-control" v-model="servico3" selected>
                                    <option v-for="servico in servicos3" :key="servico.siglaServico" :value="servico.siglaServico">
                                        {{servico.descricao}}
                                    </option>
                                </select>
                            </div>
                        </template>

                        <template v-if="servico3!=''">
                            <div class="col-lg-2">
                                <label for="servicos4" class="control-label" style="margin-bottom: 0px">Grupo</label>
                                <select id="servicos4" class="form-control" v-model="servico4" selected>
                                    <option v-for="servico in servicos4" :key="servico.siglaServico" :value="servico.siglaServico">
                                        {{servico.descricao}}
                                    </option>
                                </select>
                            </div>
                        </template>

                        <template v-if="servico4!=''">
                            <div class="col-lg-2">
                                <label for="servicos5" class="control-label" style="margin-bottom: 0px">Grupo</label>
                                <select id="servicos5" class="form-control" v-model="servico5" selected>
                                    <option v-for="servico in servicos5" :key="servico.siglaServico" :value="servico.siglaServico">
                                        {{servico.descricao}}
                                    </option>
                                </select>
                            </div>
                        </template>
                    </div>
                </template>

                <div class="row">
                    <template v-if="grupoUsuarioLogado != 'Corpo Clínico' && relatorioSelecionado<=10">
                        <div class="col-lg-3">
                            <label for="selecionarProfissional" class="control-label" style="margin-bottom: 0px">Profissional</label>
                            <select id="selecionarProfissional" class="form-control" v-model="profissionalId">
                                <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                                    {{profissional.nome}}
                                </option>
                            </select>
                        </div>
                    </template>
                    <div class="col-lg-2 flex-center">
                        <label for="dataInicial" class="control-label" style="margin-bottom: 0px">Data Inicial</label>
                        <input type="date" id="dataInicial" class="form-control" v-model="datInicial" @dblclick="dataAtual()"/>
                    </div>
                    <template v-if='relatorioSelecionado<35'>
                        <div class="col-lg-2 flex-center">
                            <label for="dataFinal" class="control-label" style="margin-bottom: 0px">Data Final</label>
                            <input type="date" id="dataFinal" class="form-control" v-model="datFinal" @dblclick="dataAtual()"/>
                        </div>
                    </template>

                    <template v-if="relatorioSelecionado<30">
                        <div class="col-lg-2 flex-center">
                            <label for="origem" class="control-label" style="margin-bottom: 0px">Origem Valores</label>
                            <select id="origem" class="form-control" v-model="relatorioOrigem">
                                <option v-for="opcRelatorioOrigem in opcRelatorioOrigens" :key="opcRelatorioOrigem.opcao" :value="opcRelatorioOrigem.opcao">
                                    {{opcRelatorioOrigem.descricao}}
                                </option>
                            </select>
                        </div>

                        <template v-if="relatorioOrigem<3">
                            <div class="col-lg-2 flex-center">
                                <label for="exibirValores" class="control-label" style="margin-bottom: 0px">Exibir Valores</label>
                                <select id="exibirValores" class="form-control" v-model="relatorioConveniosValores">
                                    <option v-for="opcRelatorioConveniosValor in opcRelatorioConveniosValores" :key="opcRelatorioConveniosValor.opcao" :value="opcRelatorioConveniosValor.opcao">
                                        {{opcRelatorioConveniosValor.descricao}}
                                    </option>
                                </select>
                            </div>
                        </template>
                    </template>

                    <div class="col-lg-2">
                        <button id="botaoImprimir" class="btn btn-sm btn-info" style="margin-top:28px; width:100%" @click="chamarRelatorio()">Imprimir</button>
                    </div>
                </div>
            </template>
        </div>
        <b-spinner v-if="loadingRelatorio" label="carregando..."/>
        <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import global from "@/mixins/global";
import profissionais from "@/mixins/profissionais";
import unidades from "@/mixins/unidades";
import relatoriosFinanceiros from "@/mixins/relatoriosFinanceiros"
import servicos from "@/mixins/servicos"
import funcoes from "@/utils/funcoes"

export default {
    mixins : [profissionais, unidades, relatoriosFinanceiros, servicos, global],
    computed : {
        ...mapGetters({
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        })
    },
    watch : {
        servico1() {
            if (this.servico1 == "Todos") {
                this.servicos2 = [...this.servicos];
                this.servicos3 = [...this.servicos];
                this.servicos4 = [...this.servicos];
                this.servicos5 = [...this.servicos];

                this.servico2 = "",
                this.servico3 = "",
                this.servico4 = "",
                this.servico5 = ""
            } else {
                this.montarSelectServicos()
            }
        },
        servico2() {
            this.montarSelectServicos()
        },
        servico3() {
            this.montarSelectServicos()
        },
        servico4() {
            this.montarSelectServicos()
        },
        servico5() {
            this.montarSelectServicos()
        }
    },
    data() {
        return {
            profissionalId : 0,
            datInicial : null,
            datFinal : null,
            loadingRelatorio : false,
            erro : "",
            grupoUsuarioLogado : "",
            relatorioSelecionado : 0,
            relatorioOrigem : 0,
            relatorioConveniosValores : 0,
            opcRelatorioOrigens : [
                {opcao : 1, descricao : "Todas as Origens"},
                {opcao : 2, descricao : "Apenas Convênios"},
                {opcao : 3, descricao : "Apenas Particulares"}
            ],
            opcRelatorioConveniosValores : [
                {opcao : 1, descricao : "Exibir Valores"},
                {opcao : 2, descricao : "Não Exibir Valores"}
            ],
            servico1 : "Todos",
            servico2 : "",
            servico3 : "",
            servico4 : "",
            servico5 : "",
            servicos1 : [],
            servicos2 : [],
            servicos3 : [],
            servicos4 : [],
            servicos5 : [],
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        dataAtual() {
            var data = new Date();
            this.datInicial = funcoes.mySqlToJSDateFormat(data);
        },
        montarSelectServicos() {
            this.servicos2 = [...this.servicos];
            this.servicos3 = [...this.servicos];
            this.servicos4 = [...this.servicos];
            this.servicos5 = [...this.servicos];

            let indice;

            //----------------------------------------------------------------
            indice = this.servicos2.findIndex(s => s.siglaServico == this.servico1);
            this.servicos2.splice(indice, 1);

            indice = this.servicos3.findIndex(s => s.siglaServico == this.servico1);
            this.servicos3.splice(indice, 1);

            indice = this.servicos4.findIndex(s => s.siglaServico == this.servico1);
            this.servicos4.splice(indice, 1);

            indice = this.servicos5.findIndex(s => s.siglaServico == this.servico1);
            this.servicos5.splice(indice, 1);
            //----------------------------------------------------------------
            if (this.servico2 == '') return;

            indice = this.servicos1.findIndex(s => s.siglaServico == this.servico2);
            this.servicos1.splice(indice, 1);

            indice = this.servicos3.findIndex(s => s.siglaServico == this.servico2);
            this.servicos3.splice(indice, 1);

            indice = this.servicos4.findIndex(s => s.siglaServico == this.servico2);
            this.servicos4.splice(indice, 1);

            indice = this.servicos5.findIndex(s => s.siglaServico == this.servico2);
            this.servicos5.splice(indice, 1);
            //----------------------------------------------------------------
            if (this.servico3 == '') return;

            indice = this.servicos1.findIndex(s => s.siglaServico == this.servico3);
            this.servicos1.splice(indice, 1);

            indice = this.servicos2.findIndex(s => s.siglaServico == this.servico3);
            this.servicos2.splice(indice, 1);

            indice = this.servicos4.findIndex(s => s.siglaServico == this.servico3);
            this.servicos4.splice(indice, 1);

            indice = this.servicos5.findIndex(s => s.siglaServico == this.servico3);
            this.servicos5.splice(indice, 1);
            //----------------------------------------------------------------
            if (this.servico4 == '') return;

            indice = this.servicos1.findIndex(s => s.siglaServico == this.servico4);
            this.servicos1.splice(indice, 1);

            indice = this.servicos2.findIndex(s => s.siglaServico == this.servico4);
            this.servicos2.splice(indice, 1);

            indice = this.servicos3.findIndex(s => s.siglaServico == this.servico4);
            this.servicos3.splice(indice, 1);

            indice = this.servicos5.findIndex(s => s.siglaServico == this.servico4);
            this.servicos5.splice(indice, 1);
            //----------------------------------------------------------------
            if (this.servico5 == '') return;

            indice = this.servicos1.findIndex(s => s.siglaServico == this.servico5);
            this.servicos1.splice(indice, 1);

            indice = this.servicos2.findIndex(s => s.siglaServico == this.servico5);
            this.servicos2.splice(indice, 1);

            indice = this.servicos3.findIndex(s => s.siglaServico == this.servico5);
            this.servicos3.splice(indice, 1);

            indice = this.servicos4.findIndex(s => s.siglaServico == this.servico5);
            this.servicos4.splice(indice, 1);
        },
        async chamarRelatorio() {
            let siglaServico = "";

            if (this.servico1 != 'Todos') {
                siglaServico = await this.montarSiglaServico();
            } else {
                siglaServico = this.servico1;
            }

            let particular = this.relatorioOrigem == 1 ? 'T' : this.relatorioOrigem == 2 ? 'N' : 'S';
            let exibirConveniosValores = this.relatorioConveniosValores == 1 ? 'S' : 'N';

            if (this.relatorioSelecionado == 10) {
                this.carregarRelatorioProducaoIndividualPorGrupo(this.usuarioLogadoDados.tenant, this.profissionalId, this.datInicial, this.datFinal, particular, exibirConveniosValores, siglaServico);
            } else if (this.relatorioSelecionado == 20) {
                this.carregarRelatorioProducaoPorGrupo(this.usuarioLogadoDados.tenant, this.datInicial, this.datFinal, particular, exibirConveniosValores, siglaServico);
            } else if (this.relatorioSelecionado == 30) {
                this.carregarRelatorioCaixaDiario01(this.usuarioLogadoDados.tenant, this.datInicial, this.datFinal);
            } else if (this.relatorioSelecionado == 35) {
                this.carregarRelatorioRecebimentosPorProfissional(this.usuarioLogadoDados.tenant, this.datInicial);
            } else if (this.relatorioSelecionado == 36) {
                this.carregarRelatorioRecebimentosPorSiglaServico(this.usuarioLogadoDados.tenant, this.datInicial);
          
            }
        },
        montarSiglaServico() {
            let siglaServico = this.servico1;

            if (this.servico2 != '') siglaServico += '-'+this.servico2;
            if (this.servico3 != '') siglaServico += '-'+this.servico3;
            if (this.servico4 != '') siglaServico += '-'+this.servico4;
            if (this.servico5 != '') siglaServico += '-'+this.servico5;

            return siglaServico;
        }
    },
    async created() {
       this.showMenu(false);

        this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);
        this.carregarUnidades(this.usuarioLogadoDados.tenant);
        await this.carregarServicos(this.usuarioLogadoDados.tenant);

        this.servicos1 = [{siglaServico : "Todos", descricao : "Todos"}, ...this.servicos];
        this.servicos2 = [...this.servicos];
        this.servicos3 = [...this.servicos];
        this.servicos4 = [...this.servicos];
        this.servicos5 = [...this.servicos];

        this.grupoUsuarioLogado = this.usuarioLogadoDados.perfilAcesso.perfil.grupo;

        if (this.grupoUsuarioLogado == 'Corpo Clínico') {
            this.profissionalId = this.usuarioLogadoDados.profissionalId;
        }
    }
}
</script>


<style scoped>

</style>