<template>
  <div>
    <div class="cabecalho">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
        <span>Detalhes do Procedimento</span>
        <hr/>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="tabela" class="control-label">Tabela</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="tabela"
            name="tabela"
            v-model="procedimento.tabela"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="grupo" class="control-label">Grupo</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="grupo"
            name="grupo"
            v-model="procedimento.grupo"
          />
        </div>
        <!-- <div class="form-group col-lg-1"> -->
          <!-- <label for="NecessitaAutorizacao" class="control-label">Autorizar</label> -->
          <!-- <select -->
            <!-- class="form-control" -->
            <!-- disabled -->
            <!-- id="NecessitaAutorizacao" -->
            <!-- name="NecessitaAutorizacao" -->
            <!-- selected -->
            <!-- v-model="procedimento.necessitaAutorizacao" -->
          <!-- > -->
            <!-- <option -->
              <!-- v-for="confirma in sim_nao" -->
              <!-- :key="confirma.opcao" -->
              <!-- :value="confirma.opcao" -->
            <!-- >{{ confirma.valor }}</option> -->
          <!-- </select> -->
        <!-- </div> -->
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="codigo" class="control-label">Código</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="codigo"
            name="codigo"
            v-model="procedimento.codigo"
          />
        </div>

        <div class="form-group col-lg-8">
          <label for="Descricao" class="control-label">Procedimento</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Descricao"
            name="Descricao"
            v-model="procedimento.descricao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="porteFracao" class="control-label">% Porte</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="porteFracao"
            name="porteFracao"
            v-model.number="procedimento.porteFracao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="porteFracaoTipo" class="control-label">Tipo</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="porteFracaoTipo"
            name="porteFracaoTipo"
            v-model.number="procedimento.porteFracaoTipo"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="porte" class="control-label">Porte</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="porte"
            name="porte"
            v-model.number="procedimento.porte"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="CO" class="control-label">CO</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="CO"
            name="CO"
            v-model.number="procedimento.co"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="CH" class="control-label">CHs</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="CH"
            name="CH"
            v-model.number="procedimento.ch"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="porteAnestesico" class="control-label">Porte Anest.</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="porteAnestesico"
            name="porteAnestesico"
            v-model.number="procedimento.porteAnestesico"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="filme" class="control-label">Filme m2</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="filme"
            name="filme"
            v-model.number="procedimento.filme"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="incidencias" class="control-label">Inc</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="incidencias"
            name="incidencias"
            v-model.number="procedimento.incidencias"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="ur" class="control-label">UR</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="ur"
            name="ur"
            v-model="procedimento.ur"
          />
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import servicos from "@/mixins/servicos";
import global from "@/mixins/global";

export default {
  mixins : [servicos, global],
  computed: {
    ...mapGetters({
      tabelaSelecionada: "tabelaProcedimentos/getTabelaSelecionada",
      procedimentoSelecionado: "procedimentos/getProcedimentoSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    procedimento() {
      return this.procedimentoSelecionado;
    }
  },
  data() {
    return {
      erro: "",
      mensagem : "",
      selecionouAlterar: false,
      sim_nao: constantes.sim_nao,
      registroProcedimento : {},
      servicos : {},
    }
  },
  methods: {
    ...mapActions({
      setProcedimentoSelecionado: "procedimentos/setProcedimentoSelecionado",
      limparRegistroSelecionado: "procedimentos/limparRegistroSelecionado",
      setServicos: "servicos/setServicos"
    }),
    retornar() {
      this.$router.go(-1);
    },
  },
  created() {
      this.showMenu(false);
      this.carregarServicos(this.usuarioLogadoDados.tenant);
  },
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
