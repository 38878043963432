<template>
  <div id="TesteGuia"> 
    <componenteA/>
    <componenteB/>
    <componenteC/>
  </div>
</template>

<script>
import componenteA from "./componenteA"
import componenteB from "./componenteB"
import componenteC from "./componenteC"

export default {
  components : {componenteA, componenteB, componenteC},
}
</script>

<style scoped>
  #TesteGuia {
    height: 100vh;
    display: grid;

    grid-template-rows: 300px 200px 200px;
    grid-template-columns: 1fr;
    grid-template-areas: 
            "componente1"
            "componente2"
            "componente3";
  }
</style>