import errors from "@/utils/errors";

export default {
    data() {
        return {
            unidadesmedidas : null, 
        }
    },
    methods: {
        carregarUnidadesMedidas(tenant) {
            this.$http
              .get(`/v1.0/unidademedidas/${tenant}`)
              .then(resp => {
                this.unidadesmedidas = resp.data;
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
          }
    }
}
