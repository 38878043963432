const state = {
    agendaContatoSelecionada : null,
}

const getters = {
    getAgendaContatoSelecionada(state) {
        return state.agendaContatoSelecionada;
    }
}

const mutations = {
    setAgendaContatoSelecionada(state, agendaContato) {
        state.agendaContatoSelecionada = {...agendaContato}
    },

    limparRegistro() {
        state.agendaContatoSelecionada = {}
    }
}

const actions = {
    setAgendaContatoSelecionada({commit}, agendaContato) {
        commit("setAgendaContatoSelecionada", agendaContato);
    },

    formLeave({commit}) {
        commit("limparRegistro");
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}