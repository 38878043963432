import errors from "@/utils/errors";

export default {
    data() {
        return {
            kitItens : [],
        }
    },
    methods : {
        carregarKitItens(tenant, KitId) {
            this.loading = true;
            this.loaded = false;

            this.$http
              .get(`/v1.0/kititens/${tenant}/KitId/${KitId}`)
              .then(resp => {
                this.kitItens = resp.data;
                this.erro = "";
                this.loaded = true;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {this.loading = false;
              });
          },
    }
}
