<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Especialidades Médicas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Nova Especialidade</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="especialidade.id"
            />
          </div>

          <div class="form-group col-lg-3">
            <label for="Descricao" class="control-label">Descrição:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="especialidade.descricao"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button id="Cancelar" 
            @click="cancelarOperacao()" 
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <div class="container-fluid">
      <div style="display:flex; flex-direction:row; justify-content:center; height:500px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" class="col-lg-6" hover striped small sort-icon-left :items="especialidades" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouEspecialidade(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="!loading" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import especialidades from "@/mixins/especialidades"
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [especialidades, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      loading: true,
      erro: "",
      modo: "",
      mensagem : "",
      especialidade : {},
      filtrar: "",
      camposTabela : [
        {key : "id", label : "Id"},
        {key : "descricao", label : "Descrição", sortable : true},
        {key : "actions", label : "Ações"}
      ]
    }
  },
  watch : {
    page() {
      this.carregarEspecialidades(this.usuarioLogadoDados.tenant, this.page);
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouEspecialidade(especialidade) {
      this.especialidade = {...especialidade}
      this.modo = "Registro";
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.especialidade = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.especialidade.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/especialidades/${this.usuarioLogadoDados.tenant}/${this.especialidade.id}`,
            this.especialidade
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarEspecialidades(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
         });
      } else {
        this.especialidade.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/especialidades/${this.usuarioLogadoDados.tenant}`, this.especialidade)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarEspecialidades(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
         });
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
           this.$http
             .delete(`v1.0/especialidades/${this.usuarioLogadoDados.tenant}/${this.especialidade.id}/${this.usuarioLogadoDados.profissionalId}`)
             .then(() => {
               this.erro = "";

               this.limparCampos();
               this.carregarEspecialidades(this.usuarioLogadoDados.tenant, this.page);
               this.$toasted.global.defaultSuccess();
             })
             .catch(error => {
               this.erro = errors.tratarErros(error);
             })
             .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.especialidade = {}
      this.modo="";
      this.erro = "";
    },
    limparCampos() {
      this.especialidade = {}

      if (this.modo!="Incluir") {
        this.modo="";
      }
    },
    verificarCamposPreenchidos() {
      if (this.especialidade.descricao == "" || this.especialidade.descricao == undefined) {
        this.$toasted.show("Digite a Descrição da Especialidade");
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarEspecialidades(this.usuarioLogadoDados.tenant, this.page);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo == "Incluir" || this.modo == "Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
