<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{fluxoSelecionado.descricao}} - Origens</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistroOrigem()"
          >Incluir Nova Origem</button>
          <hr />
        </div>
      </div>

      <template v-if="modo!=''">
          <div class="registro">
            <div class="form-group col-lg-4">
                <label for="origem" class="control-label">Nome</label>
                <input type="text" id="origem" class="form-control" :disabled="!liberarCampos" v-model="origem.descricao" placeholder="Digite o Nome Completo da Origem..."/>
            </div>
            <div class="form-group col-lg-12">
                <label for="informacoes" class="control-label">Informações</label>
                <textarea id="informacoes" rows="3" class="form-control" :disabled="!liberarCampos" v-model="origem.informacoes"/>
            </div>

            <hr />
            <div class="barraopcoescadastro">
              <button
                id="Excluir"
                :disabled="modo=='Incluir'"
                @click="excluirRegistroOrigem()"
                class="btn btn-sm btn-danger ml-2"
              >Excluir</button>
              <button
                id="Alterar"
                :disabled="modo=='Incluir' || modo=='Alterar'"
                @click="alterarRegistroOrigem()"
                class="btn btn-sm btn-secondary ml-2"
              >Alterar</button>
              <button
                id="Cancelar"
                @click="cancelarOperacaoOrigem()"
                class="btn btn-sm btn-secondary ml-2"
              >Cancelar</button>
              <button
                id="Gravar"
                :disabled="!liberarCampos"
                @click="gravarRegistroOrigem()"
                class="btn btn-sm btn-secondary ml-2"
              >Gravar</button>
            </div>
          </div>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:235px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" class="col-lg-6" small hover striped sort-icon-left :items="origens" :fields="camposTabela">
            <template v-slot:cell(actions)="row">
                <b-button variant="warning" size="sm">
                    <i class="fa fa-pencil" @click="selecionouOrigem(row.item)"/>
                </b-button>
                <b-button variant="info" size="sm" class="ml-1">
                    <i class="fa fa-plus ml-1" @click="vincularOrigem(row.item)"/>
                </b-button>
            </template>
        </b-table>
      </div>
      <b-pagination v-if="!loading" class="col-lg-12 justify-content-center" v-model="page" :total-rows="countOrigem" :per-page="limitOrigem"/>
      <hr>
      <h4>Origens Vinculadas à {{centroSelecionado.descricao}}</h4>
      <template v-if="!loadingCentroOrigens && modo==''">
        <div style="display:flex; flex-direction:row; justify-content:center;height:250px;overflow:auto">
            <b-table class="col-lg-6" small hover striped sort-icon-left :items="centroOrigens" :fields="camposTabelaCentroOrigens">
                <template v-slot:cell(actions)="row">
                    <b-button variant="danger" size="sm" class="ml-1">
                        <i class="fa fa-minus ml-1" @click="desvincularOrigem(row.item)"/>
                    </b-button>
                </template>
            </b-table>
        </div>
      </template>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import origens from "@/mixins/origens"
import global from "@/mixins/global"
import errors from "@/utils/errors";

export default {
    mixins : [origens, global],
    computed : {
        ...mapGetters({
            fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
            centroSelecionado : "centros/getCentroSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarCampos() {
            return this.modo == "Incluir" || this.modo == "Alterar"
        }
    },
    data() {
      return {
          page : 1,
          loading : true,
          loadingCentroOrigens : true,
          modo : "",
          mensagem : "",
          origem : {},
          camposTabela : [
              {key : "id", label : "Id"},
              {key : "descricao", label : "Descrição"},
              {key : "actions", label : "Ações"}
          ],
          camposTabelaCentroOrigens : [
              {key : "id", label : "Id"},
              {key : "origem", label : "Descrição"},
              {key : "actions", label : "Ações"}
          ]
      }
    },
    watch : {
        page() {
            this.carregarOrigens(this.fluxoSelecionado.id, this.page, false);
        }
    },
    methods : {
        retornar() {
          this.$router.go(-1);
        },
        incluirRegistroOrigem() {
          if (!this.verificarPermissao('Incluir')) {
            return
          }

          this.modo = "Incluir";
        },
        alterarRegistroOrigem() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.modo = "Alterar";
        },
        gravarRegistroOrigem() {
          if (!this.verificarCamposPreenchidosOrigem()) {
            return;
          }

          if (this.modo=="Alterar") {
            this.origem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/origens/${this.usuarioLogadoDados.tenant}/${this.origem.id}`, this.origem)
            .then(() => {
              this.erro = "";
              this.modo = "";
              this.origem = {}
              this.$toasted.global.defaultSuccess();
              this.carregarOrigens(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.page, false);
              this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.centroSelecionado.id, 0);
            })
            .catch(error => this.erro = errors.tratarErros(error));
          } else {
            this.origem.fluxoId = this.fluxoSelecionado.id;

            this.origem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.$http.post(`/v1.0/origens/${this.usuarioLogadoDados.tenant}`, this.origem)
            .then(() => {
              this.erro = "";
              this.modo = "";
              this.origem = {}
              this.$toasted.global.defaultSuccess();
              this.carregarOrigens(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.page, false);
            })
            .catch(error => this.erro = errors.tratarErros(error));
          }
        },
        selecionouOrigem(origem) {
            if (origem.tipoSistema != null) {
              this.$toasted.show("Essa é uma Origem Especial e não permite Alteração", {type:"info", icon:"times"});
              return
            }

            this.origem = {...origem}
            this.modo = "Registro";
        },
        vincularOrigem(origem) {
            if (!this.verificarPermissao('Alterar')) {
              return
            }

            let registroExistente = this.centroOrigens.find(co => co.origemId == origem.id);

            if (registroExistente != undefined) {
              this.$toasted.show(`${origem.descricao} já está vinculado(a) à ${this.centroSelecionado.descricao}`, {type: "info", icon : "times"});
              return;
            }

            let centroOrigem = {centroId : this.centroSelecionado.id, origemId : origem.id, 
                                datCadastro : new Date(), operadorCadastroId : this.usuarioLogadoDados.profissionalId}

            this.$http.post(`/v1.0/centroOrigens/${this.usuarioLogadoDados.tenant}`, centroOrigem)
            .then(() => {
              this.erro = "",
              this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.centroSelecionado.id, 0);
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => this.erro = errors.tratarErros(error));
        },
        desvincularOrigem(centroOrigem) {
            if (!this.verificarPermissao('Alterar')) {
              return
            }

            if (centroOrigem.tipoSistema != null) {
              this.$toasted.show("Esse é um Centro Especial e não pode ser Desvinculado", {type:"info", icon:"times"});
              return
            }

            this.$bvModal
              .msgBoxConfirm("Desvincular Registro Selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                  this.$http
                    .delete(`v1.0/centroOrigens/${this.usuarioLogadoDados.tenant}/${centroOrigem.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                      this.erro = "";
                      this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.centroSelecionado.id, 0);
                      this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        cancelarOperacaoOrigem() {
            this.modo = "";
            this.origem = {}
        },
        excluirRegistroOrigem(origem) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            if (origem.tipoSistema != null) {
              this.$toasted.show("Essa é uma Origem Especial e não pode ser Excluída.", {type:"info", icon:"times"});
              return
            }

            this.$bvModal
              .msgBoxConfirm("Excluir Registro Selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                  this.$http
                    .delete(`v1.0/origens/${this.usuarioLogadoDados.tenant}/${this.origem.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                      this.erro = "";
                      this.carregarOrigens(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.page, false);
                      this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        verificarCamposPreenchidosOrigem() {
          if (this.origem.descricao=="" || this.origem.descricao==undefined) {
            this.$toasted.show("Digite a Descrição para a Origem.", {type : "info", icon : "times"});
            return false
          }

          return true
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
    
            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");
    
            return false;
          }
    
          return true;
        }        
    },
    created() {
        this.showMenu(false);
        this.carregarOrigens(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, this.page, false);
        this.carregarCentroOrigens(this.usuarioLogadoDados.tenant, this.centroSelecionado.id, 0);
    }
}
</script>

<style scoped>

</style>
