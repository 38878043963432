<template>
  <div id="CadOperadoraDados">
    <!-- <div class="container-fluid"> -->
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="rgNumero" class="control-label">R.G.</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="rgNumero"
            name="rgNumero"
            v-model="pacienteSelecionado.rgNumero"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="rgEmissao" class="control-label">Emissao</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="rgEmissao"
            name="rgEmissao"
            v-model="pacienteSelecionado.rgEmissao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="rgOrgao" class="control-label">Orgão</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="rgOrgao"
            name="rgOrgao"
            v-model="pacienteSelecionado.rgOrgao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="cpfNumero" class="control-label">C.P.F.</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="cpfNumero"
            name="cpfNumero"
            v-model="pacienteSelecionado.cpfNumero"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="certidaoNascimento" class="control-label">Certidão Nasc.</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="certidaoNascimento"
            name="certidaoNascimento"
            v-model="pacienteSelecionado.certidaoNascimento"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="certidaoNascimentoEmissao" class="control-label">Emissão.</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="certidaoNascimentoEmissao"
            name="certidaoNascimentoEmissao"
            v-model="pacienteSelecionado.certidaoNascimentoEmissao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="cnsNumero" class="control-label">CNS</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="cnsNumero"
            name="cnsNumero"
            v-model="pacienteSelecionado.cnsNumero"
          />
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="Profissao" class="control-label">Profissao</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Profissao"
            name="Profissao"
            selected
            v-model="pacienteSelecionado.profissaoId"
          >
            <option
              v-for="profissao in profissoes"
              :key="profissao.id"
              :value="profissao.id"
            >{{ profissao.descricao }}</option>
          </select>
        </div>
        <div class="form-group col-lg-3">
          <label for="Empresa" class="control-label">Empresa</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Empresa"
            name="Empresa"
            selected
            v-model="pacienteSelecionado.empresaId"
          >
            <option
              v-for="empresa in empresas"
              :key="empresa.id"
              :value="empresa.id"
            >{{ empresa.descricao }}</option>
          </select>
        </div>
        <div class="form-group col-lg-3">
          <label for="Modelo" class="control-label">Modelo</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Modelo"
            name="Modelo"
            selected
            v-model="pacienteSelecionado.modeloId"
          >
            <option
              v-for="modelo in modelos"
              :key="modelo.id"
              :value="modelo.id"
            >{{ modelo.descricao }}</option>
          </select>
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="indicacao" class="control-label">Indicação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="indicacao"
            name="indicacao"
            v-model="pacienteSelecionado.indicacao"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="resultadoExame" class="control-label">Resultado Exames</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="resultadoExame"
            name="resultadoExame"
            v-model="pacienteSelecionado.resultadoExame"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="observacao" class="control-label">Observação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="observacao"
            name="observacao"
            v-model="pacienteSelecionado.observacao"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="observacaoMedico" class="control-label">Observação Médico</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="observacaoMedico"
            name="observacaoMedico"
            v-model="pacienteSelecionado.observacaoMedico"
          />
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="acompanhanteNome" class="control-label">Acompanhante</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="acompanhanteNome"
            name="acompanhanteNome"
            v-model="pacienteSelecionado.acompanhanteNome"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="acompanhanteCpf" class="control-label">C.P.F. Acompanhante</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="acompanhanteCpf"
            name="acompanhanteCpf"
            v-model="pacienteSelecionado.acompanhanteCpf"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="acompanhanteGrauParentesco" class="control-label">Grau Parentesco</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="acompanhanteGrauParentesco"
            name="acompanhanteGrauParentesco"
            v-model="pacienteSelecionado.acompanhanteGrauParentesco"
          />
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import empresas from "@/mixins/empresas";
import profissoes from "@/mixins/profissoes"
import modelos from "@/mixins/modelos"
import global from "@/mixins/global";

export default {
  mixins : [empresas, profissoes, modelos, global],
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      modo: "pacientes/getModo",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      empresas: [],
      profissoes: [],
      modelos: [],
    }
  },
  methods: {
    ...mapActions({
      setProfissoes: "profissoes/setProfissoes",
      setEmpresas: "empresas/setEmpresas",
      setModelos: "modelos/setModelos"
    }),
  },
  created() {
      this.carregarProfissoes(this.usuarioLogadoDados.tenant, 0);
      this.carregarEmpresas(this.usuarioLogadoDados.tenant, 0);
      this.carregarModelos(this.usuarioLogadoDados.tenant);
  },
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>