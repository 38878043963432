import errors from "@/utils/errors";

export default {
    data() {
        return {
            profissionalImpostos : [],
        }
    },
    methods: {
        carregarProfissionalImpostosPeloProfissional(tenant, profissionalSelecionadoId) {
            this.loadingProfissionalImpostos = true;
            this.loadedProfissionalImpostos = false;

            this.$http
              .get(`/v1.0/profissionalImpostos/${tenant}/Profissional/${profissionalSelecionadoId}`)
              .then(resp => {
                this.profissionalImpostos = resp.data;
                this.erro = "";
                this.loadedProfissionalImpostos = true;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingProfissionalImpostos = false;
              });
          },
          carregarProfissionalImpostosPeloServico(tenant, servicoSelecionadoId) {
            this.loadingProfissionalImpostos = true;
            this.loadedProfissionalImpostos = false;

            this.$http
              .get(`/v1.0/profissionalImpostos/${tenant}/Servico/${servicoSelecionadoId}`)
              .then(resp => {
                this.profissionalImpostos = resp.data;
                this.erro = "";
                this.loadedProfissionalImpostos = true;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingProfissionalImpostos = false;
              });
          },
    }
}
