<template>
  <div id="cadProfissionalGeral">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="datNascimento" class="control-label">Data Nascimento</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="datNascimento"
            name="datNascimento"
            v-model="profissionalGeralComplemento.datNascimento"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Sexo" class="control-label">Sexo</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Sexo"
            name="Sexo"
            selected
            v-model="profissionalGeralComplemento.sexo"
          >
            <option
              v-for="confirma in sexo"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="EstadoCivil" class="control-label">Estado Civil</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="EstadoCivil"
            name="EstadoCivil"
            selected
            v-model="profissionalGeralComplemento.estadoCivil"
          >
            <option
              v-for="confirma in estadoCivil"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label
            for="Naturalidade"
            class="control-label"
          >Naturalidade</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Naturalidade"
            name="Naturalidade"
            v-model="profissionalGeralComplemento.naturalidade"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="CPF" class="control-label">Número do CPF</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CPF"
            name="CPF"
            v-model="profissionalGeralComplemento.cpfNumero"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="RG" class="control-label">Número do RG</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="RG"
            name="RG"
            v-model="profissionalGeralComplemento.rgNumero"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="OrgaoEmissao" class="control-label">Org Emissor RG</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="OrgaoEmissao"
            name="OrgaoEmissao"
            v-model="profissionalGeralComplemento.rgOrgao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="rgEmissao" class="control-label">Data de Emissão RG</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="rgEmissao"
            name="rgEmissao"
            v-model="profissionalGeralComplemento.rgEmissao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="cnpj" class="control-label">Número do CNPJ (caso exista)</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="cnpj"
            name="cnpj"
            v-model="profissionalGeralComplemento.cnpjNumero"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datAdmissao" class="control-label">Data Admissão</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="datAdmissao"
            name="datAdmissao"
            v-model="profissionalGeralComplemento.datAdmissao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datDemissao" class="control-label">Data Demissão</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="datDemissao"
            name="datDemissao"
            v-model="profissionalGeralComplemento.datDemissao"
          />
        </div>
      </div>

      <hr style="margin:0px"/>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Cep" class="control-label">Cep</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cep"
            name="Cep"
            v-model="profissionalGeralComplemento.cep"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Logradouro" class="control-label">Logradouro</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Logradouro"
            name="Logradouro"
            v-model="profissionalGeralComplemento.logradouro"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="Numero" class="control-label">Número</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Numero"
            name="Numero"
            v-model.number="profissionalGeralComplemento.numero"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Complemento" class="control-label">Complemento</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Complemento"
            name="Complemento"
            v-model="profissionalGeralComplemento.complemento"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Bairro" class="control-label">Bairro</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Bairro"
            name="Bairro"
            v-model.number="profissionalGeralComplemento.bairro"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Cidade" class="control-label">Cidade</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cidade"
            name="Cidade"
            v-model="profissionalGeralComplemento.cidade"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="UF" class="control-label">UF</label>
          <select
            id="UF"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="profissionalGeralComplemento.uf"
          >
            <option v-for="uf in ufs" :key="uf.opcao" :value="uf.opcao">{{uf.valor}}</option>
          </select>
        </div>
      </div>

      <hr style="margin:0px"/>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="FoneDDD" class="control-label">DDD</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="FoneDDD"
            name="FoneDDD"
            v-model="profissionalGeralComplemento.foneDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Fone" class="control-label">Fone</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fone"
            name="Fone"
            v-model="profissionalGeralComplemento.fone"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="celularDDD" class="control-label">DDD</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celularDDD"
            name="celularDDD"
            v-model="profissionalGeralComplemento.celularDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="celular" class="control-label">Celular</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celular"
            name="celular"
            v-model="profissionalGeralComplemento.celular"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="whatsapp" class="control-label">WhatsApp</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="whatsapp"
            name="whatsapp"
            v-model="profissionalGeralComplemento.whatsapp"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-5">
          <label for="Email" class="control-label">E-mail</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Email"
            name="Email"
            v-model="profissionalGeralComplemento.email"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="DataTrava" class="control-label">Aviso Trava</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="DataTrava"
            name="DataTrava"
            selected
            v-model="profissionalGeralComplemento.avisarDataTrava"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="smsRecebe" class="control-label">Recebe SMS</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="smsRecebe"
            name="smsRecebe"
            selected
            v-model="profissionalGeralComplemento.smsRecebe"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="smsNome" class="control-label">Nome no SMS</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="smsNome"
            name="smsNome"
            v-model="profissionalGeralComplemento.smsNome"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="todasUnidades" class="control-label">Todas Unidades</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="todasUnidades"
            name="todasUnidades"
            selected
            v-model="profissionalGeralComplemento.todasUnidades"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>

      <hr style="margin:0px"/>

      <div class="row">
        <div class="form-group col-lg-10">
          <label for="observacao" class="control-label">Observações</label>
          <textarea rows="2" cols="80" class="form-control" :disabled="!liberarCampos" id="observacao" name="observacao" v-model="profissionalGeralComplemento.observacao"/>
        </div>
      </div>

      <hr />
      <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>

      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          :disabled="modo=='Alterar' || modo=='Incluir'"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Alterar</button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >Cancelar</button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Gravar</button>

        <button v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" @click="configurarDadosAtendimento()">Dados de Atendimento</button>
        <button class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" @click="configurarAcessoAoSistema()">Configurar Acesso ao Sistema</button>
      </div>
    </div>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import usuarios from '@/mixins/usuarios';
import perfis from "@/mixins/perfis";
import global from "@/mixins/global";
import profissionalComplementos from "@/mixins/profissionalComplementos";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";
import funcoes from "@/utils/funcoes";

export default {
  mixins : [usuarios, perfis, profissionalComplementos, global],
  computed: {
    ...mapGetters({
      modoUsuario: "usuarios/getModo",
      profissionalSelecionado : "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      perfilProfissionalSelecionado : "perfis/getPerfilSelecionado"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  },
  data() {
    return {
      erro: "",
      modo : "",
      mensagem : "",
      usuario : {},
      loadingProfissionalGeralComplemento : false,
      loadedProfissionalGeralComplemento : false,

      profissionalGeralComplemento : {},
      ufs: constantes.uf,
      sim_nao : constantes.sim_nao,
      sexo : constantes.sexo,
      estadoCivil : constantes.estadoCivil
    }
  },
  methods: {
    ...mapActions({
      setModoUsuario : "usuarios/setModo",
      setUsuarioSelecionado : "usuarios/setUsuarioSelecionado",
      setProfissionalGeralComplementoSelecionado : "profissionalComplementos/setProfissionalGeralComplementoSelecionado",
      setDefinirSenha : "usuarios/setDefinirSenha"
    }),
    retornar() {
      this.$router.go(-1);
    },
    configurarDadosAtendimento() {
      this.setProfissionalGeralComplementoSelecionado(this.profissionalGeralComplemento);
      this.$router.push("/cadProfissionalAtendimento");
    },
    async configurarAcessoAoSistema() {
      await this.carregarUsuarioPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);

      if (this.usuario.id != undefined) {
        this.setModoUsuario("Registro");
        this.setUsuarioSelecionado(this.usuario);''
        this.$router.push('/cadUsuario');
      } else {
        this.$bvModal
          .msgBoxConfirm("Esse Profissional não possui um Usuário para Acessar o Sistema. Criar Agora?", {
            title: "Atenção",
            size: "md",
            buttonSize: "sm",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              if (this.profissionalSelecionado.ativo != "S") {
                this.$toasted.show("É necessário Ativar o Profissional para realizar essa operação.", {type : 'info', icon : 'times'});
                return false;
              }

             let definirSenha = {
                 profissionalId : this.profissionalSelecionado.id,
                 usuarioId : null,
                 nome : this.profissionalSelecionado.nome,
                 email : this.profissionalSelecionado.email,
                 perfilId : this.profissionalSelecionado.perfilRaizId,
                 identity : null,
                 chamadoPor : "cadProfissionalGeral",
                 modo : "Incluir"
             }
 
             this.setDefinirSenha(definirSenha);
            this.$router.push('/frmDefinirSenha');
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      }
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.profissionalGeralComplemento.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/profissionalGeralComplementos/${this.usuarioLogadoDados.tenant}/${this.profissionalGeralComplemento.id}`,
            this.profissionalGeralComplemento
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.cancelarOperacao();
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.profissionalGeralComplemento.profissionalId = this.profissionalSelecionado.id;
        this.profissionalGeralComplemento.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/profissionalGeralComplementos/${this.usuarioLogadoDados.tenant}`, this.profissionalGeralComplemento)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.cancelarOperacao();
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
    },
    cancelarOperacao() {
      this.modo = "";
    },
    verificarCamposPreenchidos() {
      if (this.profissionalGeralComplemento.numero == 0) {
        this.$toasted.show("Digite o Número da Residência.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalGeralComplemento.email == "" || this.profissionalGeralComplemento.email == null) {
        this.$toasted.show("Digite o E-Mail do Profissional.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalGeralComplemento.avisarDataTrava == null) {
        this.$toasted.show("Selecione valor para Aviso DataTrava.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalGeralComplemento.smsRecebe == null) {
        this.$toasted.show("Selecione valor para Recebe SMS.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalGeralComplemento.todasUnidades == null) {
        this.$toasted.show("Selecione valor para Todas Unidades.", {type : 'info', icon : 'times'});
        return false;
      }
    
      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
  },
  async created() {
    this.showMenu(false);
    await this.carregarGeralPeloProfissional(this.usuarioLogadoDados.tenant ,this.profissionalSelecionado.id);

    if (this.profissionalGeralComplemento.id == undefined) {
      this.modo = "Incluir";

      this.profissionalGeralComplemento.smsRecebe = "N";
      this.profissionalGeralComplemento.sexo = "";
      this.profissionalGeralComplemento.estadoCivil = "";
      this.profissionalGeralComplemento.perfil = {realizaAtendimento : 'N'};
      this.profissionalGeralComplemento.todasUnidades = "N";
      this.profissionalGeralComplemento.avisarDataTrava = "N";
    } else {
      this.modo = "";

      this.profissionalGeralComplemento.datNascimento = funcoes.mySqlToJSDateFormat(this.profissionalGeralComplemento.datNascimento);
      this.profissionalGeralComplemento.datAdmissao = funcoes.mySqlToJSDateFormat(this.profissionalGeralComplemento.datAdmissao);
      this.profissionalGeralComplemento.datDemissao = funcoes.mySqlToJSDateFormat(this.profissionalGeralComplemento.datDemissao);
      this.profissionalGeralComplemento.emissao = funcoes.mySqlToJSDateFormat(this.profissionalGeralComplemento.emissao);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
