<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Faturamento</span>
      </div>
      <div class="coluna3"/>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2" style="margin-left:0px">
          <label for="Operadoras" class="control-label">Operadoras</label>
          <select id="Operadoras" v-model="operadoraId" class="form-control" selected>
            <option
              v-for="operadora in operadoras"
              :key="operadora.id"
              :value="operadora.id"
            >{{operadora.fantasia}}</option>
          </select>
        </div>

        <div class="form-group col-lg-3" style="margin-left:0px">
          <label for="Faturas" class="control-label">Faturas {{faturaId == 0 ? "" : faturaSelecionada.datFechamento == null ? "(Aberta)" : "(Fechada)"}}</label>
          <select id="Faturas" v-model="faturaId" class="form-control" selected>
            <option
              v-for="fatura in faturas"
              :key="fatura.id"
              :value="fatura.id"
            >{{fatura.id}} - {{fatura.descricao}} - {{fatura.datInicial | formatDate}} à {{fatura.datFinal | formatDate}}</option>
          </select>
        </div>

        <div class="form-group col-lg-3" style="margin-left:0px">
          <label for="Lotes" class="control-label">
            Lotes {{loteId == 0 ? "" : loteSelecionado.datFechamento == null ? "(Aberto)" : "(Fechado)"}} - {{guias.length}} Guia(s)
          </label>
          <select id="Lotes" v-model="loteId" class="form-control" selected>
            <option
              v-for="lote in lotes"
              :key="lote.id"
              :value="lote.id"
            >{{lote.id}} - {{lote.codLote}} - {{lote.tipoLote == 1 ? "Consultas" : lote.tipoLote == 2 ? "SADT" : "Honorários"}} - {{lote.descricao}}</option>
          </select>
        </div>

        <!-- Controle de Faturas -->
        <template v-if="faturaId>0">
          <button class="btn btn-sm btn-warning mr-1" style="margin-top:24px;height:38px;" @click="opcoesDeFatura()">Opções Fatura</button>
        </template>
        <!-- Controle de Lotes -->
        <template v-if="loteId>0">
          <button class="btn btn-sm btn-warning mr-1" style="margin-top:24px;height:38px;" @click="opcoesDeLote()">Opções Lote</button>
        </template>

        <button class="btn btn-sm btn-info mr-1" style="margin-top:24px;height:38px;" @click="controleFaturasLotes()">Criar Faturas/Lotes</button>

        <template v-if="alteracoes>0">
          <span style="margin-top:30px;">({{alteracoes}})</span>
        </template>
      </div>
      <!-------------------------------------------- Operações de Lote --------------------------------------->
      <template v-if="modo=='opcoesLote'">
        <div class="row justify-content-center">
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Sequência:</Strong> {{loteSelecionado.sequencia}}</label>
          </div>
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Descricao:</Strong> {{loteSelecionado.descricao}}</label>
          </div>
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Protocolo:</Strong> {{loteSelecionado.protocolo}}</label>
          </div>
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Total Enviado:</Strong> {{loteSelecionado.totalEnviado}}</label>
          </div>
          <div class="form-control col-lg-4">
            <label class="control-label"><Strong>XML:</Strong> {{loteSelecionado.xml}}</label>
          </div>
        </div>
        <div class="row justify-content-center mt-2 mb-2">
          <div class="form-control-lg-6">
              <template v-if="loteSelecionado.datFechamento==null">
                <template v-if="faturaSelecionada.datFechamento==null && loteSelecionado.datFechamento==null">
                  <button class="btn btn-sm btn-info mr-1" @click="periodoLancamentos('A')">Importar Guias</button>
                </template>
                <template v-if="guias.length>0 && faturaSelecionada.datFechamento==null && loteSelecionado.datFechamento==null">
                  <button class="btn btn-sm btn-warning mr-1" @click="periodoLancamentos('T')">Transferir Guias</button>
                </template>
                <template v-if="guias.length>0 && faturaSelecionada.datFechamento==null && loteSelecionado.datFechamento==null">
                  <button class="btn btn-sm btn-danger mr-3" @click="periodoLancamentos('R')">Remover Guias</button>
                </template>

                <template v-if="guias.length>0">
                  <button class="btn btn-sm btn-warning ml-3 mr-1" @click="fecharReabrirLote('F')">Fechar Lote</button>
                </template>

                <template v-if="guias.length==0">
                  <button class="btn btn-sm btn-danger ml-3 mr-1" @click="excluirLote()">Excluir Lote</button>
                </template>
              </template>
              <template v-else>
                <button class="btn btn-sm btn-warning mr-1" @click="fecharReabrirLote('R')">Reabrir Lote</button>
                <button class="btn btn-sm btn-warning mr-1" @click="alterarSequenciaLote()">Alterar Sequência</button>
                <button class="btn btn-sm btn-warning mr-1" @click="gerarXML()">Gerar XML</button>
              </template>
              <b-button v-b-tooltip.hover title="Fechar Barra de Opções de Lotes" class="ml-4" variant="info" size="sm" @click="modo=''">
                <i class="fa fa-close"/>
              </b-button>
          </div>
        </div>
      </template>
      <!-------------------------------------------- Operações de Fatura --------------------------------------->
      <template v-if="modo=='opcoesFatura'">
        <div class="row justify-content-center mb-2">
          <div class="form-control-lg-6">
            <template v-if="faturaSelecionada.datFechamento==null">
                <button class="btn btn-sm btn-warning mr-1" @click="fecharReabrirFatura('F')">Fechar Fatura</button>
                <template v-if="lotes.length==0">
                  <button class="btn btn-sm btn-danger mr-1" @click="excluirFatura()">Excluir Fatura</button>
                </template>
              </template>
              <template v-else>
                <button class="btn btn-sm btn-warning mr-1" @click="fecharReabrirFatura('R')">Reabrir Fatura</button>
              </template>
              <b-button v-b-tooltip.hover title="Fechar Barra de Opções de Faturas" class="ml-4" variant="info" size="sm" @click="modo=''">
                <i class="fa fa-close"/>
              </b-button>
          </div>
        </div>
      </template>
      <!-- Selecionou Guia -->
      <template v-if="guiaSelecionada!=null">
        <div class="row justify-content-center">
          <div class="form-control col-lg-4">
            <label class="control-label"><Strong>Paciente:</Strong> {{guiaSelecionada.paciente}}</label>
          </div>
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Atendimento:</Strong> {{guiaSelecionada.datAtendimento | formatDate}}</label>
          </div>
          <div class="form-control col-lg-3">
            <label class="control-label"><Strong>Profissional:</Strong> {{guiaSelecionada.profissionalExecutante}}</label>
          </div>
          <div class="form-control col-lg-2">
            <label class="control-label"><Strong>Nº Guia:</Strong> {{guiaSelecionada.guiaOperadora}}</label>
          </div>
        </div>

        <div class="row barraBotoes justify-content-center mt-1 mb-1">
          <!-- <span style="margin-right:10px">Operações -></span> -->
          <b-button id="btnPaciente" v-if="lOperacoesLote" variant="info" class="mr-1"
              v-b-tooltip.hover title="Cadastro do Paciente" size="sm" @click="cadastroPaciente()">
              <i class="fa fa-user"/>
          </b-button>
          <b-button id="btnInformacoes" v-if="lOperacoesLote" variant="info" class="mr-1"
              v-b-tooltip.hover title="Informações sobre a Guia" size="sm" @click="informacoesGuia()">
              <i class="fa fa-info"/>
          </b-button>
          <b-button id="btnEditarGuia" v-if="lOperacoesLote" variant="warning" class="mr-1"
              v-b-tooltip.hover title="Editar Guia Selecionada" size="sm" @click="editarGuiaSelecionada()">
              <i class="fa fa-pencil"/>
          </b-button>
          <b-button id="btnTransferirGuia" v-if="lOperacoesLote" variant="warning" class="mr-1"
              v-b-tooltip.hover title="Transferir Guia Selecionada" size="sm" @click="transferirGuiaSelecionada()">
              <i class="fa fa-exchange"/>
          </b-button>
          <b-button id="btnAlterarStatus" v-if="lOperacoesLote" variant="warning" class="mr-1"
              v-b-tooltip.hover title="Marcar/Desmarcar Guia como Verificada" size="sm" @click="alterarStatus()">
              <i class="fa fa-undo"/>
          </b-button>
          <b-button id="btnRemoverGuia" v-if="lOperacoesLote" variant="danger" class="mr-1"
              v-b-tooltip.hover title="Remover Guia Selecionada do Lote" size="sm" @click="removerGuiaSelecionada()">
              <i class="fa fa-close"/>
          </b-button>
          <b-button id="btnExcluirGuia" v-if="lOperacoesLote" variant="danger" class="mr-1"
              v-b-tooltip.hover title="Excluir (definitivamente) Guia Selecionada" size="sm" @click="excluirGuiaSelecionada()">
              <i class="fa fa-trash"/>
          </b-button>
        </div>
      </template>
      <!-- Operações de Importar / Transferir / Remover Guia do Lote -->
      <div class="registro" v-if="operacaoSelecionada=='A' || operacaoSelecionada=='T' || operacaoSelecionada=='R' || operacaoSelecionada=='TS'">
        <p style="text-align:center"><Strong>{{descricaoOperacaoSelecionada}}</Strong></p>
        <div v-if="operacaoSelecionada!='TS'" class="row justify-content-center">
          <div class="form-group col-lg-2">
            <label for="profissional" class="control-label">Profissional Executante</label>
            <select id="profissional" v-model="profissionalId" class="form-control">
              <option v-for="profissional in profissionaisExecutantes" :key="profissional.id" :value="profissional.id">
                {{profissional.nome}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-1">
            <label for="seriados" class="control-label">Seriados</label>
            <select id="seriados" v-model="apenasSeriados" class="form-control">
              <option v-for="resposta in tiposAtendimentos" :key="resposta.opcao" :value="resposta.opcao">
                {{resposta.valor}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="datInicial" class="control-label">Lançamentos Data Inicial</label>
            <input type="date" id="datInicial" class="form-control" v-model="datInicial">
          </div>
          <div class="form-group col-lg-2">
            <label for="datFinal" class="control-label">Lançamentos Data Final</label>
            <input type="date" id="datFinal" class="form-control" v-model="datFinal">
          </div>
          <div class="form-group col-lg-1" v-if="operacaoSelecionada!='A'">
            <label for="apartirDe" class="control-label">A Partir</label>
            <input type="number" id="apartirDe" class="form-control" v-model.number="apartirDe">
          </div>
        </div>
        <!-- Transferir para Outro Lote - Início -->
        <template v-if="operacaoSelecionada=='T' || operacaoSelecionada=='TS'">
          <div class="row justify-content-center">
            <div class="form-group col-lg-3">
              <label for="faturaDestino" class="control-label">Fatura Destino</label>
              <select id="faturaDestino" v-model="faturaDestinoId" class="form-control">
                <option v-for="fatura in faturasAbertas" :key="fatura.id" :value="fatura.id">
                  {{fatura.id}} - {{fatura.descricao}} - {{fatura.datInicial | formatDate}} à {{fatura.datFinal | formatDate}}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="loteDestino" class="control-label">Lote Destino</label>
              <select id="loteDestino" v-model="loteDestinoId" class="form-control">
                <option v-for="lote in lotesAbertosExceto" :key="lote.id" :value="lote.id">
                  {{lote.id}} - {{lote.codLote}} - {{lote.tipoLote == 1 ? "Consultas" : lote.tipoLote == 2 ? "SADT" : "Honorários"}} - {{lote.descricao}}
                </option>
              </select>
            </div>
          </div>
        </template>
        <!-- Transferir para Outro Lote - Fim -->
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="definirLote()">Confirmar
          </button>
        </div>
      </div>
      <hr />
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <!-- Tabela de Guias do Paciente -->
      <div style="display:flex; flex-direction:row; justify-content:center; max-height:600px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" hover striped small sort-icon-left 
          :items="guias" :fields="camposTabela"
          @row-clicked="selecionouGuia" @row-dblclicked="editarGuiaSelecionada">
          <template v-slot:cell(datAtendimento)="datAtendimento">
              {{ datAtendimento.value | formatDate}}
          </template>    
          <template v-slot:cell(datAutorizacao)="datAutorizacao">
              {{ datAutorizacao.value | formatDate}}
          </template>    
          <template v-slot:cell(datValidadeSenha)="datValidadeSenha">
              {{ datValidadeSenha.value | formatDate}}
          </template>    
          <template v-slot:cell(datCadastro)="datCadastro">
              {{ datCadastro.value | formatDate}}
          </template>    
          <template v-slot:cell(tipoLote)="tipoLote">
              {{ tipoLote.value == 1 ? "Cons" : tipoLote.value == 2 ? "SADT" : "Hon."}}
          </template>    
          <template v-slot:cell(particular)="particular">
              {{ particular.value == "S" ? "Sim" : ""}}
          </template>    

          <template v-slot:cell(atendimentoSeriado)="atendimentoSeriado">
            {{atendimentoSeriado.value == "S" ? "Sim" : null}}
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import operadoras from "@/mixins/operadoras"
import faturas from "@/mixins/faturas"
import lotes from "@/mixins/lotes"
import guias from "@/mixins/guias"
import profissionais from "@/mixins/profissionais"
import global from "@/mixins/global"
import funcoes from "@/utils/funcoes"
import constantes from "@/utils/constantes"
import errors from "@/utils/errors"
import pacientes from "@/mixins/pacientes";
import { mapActions, mapGetters } from 'vuex'

export default {
    mixins : [operadoras, faturas, lotes, guias, profissionais, pacientes, global],
    data() {
      return {
        modo : "",
        profissionalId : 0,
        operadoraId : 0,
        faturaId : 0,
        loteId : 0,
        faturaDestinoId : 0,
        loteDestinoId : 0,
        apartirDe : 0,
        datInicial : null,
        datFinal : null,
        descricaoOperacaoSelecionada : "",
        erro : "",
        mensagem : "",
        loading : true,
        apenasSeriados : "N",
        operacaoSelecionada : "",
        faturaSelecionada : {},
        loteSelecionado : {},
        guiaSelecionada : null,
        sim_nao : constantes.sim_nao,
        retornando : false,
        operadoraSelecionada : {},
        tiposAtendimentos : [
          {opcao : "S", valor : "Sim"},
          {opcao : "N", valor : "Não"},
          {opcao : "T", valor : "Todos"},
        ],
        camposTabela : [
          {key : "id", label : "Id"},
          {key : "datAtendimento", label : "Atendimento", sortable : true},
          {key : "paciente", label : "Paciente", sortable : true},
          {key : "atendimentoSeriado", label : "Seriado", sortable : true},
          {key : "profissionalExecutante", label : "Profissional Executante", sortable : true},
          {key : "guiaOperadora", label : "Guia Operad"},
          {key : "guiaPrestador", label : "Guia Prest"},
          {key : "planoSaude", label : "Plano de Saúde", sortable : true},
          {key : "particular", label : "Particular"},
          {key : "reciboMedico", label : "Nº Rec Méd"},
        ],
        profissionaisExecutantes : [],
      }
    },
    computed: {
      ...mapGetters({
        ultimaBusca : "faturamentos/getUltimaBusca",
        alteracoes : "faturamentos/getAlteracoes",
        ultimaGuiaSalva : "guias/getUltimaGuiaSalva",
        usuarioLogadoDados : "global/getUsuarioLogadoDados",
        parametrosLanctoGuia : "guias/getParametrosLanctoGuia",
      }),
      lOperacoesFatura() {
        return this.faturaSelecionada.datFechamento == null
      },
      lOperacoesLote() {
        return this.loteSelecionado.datFechamento == null
      },
      lotesAbertosExceto() {
        return this.lotesAbertos.filter(l => l.id!=this.loteId && l.tipoLote==this.loteSelecionado.tipoLote)
      }
    },
    watch: {
      operadoraId() {
        if (this.operadoraId>0) {
          if (this.ultimaBusca.operadoraId == undefined || this.ultimaBusca.operadoraId==0) {
            this.carregarFaturasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
            this.faturaId = 0;
            this.loteId = 0;
  
            this.lotes = {};

            let indice = this.operadoras.findIndex(operadora => operadora.id == this.operadoraId);
            this.operadoraSelecionada = {...this.operadoras[indice]};
          }
        }
      },
      faturaId() {
        if (this.faturaId>0) {
          if (this.ultimaBusca.faturaId == undefined || this.ultimaBusca.faturaId==0) {
            this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaId);
            this.loteId = 0;
          }
          this.faturaSelecionada = this.faturas.filter(f => f.id==this.faturaId)[0];
        }
      },
      async loteId() {
        if (this.loteId>0) {
          if (this.ultimaBusca.loteId == undefined || this.ultimaBusca.loteId==0) {
            this.loading = true;
            await this.carregarGuiasDoLote(this.usuarioLogadoDados.tenant, this.loteId, "T");
            this.loading = false;
          } else {
            if (this.alteracoes % 5==0) {
              this.loading = true;
              await this.carregarGuiasDoLote(this.usuarioLogadoDados.tenant, this.loteId, "T");
              this.loading = false;
            } else {
              this.guias = [...this.ultimaBusca.guias];

              if (this.guias!=null) {
                this.destacarLinhasGuiasLote();
              }
            }
            this.limparRegistro();
          }
          this.loteSelecionado = this.lotes.filter(l => l.id==this.loteId)[0];
        }
      },
      faturaDestinoId() {
        if (this.faturaDestinoId>0) {
          this.carregarLotesAbertosByFatura(this.usuarioLogadoDados.tenant, this.faturaDestinoId);
          this.loteDestinoId = 0;
        }
      }
    },
    methods : {
      ...mapActions({
        setGuiaSelecionada : "guias/setGuiaSelecionada",
        setGuiaChamadoPor : "guias/setChamadoPor",
        setPlanoSaudeSelecionado: "planosSaude/setPlanoSaudeSelecionado",
        setUltimaBusca : "faturamentos/setUltimaBusca",
        setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
        setUltimaGuiaSalva : "guias/setUltimaGuiaSalva",
        setPacienteSelecionado : "pacientes/setPacienteSelecionado",
        setPacienteModulo : "pacientes/setModulo",
        adicionarAlteracoes : "faturamentos/adicionarAlteracoes",
        limparRegistro : "faturamentos/limparRegistro",
        formLeave : "faturamentos/formLeave",
      }),
      
      retornar() {
        this.retornando = true;
        this.$router.go(-1);
      },
      opcoesDeLote() {
        this.modo='opcoesLote';
        this.guiaSelecionada = null;
      },
      opcoesDeFatura() {
        this.modo='opcoesFatura';
        this.guiaSelecionada = null;
      },
      async periodoLancamentos(operacao) {
        this.datInicial = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datInicial);
        this.datFinal = funcoes.mySqlToJSDateFormat(this.faturaSelecionada.datFinal);
        
        if (this.profissionaisExecutantes.length == 0) {
          this.loading = true;
          await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);
          this.profissionaisExecutantes = [...this.profissionais];
          this.profissionaisExecutantes.push({id : 0, nome : "Todos"});

          this.profissionais = [];
          this.loading = false;
        }

        if (operacao=="A") {
          this.descricaoOperacaoSelecionada='Importar Guias para esse Lote';
        } else if (operacao=="T") {
          this.descricaoOperacaoSelecionada='Transferir Guias dessa para outra Fatura/Lote';
        } else {
          this.descricaoOperacaoSelecionada='Remover a Guia desse Lote';
        }

        this.operacaoSelecionada = operacao;
        this.guiaSelecionada = null;

        if (operacao=="T") {
          this.carregarFaturasPelaOperadoraParaTransferencia(this.usuarioLogadoDados.tenant, this.operadoraId, this.faturaSelecionada.id);
        }
      },
      cancelarOperacao() {
        this.descricaoOperacaoSelecionada="";
        this.operacaoSelecionada = "";
        this.loteDestinoId = 0;
        this.faturaDestinoId = 0;
        this.erro = "";
        //this.faturasAbertas = null;
        //this.lotesAbertos = null;
      },
      definirLote() {
        if((this.operacaoSelecionada=="T" || this.operacaoSelecionada=="TS") && this.loteId == this.loteDestinoId) {
          this.$toasted.show("Lote Destino não pode ser igual ao Lote Origem", {type: "info", icon : "times"})
          return;
        }

        let adicionarGuiaAoLote = {
          operadoraId : this.operadoraId,
          tipoLote : this.loteSelecionado.tipoLote,
          profissionalExecutanteId : this.profissionalId,
          atendimentoSeriado : this.apenasSeriados,
          datInicial : this.datInicial,
          datFinal : this.datFinal,
          operacao : this.operacaoSelecionada,
          operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
        }

        if (this.operacaoSelecionada=="A") {
          adicionarGuiaAoLote.id = 0;
          adicionarGuiaAoLote.loteOrigemId = 0;
          adicionarGuiaAoLote.loteDestinoId = this.loteId;
          adicionarGuiaAoLote.apartirDe = 0;
        } else if (this.operacaoSelecionada=="T") {
          adicionarGuiaAoLote.id = 0;
          adicionarGuiaAoLote.apartirDe = this.apartirDe;
          adicionarGuiaAoLote.loteOrigemId = this.loteId;
          adicionarGuiaAoLote.loteDestinoId = this.loteDestinoId;
        } else if (this.operacaoSelecionada=="R") {
          adicionarGuiaAoLote.id = 0;
          adicionarGuiaAoLote.loteOrigemId = this.loteId;
          adicionarGuiaAoLote.loteDestinoId = this.loteId;
          adicionarGuiaAoLote.apartirDe = this.apartirDe;
        } else if (this.operacaoSelecionada=="TS") {
          adicionarGuiaAoLote.id = this.guiaSelecionada.id;
          adicionarGuiaAoLote.apartirDe = 0;
          adicionarGuiaAoLote.loteOrigemId = this.loteId;
          adicionarGuiaAoLote.loteDestinoId = this.loteDestinoId;
          adicionarGuiaAoLote.operacao = "T";
        } else if (this.operacaoSelecionada=="RS") {
          adicionarGuiaAoLote.id = this.guiaSelecionada.id;
          adicionarGuiaAoLote.loteOrigemId = this.loteId;
          adicionarGuiaAoLote.loteDestinoId = this.loteId;
          adicionarGuiaAoLote.apartirDe = 0;
          adicionarGuiaAoLote.operacao = "R";
        } else {
          this.$toasted.show(`Operação ${this.operacaoSelecionada} não definida.`, {type : "info", icon : "times"});
          return;
        }

        this.$http.put(`/v1.0/guias/${this.usuarioLogadoDados.tenant}/definirLote/${adicionarGuiaAoLote.id}`, adicionarGuiaAoLote)
        .then((resp) => {
          this.erro = "";
          this.apartirDe = 0;
          this.descricaoOperacaoSelecionada="";
          this.operacaoSelecionada = "";

          this.$toasted.show(resp.data, {type : "success", icon : "check"});
          
          if (this.operacaoSelecionada=="RS") {
            this.guiaSelecionada = [];
          }
          this.carregarGuiasDoLote(this.usuarioLogadoDados.tenant, this.loteId, "T");
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
      },
      selecionouGuia(guiaSelecionada) {
        this.guiaSelecionada = {...guiaSelecionada}
        this.descricaoOperacaoSelecionada = "";
        this.modo = "";
      },
      editarGuiaSelecionada() {
        this.guiaSelecionada.operadoraId = this.operadoraId;
        this.guiaSelecionada.registroAns = this.operadoraSelecionada.registroAns;
        this.guiaSelecionada.datAtendimento = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datAtendimento);
        this.guiaSelecionada.datValidadeCarteirinha = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datValidadeCarteirinha);
        this.guiaSelecionada.datAutorizacao = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datAutorizacao);
        this.guiaSelecionada.datValidadeSenha = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datValidadeSenha);
        this.guiaSelecionada.datSolicitacao = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datSolicitacao);
        this.guiaSelecionada.datEmissao = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datEmissao);
        this.guiaSelecionada.datInicioFaturamento = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datInicioFaturamento);
        this.guiaSelecionada.datFimFaturamento = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datFimFaturamento);
        this.setGuiaSelecionada(this.guiaSelecionada);

        if (this.guiaSelecionada.tipoLote==1) {
          this.$router.push("/frmGuiaConsulta");
        } else if (this.guiaSelecionada.tipoLote==2) {
          this.$router.push("/frmGuiaSADT");
        } else if (this.guiaSelecionada.tipoLote==6) {
          this.$router.push("/frmGuiaHonorario");
        } else {
          this.$toasted.show("Tipo de Guia Inválido", {type: "info", icon: "times"});
        }
      },
      removerGuiaSelecionada() {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Remover a Guia Selecionada do Lote?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.operacaoSelecionada = "RS";
              this.definirLote();
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      excluirGuiaSelecionada() {
        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Excluir a Guia Selecionada?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/guias/${this.usuarioLogadoDados.tenant}/${this.guiaSelecionada.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";
                  this.carregarGuiasDoLote(this.usuarioLogadoDados.tenant, this.loteId, "T");
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      transferirGuiaSelecionada() {
        if (!this.verificarPermissao('Alterar')) {
          return
        }
        
        this.operacaoSelecionada = "TS";
        this.descricaoOperacaoSelecionada = "Transferir a Guia Selecionada";
        //this.carregarFaturasPelaOperadoraAbertas(this.usuarioLogadoDados.tenant, this.operadoraId);

        this.carregarFaturasPelaOperadoraParaTransferencia(this.usuarioLogadoDados.tenant, this.operadoraId, this.faturaSelecionada.id);
      },
      alterarStatus() {
        let status = this.guiaSelecionada.status != "V" ? "V" : "N";
        this.alterarStatusGuia(this.usuarioLogadoDados.tenant ,this.guiaSelecionada.id, status, this.usuarioLogadoDados.profissionalId);
      },
      async cadastroPaciente() {
        await this.carregarPacienteRegistrarAcesso(this.usuarioLogadoDados.tenant, this.guiaSelecionada.pacienteId, this.usuarioLogadoDados.profissionalId);

        this.paciente.datNascimento = funcoes.mySqlToJSDateFormat(this.paciente.datNascimento);
            
        this.setPacienteSelecionado(this.paciente);
        this.erro = "";
        this.setPacienteModulo("");
        this.$router.push('/cadPaciente');
      },
      informacoesGuia() {
        let parametros = {
                          paciente : {id : this.guiaSelecionada.pacienteId, nome : this.guiaSelecionada.paciente.nome},
                          profissionalExecutanteId : this.guiaSelecionada.profissionalExecutanteId, 
                          profissionalSolicitanteId : this.guiaSelecionada.profissionalSolicitanteId, 
                          operadoraId : this.guiaSelecionada.planoSaude.operadoraId,
                          planoSaudeId : this.guiaSelecionada.planoSaudeId,
                          tipoLote : this.guiaSelecionada.tipoLote, guiaOperadora : null,
                          datAutorizacao : this.guiaSelecionada.datAutorizacao, 
                          senhaAutorizacao : this.guiaSelecionada.senhaAutorizacao, datValidadeSenha : this.guiaSelecionada.datValidadeSenha,

                          datAtendimento : funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datAtendimento)
                        }

        this.setParametrosLanctoGuia(parametros);

        this.setGuiaSelecionada(this.guiaSelecionada);
        this.setPlanoSaudeSelecionado()
        this.setGuiaChamadoPor("Faturamento");
        this.$router.push('/frmLanctoGuiaItens');
      },
      controleFaturasLotes() {
        this.$router.push('/frmFaturas');
      },
      fecharReabrirLote(operacao) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        let mensagemConfirmacao = "";

        if (operacao == 'F') {
          var guiasNaoVerificadas=0;
  
          this.guias.forEach(guia => {
            if (guia.status==null) {
              guiasNaoVerificadas++;
            }
          });
  
          if (guiasNaoVerificadas>0) {
            this.$toasted.show(`Lote não pode ser fechado pois ainda possui ${guiasNaoVerificadas} guia(s) não Verificada(s).`, {type: "info", icon : "times"});
            return
          }

          mensagemConfirmacao = "Fechar o Lote Selecionado?";
        } else {
          mensagemConfirmacao = "Reabrir o Lote Selecionado?";
        }

        this.$bvModal
          .msgBoxConfirm(mensagemConfirmacao, {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              /*
                  [
                      {op : "replace", path : "/DatFechamento", value : data do dia}
                      {op : "replace", path : "/OperadorAlteracaoId", value : 10}
                  ]
              */

              let data = operacao == "F" ? new Date() : null;

              let patchJSON = [
                                {op : "replace", path : "/DatFechamento", value : data},
                                {op : "replace", path : "/OperadorAlteracaoId", value : this.usuarioLogadoDados.profissionalId}
                              ];

              this.$http
                .patch(`v1.0/lotes/${this.usuarioLogadoDados.tenant}/fecharReabrirLote/${this.loteSelecionado.id}`, patchJSON)
                .then(() => {
                  this.erro = "";

                  //this.loteSelecionado.datFechamento = new Date();
                  this.loteSelecionado.datFechamento = data;
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      //reabrirLote() {
      //  if (!this.verificarPermissao('Alterar')) {
      //    return
      //  }
//
      //  this.$bvModal
      //    .msgBoxConfirm("Reabrir o Lote Selecionado?", {
      //      title: "Atenção",
      //      size: "sm",
      //      buttonSize: "sm",
      //      okVariant: "danger",
      //      okTitle: "Sim",
      //      cancelTitle: "Não",
      //      footerClass: "p-2",
      //      hideHeaderClose: true,
      //      centered: true
      //    })
      //    .then(value => {
      //      if (value) {
      //        let fecharReabrirLote = {id : this.loteId, operacao : "R", operadorAlteracaoId : this.usuarioLogadoDados.profissionalId};
//
      //        this.$http
      //          .put(`/v1.0/lotes/${this.usuarioLogadoDados.tenant}/fecharReabrirLote/${fecharReabrirLote.id}`, fecharReabrirLote)
      //          .then(() => {
      //            this.erro = "";
      //            this.loteSelecionado.datFechamento = null;
      //            this.$toasted.global.defaultSuccess();
      //          })
      //          .catch(error => {
      //            this.erro = errors.tratarErros(error);
      //          })
      //          .finally(() => {});
      //      }
      //    })
      //    .catch(error => {
      //      this.erro = errors.tratarErros(error);
      //    });
      //},
      alterarSequenciaLote() {

      },
      gerarXML() {

      },
      excluirLote() {
        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Excluir Lote Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/lotes/${this.usuarioLogadoDados.tenant}/${this.loteId}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                    this.erro = "";
                    this.$toasted.global.defaultSuccess();
                    this.loteId = 0;
                    this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaId);
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      fecharReabrirFatura(operacao) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        let mensagemConfirmacao = '';

        if (operacao == "F") {
          var lotesAbertos=0;
  
          this.lotes.forEach(lote => {
            if (lote.datFechamento==null) {
              lotesAbertos++;
            }
          });
  
          if (lotesAbertos>0) {
            this.$toasted.show(`Fatura não pode ser fechada pois ainda possui ${lotesAbertos} Lotes Abertos.`, {type: "info", icon : "times"});
            return
          }

          mensagemConfirmacao = "Fechar a Fatura Selecionada?"
        } else {
          mensagemConfirmacao = "Reabrir a Fatura Selecionada?"
        }

        this.$bvModal
          .msgBoxConfirm(mensagemConfirmacao, {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              /*
                  [
                      {op : "replace", path : "/DatFechamento", value : data do dia}
                      {op : "replace", path : "/OperadorAlteracaoId", value : 10}
                  ]
              */

              let data = operacao == "F" ? new Date() : null;

              let patchJSON = [
                                {op : "replace", path : "/DatFechamento", value : data},
                                {op : "replace", path : "/OperadorAlteracaoId", value : this.usuarioLogadoDados.profissionalId}
                              ];

              this.$http
                .patch(`/v1.0/faturas/${this.usuarioLogadoDados.tenant}/fecharReabrirFatura/${this.faturaSelecionada.id}`, patchJSON)
                .then(() => {
                  this.erro = "";

                  this.faturaSelecionada.datFechamento = data;
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      //reabrirFatura() {
      //  if (!this.verificarPermissao('Alterar')) {
      //    return
      //  }
//
      //  this.$bvModal
      //    .msgBoxConfirm("Reabrir a Fatura Selecionada?", {
      //      title: "Atenção",
      //      size: "sm",
      //      buttonSize: "sm",
      //      okVariant: "danger",
      //      okTitle: "Sim",
      //      cancelTitle: "Não",
      //      footerClass: "p-2",
      //      hideHeaderClose: true,
      //      centered: true
      //    })
      //    .then(value => {
      //      if (value) {
      //        let fecharReabrirFatura = {id : this.faturaId, operacao : "R"}
//
      //        this.$http
      //          .put(`/v1.0/faturas/${this.usuarioLogadoDados.tenant}/fecharReabrirFatura/${fecharReabrirFatura.id}`, fecharReabrirFatura)
      //          .then(() => {
      //            this.erro = "";
//
      //            this.faturaSelecionada.datFechamento = null;
      //            this.$toasted.global.defaultSuccess();
      //          })
      //          .catch(error => {
      //            this.erro = errors.tratarErros(error);
      //          })
      //          .finally(() => {});
      //      }
      //    })
      //    .catch(error => {
      //      this.erro = errors.tratarErros(error);
      //    });
      //},
      verificarPermissao(operacao) {
        if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
          let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

          this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
          this.$bvModal.show("modal-mensagem");

          return false;
        }

        return true;
      }
    },
    created() {
      this.showMenu(false);
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);
    },
    mounted() {
        if (this.ultimaBusca.operadoraId != undefined && this.ultimaBusca.operadoraId!=0) {
          this.operadoras = [...this.ultimaBusca.operadoras];
          this.faturas = [...this.ultimaBusca.faturas];
          this.lotes = [...this.ultimaBusca.lotes];

          this.operadoraId = this.ultimaBusca.operadoraId;
          this.faturaId = this.ultimaBusca.faturaId;
          this.loteId = this.ultimaBusca.loteId;

          this.operadoraSelecionada = {...this.operadoras.filter(operadora => operadora.id == this.operadoraId)[0]};

          if (this.ultimaGuiaSalva!=null) {
            this.ultimaBusca.guias[this.ultimaBusca.guias.findIndex(i => i.id==this.ultimaGuiaSalva.id)] = {...this.ultimaGuiaSalva}
          }
        }
    },
    beforeDestroy() {
      if (this.retornando) {
        this.formLeave();
      } else {
        let registroUltimaBusca = {operadoras : this.operadoras, operadoraId : this.operadoraId, 
                                   faturas : this.faturas, faturaId : this.faturaId, 
                                   lotes : this.lotes, loteId : this.loteId,
                                   guias : this.guias}
        this.setUltimaBusca(registroUltimaBusca);
      }
    }
}
</script>

<style scoped>
  hr {
    margin-top : 0px;
    margin-bottom: 0px;
  }

  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
