import errors from "@/utils/errors";

export default {
    data() {
        return {
            consultorioItens : [],
            consultorioItensParcial : []
        }
    },
    methods : {
        carregarConsultorioItens(tenant, consultorioId) {
            this.$http.get(`/v1.0/consultorioItens/${tenant}/Consultorio/${consultorioId}`)
            .then(resp => {
                this.consultorioItens = resp.data;
                this.loading = false;
                this.erro= ""
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        carregarConsultorioItensParcial(tenant, consultorioId) {
            this.$http.get(`/v1.0/consultorioItens/${tenant}/ConsultorioParcial/${consultorioId}`)
            .then(resp => {
                this.consultorioItensParcial = resp.data;
                this.loading = false;
                this.erro= ""
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        carregarConsultorioItem(tenant, id) {
            this.$http.get(`/v1.0/consultorioItens/${tenant}/${id}`)
            .then(resp => {
                this.erro = "";
                this.consultorioItem = resp.data;
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
