const indicadorAcidente = [
    {opcao : "0", valor : "Acidente ou doença relacionada a trabalho"},
    {opcao : "1", valor : "Acidente ou doença relacionada a trânsito"},
    {opcao : "2", valor : "Outros"},
    {opcao : "9", valor : "Não Acidente"}
]

const tipoConsulta = [
    {opcao : 1, valor : "Primeira Consulta"},
    {opcao : 2, valor : "Seguimento"},
    {opcao : 3, valor : "Pré-natal"},
    {opcao : 4, valor : "Por Encaminhamento"},
]

const caraterAtendimento = [
    {opcao : "E", valor : "Eletivo"},
    {opcao : "U", valor : "Urgência/Emergência"},
]

const codAtendimento = [
    {opcao : "01", valor : "Remoção"},
    {opcao : "02", valor : "Pequena Cirurgia"},
    {opcao : "03", valor : "Terapias"},
    {opcao : "04", valor : "Consulta"},
    {opcao : "05", valor : "Exames"},
    {opcao : "06", valor : "Atendimento Domiciliar"},
    {opcao : "07", valor : "SADT Internado"},
    {opcao : "08", valor : "Quimioterapia"},
    {opcao : "09", valor : "Radioterapia"},
    {opcao : "10", valor : "Terapia Renal Substitutiva (TRS)"},
    {opcao : "11", valor : "Pronto Socorro"},
    {opcao : "12", valor : "Ocupacional"},
    {opcao : "13", valor : "Pequenos Atendimentos"},
]


export default {
    indicadorAcidente,
    tipoConsulta,
    caraterAtendimento,
    codAtendimento,
}