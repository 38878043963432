const state = {
    profissionalGeralComplementoSelecionado : {},
    profissionalAtendimentoComplementoSelecionado : {}
}

const getters = {
    getProfissionalGeralComplementoSelecionado(state) {
        return state.profissionalGeralComplementoSelecionado;
    },
    getProfissionalAtendimentoComplementoSelecionado(state) {
        return state.profissionalAtendimentoComplementoSelecionado;
    }
}

const mutations = {
    setProfissionalGeralComplementoSelecionado(state, profissionalGeralComplementoSelecionado) {
        state.profissionalGeralComplementoSelecionado = {...profissionalGeralComplementoSelecionado}
    },
    setProfissionalAtendimentoComplementoSelecionado(state, profissionalAtendimentoComplementoSelecionado) {
        state.profissionalAtendimentoComplementoSelecionado = {...profissionalAtendimentoComplementoSelecionado}
    }
}

const actions = {
    setProfissionalGeralComplementoSelecionado({commit}, profissionalGeralComplementoSelecionado) {
        commit("setProfissionalGeralComplementoSelecionado", profissionalGeralComplementoSelecionado);
    },
    setProfissionalAtendimentoComplementoSelecionado({commit}, profissionalAtendimentoComplementoSelecionado) {
        commit("setProfissionalAtendimentoComplementoSelecionado", profissionalAtendimentoComplementoSelecionado);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
