<template>
    <div class="conta col-lg-3" :class="saldoConta" @click="$emit('selecionouLancamentos', conta)">
        <span class="edit" @click.stop="$emit('selecionouConta', conta)">
            E
        </span>
        {{conta.descricao}} - {{conta.tipo}}<br>
        Criado em : {{conta.datCadastro | formatDate}}<br><br>
        Primeiro Lançamento: {{conta.datPrimeiroLancamento | formatDate}}<br>
        Último Lançamento: {{conta.datUltimoLancamento | formatDate}}<br><br>
        Saldo de Abertura R$ : {{conta.saldoAbertura.toFixed(2).replace('.',',')}}<br>
        Saldo Atual R$ : {{conta.saldoAtual.toFixed(2).replace('.',',')}}
    </div>
</template>

<script>
export default {
    props : {conta : {type : Object, required : true}},
    data() {
        return {
        }
    },
    computed : {
        saldoConta() {
            return {
                positivo : this.conta.saldoAtual > 0,
                zerado : this.conta.saldoAtual == 0,
                negativo : this.conta.saldoAtual < 0
            }
        }
    }
}
</script>

<style scoped>
    .conta {
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 300;
        cursor : pointer;
        user-select: none;
        margin : 10px;
    }

    .negativo {
        color: white;
        border-left : 12px solid rgb(150, 54, 54);
        background-color: red
    }

    .zerado {
        color: black;
        border-left : 12px solid rgb(177, 177, 35);
        background-color: yellow;
    }

    .positivo {
        color: white;
        border-left: 12px solid rgb(28, 80, 28);
        background-color: green;
    }

    .negativo .edit{
        background-color :  rgb(150, 54, 54);
    }

    .zerado .edit{
        background-color : rgb(177, 177, 35)
    }

    .positivo .edit {
        background-color:  rgb(28, 80, 28);
    }

    .edit {
        position : absolute;
        top: 10px;
        right: 10px;
        font-size: 0.5rem;
        height: 12px;
        width: 12px;
        border-radius: 5px;
        padding-left: 4px;
    }
</style>