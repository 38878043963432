<template>
  <div id="frmProfissionalDisponibilidades">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}} - Disponibilidade de Horários</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Adicionar Nova Disponibilidade</button>
          <hr/>
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <!-- Inclusão de Disponibilidades - Início -->
        <div v-if="modo=='Incluir'">
          <div class="row">
            <div class="col ml-2 mr-2">
              <h4 style="text-align:left">Adicionar Nova Disponibilidade</h4>
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-2 ml-2">
              <label for="destinado" class="control-label">Tipo Atendimento:</label>
              <select id="destinado" class="form-control" selected v-model="destinadoNovo">
                <option
                  v-for="resposta in opcoesDestinados"
                  :key="resposta.opcao"
                  :value="resposta.opcao"
                >{{resposta.valor}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Inicial" class="control-label">Inicial:</label>
              <input type="time" class="form-control" id="Inicial" name="Inicial" v-model="inicial" />
            </div>
            <div class="form-group col-lg-2">
              <label for="Duracao" class="control-label">Duração:</label>
              <input type="time" class="form-control" id="Duracao" name="Duracao" v-model="duracao" />
            </div>
            <div class="form-group col-lg-2">
              <label for="Final" class="control-label">Final:</label>
              <input type="time" class="form-control" id="Final" name="Final" v-model="final" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-2 ml-2">
              <label for="Preferencial" class="control-label">1º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial"
                name="Preferencial"
                selected
                v-model="preferencial"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Preferencial2" class="control-label">2º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial2"
                name="Preferencial2"
                selected
                v-model="preferencial2"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Preferencial3" class="control-label">3º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial3"
                name="Preferencial3"
                selected
                v-model="preferencial3"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
            </div>
            <div class="form-group col-lg-1">
              <label for="PorVez" class="control-label">Por vez:</label>
              <input
                type="number"
                class="form-control"
                id="PorVez"
                name="PorVez"
                v-model.number="porVez"
              />
            </div>
            <div class="form-group col-lg-1">
              <label for="Livres" class="control-label">Livres:</label>
              <input
                type="number"
                class="form-control"
                id="Livres"
                name="Livres"
                v-model.number="livres"
              />
            </div>
            <div class="form-group col-lg-2">
              <label for="Bloqueio" class="control-label">Bloqueio:</label>
              <input
                type="text"
                class="form-control"
                id="Bloqueio"
                name="Bloqueio"
                v-model="bloqueio"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <button
              class="btn btn-warning mr-2"
              @click="cancelarOperacao()"
              style="margin-bottom:10px;"
            >Cancelar</button>
            <button
              class="btn btn-info"
              @click="adicionarDisponibilidades()"
              style="margin-bottom:10px;"
            >Adicionar Nova Disponibilidade</button>
          </div>
        </div>
        <!-- Inclusão de Disponibilidades - Fim -->

        <!-- Alteração Disponibilidade Selecionada - Início -->
        <div v-else>
          <div class="row">
            <div class="col ml-2 mr-2">
              <h4 style="text-align:left">Alterar Disponibilidade</h4>
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-2 ml-2">
              <label for="destinado" class="control-label">Tipo Atendimento:</label>
              <select
                id="destinado"
                class="form-control"
                selected
                :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.destinado"
              >
                <option
                  v-for="resposta in opcoesDestinados"
                  :key="resposta.opcao"
                  :value="resposta.opcao"
                >{{resposta.valor}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Inicial" class="control-label">Inicial:</label>
              <input
                type="time"
                class="form-control"
                id="Inicial"
                name="Inicial"
                :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.inicial"
              />
            </div>
            <div class="form-group col-lg-2 ml-2">
              <label for="Preferencial" class="control-label">1º Prefer.</label>
                <select
                  class="form-control"
                  id="Preferencial"
                  name="Preferencial"
                  selected
                 :disabled="!liberarCampos"
                  v-model="profissionalDisponibilidade.preferencial"
                >
                  <option
                    v-for="servico in servicos"
                    :key="servico.siglaServico"
                    :value="servico.siglaServico"
                  >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Preferencial2" class="control-label">2º Prefer.</label>
                <select
                  class="form-control"
                  id="Preferencial2"
                  name="Preferencial2"
                  selected
                  :disabled="!liberarCampos"
                  v-model="profissionalDisponibilidade.preferencial2"
                >
                  <option
                    v-for="servico in servicos"
                    :key="servico.siglaServico"
                    :value="servico.siglaServico"
                  >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="Preferencial3" class="control-label">3º Prefer.</label>
                <select
                  class="form-control"
                  id="Preferencial3"
                  name="Preferencial3"
                  selected
                  :disabled="!liberarCampos"
                  v-model="profissionalDisponibilidade.preferencial3"
                >
                  <option
                    v-for="servico in servicos"
                    :key="servico.siglaServico"
                    :value="servico.siglaServico"
                  >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                </select>
            </div>
          </div>  

          <div class="row">
            <div class="form-group ml-2 col-lg-1">
              <label for="PorVez" class="control-label">Por vez:</label>
              <input
                type="number"
                class="form-control"
                id="PorVez"
                name="PorVez"
                :disabled="!liberarCampos"
                v-model.number="profissionalDisponibilidade.porVez"
              />
            </div>
            <div class="form-group col-lg-1">
              <label for="Livres" class="control-label">Livres:</label>
              <input
                type="number"
                class="form-control"
                id="Livres"
                name="Livres"
                :disabled="!liberarCampos"
                v-model.number="profissionalDisponibilidade.livres"
              />
            </div>
            <div class="form-group col-lg-2">
              <label for="Bloqueio" class="control-label">Bloqueio:</label>
              <input
                type="text"
                class="form-control"
                id="Bloqueio"
                name="Bloqueio"
                :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.bloqueio"
              />
            </div>

            <div class="form-group col-lg-2 ml-2">
              <label for="fechado" class="control-label">Fechado:</label>
                <select
                  class="form-control"
                  id="fechado"
                  name="fechado"
                  selected
                 :disabled="!liberarCampos"
                  v-model="profissionalDisponibilidade.fechado"
                >
                  <option
                    v-for="resposta in sim_nao"
                    :key="resposta.opcao"
                    :value="resposta.opcao"
                  >{{ resposta.valor }}</option>
                </select>
            </div>
          </div>

          <hr />
          <div class="barraopcoescadastro">
            <button
              id="Alterar"
              :disabled="modo=='Alterar'"
              @click="alterarRegistro()"
              class="btn btn-sm btn-secondary mr-2"
            >Alterar</button>
            <button
              id="Cancelar"
              @click="cancelarOperacao()"
              class="btn btn-sm btn-secondary mr-2"
            >Cancelar</button>
            <button
              id="Gravar"
              :disabled="!liberarCampos"
              @click="gravarRegistroSelecionado()"
              class="btn btn-sm btn-secondary"
            >Gravar</button>
          </div>
        </div>
        <!-- Alteração Disponibilidade Selecionada - Fim -->
      </div>
      <hr />

      <div id="filtrarRegistros" class="row">
        <div class="form-group col-lg-2">
          <label for="Unidade" class="control-label">Unidade:</label>
          <select
            id="Unidade"
            class="form-control"
            @change="limparLista()"
            selected
            v-model="unidadeId"
          >
            <option
              v-for="unidade in unidades"
              :key="unidade.id"
              :value="unidade.id"
            >{{unidade.descricao}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="destinado" class="control-label">Tipo Atendimento:</label>
          <select
            id="destinado"
            class="form-control"
            @change="limparLista()"
            selected
            v-model="destinado"
          >
            <option
              v-for="resposta in destinados"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="diaSemana" class="control-label">Dia da Semana:</label>
          <select
            id="diaSemana"
            class="form-control"
            @change="limparLista()"
            selected
            v-model="diaSemana"
          >
            <option
              v-for="resposta in diasSemana"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:32px">
          <button id="btnFiltrar" class="form-control" @click="buscarProfissionalDisponibilidades()">
            <i class="fa fa-search"/> 
          </button>
        </div>

        <div class="form-group col-lg-2" style="padding-top:36px">
          <button
            id="btnExcluirTodos"
            class="btn btn-sm btn-danger"
            :disabled="profissionalDisponibilidades.length==0"
            @click="ExcluirTodos()"
          >Excluir Todos</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center">
        <!-- <b-table v-if="!loading" hover striped small sort-icon-left :items="profissionalDisponibilidades" :fields="camposTabela"> -->
        <!-- <template vslot:cell(actions)="row"> -->
        <!-- <b-button variant="warning" size="sm" @click="selecionouProfissionalDisponibilidade(row.item)"> -->
        <!-- <i class="fa fa-pencil"> -->
        <!-- </b-button> -->
        <!-- </template> -->
        <!-- </b-table> -->

        <b-spinner v-if="loadingProfissionalDisponibilidades" label="carregando..."/>
        <table class="table table-sm table-hover col-lg-9" v-if="loadedProfissionalDisponibilidades">
          <thead class="thead-light">
            <th>Tipo Atend.</th>
            <th>Inicial</th>
            <th>1º Pref.</th>
            <th>2º Pref.</th>
            <th>3º Pref.</th>
            <th>Por Vez</th>
            <th>Livres</th>
            <th>Bloqueio</th>
            <th>Fechado</th>
            <th>Ações</th>
          </thead>
          <tbody>
            <tr
              v-for="profissionalDisponibilidade in profissionalDisponibilidades"
              :key="profissionalDisponibilidade.id"
            >
              <td>{{profissionalDisponibilidade.destinado == 'C' ? 'Consultório' : profissionalDisponibilidade.destinado == 'S' ? 'Sessões' : 'Mensalistas'}}</td>
              <td>{{profissionalDisponibilidade.inicial}}</td>
              <td>{{profissionalDisponibilidade.preferencial}}</td>
              <td>{{profissionalDisponibilidade.preferencial2}}</td>
              <td>{{profissionalDisponibilidade.preferencial3}}</td>
              <td>{{profissionalDisponibilidade.porVez}}</td>
              <td>{{profissionalDisponibilidade.livres}}</td>
              <td>{{profissionalDisponibilidade.bloqueio}}</td>
              <td>{{profissionalDisponibilidade.fechado == null ? '_' : profissionalDisponibilidade.fechado}}</td>
              <td>
                <b-button
                  variant="warning"
                  size="sm"
                  class="mr-2"
                  @click="selecionouProfissionalDisponibilidade(profissionalDisponibilidade)"
                >
                  <i class="fa fa-pencil" />
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="ExcluirRegistroSelecionado(profissionalDisponibilidade)"
                >
                  <i class="fa fa-trash" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import constantes from "@/utils/constantes";
import { mapGetters, mapActions } from "vuex";
import servicos from "@/mixins/servicos";
import unidades from "@/mixins/unidades";
import profissionalDisponibilidades from "@/mixins/profissionalDisponibilidades";
import errors from "@/utils/errors";

export default {
  mixins: [profissionalDisponibilidades, servicos, unidades],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
    }),
    opcoesDestinados: function() {
      return constantes.destinados.filter(function(u) {
        return u.opcao != "T";
      });
    },
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingProfissionalDisponibilidades : false,
      loadedProfissionalDisponibilidades : false, 
      erro: "",
      retornando: false,
      unidadeId: 1,
      diasSemana: constantes.diasSemana,
      diaSemana: 1,
      destinados: constantes.destinados,
      destinado: "T",
      destinadoNovo: "C",
      inicial: "",
      final: "",
      duracao: "",
      preferencial: "",
      preferencial2: "",
      preferencial3: "",
      porVez: 1,
      livres: 1,
      bloqueio: "",
      novasDisponibilidades: null,
      mensagem : "",
      modo: "",
      profissionalDisponibilidade: {},
      sim_nao : constantes.sim_nao
    }
  },
  methods: {
    ...mapActions({}),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.profissionalDisponibilidade = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },    
    selecionouProfissionalDisponibilidade(profissionalDisponibilidade) {
      this.profissionalDisponibilidade = { ...profissionalDisponibilidade }
      this.modo = "Registro";
    },
    buscarProfissionalDisponibilidades() {
      this.carregarProfissionalDisponibilidades(this.usuarioLogadoDados.tenant, this.unidadeId, this.profissionalSelecionado.id, this.destinado, this.diaSemana);
    },
    adicionarDisponibilidades() {
      this.novasDisponibilidades = new Array();

      var arrHoraInicial = this.inicial.split(":");
      var horaInicial = new Date();
      horaInicial.setHours(parseInt(arrHoraInicial[0]));
      horaInicial.setMinutes(parseInt(arrHoraInicial[1]));
      horaInicial.setSeconds(0);
      horaInicial.setMilliseconds(0);

      var arrHoraFinal = this.final.split(":");
      var horaFinal = new Date();
      horaFinal.setHours(parseInt(arrHoraFinal[0]));
      horaFinal.setMinutes(parseInt(arrHoraFinal[1]));
      horaFinal.setSeconds(0);
      horaFinal.setMilliseconds(0);

      var arrDuracao = this.duracao.split(":");
      var tempoDuracao = new Date();
      tempoDuracao.setHours(parseInt(arrDuracao[0]));
      tempoDuracao.setMinutes(parseInt(arrDuracao[1]));
      tempoDuracao.setSeconds(0);
      tempoDuracao.setMilliseconds(0);

      this.profissionalDisponibilidade = {}

      var zeros = new String();
      var horas = new String();
      var minutos = new String();

      zeros = "0";
      horas = "";
      minutos = "";

      while (horaInicial <= horaFinal) {
        horas = horaInicial.getHours().toString();
        minutos = horaInicial.getMinutes().toString();

        horas = zeros.repeat(2 - horas.length) + horas;
        minutos = minutos + zeros.repeat(2 - minutos.length);

        this.profissionalDisponibilidade.unidadeId = this.unidadeId;
        this.profissionalDisponibilidade.porVez = this.porVez;
        this.profissionalDisponibilidade.destinado = this.destinadoNovo;
        this.profissionalDisponibilidade.diaSemana = this.diaSemana;
        this.profissionalDisponibilidade.inicial = horas + ":" + minutos;
        this.profissionalDisponibilidade.preferencial = this.preferencial;
        this.profissionalDisponibilidade.preferencial2 = this.preferencial2;
        this.profissionalDisponibilidade.preferencial3 = this.preferencial3;

        this.profissionalDisponibilidade.livres = this.livres;
        this.profissionalDisponibilidade.bloqueio = this.bloqueio;
        this.profissionalDisponibilidade.profissionalId = this.profissionalSelecionado.id;
        this.profissionalDisponibilidade.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.profissionalDisponibilidade.fechado = "N";

        this.novasDisponibilidades.push({
          ...this.profissionalDisponibilidade
        });

        horaInicial.setMinutes(
          horaInicial.getMinutes() + tempoDuracao.getMinutes()
        );
      }

      this.gravarRegistros();
    },
    async gravarRegistros() {
      this.loading = true;

      await this.$http.post(`/v1.0/profissionaldisponibilidades/${this.usuarioLogadoDados.tenant}`, this.novasDisponibilidades)
        .then(() => {
          this.erro = "";
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loading = false;
        });

      this.$toasted.global.defaultSuccess();
      this.carregarProfissionalDisponibilidades(this.usuarioLogadoDados.tenant, this.unidadeId, this.profissionalSelecionado.id, this.destinado, this.diaSemana);
    },
    gravarRegistroSelecionado() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.profissionalDisponibilidade.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

      this.$http
        .put(`/v1.0/profissionaldisponibilidades/${this.usuarioLogadoDados.tenant}/${this.profissionalDisponibilidade.id}`, this.profissionalDisponibilidade)
        .then(() => {
          this.erro = "";
          this.limparCampos();
          this.buscarProfissionalDisponibilidades();
          this.$toasted.global.defaultSuccess();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    ExcluirRegistroSelecionado(profissionalDisponibilidade) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                `v1.0/profissionaldisponibilidades/${this.usuarioLogadoDados.tenant}/${profissionalDisponibilidade.id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarProfissionalDisponibilidades(this.usuarioLogadoDados.tenant, this.unidadeId, this.profissionalSelecionado.id, this.destinado, this.diaSemana);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    ExcluirTodos() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm(
          "Excluir Todas as Disponibilidades do Profissional nessa Unidade/Dia?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          }
        )
        .then(value => {
          if (!value) {
            return;
          }

          for (
            var indice = 0;
            indice < this.profissionalDisponibilidades.length;
            indice++
          ) {
            this.$http
              .delete(
                `v1.0/profissionaldisponibilidades/${this.usuarioLogadoDados.tenant}/${this.profissionalDisponibilidades[indice].id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }

          this.limparLista();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.profissionalDisponibilidade = {}
      this.modo="";
      this.erro = "";
    },
    limparLista() {
      this.profissionalDisponibilidades = [];
    },
    limparCampos() {
      this.profissionalDisponibilidade = {}

      if (this.modo!="Incluir") {
        this.modo="";
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalDisponibilidade.inicial === "" || this.profissionalDisponibilidade.inicial === undefined) {
        this.$toasted.show("Digite o Horário Inicial", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.carregarUnidades(this.usuarioLogadoDados.tenant);
    this.carregarServicos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
