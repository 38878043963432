function iniciarWebCam() {
    var video = document.querySelector('video');
    
    navigator.mediaDevices.getUserMedia({video:true})
    .then(stream => {
        video.srcObject = stream;
        video.play;
    })
    .catch(() => {
        return;
    })

    var canvas = document.querySelector('canvas');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0);
}

function capturarFoto() {
    var video = document.querySelector('video');
    var canvas = document.querySelector('canvas');
    
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;

    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0);
}

async function downloadFoto(nomeArquivo) {
    //----------------------------------------- montar link para download
    var canvas = document.querySelector('canvas');

    var link = document.createElement('a');
    link.download = nomeArquivo+'.jpg';
    link.href = canvas.toDataURL();
    link.textContent = 'Clique para baixar a imagem';


    document.body.appendChild(link).click();
}

export default {
    iniciarWebCam,
    capturarFoto,
    downloadFoto
}