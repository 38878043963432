<template>
    <div class="content">
        <router-view/>
    </div>
</template>

<script>
export default {
    name : 'Content'
}
</script>

<style scoped>
    .content {
        grid-area: content;
        background-color: #efefef;
        padding : 0px;
    }
</style>