import errors from "@/utils/errors";

export default {
    data() {
        return {
            centros : [],
            contador : 0,
        }
    },
    methods : {
        async carregarCentros(tenant, fluxoId, estruturaCompleta, movimento) {
            await this.$http.get(`/v1.0/centros/${tenant}/Fluxo/${fluxoId}/${movimento}`)
            .then(resp => {
                let centrosSemEstrutura = resp.data;

                this.erro = "";
                this.loading = false;

                if (estruturaCompleta) {
                    this.montarEstruturaCompleta(centrosSemEstrutura);
                } else {
                    this.montarEstruturaParcial(centrosSemEstrutura);
                }
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        getParent(centrosSemEstrutura, parentId) {
            let parent = centrosSemEstrutura.find(p => p.id == parentId);
            return parent == undefined ? null : parent;
        },
        hasChild(centrosSemEstrutura, id) {
            let child = centrosSemEstrutura.find(p => p.parentId == id)
            return child == undefined ? false : true;
        },
        montarEstruturaCompleta(centrosSemEstrutura) {
            this.centros = centrosSemEstrutura.map(centro => {
                let estrutura = centro.descricao;
                let parent = this.getParent(centrosSemEstrutura, centro.parentId);

                if (parent == null) {
                    return {...centro, estrutura}
                }

                while (parent) {
                    estrutura = `${parent.descricao} > ${estrutura}`
                    parent = this.getParent(centrosSemEstrutura, parent.parentId);
                }

                return {...centro, estrutura}
            });

            this.centros.sort((d1, d2) => d1.estrutura > d2.estrutura ? 1 : -1)
        },
        montarEstruturaParcial(centrosSemEstrutura) {
            this.montarEstruturaCompleta(centrosSemEstrutura);

            this.centrosChilds = [...this.centros];

            this.centros = this.centrosChilds.filter(centro => {
                let hasChild = this.hasChild(centrosSemEstrutura, centro.id);
                return !hasChild;
            });
        },
    }
}
