<template>
  <div id="CadPaciente">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Prontuário: {{pacienteSelecionado.id}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="corpo">
      <b-card no-body style="padding:0px">
        <b-tabs card>
          <b-tab title="Dados do Paciente" active>
              <cmpPacienteDados/>
          </b-tab>
          <b-tab title="Anamnse">
              <cmpAnamneses/>
          </b-tab>
          <b-tab title="Solicitações">
              <cmpSolicitacoes/>
          </b-tab>
          <b-tab title="Solicitações Internas">
              <cmpSolicitacoesInternas/>
          </b-tab>
          <b-tab title="Acompanhamentos">
              <cmpAcompanhamentos/>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global";
import cmpPacienteDados from "./cmpPacienteDados.vue";
import cmpAnamneses from "./cmpAnamneses.vue";
import cmpSolicitacoes from "./cmpSolicitacoes.vue";
import cmpSolicitacoesInternas from "./cmpSolicitacoesInternas.vue";
import cmpAcompanhamentos from "./cmpAcompanhamentos.vue";
import errors from "@/utils/errors.js";

export default {
  mixins : [global],
  components: {
    cmpPacienteDados,
    cmpAnamneses,
    cmpSolicitacoes,
    cmpSolicitacoesInternas,
    cmpAcompanhamentos
  },
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    medicoDoPaciente() {
      return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      modo: "",
      retornando : false
    }
  },
  methods: {
    ...mapActions({
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }
    },
    alterarRegistro() {
      this.modo = "Alterar";
    },
    cancelarOperacao() {
      if (this.modo=="Alterar") {
        this.modo = "Registro";
      } else {
        this.modo = "";
        this.retornar();
      }
      this.erro = "";
    },
    limparCampos() {
    },
    verificarCamposPreenchidos() {

      return true;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  },
  created() {
    this.showMenu(false);
  }
}
</script>

<style scoped>
</style>
