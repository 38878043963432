// const parametrosIniciais = {
    // datAtendimento : null, paciente : {id : 0, nome : ""}, 
    // profissionalId : 0, operadoraId : 0, planoSaudeId : 0, 
    // guiaOperadora : null, tipoLote : -1, datAutorizacao : null, 
    // senhaAutorizacao : null, datValidadeSenha : null    
// }

const state = {
    guiaSelecionada : null,
    guiasDoPaciente : [],
    ultimaGuiaSalva : null,

    parametrosLanctoGuia : null,
    chamadoPor : null,
}

const getters = {
    getGuiaSelecionada(state) {
        return state.guiaSelecionada;
    },

    getParametrosLanctoGuia(state) {
        return state.parametrosLanctoGuia;
    },

    getUltimaGuiaSalva(state) {
        return state.ultimaGuiaSalva;
    },

    getChamadoPor(state) {
        return state.chamadoPor;
    },

    getGuiasDoPaciente(state) {
        return state.guiasDoPaciente;
    }
}

const mutations = {
    setGuiaSelecionada(state, guia) {
        state.guiaSelecionada = {...guia}
    },

    setParametrosLanctoGuia(state, parametros) {
        state.parametrosLanctoGuia = {...parametros}
    },

    setUltimaGuiaSalva(state, guia) {
        state.ultimaGuiaSalva = {...guia}
    },

    setParametrosIniciais(state) {
        state.parametrosLanctoGuia = {...this.parametrosIniciais}
    },

    setChamadoPor(state, chamadoPor) {
        state.chamadoPor = chamadoPor;
    },

    setGuiasDoPaciente(state, guias) {
        state.guiasDoPaciente = [...guias];
    }
}

const actions = {
    setGuiaSelecionada({commit}, guia) {
        commit("setGuiaSelecionada", guia);
    },

    setParametrosLanctoGuia({commit}, parametros) {
        commit('setParametrosLanctoGuia', parametros);
    },

    setUltimaGuiaSalva({commit}, guia) {
        commit('setUltimaGuiaSalva', guia)
    },

    setParametrosIniciais({commit}) {
        commit('setParametrosIniciais');
    },

    setChamadoPor({commit}, chamadoPor) {
        commit('setChamadoPor', chamadoPor);
    },

    setGuiasDoPaciente({commit}, guias) {
        commit("setGuiasDoPaciente", guias);
    },

    limparRegistro({commit}) {
        commit('setChamadoPor', null);
        commit('setParametrosLanctoGuia', null);
        commit('setUltimaGuiaSalva', null);
        commit('setGuiasDoPaciente', null);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}