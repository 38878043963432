<template>
  <div id="frmDespesaApresentacoes">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar()" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{despesaSelecionada.descricao}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      <!------------------------------------------------------ Tabela Simpro ----------------------------------------->
      <h4>Apresentações Simpro</h4>
      <div style="display:flex; flex-direction:row; justify-content:center;height:240px">
        <b-table v-if="loadedDespesasSimpro" class="col-lg-12" hover striped small sort-icon-left :items="registrosSimpro" :fields="camposTabelaSimpro">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouDespesaSimpro(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <template v-if="assinanteSimpro=='S'">
              <b-button variant="info" size="sm" class="ml-1" @click="visualizarApresentacaoSimpro(row.item)">
                <i class="fa fa-eye"/>
              </b-button>
              <b-button variant="info" size="sm" class="ml-1" @click="refreshSimpro()">
                <i class="fa fa-refresh"/>
              </b-button>
            </template>
            <b-button variant="danger" size="sm" class="ml-1" @click="desvincularApresentacaoSimpro(row.item)">
              <i class="fa fa-minus"/>
            </b-button>
          </template>
        </b-table>
      </div>

        <div class="row justify-content-center">
          <template v-if="assinanteSimpro=='S'">
            <div class="col-lg-12">
                <b-button variant="primary" size="sm" @click="vincularApresentacaoSimpro()">Vincular Apresentação Simpro</b-button>
            </div>
          </template>
          <template v-else>
            <div class="col-lg-12">
                <b-button variant="primary" size="sm" @click="adicionarApresentacaoSimpro()">Adicionar Apresentação Simpro</b-button>
            </div>
          </template>
        </div>
      <hr/>
      <!------------------------------------------------------ Tabela Brasindice ----------------------------------------->
      <h4>Apresentações Brasindice</h4>
      <div style="display:flex; flex-direction:row; justify-content:center;height:240px">
        <b-table v-if="loadedDespesasBrasindice" class="col-lg-12" hover striped small sort-icon-left :items="registrosBrasindice" :fields="camposTabelaBrasindice">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouDespesaBrasindice(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <template v-if="assinanteBrasindice=='S'">
              <b-button variant="info" size="sm" class="ml-1" @click="visualizarApresentacaoBrasindice(row.item)">
                <i class="fa fa-eye"/>
              </b-button>
              <b-button variant="info" size="sm" class="ml-1" @click="refreshBrasindice()">
                <i class="fa fa-refresh"/>
              </b-button>
            </template>
            <b-button variant="danger" size="sm" class="ml-1" @click="desvincularApresentacaoBrasindice(row.item)">
              <i class="fa fa-minus"/>
            </b-button>
          </template>

          <template v-slot:cell(datExclusao)="datExclusao">
            {{datExclusao.value | formatDate}}
          </template>
        </b-table>
      </div>

      <div class="row justify-content-center">
        <template v-if="assinanteSimpro=='S'">
          <div class="col-lg-12">
            <b-button variant="primary" size="sm" @click="vincularApresentacaoBrasindice()">Vincular Apresentação Brasindice</b-button>
          </div>
        </template>
        <template v-else>
          <div class="col-lg-12">
            <b-button variant="primary" size="sm" @click="adicionarApresentacaoBrasindice()">Adicionar Apresentação Brasindice</b-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import despesasBrasindice from "@/mixins/despesasBrasindice";
import despesasSimpro from "@/mixins/despesasSimpro";
import tabelaSimpro from "@/mixins/tabelaSimpro";
import tabelaBrasindice from "@/mixins/tabelaBrasindice";
import global from "@/mixins/global";
import errors from "@/utils/errors"
import funcoes from "@/utils/funcoes"

export default {
  mixins : [despesasBrasindice, despesasSimpro, tabelaSimpro, tabelaBrasindice, global],
  computed: {
      ...mapGetters({
        despesaSelecionada : "despesas/getDespesaSelecionada",
        usuarioLogadoDados : "global/getUsuarioLogadoDados",
        assinanteBrasindice : "global/getAssinanteBrasindice",
        assinanteSimpro : "global/getAssinanteSimpro",
      }),
      liberarCampos() {
        return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
      },
  },
  data() {
    return {
      erro : "",
      loadingDespesasBrasindice : true,
      loadedDespesasBrasindice : false,

      loadingDespesasSimpro : true,
      loadedDespesasSimpro : false,

      loading : true,
      loaded : false,

      tabelaBrasindice : {},
      tabelaSimpro : {},

      despesaBrasindiceSelecionada : {},
      despesaSimproSelecionada : {},

      camposTabelaBrasindice : [
        {key : "operadora", label : "Utilizado por"},
        {key : "medicamento", label : "Medicamento"},
        {key : "apresentacao", label : "Apresentação"},
        {key : "eaN13", label : "EAN13"},
        {key : "codigoTiss", label : "TISS"},
        {key : "tipoPreco", label : "Tipo"},
        {key : "preco", label : "R$ Emb."},
        {key : "qtde", label : "Qtde"},
        {key : "precoUnitario", label : "R$ Unit."},
        {key : "precoFaturado", label : "R$ Faturado"},
        {key : "datExclusao", label : "Exclusão"},
        {key : "actions", label : "Ações"},
      ],
      camposTabelaSimpro : [
        {key : "operadora", label : "Utilizado por"},
        {key : "apresentacao", label : "Apresentação"},
        {key : "codigoTiss", label : "TISS"},
        {key : "unidadeEmbalagem", label : "Emb."},
        {key : "precoEmbalagem", label : "R$ Emb."},
        {key : "unidadeFracao", label : "Unid."},
        {key : "qtdeEmbalagem", label : "Qtde"},
        {key : "precoFaturado", label : "Preço R$"},
        {key : "actions", label : "Ações"},
      ]
    }
  },
  methods : {
    ...mapActions({
      setDespesaSimproSelecionada : "despesasSimpro/setDespesaSimproSelecionada",

      setDespesaBrasindiceSelecionada : "despesasBrasindice/setDespesaBrasindiceSelecionada",

      setModuloBrasindice: "tabelaBrasindice/setModulo",
      setRegistroBrasindiceSelecionado : "tabelaBrasindice/setRegistroSelecionado",
      setRegistroSimproSelecionado : "tabelaSimpro/setRegistroSelecionado",
    }),
    retornar() {
      this.$router.go(-1);
    },
    selecionouDespesaBrasindice(despesaBrasindiceSelecionada) {
      this.setDespesaBrasindiceSelecionada(despesaBrasindiceSelecionada);
      this.$router.push('/cadDespesaBrasindice');
    },
    selecionouDespesaSimpro(despesaSimproSelecionada) {
      despesaSimproSelecionada.datExclusao = funcoes.mySqlToJSDateFormat(despesaSimproSelecionada.datExclusao);
      this.setDespesaSimproSelecionada(despesaSimproSelecionada);
      this.$router.push('/cadDespesaSimpro');
    },
    vincularApresentacaoBrasindice() {
      this.setModuloBrasindice("Despesas");
      this.$router.push("/frmTabelaBrasindice");
    },
    adicionarApresentacaoBrasindice() {
      this.setDespesaBrasindiceSelecionada(null);
      this.$router.push("/cadDespesaBrasindice");
    },
    vincularApresentacaoSimpro() {
      this.setModuloSimpro("Despesas");
      this.$router.push("/frmTabelaSimpro");
    },
    adicionarApresentacaoSimpro() {
      this.setDespesaSimproSelecionada(null);
      this.$router.push("/cadDespesaSimpro");
    },
    desvincularApresentacaoBrasindice(registro) {
      let confirmacaoDesvincular;

      if (this.assinanteBrasindice == "S") {
        confirmacaoDesvincular = "Desvincular Apresentação da Brasindice?"
      } else {
        confirmacaoDesvincular = "Remover Registro Brasindice?"
      }

      this.$bvModal.msgBoxConfirm(confirmacaoDesvincular, {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http.delete(`despesasBrasindice/${this.usuarioLogadoDados.tenant}/${registro.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
              this.carregarDespesasBrasindice(this.usuarioLogadoDados.tenant, this.despesaSelecionada.id);
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => this.erro = errors.tratarErros(error));
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    desvincularApresentacaoSimpro(registro) {
      let confirmacaoDesvincular;

      if (this.assinanteBrasindice == "S") {
        confirmacaoDesvincular = "Desvincular Apresentação da Brasindice?"
      } else {
        confirmacaoDesvincular = "Remover Registro Brasindice?"
      }

      this.$bvModal.msgBoxConfirm(confirmacaoDesvincular, {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http.delete(`despesasSimpro/${this.usuarioLogadoDados.tenant}/${registro.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
              this.carregarDespesasSimpro(this.usuarioLogadoDados.tenant, this.despesaSelecionada.id);
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => this.erro = errors.tratarErros(error));
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    refreshBrasindice() {
      this.$bvModal.msgBoxConfirm("Refresh Apresentação Brasindice?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http.get(`/v1.0/tabelaBrasindices/CodigoTiss/${this.despesaSelecionada.codTissBrasindice}`)
            .then(resp => {
                this.tabelaBrasindice = resp.data;
                this.erro = "";

                this.setRegistroBrasindiceSelecionado(this.tabelaBrasindice);
                this.registrarDadosBrasindice();
                this.setRegistroBrasindiceSelecionado({});
            })
            .catch(error => this.erro = errors.tratarErros(error))
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    refreshSimpro() {
      this.$bvModal.msgBoxConfirm("Refresh Apresentação Simpro?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http.get(`/v1.0/tabelaBrasindices/CodigoTiss/${this.despesaSelecionada.codTissBrasindice}`)
            .then(resp => {
                this.tabelaBrasindice = resp.data;
                this.erro = "";

                this.setRegistroBrasindiceSelecionado(this.tabelaBrasindice);
                this.registrarDadosBrasindice();
                this.setRegistroBrasindiceSelecionado({});
            })
            .catch(error => this.erro = errors.tratarErros(error))
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    async visualizarApresentacaoBrasindice(registroSelecionado) {
      await this.carregarBrasindicePeloCodigoTiss(this.usuarioLogadoDados.tenant, registroSelecionado.codigoTiss)

      if (this.tabelaBrasindice.id != undefined) {
          this.setRegistroBrasindiceSelecionado(this.tabelaBrasindice);
          this.$router.push('/conTabelaBrasindice')
      }
    },
    async visualizarApresentacaoSimpro(registroSelecionado) {
      await this.carregarRegistroSimproByCodigoTiss(this.usuarioLogadoDados.tenant, registroSelecionado.codigoTiss)

      if (this.tabelaSimpro.id != undefined) {
        this.setRegistroSimproSelecionado(this.tabelaSimpro);
        this.$router.push('/conTabelaSimpro')
      }
    },
    registrarDadosBrasindice() {
      this.despesaSelecionada.codBrasindice     = this.registroBrasindiceSelecionado.codigoBrasindice;
      this.despesaSelecionada.codTissBrasindice = this.registroBrasindiceSelecionado.codigoTiss;
      this.despesaSelecionada.codTussBrasindice = this.registroBrasindiceSelecionado.codigoTuss;
      this.despesaSelecionada.apresentacaoBrasindice = this.registroBrasindiceSelecionado.medicamento + " " + 
                                                      this.registroBrasindiceSelecionado.apresentacao;
      this.despesaSelecionada.eaN13Brasindice = this.registroBrasindiceSelecionado.eaN13;
      this.despesaSelecionada.identifBrasindice = this.registroBrasindiceSelecionado.datExclusao != null ? "A" : "E";

      this.despesaSelecionada.tipoPrecoBrasindice = this.registroBrasindiceSelecionado.tipoPreco;

      this.despesaSelecionada.restritoHospitalar = this.registroBrasindiceSelecionado.restritoHospitalar;
            
      this.despesaSelecionada.qtdeBrasindice = this.registroBrasindiceSelecionado.qtde;

      this.despesaSelecionada.coefBrasindice = this.despesaSelecionada.coefBrasindice > 0 ? this.despesaSelecionada.coefBrasindice : 1;

      this.despesaSelecionada.precoBrasindice = this.registroBrasindiceSelecionado.precoMedicamento;
      this.despesaSelecionada.precoBrasindiceUnitario = this.registroBrasindiceSelecionado.precoUnitario;
      this.despesaSelecionada.precoBrasindiceFat = this.registroBrasindiceSelecionado.precoUnitario * 
                                                  this.despesaSelecionada.coefBrasindice;

      if (this.registroBrasindiceSelecionado.tipoPreco == "PMC") {
        this.despesaSelecionada.precoBrasindiceFab = 0;
        this.despesaSelecionada.precoBrasindiceUnitarioFab = 0;
        this.despesaSelecionada.precoBrasindiceFatFab = 0;
      } else {
        this.despesaSelecionada.precoBrasindiceFab = this.registroBrasindiceSelecionado.precoMedicamentoFabrica;
        this.despesaSelecionada.precoBrasindiceUnitarioFab = this.registroBrasindiceSelecionado.precoUnitarioFabrica;
        this.despesaSelecionada.precoBrasindiceFatFab = this.registroBrasindiceSelecionado.precoUnitarioFabrica * 
                                                    this.despesaSelecionada.coefBrasindice;
      }
    }
  },
  created() {
    this.showMenu(false);
    this.carregarDespesasSimpro(this.usuarioLogadoDados.tenant, this.despesaSelecionada.id);
    this.carregarDespesasBrasindice(this.usuarioLogadoDados.tenant, this.despesaSelecionada.id);
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }

    h4 {
      text-align: center;
    }
</style>
