import errors from "@/utils/errors";

export default {
    data() {
        return {
            solicitacoes : [],
            solicitacoesParcial : []
        }
    },
    methods : {
        carregarSolicitacoes(tenant, pacienteId, profissionalId) {
            this.loadingSolicitacoes = true;
            this.loadedSolicitacoes = false;

            this.$http.get(`/v1.0/solicitacoes/${tenant}/Paciente/${pacienteId}/${profissionalId}`)
            .then(resp => {
                this.solicitacoes = resp.data;
                this.erro = "";
                this.loadedSolicitacoes = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {this.loadingSolicitacoes = false});
        },
        carregarSolicitacoesParcial(tenant, pacienteId, profissionalId) {
            this.loadingSolicitacoes = true;
            this.loadedSolicitacoes = false;

            this.$http.get(`/v1.0/solicitacoes/${tenant}/PacienteParcial/${pacienteId}/${profissionalId}`)
            .then(resp => {
                this.solicitacoesParcial = resp.data;
                this.erro = "";
                this.loadedSolicitacoes = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {this.loadingSolicitacoes = false});
        },
        carregarSolicitacao(tenant, id) {
            this.loadingSolicitacoes = true;
            this.loadedSolicitacoes = false;

            this.$http.get(`/v1.0/solicitacoes/${tenant}/${id}`)
            .then(resp => {
                this.solicitacaoSelecionada = resp.data;
                this.erro = "";
                this.loadedSolicitacoes = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {this.loadingSolicitacoes = false});
        },
    }
}
