import errors from "@/utils/errors";

export default {
    data() {
      return {
        unidades : [],
      }
    },
    methods : {
        async carregarUnidades(tenant) {
          await this.$http
            .get(`/v1.0/unidades/${tenant}`)
            .then(resp => {
              this.unidades = resp.data;
              this.erro = "";
              this.loading = false;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
            });
        }
    }
}
