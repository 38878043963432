<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Opções de Impressão</span>
          </div>
          <div class="coluna3"/>
        </div>  
        <!-- Período -->
        <template v-if="opcoesRelatorio.solicitarPeriodo=='S'">
          <div class="row">
              <div class="form-group col-lg-2">
                  <label for="datInicial" class="control-label">Data Inicial</label>
                  <input type="date" id="datInicial" class="form-control" v-model="opcoesFiltro.datInicial">
              </div>
              <div class="form-group col-lg-2">
                  <label for="datFinal" class="control-label">Data Final</label>
                  <input type="date" id="datFinal" class="form-control" v-model="opcoesFiltro.datFinal">
              </div>
          </div>
        </template>
        <!-- Operadora -->
        <div class="row">
          <template v-if="opcoesRelatorio.solicitarOperadora=='S'">
            <div class="form-group col-lg-2">
              <label for="selecionaOperadora" class="control-label">Operadora</label>
              <select
                class="form-control"
                id="selecionaOperadora"
                name="Ativo"
                selected
                v-model="opcoesFiltro.operadoraId"
                @change="selecionouOperadora()"
              >
                <option
                  v-for="operadora in operadoras"
                  :key="operadora.id"
                  :value="operadora.id"
                >{{ operadora.fantasia }}</option>
              </select>
            </div>

            <!-- Fatura -->
            <template v-if="opcoesRelatorio.solicitarFatura=='S'">
              <div class="form-group col-lg-2">
                <label for="selecionarFatura" class="control-label">Fatura</label>
                <select
                  class="form-control"
                  id="selecionarFatura"
                  name="Ativo"
                  selected
                  v-model="opcoesFiltro.faturaId"
                  @change="selecionouFatura()"
                >
                  <option
                    v-for="fatura in faturas"
                    :key="fatura.id"
                    :value="fatura.id"
                  >{{ fatura.descricao }}</option>
                </select>
              </div>
              <!-- Lote -->
              <template v-if="opcoesRelatorio.solicitarLote=='S'">
                <div class="form-group col-lg-2">
                  <label for="selecionarLote" class="control-label">Lote</label>
                  <select
                    class="form-control"
                    id="selecionarLote"
                    name="Ativo"
                    selected
                    v-model="opcoesFiltro.loteId"
                  >
                    <option
                      v-for="lote in lotes"
                      :key="lote.id"
                      :value="lote.id"
                    >{{ lote.descricao }}</option>
                  </select>
                </div>
              </template>
            </template>
          </template>
          <!-- Profissional Executante -->
          <template v-if="opcoesRelatorio.solicitarProfissionalExecutante=='S'">
            <div class="form-group col-lg-2">
              <label for="selecionarExecutante" class="control-label">Executante</label>
              <select
                class="form-control"
                id="selecionarExecutante"
                name="Ativo"
                selected
                v-model="opcoesFiltro.profissionalExecutanteId"
              >
                <option
                  v-for="profissional in profissionais"
                  :key="profissional.id"
                  :value="profissional.id"
                >{{ profissional.nome }}</option>
              </select>
            </div>
          </template>
        </div>
        <!-- Paciente -->
        <template v-if="opcoesRelatorio.solicitarPaciente=='S'">
          <div class="form-group col-lg-2">
            <label for="buscarPaciente" class="control-label">Id do Paciente</label>
            <input type="text" id="buscarPaciente" class="form-control" v-model.number="opcoesFiltro.pacienteId"/>
          </div>
        </template>
        <!-- Chamar Filtro -->
        <div class="row">
          <div class="col-lg-12">
            <b-button id="btnFiltrar" @click="filtrar()" variant="warning" 
                size="sm" style="margin-top:27px;">
                <i class="fa fa-print"/>
            </b-button>
          </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import global from '@/mixins/global'
import operadoras from "@/mixins/operadoras"
import faturas from "@/mixins/faturas"
import lotes from "@/mixins/lotes"
import profissionais from "@/mixins/profissionais"
import guias from "@/mixins/guias"

export default {
  mixins : [operadoras, faturas, lotes, profissionais, guias, global],
  computed : {
    ...mapGetters({
      opcoesRelatorio : 'relatorios/getOpcoesGeraisRelatorio',
      opcoesFiltro : {
        datInicial : null, datFinal : null, operadoraId : 0, faturaId : 0, loteId : 0, guiaId : 0,
        profissionalExecutanteId : 0, pacienteId : 0
      }
    }),
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    filtrar() {
      
    },
    selecionouOperadora() {
      if (this.opcoesRelatorio.solicitarFatura=='S') {
        this.carregarFaturasPelaOperadora(this.opcoesFiltro.operadoraId);
      }
    },
    selecionouFatura() {
      if (this.opcoesRelatorio.solicitarLote=='S') {
        this.carregarLotesPelaFatura(this.opcoesFiltro.faturaId);
      }
    }
  },
  created() {
    this.showMenu(false);

    if (this.opcoesRelatorio.solicitarOperadora == 'S') {
      this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
    }
    if (this.opcoesRelatorio.solicitarProfissionalExecutante == 'S') {
      this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);
    }
  }
}
</script>

<style scoped>

</style>