<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="faturaIr" class="control-label">IR Fatura:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="faturaIr"
            name="faturaIr"
            v-model="unidadeSelecionada.faturaIr"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="faturaPis" class="control-label">PIS Fatura:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="faturaPis"
            name="faturaPis"
            v-model="unidadeSelecionada.faturaPis"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="faturaCofins" class="control-label">COFINS Fatura:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="faturaCofins"
            name="faturaCofins"
            v-model="unidadeSelecionada.faturaCofins"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="faturaCsll" class="control-label">CSLL Fatura:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="faturaCsll"
            name="faturaCsll"
            v-model="unidadeSelecionada.faturaCsll"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="faturaIss" class="control-label">ISS Fatura:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="faturaIss"
            name="faturaIss"
            v-model="unidadeSelecionada.faturaIss"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>