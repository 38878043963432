import errors from '@/utils/errors';

export default {
    data() {
        return {
        }
    },
    methods : {
        async carregarGeralPeloId(tenant, id) {
            this.loadingProfissionalGeralComplemento = true;
            this.loadedProfissionalGeralComplemento = false;
          
            await this.$http.get(`/v1.0/profissionalGeralComplementos/${tenant}/${id}`)
                  .then((resp) => {
                    this.profissionalGeralComplemento = resp.data;  
                    this.erro = "";
                    this.loadedProfissionalGeralComplemento = true;
                  })
                  .catch((error) => this.erro = errors.tratarErros(error))
                  .finally(() => this.loadingProfissionalGeralComplemento = false)
        },
        async carregarGeralPeloProfissional(tenant, profissionalId) {
            this.loadingProfissionalGeralComplemento = true;
            this.loadedProfissionalGeralComplemento = false;
        
            await this.$http.get(`/v1.0/profissionalGeralComplementos/${tenant}/profissional/${profissionalId}`)
                  .then((resp) => {
                    this.profissionalGeralComplemento = resp.data;  
                    this.erro = "";
                    this.loadedProfissionalGeralComplemento = true;
                  })
                  .catch((error) => this.erro = errors.tratarErros(error))
                  .finally(() => this.loadingProfissionalGeralComplemento = false)
        },

        async carregarAtendimentoPeloId(tenant, id) {
            this.loadingProfissionalAtendimentoComplemento = true;
            this.loadedProfissionalAtendimentoComplemento = false;

            await this.$http.get(`/v1.0/profissionalAtendimentoComplementos/${tenant}/${id}`)
                  .then((resp) => {
                    this.profissionalAtendimentoComplemento = resp.data;  
                    this.erro = "";
                    this.loadedProfissionalAtendimentoComplemento = true;
                  })
                  .catch((error) => this.erro = errors.tratarErros(error))
                  .finally(() => this.loadingProfissionalAtendimentoComplemento = false)
        },
        async carregarAtendimentoPeloProfissional(tenant, profissionalId) {
            this.loadingProfissionalAtendimentoComplemento = true;
            this.loadedProfissionalAtendimentoComplemento = false;

            await this.$http.get(`/v1.0/profissionalAtendimentoComplementos/${tenant}/profissional/${profissionalId}`)
                  .then((resp) => {
                    this.profissionalAtendimentoComplemento = resp.data;  
                    this.erro = "";
                    this.loadedProfissionalAtendimentoComplemento = true;
                  })
                  .catch((error) => this.erro = errors.tratarErros(error))
                  .finally(() => this.loadingProfissionalAtendimentoComplemento = false)
        },
        async atualizarTokenDoProfissionalAtendimento(tenant, profissionalAtendimentoId, dadosToken) {
            await this.$http.put(`/v1.0/profissionalAtendimentoComplementos/${tenant}/${profissionalAtendimentoId}`, dadosToken)
                  .then(() => {
                    this.erro = "";
                  })
                  .catch((error) => {this.erro = errors.tratarErros(error)})
                  .finally(() => {})
        }
    }
}
