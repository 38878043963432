import errors from "@/utils/errors";

export default {
    data() {
        return {
            //pacienteOperadoras : []
        }
    },
    methods : {
        async carregarPacienteOperadora(tenant, pacienteId, operadoraId, planoSaudeId, operadorCadastroId, criarSeNaoEncontrado) {
            await this.$http.get(`/v1.0/pacienteoperadoras/${tenant}/pacienteOperadora/${pacienteId}/${operadoraId}/${planoSaudeId}/${operadorCadastroId}/${criarSeNaoEncontrado}`)
            .then(resp => {
                this.pacienteOperadora = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        async carregarPacienteOperadoras(tenant, pacienteId) {
            await this.$http.get(`/v1.0/pacienteoperadoras/${tenant}/paciente/${pacienteId}`)
            .then(resp => {
                this.pacienteOperadoras = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        }
    }
}
