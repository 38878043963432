<template>
  <div id="frmAgendaContatosItens">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{agendaContatoSelecionada.descricao}} - Contatos</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div class="row"  v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Contato</button>
          <hr />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="registro" v-if="modo!=''" id="adicionarItem">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col">
            <h4 style="text-align:left">Incluir Novo Contato</h4>
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1">
            <label for="id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="id"
              name="id"
              v-model="agendaContatoItem.id"
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="descricao" class="control-label">Descrição/Nome:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="descricao"
              name="descricao"
              v-model="agendaContatoItem.descricao"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1">
            <label for="foneDDD" class="control-label">DDD:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="foneDDD"
              name="foneDDD"
              v-model="agendaContatoItem.foneDdd"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="fone" class="control-label">Fone:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="fone"
              name="fone"
              v-model="agendaContatoItem.fone"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="celularDDD" class="control-label">DDD:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="celularDDD"
              name="celularDDD"
              v-model="agendaContatoItem.celularDDD"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="celular" class="control-label">Celular:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="celular"
              name="celular"
              v-model="agendaContatoItem.celular"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="whatsapp" class="control-label">Whatsapp:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="whatsapp"
              name="whatsapp"
              v-model="agendaContatoItem.whatsapp"
            />
          </div>

          <div class="form-group col-lg-3">
            <label for="email" class="control-label">E-mail:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="email"
              name="email"
              v-model="agendaContatoItem.email"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-5">
            <label for="logradouro" class="control-label">Logradouro:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="logradouro"
              name="logradouro"
              v-model="agendaContatoItem.logradouro"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="numero" class="control-label">Número:</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="numero"
              name="numero"
              v-model="agendaContatoItem.numero"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="complemento" class="control-label">Complemento:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="complemento"
              name="complemento"
              v-model="agendaContatoItem.complemento"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-3">
            <label for="bairro" class="control-label">Bairro:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="bairro"
              name="bairro"
              v-model="agendaContatoItem.bairro"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="cidade" class="control-label">Cidade:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="cidade"
              name="cidade"
              v-model="agendaContatoItem.cidade"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="UF" class="control-label">UF:</label>
            <select
              id="UF"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="agendaContatoItem.uf"
            >
              <option v-for="uf in ufs" :key="uf.opcao" :value="uf.opcao">{{uf.valor}}</option>
            </select>
          </div>
          <div class="form-group col-lg-1">
            <label for="cep" class="control-label">Cep:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="cep"
              name="cep"
              v-model="agendaContatoItem.cep"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label for="contato" class="control-label">Contato:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="contato"
              name="contato"
              v-model="agendaContatoItem.contato"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="observacao" class="control-label">Observação:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="observacao"
              name="observacao"
              v-model="agendaContatoItem.observacao"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;max-height:550px;overflow:auto">
        <b-spinner v-if="loadingItens" label="carregando..."/>
        <table class="table table-sm table-hover col-lg-10" v-if="loadedItens">
          <thead class="thead-light">
            <th>Descrição/Nome</th>
            <th>DDD</th>
            <th>Fone</th>
            <th>DDD</th>
            <th>Celular</th>
            <th>Ações</th>
          </thead>
          <tbody>
            <tr 
              v-for="agendaContatoItem in agendaContatoItens"
              :key="agendaContatoItem.id"
            >
              <td>{{agendaContatoItem.descricao}}</td>
              <td>{{agendaContatoItem.foneDdd}}</td>
              <td>{{agendaContatoItem.fone}}</td>
              <td>{{agendaContatoItem.celularDdd}}</td>
              <td>{{agendaContatoItem.celular}}</td>
              <td>
                <b-button variant="warning" size="sm" class="mr-2" @click="selecionouAgendaContatoItem(agendaContatoItem)">
                  <i class="fa fa-pencil"/>
                </b-button>
                <b-button variant="danger" size="sm" class="mr-2" @click="excluirRegistroSelecionado(agendaContatoItem)">
                  <i class="fa fa-trash"/>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-pagination v-if="loadedItens" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import constantes from "@/utils/constantes";
import agendaContatos from "@/mixins/agendaContatos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  name : 'frmAgendaContatosItens',
  mixins : [agendaContatos, global],
  computed: {
    ...mapGetters({
        agendaContatoSelecionada : "agendaContatos/getAgendaContatoSelecionada",
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      loadingItens: true,
      loadedItens : false,
      erro: "",
      modo: "",
      agendaContatoItem: {},
      ufs : constantes.uf,
      mensagem : "",
}
  },
  watch : {
    page() {
      this.carregarAgendaContatoItens(this.usuarioLogadoDados.tenant, this.agendaContatoSelecionada.id);
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouAgendaContatoItem(agendaContatoItem) {
      this.agendaContatoItem = {...agendaContatoItem}
      this.modo = "Registro";
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.agendaContatoItem = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.agendaContatoItem.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/agendacontatoitens/${this.usuarioLogadoDados.tenant}/${this.agendaContatoItem.id}`, this.agendaContatoItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarAgendaContatoItens(this.usuarioLogadoDados.tenant, this.agendaContatoSelecionada.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.agendaContatoItem.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.agendaContatoItem.agendaContatoId = this.agendaContatoSelecionada.id;  

        this.$http
          .post(`/v1.0/agendacontatoitens/${this.usuarioLogadoDados.tenant}`, this.agendaContatoItem)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarAgendaContatoItens(this.usuarioLogadoDados.tenant, this.agendaContatoSelecionada.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
      
    },
    excluirRegistroSelecionado(agendaContatoItem) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/agendacontatoitens/${this.usuarioLogadoDados.tenant}/${agendaContatoItem.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarAgendaContatoItens(this.usuarioLogadoDados.tenant, this.agendaContatoSelecionada.id);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.agendaContatoItem = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.agendaContatoItem = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.agendaContatoItem.descricao == "" || this.agendaContatoItem.descricao == undefined) {
          this.$toasted.show("Digite o Grupo de Contato", {type : "info", icon : "times"});
          return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
  },
  mounted() {
    this.carregarAgendaContatoItens(this.usuarioLogadoDados.tenant, this.agendaContatoSelecionada.id);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
