<template>
  <div id="CadOperadora">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{operadoraSelecionada.fantasia}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="corpo">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Dados Gerais" active>
                <cadOperadoraDados/>
            </b-tab>
            <b-tab title="Faturamento">
                <cadOperadoraFaturamento/>
            </b-tab>
            <b-tab title="Financeiro">
                <cadOperadoraFinanceiro/>
            </b-tab>
            <b-tab title="Observações">
                <cadOperadoraObservacoes/>
            </b-tab>
            <b-tab title="Recebimento">
                <cadOperadoraRecebimento/>
            </b-tab>
            <b-tab title="TISS">
                <cadOperadoraTiss/>
            </b-tab>
          </b-tabs>
        </b-card>
  
        <hr />
  
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
        
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            :disabled="!liberarCampos"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmOperadoraContatos">Contatos</router-link>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmPlanosSaude">Planos de Saúde</router-link>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmDespesasCobertas">Taxas</router-link>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmOperadoraProfissionais">Profissionais</router-link>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmConsultasDiferenciadas">Consultas Diferenciadas</router-link>
        <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/" disabled>Negociações</router-link>
        <router-link class="btn btn-sm btn-info" tag="button" to="/" disabled>% Lanctos</router-link>
        </div>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cadOperadoraDados from './cadOperadoraDados.vue'
import cadOperadoraFaturamento from './cadOperadoraFaturamento.vue'
import cadOperadoraFinanceiro from './cadOperadoraFinanceiro.vue'
import cadOperadoraObservacoes from './cadOperadoraObservacoes.vue'
import cadOperadoraRecebimento from './cadOperadoraRecebimento.vue'
import cadOperadoraTiss from './cadOperadoraTiss.vue'
import errors from "@/utils/errors";

export default {
  components: {
    cadOperadoraDados, cadOperadoraFaturamento, cadOperadoraFinanceiro,
    cadOperadoraObservacoes, cadOperadoraRecebimento, cadOperadoraTiss
  },
  computed: {
    ...mapGetters({
      operadoraSelecionada : "operadoras/getOperadoraSelecionada",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      componente: "cadOperadoraDados",
      erro: "",
      modo: "",
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      setModo: "operadoras/setModo",
      limparRegistroSelecionado : "operadoras/limparRegistroSelecionado",
      formLeave : "operadoras/formLeave"
    }),
    retornar() {
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.operadoraSelecionada.venctoFatura = parseInt(this.operadoraSelecionada.venctoFatura);

      if (this.modo=="Alterar") {
        this.operadoraSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/operadoras/${this.usuarioLogadoDados.tenant}/${this.operadoraSelecionada.id}`,
            this.operadoraSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "Registro";
            this.setModo(this.modo);

            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.operadoraSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/operadoras/${this.usuarioLogadoDados.tenant}`, this.operadoraSelecionada)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.setModo(this.modo);

            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
      this.setModo(this.modo);
    },
    cancelarOperacao() {
      this.$bvModal
        .msgBoxConfirm("Abandonar Operação de Inclusão/Edição?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.limparCampos();

            this.modo = "";
            this.erro = "";
            this.setModo(this.modo); 

            this.$router.go(-1);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.operadoraSelecionada.fantasia == undefined) {
        this.$toasted.show("Digite o Nome Fantasia da Operadora", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.razaoSocial == undefined) {
        this.$toasted.show("Digite a Razão Social da Operadora", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.lanctoCodTabelaMedicamentos == 0) {
        this.$toasted.show("Selecione a Tabela de Medicamentos", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.lanctoCodTabelaMateriais == 0) {
        this.$toasted.show("Selecione a Tabela de Matariais", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.tipoGuiaAutoConsulta == 0) {
        this.$toasted.show("Selecione Tipo Guia Consulta", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.venctoFatura == 0) {
        this.$toasted.show("Selecione Recebimento", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.tissVersaoXsd == null) {
        this.$toasted.show("Selecione Versão TISS", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.registroAns == null) {
        this.$toasted.show("Digite o Registro na ANS", {type : "info", icon : "times"});
        return false;
      }

      if (this.operadoraSelecionada.tissSolicitarEquipeSadt == null) {
        this.$toasted.show("Selecione Solicitar Equipe SADT", {type : "info", icon : "times"});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.formLeave();
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  },
  created() {
    if (this.operadoraSelecionada.id==0) {
      this.modo = "Incluir"
    } else {
      this.modo = "Registro"
    }

    this.setModo(this.modo);
  }
}
</script>

<style scoped>
</style>
