<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{operadoraSelecionada.fantasia}} - Taxas Cobertas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <!--  Adicionar Despensa - Início -->
    <div class="container-fluid">
      <div class="row" v-if="modo=='Incluir' || modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >{{modo=='' ? "Adicionar Despesa à Lista" : "Fechar Adicionar"}}</button>
          <hr />
        </div>
      </div>

      <div class="registro mb-5" v-if="modo=='Incluir'">
        <div id="selecionarTipo" class="row">
            <div class="col-lg-7">
              <div class="ml-4 mt-2" style="display:flex; flex-direction:row;">
                <span>Tipo de Despesa:</span>
                <select id="despesaTipo" class="form-control ml-2 col-lg-4" selected v-model="despesaTipoId">
                    <option v-for="despesaTipo in despesaTipos" :key="despesaTipo.id" :value="despesaTipo.id">
                      {{despesaTipo.descricao}}
                    </option>
                </select>
              </div>
          </div>
        </div>
        <br>
        <div id="carregarDespesas" class="row">
          <div class="form-group ml-4 col-lg-5">
            <label for="campoBuscar" class="control-label">Selecione o Campo para Busca:</label>
            <br />
            <input type="radio" id="descricao" value="Descricao" v-model="campoBusca" />
            <label for="descricao" class="control-label ml-1 mr-2">Descrição</label>
            <input
              type="radio"
              id="apresentacaoSimpro"
              value="apresentacaoSimpro"
              v-model="campoBusca"
            />
            <label for="apresentacaoSimpro" class="control-label ml-1 mr-2">Apresentação Simpro</label>
            <input
              type="radio"
              id="apresentacaoBrasindice"
              value="apresentacaoBrasindice"
              v-model="campoBusca"
            />
            <label for="apresentacaoBrasindice" class="control-label ml-1">Apresentação Brasindice</label>
          </div>

          <div class="form-group ml-4 col-lg-3">
            <label for="Filter" class="control-label">Buscar:</label>
            <input
              type="text"
              class="form-control"
              id="filter"
              @input="filtrar=$event.target.value"
              placeholder="digite o que deseja localizar..."
            />
          </div>
          <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:32px">
            <button id="btnFiltrar" class="form-control" @click="chamarCarregarDespesas()">
              <i class="fa fa-search"/> 
            </button>
          </div>
        </div>

        <div style="display:flex; flex-direction:row; justify-content:center">
          <b-spinner v-if="loadingDespesas" label="carregando..."/>
          <b-table v-if="loadedDespesas" class="col-lg-12" hover striped small sort-icon-left :items="despesas" :fields="camposTabelaDespesas">
            <template v-slot:cell(actions)="row">
              <b-button variant="info" size="sm" @click="adicionarDespesa(row.item)">
                <i class="fa fa-plus"/>
              </b-button>
            </template>
          </b-table>
        </div>
        <b-pagination v-if="loadedDespesas" class="col-lg-12 justify-content-center" size="md" v-model="pageDespesas" :total-rows="countDespesas" :per-page="limitDespesas"/>
      </div>
    </div>
    <!--  Adicionar Despensa - Fim -->

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div class="container-fluid">
      <div style="display:flex; flex-direction:row; justify-content:center">
          <b-spinner v-if="loadingDespesasCobertas" label="carregando..."/>
          <b-table v-if="loadedDespesasCobertas" class="col-lg-12" hover striped small sort-icon-left :items="despesasCobertas" :fields="camposTabela">
            <template v-slot:cell(actions)="row">
              <b-button class="mr-2" variant="warning" size="sm" @click="selecionouDespesaCoberta(row.item)">
                <i class="fa fa-pencil"/>
              </b-button>
              <b-button variant="danger" size="sm" @click="removerDespesaCoberta(row.item)">
                <i class="fa fa-minus"/>
              </b-button>
            </template>
          </b-table>
      </div>
      <b-pagination v-if="loadedDespesasCobertas" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import despesas from "@/mixins/despesas";
import despesaTipos from "@/mixins/despesaTipos"
import despesasCobertas from "@/mixins/despesasCobertas"
import global from "@/mixins/global"
import errors from "@/utils/errors"

export default {
  mixins : [despesas, despesaTipos, despesasCobertas, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      operadoraSelecionada : "operadoras/getOperadoraSelecionada",
    }),
  },
  watch: {
    page() {
      this.chamarCarregarDespesasCobertas();
    },
    pageDespesas() {
      this.chamarCarregarDespesas();
    }
  },
  data() {
    return {
      page : 1,
      pageDespesas : 1,
      loading: true,
      loadingDespesas : false,
      loadedDespesas : false,
      loadingDespesasCobertas : false,
      loadedDespesasCobertas : false,
      loadingDespesaTipos : false,
      loadedDespesaTipos : false,
      erro: "",
      retornando: false,
      modo : "",
      mensagem : "",

      camposTabela: [
        {key : "descricao", label : "Descrição", sortable: true},
        {key : "valor", label : "Valor R$"},
        {key : "unidadeConsumo", label : "Unidade"},
        {key : "unidadeTuss", label : "Unidade Tuss"},
        {key : "codigo", label : "Código"},
        {key : "codigoTuss", label : "Código Tuss"},
        {key : "actions", label : "Ações"},
      ],
      //------------------------------------ Despesas --- Inicio
      campoBusca: "Descricao",
      despesa: {},
      despesas: [],
      camposTabelaDespesas: [
        {key : "id", label : "Id"},
        {key : "descricao", label : "Descrição", sortable: true},
        {key : "apresentacaoSimpro", label : "Apresentação Simpro", sortable: true},
        {key : "apresentacaoBrasindice", label : "Apresentação Brasindice", sortable: true},
        {key : "actions", label : "Ações"},
      ],
      //------------------------------------ Despesas --- Fim
    }
  },
  methods: {
    ...mapActions({
      setModo : "despesasCobertas/setModo",
      setDespesaCobertaSelecionada : "despesasCobertas/setDespesaCobertaSelecionada",
      limparRegistroSelecionado: "despesasCobertas/limparRegistroSelecionado",
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    selecionouDespesaCoberta(despesaCoberta) {
      this.setModo("Registro");
      this.setDespesaCobertaSelecionada(despesaCoberta);
      this.$router.push("/cadDespesaCoberta");
    },
    incluirRegistro() {
      if (this.modo == "" ) {
        if (!this.verificarPermissao('Incluir')) {
          return;
        }

        if (this.despesaTipos.length == 0) {
          this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, 0);
        }
      }

      this.modo = this.modo == "" ? "Incluir" : "";
    },
    adicionarDespesa(despesa) {
      this.$bvModal
        .msgBoxConfirm("Adicionar a Despesa " + despesa.descricao + " à lista da Operadora?", {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "info",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            var despesaAdicionar = {}

            despesaAdicionar.id = 0;
            despesaAdicionar.despesaId = despesa.id;
            despesaAdicionar.operadoraId = this.operadoraSelecionada.id;
            despesaAdicionar.operadorCadastroId = this.usuarioLogadoDados.profissionalId; 
            despesaAdicionar.descricao = despesa.descricao;
            despesaAdicionar.valor = 0;
            despesaAdicionar.codigoTuss = "";
            despesaAdicionar.codigo = "";
            despesaAdicionar.unidadeTuss = "";
            despesaAdicionar.unidadeConsumo = "";

            this.$http
              .post(`/v1.0/despesasCobertas/${this.usuarioLogadoDados.tenant}`, despesaAdicionar)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.chamarCarregarDespesasCobertas();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    removerDespesaCoberta(despesaCoberta) {
      this.$bvModal
        .msgBoxConfirm(`Remover ${despesaCoberta.descricao} da lista da Operadora?`, {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/despesasCobertas/${this.usuarioLogadoDados.tenant}/${despesaCoberta.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.chamarCarregarDespesasCobertas();
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    chamarCarregarDespesas() {
      this.carregarDespesasPeloTipo(this.usuarioLogadoDados.tenant, this.despesaTipoId, this.campoBusca, this.filtrar, this.pageDespesas);
    },
    chamarCarregarDespesasCobertas() {
      this.carregarDespesasCobertasPelaOperadora(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id, this.page);
    }
  },
  created() {
    this.showMenu(false);
    this.chamarCarregarDespesasCobertas();
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.$store.dispatch("despesasCobertas/formLeave");
    }
    next(true);
  }
}
</script>

<style scoped>
</style>
