<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span
          >Perfis de Acesso do Usuário {{ profissionalSelecionado.nome }}</span
        >
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo == ''">
        <div class="col-12">
          <button class="btn btn-sm btn-info" @click="incluirRegistro()">
            Incluir Novo Perfil de Acesso
          </button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo != ''">
        <template v-if="modo == 'Incluir'">
          <div class="row">
            <div class="form-group col-lg-3">
              <label for="usuarioPerfil" class="control-label">Perfil</label>
              <select
                id="usuarioPerfil"
                class="form-control"
                selected
                :disabled="!liberarCampos"
                v-model="usuarioPerfil.perfilId"
              >
                <option
                  v-for="perfil in perfis"
                  :key="perfil.id"
                  :value="perfil.id"
                >
                  {{ perfil.grupo }} - {{ perfil.tipo }}
                </option>
              </select>
            </div>
          </div>
        </template>

        <div class="row">
          <div class="form-group col-lg-2">
            <label for="nivelPermissao" class="control-label">Nível</label>
            <select
              id="nivelPermissao"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="usuarioPerfil.nivelPermissao"
            >
              <option
                v-for="nivel in niveisCRUD"
                :key="nivel.opcao"
                :value="nivel.opcao"
              >
                {{ nivel.valor }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="nivelPermissaoViewInferiores" class="control-label"
              >Nível View Inferiores</label
            >
            <select
              id="nivelPermissaoViewInferiores"
              class="form-control"
                 :disabled="!liberarCampos"
             selected
              v-model="usuarioPerfil.nivelPermissaoViewInferiores"
            >
              <option
                v-for="nivel in niveisView"
                :key="nivel.opcao"
                :value="nivel.opcao"
              >
                {{ nivel.valor }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="nivelPermissaoCRUDInferiores" class="control-label"
              >Nível CRUD Inferiores</label
            >
            <select
              id="nivelPermissaoCRUDInferiores"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="usuarioPerfil.nivelPermissaoCRUDInferiores"
            >
              <option
                v-for="nivel in niveisCRUD"
                :key="nivel.opcao"
                :value="nivel.opcao"
              >
                {{ nivel.valor }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="permitidoEmFeriados" class="control-label"
              >Permitido em Feriados</label
            >
            <select
              id="permitidoEmFeriados"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="usuarioPerfil.permitidoEmFeriados"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >
                {{ resposta.valor }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="mfa" class="control-label"
              >MFA (Multi-Factor Authentication)</label
            >
            <select
              id="mfa"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="usuarioPerfil.mfa"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >
                {{ resposta.valor }}
              </option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo == 'Incluir' || modo == 'Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Alterar
          </button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Cancelar
          </button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Gravar
          </button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p
        v-if="erro"
        style="background-color: red; color: white; text-align: center"
      >
        {{ erro }}
      </p>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 200px;
      "
    >
      <b-spinner v-if="loading" label="carregando..." />
      <b-table
        v-if="loaded"
        class="col-lg-8"
        hover
        striped
        small
        sort-icon-left
        :items="usuarioPerfis"
        :fields="camposTabela"
        @row-clicked="clicouRegistroPerfil"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            variant="warning"
            size="sm"
            @click="selecionouRegistro(row.item)"
            class="mr-1"
          >
            <i class="fa fa-pencil" />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="desativarPerfil(row.item)"
          >
            <i class="fa fa-close" />
          </b-button>
        </template>

        <template v-slot:cell(datAtivacao)="datAtivacao">
          {{ datAtivacao.value | formatDate }}
        </template>

        <template v-slot:cell(datDesativacao)="datDesativacao">
          {{ datDesativacao.value | formatDate }}
        </template>
      </b-table>
    </div>
    <hr />

    <!-- Horarios Inicio -->

    <div class="container-fluid">
      <div class="row" v-if="modoHorario == ''">
        <div class="col-12">
          <button class="btn btn-sm btn-info" @click="incluirRegistroHorario()">
            Incluir Novo Dia para Acesso
          </button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modoHorario != ''">
        <div class="row">
          <template v-if="modoHorario == 'Incluir'">
            <div class="form-group col-lg-3">
              <label for="usuarioPerfilHorarioDia" class="control-label"
                >Dia Semana</label
              >
              <select
                id="usuarioPerfilHorarioDia"
                class="form-control"
                v-model="usuarioPerfilHorario.diaSemana"
              >
                <option
                  v-for="diaSemana in diasSemana"
                  :key="diaSemana.opcao"
                  :value="diaSemana.opcao"
                >
                  {{ diaSemana.valor }}
                </option>
              </select>
            </div>
          </template>
          <div class="form-group col-lg-1">
            <label for="horarioInicial" class="control-label">Inicial</label>
            <input type="time" id="horarioInicial" class="form-control" :disabled="!liberarCamposHorarios" v-model="usuarioPerfilHorario.horarioInicial">
          </div>
          <div class="form-group col-lg-1">
            <label for="horarioFinal" class="control-label">Final</label>
            <input type="time" id="horarioFinal" class="form-control" :disabled="!liberarCamposHorarios" v-model="usuarioPerfilHorario.horarioFinal">
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="AlterarHorario"
            :disabled="modoHorario == 'Incluir' || modoHorario == 'Alterar'"
            @click="alterarRegistroHorario()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Alterar
          </button>
          <button
            id="CancelarHorario"
            @click="cancelarOperacaoHorario()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Cancelar
          </button>
          <button
            id="GravarHorario"
            :disabled="!liberarCamposHorarios"
            @click="gravarRegistroHorario()"
            class="btn btn-sm btn-secondary ml-2"
          >
            Gravar
          </button>
        </div>
      </div>
    </div>

    <!-- Horarios Final -->

    <div>
      <p style="text-align: center"> Perfil - {{descricaoPerfilSelecionado}}</p>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 450px;
      "
    >
      <b-table
        v-if="loadedHorarios"
        class="col-lg-4"
        hover
        striped
        small
        sort-icon-left
        :items="usuarioPerfilHorarios"
        :fields="camposTabelaHorarios"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            variant="warning"
            size="sm"
            @click="selecionouRegistroHorarios(row.item)"
            class="mr-1"
          >
            <i class="fa fa-pencil" />
          </b-button>

          <b-button
            variant="danger"
            size="sm"
            @click="excluirHorario(row.item)"
          >
            <i class="fa fa-trash" />
          </b-button>
        </template>

        <template v-slot:cell(diaSemana)="diaSemana">
          {{
            (diaSemana.value =
              diaSemana.value == 1
                ? "Segunda"
                : diaSemana.value == 2
                ? "Terça"
                : diaSemana.value == 3
                ? "Quarta"
                : diaSemana.value == 4
                ? "Quinta"
                : diaSemana.value == 5
                ? "Sexta"
                : diaSemana.value == 6
                ? "Sábado"
                : "Domingo")
          }}
        </template>
      </b-table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{ mensagem }}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import usuarios from "@/mixins/usuarios";
import perfis from "@/mixins/perfis";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";

export default {
  mixins: [usuarios, perfis, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados: "global/getUsuarioLogadoDados",
      usuarioSelecionado: "usuarios/getUsuarioSelecionado",
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    liberarCamposHorarios() {
      return this.modoHorario == "Incluir" || this.modoHorario == "Alterar"
        ? true
        : false;
    },
  },
  data() {
    return {
      page: 1,
      usuarioPerfil: {},
      usuarioPerfis: [],
      usuarioPerfilId: 0,
      usuarioPerfilHorarios: [],
      usuarioPerfilHorario: {},
      descricaoPerfilSelecionado : "",
      camposTabela: [
        { key: "id", label: "Id" },
        { key: "perfil.grupo", label: "Grupo" },
        { key: "perfil.tipo", label: "Tipo" },
        { key: "datAtivacao", label: "Ativado Em" },
        { key: "datDesativacao", label: "Desativado Em" },
        { key: "perfil.grauPermissao", label: "Grau" },
        { key: "nivelPermissao", label: "Nível" },
        { key: "nivelPermissaoViewInferiores", label: "Nível View Inf" },
        { key: "nivelPermissaoCRUDInferiores", label: "Nível CRUD Inf" },
        { key: "permitidoEmFeriados", label: "Lib. Feriados" },
        { key: "mfa", label: "MFA" },
        { key: "actions", label: "Ações" },
      ], 
      camposTabelaHorarios: [
        { key: "diaSemana", label: "Dia Semana" },
        { key: "horarioInicial", label: "Inicial" },
        { key: "horarioFinal", label: "Final" },
        { key: "actions", label: "Ações" },
      ],
      erro: "",
      modo: "",
      modoHorario: "",
      loading: false,
      loaded: false,
      loadedHorarios: false,
      menuItem: {},
      mensagem: "",
      sim_nao: constantes.sim_nao,
      niveisCRUD: constantes.nivelCRUD,
      niveisView: constantes.nivelView,
      diasSemana: constantes.diasSemana,
    };
  },
  watch: {
    usuarioPerfilId() {
      if (this.usuarioPerfilId > 0) {
        this.carregarUsuarioPerfilHorarios(this.usuarioLogadoDados.tenant, this.usuarioPerfilId);
      }
    },
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouRegistro(registro) {
      this.usuarioPerfilId = registro.id;
      this.usuarioPerfil = {...registro};

      this.modo = "Registro";
      this.descricaoPerfilSelecionado = this.usuarioPerfil.perfil.tipo;
    },
    clicouRegistroPerfil(registro) {
      this.usuarioPerfilId = registro.id;
      this.usuarioPerfil = {...registro};

      this.descricaoPerfilSelecionado = this.usuarioPerfil.perfil.tipo;
    },
    incluirRegistro() {
      this.usuarioPerfilId = 0;
      this.usuarioPerfil = {
        nivelPermissao: 2,
        nivelPermissaoViewInferiores: 0,
        nivelPermissaoCRUDInferiores: 0,
        permitidoEmFeriados: "N",
      };
      this.usuarioPerfilHorario = {};
      this.usuarioPerfilHorarios = [];

      if (this.perfis.length == 0) {
        this.carregarPerfis(this.usuarioLogadoDados.tenant);
      }

      this.modo = "Incluir";
    },
    alterarRegistro() {
      this.modo = "Alterar";
    },
    desativarPerfil(registro) {
      this.usuarioPerfilId = registro.id;

      if (!this.verificarPermissao("Excluir")) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm("Desativar Perfil Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http
              .put(`/usuarioperfis/${this.usuarioLogadoDados.tenant}/desativar/${this.usuarioPerfil.id}`)
              .then(() => {
                this.erro = "";

                this.carregarTabelas();
                this.$toasted.global.defaultSuccess();
              })
              .catch((error) => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch((error) => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.modo = "";
      this.usuarioPerfil = {};
      this.usuarioPerfilHorario = {};
      this.erro = "";
    },
    gravarRegistro() {
      if (this.modo == "Alterar") {
        let registro = {
          id : this.usuarioPerfil.id,
          usuarioId : this.usuarioSelecionado.id,
          nivelPermissao : this.usuarioPerfil.nivelPermissao,
          nivelPermissaoViewInferiores : this.usuarioPerfil.nivelPermissaoViewInferiores,
          nivelPermissaoCRUDInferiores : this.usuarioPerfil.nivelPermissaoCRUDInferiores,
          permitidoEmFeriados : this.usuarioPerfil.permitidoEmFeriados,
          mfa : this.usuarioPerfil.mfa,
          operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
        }

        this.$http
          .put(
            `/v1.0/usuarioPerfis/${this.usuarioLogadoDados.tenant}/alterarNivelAcesso/${registro.id}`,
            registro
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
            this.carregarUsuarioPerfis(this.usuarioLogadoDados.tenant, this.usuarioSelecionado.id);
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      } else {
        let registro = {
          usuarioId : this.usuarioSelecionado.id,
          perfilId : this.usuarioPerfil.perfilId,
          nivelPermissao : this.usuarioPerfil.nivelPermissao,
          nivelPermissaoViewInferiores : this.usuarioPerfil.nivelPermissaoViewInferiores,
          nivelPermissaoCRUDInferiores : this.usuarioPerfil.nivelPermissaoCRUDInferiores,
          permitidoEmFeriados : this.usuarioPerfil.permitidoEmFeriados,
          operadorCadastroId : this.usuarioLogadoDados.profissionalId
        }

        this.$http
          .post(`/v1.0/usuarioPerfis/${this.usuarioLogadoDados.tenant}`, registro)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
            this.carregarUsuarioPerfis(this.usuarioLogadoDados.tenant, this.usuarioSelecionado.id);
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      }
    },
    async carregarTabelas() {
      await this.carregarUsuarioPerfis(this.usuarioLogadoDados.tenant, this.usuarioSelecionado.id);

      if (this.usuarioPerfis.length > 0) {
        this.usuarioPerfilId = this.usuarioPerfis[0].id;
        this.descricaoPerfilSelecionado = this.usuarioPerfis[0].perfil.tipo;

        await this.carregarUsuarioPerfilHorarios(this.usuarioLogadoDados.tenant, this.usuarioPerfilId);
      }
    },
    verificarPermissao(operacao) {
      if (
        !errors.verificarPermissao(
          this.usuarioLogadoDados,
          this.$store.state.global.grauPermissaoMinimo,
          operacao
        )
      ) {
        let descricaoOperacao =
          operacao == "Incluir"
            ? "Inclusão"
            : operacao == "Alterar"
            ? "Alteração"
            : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    selecionouRegistroHorarios(horario) {
      this.modoHorario = "Registro";
      this.usuarioPerfilHorario = { ...horario };
    },
    incluirRegistroHorario() {
      if (!this.verificarPermissao("Incluir")) {
        return;
      }

      this.modoHorario = "Incluir";
      this.usuarioPerfilHorario = {};
    },
    alterarRegistroHorario() {
      if (!this.verificarPermissao("Alterar")) {
        return;
      }

      this.modoHorario = "Alterar";
    },
    excluirHorario(horario) {
      if (!this.verificarPermissao("Excluir")) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Dia do Perfil Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http
              .delete(
                `v1.0/usuarioPerfilHorariosPermitidos/${this.usuarioLogadoDados.tenant}/${horario.id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";

                this.carregarUsuarioPerfilHorarios(this.usuarioLogadoDados.tenant, this.usuarioPerfilId)
                this.$toasted.global.defaultSuccess();
              })
              .catch((error) => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch((error) => {
          this.erro = errors.tratarErros(error);
        });
    },
    gravarRegistroHorario() {
      if (this.modoHorario == "Alterar") {
        this.usuarioPerfilHorario.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/usuarioPerfilHorariosPermitidos/${this.usuarioLogadoDados.tenant}/${this.usuarioPerfilHorario.id}`,
            this.usuarioPerfilHorario
          )
          .then(() => {
            this.erro = "";
            this.modoHorario = "";
            this.$toasted.global.defaultSuccess();

            this.carregarUsuarioPerfilHorarios(this.usuarioLogadoDados.tenant, this.usuarioPerfilId);
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      } else {
        this.usuarioPerfilHorario.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.usuarioPerfilHorario.usuarioPerfilId = this.usuarioPerfilId;

        this.$http
          .post(`/v1.0/usuarioPerfilHorariosPermitidos/${this.usuarioLogadoDados.tenant}`, this.usuarioPerfilHorario)
          .then(() => {
            this.erro = "";
            this.modoHorario = "";
            this.$toasted.global.defaultSuccess();
            
            this.carregarUsuarioPerfilHorarios(this.usuarioLogadoDados.tenant, this.usuarioPerfilId);
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      }
    },
    cancelarOperacaoHorario() {
      this.modoHorario = "";
      this.usuarioPerfilHorario = {};
    },
  },
  created() {
    this.showMenu(false);
    this.carregarTabelas();
  },
};
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
