<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="datRegistro" class="control-label">Registro do Sistema:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datRegistro"
            name="datRegistro"
            v-model="unidadeSelecionada.datRegistro"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datPrimeiroLogin" class="control-label">Login:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datPrimeiroLogin"
            name="datPrimeiroLogin"
            v-model="unidadeSelecionada.datPrimeiroLogin"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datArquivoAgenda" class="control-label">Arquivo Agenda:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datArquivoAgenda"
            name="datArquivoAgenda"
            v-model="unidadeSelecionada.datArquivoAgenda"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datAgendaCompactacao" class="control-label">Compactação Agenda:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datAgendaCompactacao"
            name="datAgendaCompactacao"
            v-model="unidadeSelecionada.datAgendaCompactacao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datArquivoFaturas" class="control-label">Arquivo Faturas:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datArquivoFaturas"
            name="datArquivoFaturas"
            v-model="unidadeSelecionada.datArquivoFaturas"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datArquivoOcorrencias" class="control-label">Arquivo Ocorrências:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datArquivoOcorrencias"
            name="datArquivoOcorrencias"
            v-model="unidadeSelecionada.datArquivoOcorrencias"
          />
        </div>
      </div>

      <div class="row">
      </div>
      <hr />

      <div class="row">
      </div>

      <div class="row">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
