<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Paciente {{parametrosLanctoGuia.paciente.nome}} - Prontuário {{parametrosLanctoGuia.paciente.id}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="tituloPagina">
        <span>Tipo {{ guiaSelecionada.tipoLote == 1 ? "Consulta" : guiaSelecionada.tipoLote == 2 ? "SADT" : "Honorários"}}
              - Guia no Prestador: {{guiaSelecionada.guiaPrestador}}</span>
      <hr />
    </div>

    <div class="container-fluid">
      <div class="row justify-content-end">
          <button v-if="liberarAcoes" class="btn btn-sm btn-info mr-1" @click="carrinhoGuia()">
            <i class="fa fa-shopping-cart"/>
          </button>
          <button class="btn btn-sm btn-info mr-3" @click="gerarPDF()">
            <i class="fa fa-print"/>
          </button>
      </div>
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <!-- Tabela de Tratamentos da Guia -->
      <div style="display:flex; flex-direction:row; justify-content:center;height:200px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loadedTratamentos" hover striped small sort-icon-left 
          :items="guiaTratamentos" :fields="camposTabelaTratamentos">
          <template v-slot:cell(actions)="row">
            <b-button v-if="liberarAcoes" variant="warning" size="sm" class="mr-1" @click="alterarTratamento(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button v-if="liberarAcoes" variant="danger" size="sm" class="mr-1" @click="confirmarExcluirTratamento(row.item)">
              <i class="fa fa-trash"/>
            </b-button>
          </template>

          <template v-slot:cell(valProcedimentoUnitario)="valProcedimentoUnitario">
            {{valProcedimentoUnitario.value | formatValor}}
          </template>
          <template v-slot:cell(valTratamento)="valTratamento">
            {{valTratamento.value | formatValor}}
          </template>
          <template v-slot:cell(filme)="filme">
            {{filme.value | formatValor}}
          </template>
          <template v-slot:cell(valDesconto)="valDesconto">
            {{valDesconto.value | formatValor}}
          </template>
          <template v-slot:cell(valorTotal)="valorTotal">
            {{valorTotal.value | formatValor}}
          </template>

        </b-table>
      </div>  
      <!-- Tabela de Despesas da Guia -->
      <div style="display:flex; flex-direction:row; justify-content:center;height:350px;overflow:auto">
        <b-table v-if="loadedDespesas" hover striped small sort-icon-left 
          :items="guiaDespesas" :fields="camposTabelaDespesas">
          <template v-slot:cell(actions)="row">
            <b-button v-if="guiaSelecionada.loteId==null || chamadoPor=='Faturamento'" variant="warning" size="sm" class="mr-1" @click="alterarDespesa(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button v-if="guiaSelecionada.loteId==null || chamadoPor=='Faturamento'" variant="danger" size="sm" class="mr-1" @click="confirmarExcluirDespesa(row.item)">
              <i class="fa fa-trash"/>
            </b-button>
          </template>
          <template v-slot:cell(tipo)="tipo">
            {{tipo.value == 3 ? "Mat" : tipo.value == 2 ? "Med" : "Tax"}}
          </template>

          <template v-slot:cell(qtdMat)="qtdMat">
            {{qtdMat.value | formatValor}}
          </template>
          <template v-slot:cell(valMat)="valMat">
            {{valMat.value | formatValor}}
          </template>
          <template v-slot:cell(valDesconto)="valDesconto">
            {{valDesconto.value | formatValor}}
          </template>
          <template v-slot:cell(valTotal)="valTotal">
            {{valTotal.value | formatValor}}
          </template>
        </b-table>
      </div>  
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import guias from "@/mixins/guias"
import global from "@/mixins/global"
import errors from "@/utils/errors"
import funcoesRelatorios from "@/utils/funcoesRelatorios"

export default {
    mixins : [guias, global],
    computed: {
        ...mapGetters({
          chamadoPor : "guias/getChamadoPor",
          guiaSelecionada : "guias/getGuiaSelecionada",
          parametrosLanctoGuia : "guias/getParametrosLanctoGuia",
          usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarAcoes() {
          return this.guiaSelecionada.loteId==null || this.chamadoPor=='Faturamento' ? true : false
        }
    },
    data() {
      return {
        erro : "",
        loading : true,
        loadedTratamentos : false,
        loadedDespesas : false,
        modo : "",
        retornando : false,
        mensagem : "",
        camposTabelaTratamentos : [
          {key : "id", label : "Id"},
          {key : "codigoProcedimento", label : "Código"},
          {key : "descricao", label : "Descrição Proc."},
          {key : "valProcedimentoUnitario", label : "R$ Unit"},
          {key : "qtdPro", label : "Qtde"},
          {key : "filme", label : "R$ Filme"},
          {key : "valTratamento", label : "R$ Proc."},
          {key : "valDesconto", label : "R$ Desconto"},
          {key : "valorTotal", label : "R$ Total"},
          {key : "actions", label : "Ações"}
        ],
  
        camposTabelaDespesas : [
          {key : "id", label : "Id"},
          {key : "tipo", label : "Tipo"},
          {key : "codigoXML", label : "XML"},
          {key : "codigoTUSS", label : "TUSS"},
          {key : "descricao", label : "Despesa"},
          {key : "unidade", label : "Unid."},
          {key : "unidadeTiss", label : "Unid. Tiss"},
          {key : "valMat", label : "R$ Unit"},
          {key : "qtdMat", label : "Qtde"},
          {key : "valDesconto", label : "R$ Desc"},
          {key : "valTotal", label : "R$ Total"},
          {key : "actions", label : "Ações"}
        ],
      }
    },
    methods: {
      ...mapActions({
        setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
        setGuiaSelecionada : "guias/setGuiaSelecionada",
        setGuiaTratamentoSelecionado : "guiaTratamentos/setGuiaTratamentoSelecionado",
        setGuiaDespesaSelecionada : "guiaDespesas/setGuiaDespesaSelecionada"
      }),
      retornar() {
        this.retornando = true;
        this.$router.go(-1);
      },
      selecionouOperadora() {
        this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.guiaSelecionada.operadoraId, 'S');
      },
      alterarTratamento(guiaTratamento) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.setGuiaTratamentoSelecionado(guiaTratamento);

        if (this.guiaSelecionada.particular) {
          this.$router.push('/cadGuiaTratamentoParticular');
        } else {
          this.$router.push('/cadGuiaTratamentoConvenio');
        }
      },
      alterarDespesa(guiaDespesa) {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.setGuiaDespesaSelecionada(guiaDespesa);

        if (this.guiaSelecionada.particular) {
          this.$router.push('/cadGuiaDespesaParticular');
        }// else {
        //  this.$router.push('/cadGuiaDespesaConvenio');
        //}
      },
      confirmarExcluirTratamento(guiaTratamento) {
        this.modo = "";

        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Excluir Tratamento Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
               this.excluirTratamento(guiaTratamento);
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      async excluirTratamento(guiaTratamento) {
        this.loading = true;

        await this.$http.delete(`v1.0/guiaTratamentos/${this.usuarioLogadoDados.tenant}/${guiaTratamento.id}/${this.usuarioLogadoDados.profissionalId}`)
        .then(() => {
          this.erro = "";
          this.carregarTratamentos(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);        
        })
        .catch(error => this.erro = errors.tratarErros(error))
        .finally(() => this.loading = false);
      },
      confirmarExcluirDespesa(guiaDespesa) {
        this.modo = "";

        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal
          .msgBoxConfirm("Excluir Despesa Selecionada?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.excluirDespesa(guiaDespesa);
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      async excluirDespesa(guiaDespesa) {
        this.loading = true;

        await this.$http.delete(`v1.0/guiaDespesas/${this.usuarioLogadoDados.tenant}/${guiaDespesa.id}/${this.usuarioLogadoDados.profissionalId}`)
        .then(() => {
          this.erro = "";
          this.carregarDespesas(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);        
        })
        .catch(error => this.erro = errors.tratarErros(error))
        .finally(() => this.loading = false);
      },
      carrinhoGuia() {
        this.carregar
        this.$router.push('/frmCarrinhoItens');
      },
      async gerarPDF() {
        let parametros = {
          configuracao : {format : 'A4', orientation : 'landscape', 
                          margenSuperior : 2, margenInferior : 2, margenEsquerda : 5, margenDireita : 5,
                          cabecalhoAltura : 20, rodapeAltura : 20
          },
          detalheHeader : {guiaNaOperadora : this.guiaSelecionada.guiaNaOperadora, datAtendimento : this.guiaSelecionada.datAtendimento, 
                           pacienteNome : this.parametrosLanctoGuia.paciente.nome, pacienteId : this.parametrosLanctoGuia.paciente.id 
          },
          identificacao : {relatorioId : 'Fat0001', titulo : 'Detalhamento de Guia', clinicaId : 'R09', clinica : 'Clínica Note', 
                           profissionalId : this.usuarioLogadoDados.profissionalId},
          lista1 : {...this.guiaTratamentos},
          lista2 : {...this.guiaDespesas},
          lista3 : null,
        }

        //if (await funcoesRelatorios.gerarRelatorioPDF(parametros)!='') {
        //  this.$toasted.show(`Não foi possível gerar o PDF`, {type: "warning", icon : "times"});
        //}

        let resultado = await funcoesRelatorios.gerarRelatorioPDF(parametros)

        if (resultado!='') {
          this.$toasted.show(`Não foi possível gerar o PDF: ${resultado}`, {type: "warning", icon : "times"});
        }
      },
      guiaCancelarOperacao() {
        this.modo = "";
        this.erro = "";
      },
      gravarTratamento() {
        if (!this.verificarCamposPreenchidos()) {
          return;
        }

      },
      gravarDespesa() {
        if (!this.verificarCamposPreenchidos()) {
          return;
        }

      },
      verificarCamposPreenchidos() {

        return true;
      },
      verificarPermissao(operacao) {
        if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
          let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";
  
          this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
          this.$bvModal.show("modal-mensagem");
  
          return false;
        }
  
        return true;
      }      
    },
    async created() {
      this.showMenu(false);

      this.loading = true;
      await this.carregarTratamentos(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);
      this.loadedTratamentos = true;

      if (this.guiaTratamentos != null) {
        this.guiaTratamentos.forEach(element => {
          element.valorTotal = element.valTratamento - element.valDesconto;
        });
      }

      await this.carregarDespesas(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);
      this.loadedDespesas = true;

      this.loading = false;
    },
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
