import errors from "@/utils/errors";

export default {
    data() {
        return {
        }
    },
    methods : {
        async carregarConfigs(tenant) {
            await this.$http
              .get(`/v1.0/configs/${tenant}`)
              .then(resp => {
                this.configSelecionado = resp.data;
                this.erro = "";
                this.loading = false;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
              });
          }
    }
}
