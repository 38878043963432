<template>
  <div>
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
      <span>Atendimentos de {{pacienteSelecionado.nome}} - Prontuário: {{pacienteSelecionado.id}}</span>
      <hr />
    </div>

    <div class="container-fluid">
        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>

        <div style="display:flex; flex-direction:row; justify-content:center">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table v-if="!loading" hover striped small sort-icon-left 
            :items="agendaAtendimentos" :fields="camposTabela">
            <template v-slot:cell(destinado)="data">
                {{ data.value == "C" ? "Cons" : data.value == "S" ? "Sessão" : "Mensal"}}
            </template>              
            <template v-slot:cell(data)="data">
                {{ data.value | formatDate}}
            </template>              
          </b-table>
        </div>  
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import global from "@/mixins/global"

export default {
    mixins : [agendaAtendimentos, global],
    computed : {
      ...mapGetters({
        pacienteSelecionado : "pacientes/getPacienteSelecionado",
        pacienteModulo : "pacientes/getModulo",
        usuarioLogadoDados : "global/getUsuarioLogadoDados",
      })
    },
    data() {
        return {
            erro : "",
            loading : true,
            camposTabela : [
                {key : 'data', label : 'Data', sortable : true},
                {key : 'horario', label : 'Horário', sortable : true},
                {key : 'destinado', label : 'Para', sortable : true},
                {key : 'profissional', label: 'Profissional', sortable : true},
                {key : 'atendimento', label : 'Tipo', sortable : true},
                {key : 'observacao', label: 'Observação', sortable : true},
                {key : 'status', label : 'Status'},
                {key : 'horaChegada', label : 'Chegada', sortable: true},
                {key : 'horaAtendimento', label : 'Atend'},
                {key : 'horaSaida', label : 'Saída'},
                {key : 'horaChegada', label : 'Chegada'},
                {key : 'operadora', label: 'Operadora', sortable : true},
                {key : 'planoSaude', label: 'Plano', sortable : true},
                {key : 'pendente', label : 'Pagar'},
                {key : 'recebido', label : 'Recebido'},
            ],
        }
    },
    methods : {
      ...mapActions({
        formLeave : "pacientes/formLeave",
      }),
      retornar() {
          this.$router.go(-1);
      },
    },
    created() {
      this.showMenu(false);
      this.carregarAgendaPaciente(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id);
    },
    beforeRouteLeave(to, from , next) {
      if (this.pacienteModulo=="Agenda") {
        this.formLeave();
      }

      next(true);
    }
}
</script>

<style scoped>

</style>