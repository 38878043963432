<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{operadoraSelecionada.fantasia}} - Contatos</span>
      </div>
      <div class="coluna3"/>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12" v-if="modo==''">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >{{modo=="" ? "Incluir Novo Contato" : "Fechar Inclusão"}}</button>
          <hr/>
        </div>
      </div>

      <div class="registro">
        <div v-if="modo!=''" id="adicionarItem">
          <div class="row" v-if="modo=='Incluir'">
            <div class="col">
              <h4 style="text-align:left">Incluir Novo Contato</h4>
              <hr />
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col-lg-1">
              <label for="Id" class="control-label">Id:</label>
              <input
                type="text"
                class="form-control"
                disabled
                id="Id"
                name="Id"
                :value="operadoraContato.id"
              />
            </div>
  
            <div class="form-group col-lg-4">
              <label for="Nome" class="control-label">Nome:</label>
              <input
                type="text"
                class="form-control"
                id="Nome"
                name="Nome"
                v-model="operadoraContato.nome"
              />
            </div>
  
            <div class="form-group col-lg-2">
              <label for="Funcao" class="control-label">Função:</label>
              <input
                type="text"
                class="form-control"
                id="Funcao"
                name="Funcao"
                v-model="operadoraContato.funcao"
              />
            </div>
          </div>
  
          <div class="row align-items-end">
            <div class="form-group col-lg-1"></div>
  
            <div class="form-group col-lg-2">
              <label for="Fone" class="control-label">Fone:</label>
              <input
                type="text"
                class="form-control"
                id="Fone"
                name="Fone"
                v-model="operadoraContato.fone"
              />
            </div>
  
            <div class="form-group col-lg-1">
              <label for="Ramal" class="control-label">Ramal:</label>
              <input
                type="text"
                class="form-control"
                id="Ramal"
                name="Ramal"
                v-model="operadoraContato.ramal"
              />
            </div>
  
            <div class="form-group col-lg-4">
              <label for="Email" class="control-label">E-mail:</label>
              <input
                type="text"
                class="form-control"
                id="Email"
                name="Email"
                v-model="operadoraContato.email"
              />
            </div>
          </div>
  
          <hr />
          <div class="barraopcoescadastro">
            <button
              id="Alterar"
              :disabled="modo!='Registro'"
              @click="alterarRegistro()"
              class="btn btn-sm btn-secondary mr-2"
            >Alterar</button>
            <button
              id="Cancelar"
              @click="cancelarOperacao()"
              class="btn btn-sm btn-secondary mr-2"
            >Cancelar</button>
            <button
              id="Gravar"
              :disabled="!liberarCampos"
              @click="gravarRegistro()"
              class="btn btn-sm btn-secondary"
            >Gravar</button>
          </div>
        </div>
      </div>
      <hr/>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <table class="table table-sm table-hover col-lg-9" v-if="!loading">
          <thead class="thead-light">
            <th>Id</th>
            <th>Nome</th>
            <th>Função</th>
            <th>Fone</th>
            <th>Ramal</th>
            <th>E-mail</th>
            <td>Ações</td>
          </thead>
          <tbody>
            <tr
              v-for="operadoraContato in operadoraContatos"
              :key="operadoraContato.id"
            >
              <td>{{operadoraContato.id}}</td>
              <td>{{operadoraContato.nome}}</td>
              <td>{{operadoraContato.funcao}}</td>
              <td>{{operadoraContato.fone}}</td>
              <td>{{operadoraContato.ramal}}</td>
              <td>{{operadoraContato.email}}</td>
              <td>
                <b-button variant="warning" size="sm" class="mr-2" @click="selecionouOperadoraContato(operadoraContato)">
                  <i class="fa fa-pencil"/>
                </b-button>
                <b-button variant="danger" size="sm" @click="excluirRegistro(operadoraContato)">
                  <i class="fa fa-trash"/>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import operadoraContatos from "@/mixins/operadoraContatos";
import global from "@/mixins/global";
import errors from "@/utils/errors";


export default {
  mixins : [operadoraContatos, global],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    }
  },
  data() {
    return {
      loading: true,
      erro: "",
      retornando: false,
      exibirAdicaoContato: false,
      modo : "",
      mensagem : "",
      operadoraContato : {},
    }
  },
  methods: {
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.operadoraContato = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouOperadoraContato(operadoraContato) {
      this.operadoraContato = {...operadoraContato}
      this.modo = "Registro";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.operadoraContato.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/operadoracontatos/${this.usuarioLogadoDados.tenant}/${this.operadoraContato.id}`,
            this.operadoraContato
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarOperadoraContatos(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.operadoraContato.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.operadoraContato.operadoraId = this.operadoraSelecionada.id;

        this.$http
          .post(`/v1.0/operadoracontatos/${this.usuarioLogadoDados.tenant}`, this.operadoraContato)
          .then(() => {
            this.erro = "";
            this.$toasted.global.defaultSuccess();
            this.limparCampos();
            this.carregarOperadoraContatos(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.operadoraContato.nome == "" || this.operadoraContato.nome == undefined ) {
        this.$toasted.show("Digite o Nome do Contato", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.operadoraContato.funcao == "" || this.operadoraContato.funcao == undefined ) {
        this.$toasted.show("Digite a Função do Contato", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.operadoraContato.fone == "" || this.operadoraContato.fone == undefined ) {
        this.$toasted.show("Digite o Fone do Contato", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.operadoraContato.email == "" || this.operadoraContato.email == undefined ) {
        this.$toasted.show("Digite o E-mail do Contato", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    cancelarOperacao() {
      this.operadoraContato = {}
      this.modo="";
      this.erro = "";
    },
    limparCampos() {
      this.operadoraContato = {}

      if (this.modo!="Incluir") {
        this.modo="";
      }
    },
    excluirRegistro(operadoraContato) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                `/v1.0/operadoracontatos/${this.usuarioLogadoDados.tenant}/${operadoraContato.id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.$toasted.global.defaultSuccess();
                this.carregarOperadoraContatos(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarOperadoraContatos(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
