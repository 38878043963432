import errors from "@/utils/errors";

export default {
    data() {
        return {
        }
    },
    methods : {
        async carregarUsuarioPeloId(tenant, incluirProfissional) {
            await this.$http.get(`/v1.0/usuarios/${tenant}/${this.authResult.id}/${incluirProfissional}`)
              .then(resp => {
                this.usuarioLogadoDados = resp.data;
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
        },
        async carregarUsuarioPerfil(tenant) {
            this.usuarioPerfis = [];
            this.perfis = [];

            await this.$http.get(`/v1.0/usuarioperfis/${tenant}/usuario/${this.usuarioLogadoDados.id}`)
            .then(resp => {
              this.usuarioPerfis = resp.data;
              this.erro = ""
            })
            .catch(error => this.erro = errors.tratarErros(error));
        },
        async carregarHorarioPermitidoPerfil(tenant, id) {
            await this.$http.get(`/v1.0/UsuarioPerfilHorariosPermitidos/${tenant}/usuarioperfil/diaSemana/${id}`)
            .then(resp => {
              this.horarioPermitidoPerfil = resp.data;
              this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error));
        },
        async carregarUsuarioPeloProfissional(tenant, profissionalId) {
              await this.$http.get(`/v1.0/usuarios/${tenant}/Profissional/${profissionalId}`)
                .then(resp => {
                  this.usuario = resp.data;
                  this.erro = "";
                })
                .catch(error => {
                  if (error.response.status != 404) {
                    this.erro = errors.tratarErros(error);
                  }
                })
        },
        async registrarAcesso(tenant, operadorCadastroId, perfilAcesso) {
            let registro = {operadorCadastroId : operadorCadastroId, informacoes : "Realizou Login com Perfil: " + perfilAcesso};

            await this.$http.post(`/v1.0/registroacessos/${tenant}`, registro)
              .then(resp => {
                this.registroAcesso = resp.data;
                this.erro = "";
              })
              .catch(error => this.erro = errors.tratarErros(error));
        },
        async registrarSaida(tenant) {
          let registro = {operadorCadastroId : this.usuarioLogadoDados.id, informacoes : "Realizou Logout"};

          await this.$http.post(`/v1.0/registroacessos/${tenant}`, registro)
            .then(resp => {
              this.
              this.registroAcessoId = resp.data.id;
              this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error));
        },
        async carregarUsuarioPerfis(tenant, usuarioId) {
          this.loading = true;
          this.loaded = false;

          await this.$http.get(`/v1.0/usuarioPerfis/${tenant}/Usuario/${usuarioId}`)
          .then(resp => {
            this.usuarioPerfis = resp.data;
            this.erro = "";
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
            this.loading = false;
            this.loaded = true;
          }) 
        },
        async carregarUsuarioPerfilHorarios(tenant, usuarioPerfilId) {
          this.loading = true;
          this.loadedHorarios = false;

          await this.$http.get(`/v1.0/UsuarioPerfilHorariosPermitidos/${tenant}/UsuarioPerfil/${usuarioPerfilId}`)
          .then(resp => {
            this.usuarioPerfilHorarios = resp.data;
            this.erro = "";
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
            this.loading = false;
            this.loadedHorarios = true;
          }) 
        }
  }
}
