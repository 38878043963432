const state = {
    contaSelecionada : {}
}

const getters = {
    getContaSelecionada(state) {
        return state.contaSelecionada;
    },
}

const mutations = {
    setContaSelecionada(state, conta) {
        state.contaSelecionada = {...conta}
    }
}

const actions = {
    setContaSelecionada({commit}, conta) {
        commit("setContaSelecionada", conta);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}