import errors from "@/utils/errors";

export default {
    data() {
        return {
            //filtrar: "",
            kits : [],
        }
    },
    methods: {
        async carregarKits(tenant, planoSaudeId, campoFiltro, filtro, lancamento) {
          if (filtro.length < 4) {
            this.$toasted.show("Digite pelo menos 4 dígitos para realizar a Busca.", {type:"info", icon:"times"});
            return;
          }

          this.loading = true;
          this.loaded = false;

          await this.$http
            .get(`/v1.0/kits/${tenant}/PlanoSaude/${planoSaudeId}/${campoFiltro}/${filtro}/${lancamento}`)
            .then(resp => {
              this.kits = resp.data;
              this.erro = "";
              this.loaded = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {this.loading = false;});
        }
    }
}
