const state = {
    procedimentoCobertoSelecionado : Object,
    ultimaBusca : {planoSaudeId : null, codigoInicial : null, codigoFinal : null, 
                   descricao : null, descricaoTuss : null, siglaServico : null, tabela : null}
}

const getters = {
    getProcedimentoCobertoSelecionado(state) {
        return state.procedimentoCobertoSelecionado
    },
    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setProcedimentoCobertoSelecionado(state, procedimentoCoberto) {
        state.procedimentoCobertoSelecionado = {...procedimentoCoberto}
    },
    setUltimaBusca(state, busca) {
        state.ultimaBusca = {...busca};
    },

    limparRegistroSelecionado(state) {
        state.procedimentoCobertoSelecionado = {};
    },
}

const actions = {
    setProcedimentoCobertoSelecionado({commit}, procedimentoCoberto) {
        commit("setProcedimentoCobertoSelecionado", procedimentoCoberto)
    },
    setUltimaBusca({commit}, busca) {
        commit("setUltimaBusca", busca);
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}