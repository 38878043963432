<template>
  <div class="frmProfissionais">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Profissionais cadastrados</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <div class="form-group col-lg-3">
          <label for="profissionalTipo" class="control-label">Perfil</label>
          <select id="profissionalTipo" class="form-control" selected v-model="perfilId" @change="alterouTipo()">
            <option
              v-for="perfil in perfis"
              :key="perfil.id"
              :value="perfil.id"
            >{{perfil.grupo}} - {{perfil.tipo}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="ativo" class="control-label">Apenas</label>
          <select id="ativo" class="form-control" selected v-model="selecionarAtivos">
            <option
              v-for="resposta in opcoesAtivo"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:25px">
          <button id="btnFiltrar" class="form-control" @click="buscarProfissionais()">
            <i class="fa fa-search"/> 
          </button>
        </div>

        <div class="form-group col-sm-2 col-md-2 col-lg-2" style="padding-top:28px">
          <button id="btnIncluirNovoProfissional" class="btn btn-info btn-sm" @click="incluirRegistro()">
            Incluir Novo Profissional 
          </button>
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="profissionalSelecionado.id" />
          </div>

          <div class="form-group col-lg-3">
            <label for="Nome" class="control-label">Nome:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Nome"
              name="Nome"
              v-model="profissionalSelecionado.nome"
              aria-describedby="NomeHelp"
            />
            <small id="NomeHelp" class="form-text text-muted">Nome completo do Profissional.</small>
          </div>

          <div class="form-group col-lg-1">
            <label for="ativo" class="control-label">Ativo</label>
            <select id="ativo" class="form-control" selected :disabled="modo!='Incluir'" v-model="profissionalSelecionado.ativo">
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="externo" class="control-label">Externo</label>
            <select id="externo" class="form-control" selected :disabled="modo!='Incluir'" v-model="profissionalSelecionado.externo">
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="possuiAgendaPropria" class="control-label">Possui Agenda</label>
            <select id="possuiAgendaPropria" class="form-control" selected v-model="profissionalSelecionado.possuiAgendaPropria">
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="perfilRaizId" class="control-label">Perfil Principal</label>
            <select id="perfilRaizId" class="form-control" disabled selected v-model="profissionalSelecionado.perfilRaizId">
              <option
                v-for="perfil in perfis"
                :key="perfil.id"
                :value="perfil.id"
              >{{perfil.grupo}} - {{perfil.tipo}}</option>
            </select>
          </div>
        </div>

        <template v-if = "profissionalSelecionado.perfilRaizId == 1">
          <div class="row">
            <div class="form-group col-lg-4">
              <label for="cbos" class="control-label">CBOS</label>
              <select
                id="cbos"
                class="form-control"
                :disabled="!liberarCampos"
                selected
                v-model="profissionalSelecionado.cbos"
              >
                <option v-for="cbos in cboss" :key="cbos.codTermo" :value="cbos.codTermo">{{cbos.codTermo}} - {{cbos.termo}}</option>
              </select>
            </div>

            <div class="form-group col-lg-3">
              <label for="conselho" class="control-label">Conselho</label>
              <select
                id="conselho"
                class="form-control"
                :disabled="!liberarCampos"
                selected
                v-model="profissionalSelecionado.conselho"
              >
                <option
                  v-for="conselho in conselhos"
                  :key="conselho.siglaConselho"
                  :value="conselho.siglaConselho"
                >{{conselho.termo}}</option>
              </select>
            </div>

            <div class="form-group col-lg-2">
              <label for="conselhoNumero" class="control-label">Número Conselho</label>
              <input
                type="text"
                class="form-control"
                :disabled="!liberarCampos"
                id="conselhoNumero"
                name="conselhoNumero"
                v-model="profissionalSelecionado.conselhoNumero"
              />
            </div>

            <div class="form-group col-lg-1">
              <label for="conselhoUf" class="control-label">UF</label>
              <select
                id="conselhoUf"
                class="form-control"
                :disabled="!liberarCampos"
                selected
                v-model="profissionalSelecionado.conselhoUf"
              >
                <option v-for="uf in ufs" :key="uf.opcao" :value="uf.valor">{{uf.valor}}</option>
              </select>
            </div>

            <div class="form-group col-lg-1">
              <label for="possuiAgendaPropria" class="control-label">Agenda Própria</label>
              <select id="possuiAgendaPropria" class="form-control" selected v-model="profissionalSelecionado.possuiAgendaPropria">
                <option
                  v-for="resposta in sim_nao"
                  :key="resposta.opcao"
                  :value="resposta.opcao"
                >{{resposta.valor}}</option>
              </select>
            </div>
          </div>
        </template>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Gravar</button>

          <template v-if="this.profissionalSelecionado.ativo == 'S'">
            <button class="btn btn-sm btn-info ml-2" :disabled="usuarioLogadoDados.perfilAcesso.perfil.funcao!='ADM'" @click="alterarProfissionalAtivado('N')">Inativar Profissional</button>
            <button class="btn btn-sm btn-info ml-2" :disabled="usuarioLogadoDados.perfilAcesso.perfil.funcao!='ADM'" @click="confirmarAlterarPerfilPrincipal()">Alterar Perfil Principal</button>
          </template>
          <template v-else>
            <button class="btn btn-sm btn-info ml-2" :disabled="usuarioLogadoDados.perfilAcesso.perfil.funcao!='ADM'" @click="alterarProfissionalAtivado('S')">Ativar Profissional</button>
            <template v-if="profissionalSelecionado.externo== 'S'">
              <button class="btn btn-sm btn-info ml-2" :disabled="usuarioLogadoDados.perfilAcesso.perfil.funcao!='ADM'" @click="alterarProfissionalExterno('N')">Profissional Excluído</button>
            </template>
            <template v-else>
              <button class="btn btn-sm btn-info ml-2" :disabled="usuarioLogadoDados.perfilAcesso.perfil.funcao!='ADM'" @click="alterarProfissionalExterno('S')">Profissional Externo</button>
            </template>
          </template>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center; height:450px">
        <b-spinner v-if="loadingProfissionais" label="carregando..."/>
        <b-table v-if="loadedProfissionais" hover striped small sort-icon-left :items="profissionais" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" class='mr-1' @click="selecionouProfissional(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button variant="warning" size="sm" class='mr-1' @click="selecionouProfissionalGeral(row.item)">
              <i class="fa fa-user"/>
            </b-button>
            <b-button v-if="perfilSelecionadoBusca.realizaAtendimento=='S'" variant="info" class='mr-1' size="sm" @click="disponibilidadeProfissional(row.item)">
              <i class="fa fa-calendar-check-o"/>
            </b-button>
          </template>
          <template v-slot:cell(externo)="externo">
            {{externo.value = externo.value == "S" ? "Sim" : "Não"}}
          </template>
          <template v-slot:cell(ativo)="ativo">
            {{ativo.value = ativo.value == "S" ? "Sim" : "Não"}}
          </template>
          <template v-slot:cell(possuiAgendaPropria)="possuiAgendaPropria">
            {{possuiAgendaPropria.value = possuiAgendaPropria.value == "S" ? "Sim" : "Não"}}
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loadedProfissionais" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>

    <div class="Perfil">
      <b-modal
        id="modal-select-perfil"
        ref="modal"
        title="Selecione o novo Perfil Principal para o Profissional"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalPerfil"
        @hidden="resetModalPerfil"
        @ok="handleOkPerfil"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitPerfil">
          <b-form-group
            :state="perfilState"
            label="Perfis disponíveis"
            label-for="perfil"
            invalid-feedback="É necessário selecionar um Perfil Principal"
          >
            <b-form-select id="perfil" v-model="perfilSelecionadoBusca" :options="perfisOpcoes" required></b-form-select>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import profissionais from "@/mixins/profissionais";
import usuarios from "@/mixins/usuarios";
import perfis from "@/mixins/perfis";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";
import cbos from "@/mixins/cboss";
import conselhos from "@/mixins/conselhos"

export default {
  mixins: [profissionais, usuarios, perfis, cbos, conselhos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      ultimaBusca : "profissionais/getUltimaBusca"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      loadingProfissionais: false,
      loadedProfissionais: false,
      erro: "",
      mensagem : "",
      retornando: false,
      modo: "",
      opcoesAtivo: [
        { opcao: "S", valor: "Ativos" },
        { opcao: "N", valor: "Inativos" }
      ],
      selecionarAtivos: "S",
      perfilId: 11,
      perfilSelecionadoBusca: {},
      camposTabela: [
        {key : "id", label : "Id"},
        {key : "nome", label : "Nome" , sortable: true},
        {key : "ativo", label : "Ativo"},
        {key : "externo", label : "Externo"},
        {key : "possuiAgendaPropria", label : "Possui Agenda"},
        {key : "actions", label : "Ações"},
      ],
      usuario : {},
      profissionalSelecionado : {},
      sim_nao : constantes.sim_nao,
      ufs: constantes.uf,

      //--------------------- utilizados apenas no caso de troca do Perfil Principal
      perfisOpcoes : [],
      perfilSelecionado : null,
      perfilState : null,
      //----------------------------------------------------------------------------

    }
  },
  watch : {
    page() {
      this.chamarCarregarProfissionais();
    },
  },
  methods: {
    ...mapActions({
      setProfissionalSelecionado: "profissionais/setProfissionalSelecionado",
      setPerfilSelecionado: "perfis/setPerfilSelecionado",
      setUsuarioSelecionado : "usuarios/setUsuarioSelecionado",
      setUltimaBusca : "profissionais/setUltimaBusca",
      limparRegistroSelecionado: "profissionais/limparRegistroSelecionado",

      setAtivo: "profissionais/setAtivo",
      setModo: "profissionais/setModo",

      formLeave: "profissionais/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    cancelarOperacao() {
      this.modo = '';
      this.profissionalSelecionado = {};
    },
    incluirRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }

      this.profissionalSelecionado = {};

      if (this.modo=='Incluir') {
        this.modo = ''
      } else {
        this.modo = 'Incluir'
      }
    },
    selecionouProfissional(profissional) {
      this.carregarTabelasComplementares();
      this.profissionalSelecionado = {...profissional};
      this.modo = 'Alterar';
    },
    selecionouProfissionalGeral(profissional) {
      this.setPerfilSelecionado(this.perfilSelecionadoBusca);
      this.setProfissionalSelecionado(profissional);

      this.$router.push("/cadProfissionalGeral");
    },
    disponibilidadeProfissional(profissional) {
      this.setProfissionalSelecionado(profissional);
      this.$router.push("/frmProfissionalDisponibilidades");
    },
    buscarProfissionais() {
      this.perfilSelecionadoBusca = {...this.perfis.find(p => p.id == this.perfilId)};
      this.chamarCarregarProfissionais();
    },
    alterouTipo() {
      this.profissionais = [];
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    carregarTabelasComplementares() {
      if (this.cboss.length == 0) {
        this.carregarCbos("", 0);
      }

      if (this.conselhos.length == 0) {
        this.carregarConselhos("", 0);
      }
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }
      
      if (this.profissionalSelecionado.ativo == 'S') {
        this.profissionalSelecionado.externo = 'N';
      }

      if (this.modo=="Alterar") {
        this.profissionalSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http.put(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}/${this.profissionalSelecionado.id}`, this.profissionalSelecionado)
        .then(() => {
          this.erro = "";
          this.modo = "";
          this.$toasted.global.defaultSuccess();

          this.buscarProfissionais();
        })
        .catch((error) => {
          this.erro = errors.tratarErros(error);
        })
      } else {
        this.profissionalSelecionado.perfilRaizId = this.perfilId;
        this.profissionalSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http.post(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}`, this.profissionalSelecionado)
        .then(() => {
          this.erro = "";
          this.modo = "";
          this.$toasted.global.defaultSuccess();

          this.buscarProfissionais();
        })
        .catch((error) => {
          this.erro = errors.tratarErros(error);
        })
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalSelecionado.nome == "" || this.profissionalSelecionado.nome == undefined) {
        this.$toasted.show("Digite o Nome do Profissional.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.ativo == "" || this.profissionalSelecionado.ativo == undefined) {
        this.$toasted.show("Selecione se o Profissional está Ativo no Sistema.", {type : 'info', icon : 'times'});
        return false;
      }
      
      if (this.profissionalSelecionado.externo == "" || this.profissionalSelecionado.externo == undefined) {
        this.$toasted.show("Selecione se o Profissional é Externo.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalSelecionado.possuiAgendaPropria == "" || this.profissionalSelecionado.possuiAgendaPropria == undefined) {
        this.$toasted.show("Selecione se o Profissional Possui Agenda Própria.", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    resetModalPerfil() {
      this.perfilSelecionadoBusca = null;
    },
    handleOkPerfil() {
      this.handleSubmitPerfil();
    },
    handleSubmitPerfil() {
      let novoPerfilPrincipal = this.perfis.find(novoPerfil => novoPerfil.tipo == this.perfilSelecionadoBusca);
      this.alterarPerfilPrincipal(novoPerfilPrincipal);
    },
    confirmarAlterarPerfilPrincipal() {
      this.$bvModal
        .msgBoxConfirm("Alterar o Perfil Principal (original) do Profissional?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.selecionarNovoPerfilPrincipal();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    async selecionarNovoPerfilPrincipal() {
      this.perfisOpcoes = [];
      await this.carregarPerfis(this.usuarioLogadoDados.tenant);

      //this.perfis.sort((d1, d2) => d1.tipo > d2.tipo ? 1 : -1);

      this.perfis.forEach(perfil => {
        if (perfil.id != this.profissionalSelecionado.perfilId) {
          this.perfisOpcoes.push(perfil.tipo);
        }
      });

      this.perfisOpcoes.sort();
      this.$bvModal.show("modal-select-perfil");
    },
    async alterarPerfilPrincipal(novoPerfilPrincipal) {
      let alterarPerfil = {id : this.profissionalSelecionado.id, 
                           perfilId : novoPerfilPrincipal.id,
                           operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
                          };

      this.$http.put(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}/alterarPerfil/${alterarPerfil.id}`, alterarPerfil)
      .then(() => {
        this.erro = "";
        this.$toasted.global.defaultSuccess();
      })
      .catch(error => this.erro = errors.tratarErros(error))
    },
    alterarProfissionalAtivado(operacao) {
      let mensagemOperacao = "";

      if (operacao == 'S') {
        mensagemOperacao = "Ativar a Conta do Profissional?";
      } else {
        mensagemOperacao = "Inativar a Conta do Profissional?"
      }

      this.$bvModal
        .msgBoxConfirm(mensagemOperacao, {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            /*
                [
                  {"op" : "replace", "path" : "/ativo", "value" : "S"},
                  {"op" : "replace", "path" : "/operadorAlteracaoId", "value" : 10}
                ]
            */

            let externo = operacao == "S" ? "N" : "S";

            let patchJSON = [
                              {op : "replace", path : "/Ativo", value : operacao},
                              {op : "replace", path : "/Externo", value : externo},
                              {op : "replace", path : "/OperadorAlteracaoId", value : this.usuarioLogadoDados.profissionalId}
                            ];

            this.$http.patch(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}/alterarAtivo/${this.profissionalSelecionado.id}`, patchJSON)
            .then(() => {
              this.$toasted.global.defaultSuccess();
              this.erro = "";
              this.modo = "";

              this.chamarCarregarProfissionais();
              // this.profissionalSelecionado.ativo = operacao;
              // this.profissionalSelecionado.externo = externo;

              // let indice = this.profissionais.filter(p => p.id == this.profissionalSelecionado.id);
              // profissionais[indice].ativo = operacao;
              // profissionais[indice].externo = externo;
            })
            .catch(error => this.erro = errors.tratarErros(error));
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    alterarProfissionalExterno(operacao) {
      let mensagemOperacao = "";

      if (operacao == 'S') {
        mensagemOperacao = "Marcar Profissional como Externo?";
      } else {
        mensagemOperacao = "Marcar Profissional como Excluído?";
      }

      this.$bvModal
        .msgBoxConfirm(mensagemOperacao, {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            /*
                [
                  {"op" : "replace", "path" : "/externo", "value" : "S"},
                  {"op" : "replace", "path" : "/operadorAlteracaoId", "value" : 10}
                ]
            */

            let patchJSON = [
                              {op : "replace", path : "/Externo", value : operacao},
                              {op : "replace", path : "/OperadorAlteracaoId", value : this.usuarioLogadoDados.profissionalId}
                            ];

            this.$http.patch(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}/alterarExterno/${this.profissionalSelecionado.id}`, patchJSON)
            .then(() => {
              this.$toasted.global.defaultSuccess();
              this.erro = "";
              this.modo = "";

              this.chamarCarregarProfissionais();
              // this.profissionalSelecionado.externo = operacao;

              // let indice = this.profissionais.filter(p => p.id == this.profissionalSelecionado.id);
              // profissionais[indice].externo = operacao;
          })
            .catch(error => this.erro = errors.tratarErros(error));
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    chamarCarregarProfissionais() {
      this.carregarProfissionaisPeloPerfil(this.usuarioLogadoDados.tenant, this.perfilId, "ativo", this.selecionarAtivos, this.page);
    }
  },
  created() {
    this.showMenu(false);
    this.carregarPerfis(this.usuarioLogadoDados.tenant);

    if (this.ultimaBusca.perfilId != undefined) {
      this.perfilId = this.ultimaBusca.perfilId;
      this.selecionarAtivos = this.ultimaBusca.selecionarAtivos;
      this.page = this.ultimaBusca.page;
      this.count = this.ultimaBusca.count;
      this.limit = this.ultimaBusca.limit;
      this.perfilSelecionadoBusca = this.ultimaBusca.perfilSelecionado;

      this.chamarCarregarProfissionais();
    }

    /*
      profissional.datCadastro = funcoes.mySqlToJSDateFormat(profissional.datCadastro);
      profissional.datAlteracao = funcoes.mySqlToJSDateFormat(profissional.datAlteracao);
    */

  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
      this.setUltimaBusca(null);
    } else {
      let registro = {
        perfilId : this.perfilId,
        selecionarAtivos : this.selecionarAtivos,
        page : this.page,
        count : this.count,
        limit : this.limit,
        perfilSelecionado : this.perfilSelecionadoBusca
      }
  
      this.setUltimaBusca(registro);
    }

    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
