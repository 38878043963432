const estruturaTabela = {
    id : 0, codAnt : 0, plano : "", datInicioVigencia : null, datFinalVigencia : null,
    padrao : "N", utilizarPadrao : "S", prazoRetorno : 30, codConsulta : "10101012",
    tamMinCarteirinha : 0, tamMaxCarteirinha : 0, valorFilme : 0, valChsUco : 0,
    coefPorteCbhpm : 0, datCadastro : null, datAlteracao : null, 
    operadoraId : 0, operadorCadastroId : 0, operadorAlteracaoId : 0
}

const state = {
    planoSaudeSelecionado : Object,
}

const getters = {
    getPlanoSaudeSelecionado(state) {
        return state.planoSaudeSelecionado;
    },
}

const mutations = {
    setPlanoSaudeSelecionado(state, planoSaude) {
        state.planoSaudeSelecionado = planoSaude;
        state.cadastro = true;
    },
    limparRegistroSelecionado(state) {
        state.planoSaudeSelecionado = {...estruturaTabela}
    },
}

const actions = {
    setPlanoSaudeSelecionado({commit}, planoSaude) {
        commit("setPlanoSaudeSelecionado", planoSaude);
    },

    limparRegistroSelecionado({commit}) {
        commit('limparRegistroSelecionado');
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}