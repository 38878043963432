const state = {
    despesaBrasindiceSelecionada : null,
}

const getters = {
    getDespesaBrasindiceSelecionada(state) {
        return state.despesaBrasindiceSelecionada;
    },
}

const mutations = {
    setDespesaBrasindiceSelecionada(state, registro) {
        state.despesaBrasindiceSelecionada = {...registro};
    },
}

const actions = {
    setDespesaBrasindiceSelecionada({commit}, registro) {
        commit("setDespesaBrasindiceSelecionada", registro)
    },

    formLeave({commit}) {
        commit("setDespesaBrasindiceSelecionada", null);
    }
}

export default {
    namespaced : true,
    state, 
    getters,
    mutations,
    actions
}