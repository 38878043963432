const state = {
    descricaoSistema : 'DoctorOn - Gestão de Clínicas e Consultórios Médicos',
    versaoSistema: '2023-01-01-01'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}