<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Contratados</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Contratado</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="contratado.id" />
          </div>

          <div class="form-group col-lg-5">
            <label for="Contratado" class="control-label">Contratado</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Contratado"
              name="Contratado"
              v-model="contratado.nome"
              aria-describedby="ContratadoHelp"
            />
            <small id="ContratadoHelp" class="form-text text-muted">Nome completo do Contratado.</small>
          </div>

          <div class="form-group col-lg-1">
            <label for="CNES" class="control-label">CNES</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="CNES"
              name="CNES"
              v-model="contratado.cnes"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="ativo" class="control-label">Ativo:</label>
            <select
              id="ativo"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="contratado.ativo"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="Contato" class="control-label">Contato</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Contato"
              name="Contato"
              v-model="contratado.contato"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="Fone" class="control-label">Fone</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Fone"
              name="Fone"
              v-model="contratado.fones"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="Email" class="control-label">Email</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Email"
              name="Email"
              v-model="contratado.email"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-8">
            <label for="Email" class="control-label">Observação</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Observacao"
              name="Observacao"
              v-model="contratado.observacao"
            />
          </div>
        </div>
        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger ml-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>
      
      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" class="col-lg-9" hover striped small sort-icon-left :items="contratados" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-1">
              <i class="fa fa-pencil"/> 
            </b-button>  
            <b-button variant="info" size="sm" @click="operadorasRelacionadas(row.item)" class="mr-1">
              Operadoras
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import contratados from "@/mixins/contratados";
import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  mixins: [contratados, global],
  data() {
    return {
      modo: "",
      erro: "",
      mensagem : "",      
      loading: true,
      contratado: {},
      camposTabela: [
        { key: "nome", label: "Contratado" },
        { key: "cnes", label: "CNES" },
        { key: "fones", label: "Fone" },
        { key: "email", label: "Email" },
        { key: "actions", label: "Ações" }
      ],
      sim_nao: constantes.sim_nao,
    }
  },
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    }
  },
  methods: {
    ...mapActions({
      setContratadoSelecionado : "contratados/setContratadoSelecionado"
    }),
    retornar() {
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.contratado = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },    
    selecionouRegistro(contratado) {
      this.contratado = { ...contratado }
      this.modo = 'Registro';
    },
    operadorasRelacionadas(contratado) {
      this.setContratadoSelecionado(contratado);
      this.$router.push("/frmContratadoOperadoras");
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
          this.contratado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

          this.$http
            .put(`/v1.0/contratados/${this.usuarioLogadoDados.tenant}/${this.contratado.id}`, this.contratado)
            .then(() => {
              this.erro = "";

              this.limparCampos();
              this.carregarContratados(this.usuarioLogadoDados.tenant);
              this.$toasted.global.defaultSuccess();
          })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {});
      } else {
         this.contratado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
         this.contratado.id = 0;

         this.$http
           .post(`/v1.0/contratados/${this.usuarioLogadoDados.tenant}`, this.contratado)
           .then(() => {
             this.erro = "";
             this.limparCampos();
             this.carregarContratados(this.usuarioLogadoDados.tenant);
             this.$toasted.global.defaultSuccess();
           })
           .catch(error => {
             this.erro = errors.tratarErros(error);
           })
           .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`/v1.0/contratados/${this.usuarioLogadoDados.tenant}/${this.contratado.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarContratados(this.usuarioLogadoDados.tenant);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.contratado = {}
      this.modo = "";
      this.erro = "";
    },
    verificarCamposPreenchidos() {
      if (this.contratado.nome === "" || this.contratado.nome === undefined) {
        this.$toasted.show("Digite o Nome do Contratado", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.contratado.cnes === "" || this.contratado.cnes === undefined) {
        this.$toasted.show("Digite o CNES do Contratado", {type : 'info', icon : 'times'});
        return false;
      }

      this.contratado.numero = this.contratado.numero == null ? 0 : this.contratado.numero;

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    limparCampos() {
      this.contratado = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    }
  },
  created() {
    this.showMenu(false);
    this.carregarContratados(this.usuarioLogadoDados.tenant);
  }
}
</script>

<style scoped>
</style>
