import errors from "@/utils/errors";

export default {
    data() {
        return {
            consultorios : []
        }
    },
    methods : {
        carregarConsultorios(tenant) {
            this.loadingConsultorios = true;
            this.loadedConsultorios = false;
    
            this.$http.get(`/v1.0/consultorios/${tenant}`)
            .then(resp => {
                this.consultorios = resp.data;
                this.erro= ""
                this.loadedConsultorios = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loadingConsultorios = false);
        }
    }
}
