import errors from "@/utils/errors";

export default {
    data() {
        return {
          count : 0,
          limit : 0,
          tabelaProcedimentos : []
        }
    },
    methods : {
      carregarTabelasProcedimentos(pagina) {
            this.$http
              .get(`/v1.0/tabelaprocedimentos/pagina/${pagina}`)
              .then(resp => {
                this.tabelaProcedimentos = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.erro = "";
                this.loading = false;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
          }
      }
}
